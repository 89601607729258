import styled from 'styled-components'


export const AlertSelect = styled.div`
  background: #2B45D4;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 21px;
  height: 56px;
  margin-bottom: 32px;
  color: #fff;

  div:first-child{
    display: flex;
    align-items: center;
    gap: 24px;

    svg, img {
      cursor: pointer;
    }

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
    }
  }

  div:last-child{
    display: flex;
    align-items: center;
    gap: 51px;

    svg, img {
      cursor: pointer;
    }
  }

`