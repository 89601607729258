import styled from "styled-components";


export const Subtile = styled.h4`
font-size: 14px;
line-height: 24px;
font-weight: 600;
color: var(--life-neutral-black-56);
 margin-bottom: 8px;
`

export const SelectWrapper = styled.div`
 margin-bottom: 24px;
 
`

export const Description = styled.p`
 margin-bottom: 8px;

`

export const TextArea = styled.textarea`
    outline: none;
    resize: none;
    min-height: 104px ;
    width: 100% ;
    padding: 12px 16px ;
    margin: 32px 0 ;
    border-radius: 4px ;
    background: #FFFFFF;
    font-size: 16px ;
    line-height: 24px;
    font-family: 'Open Sans';
    color: rgba(0, 0, 0, 0.88);
    
    border: 1px solid rgba(0, 0, 0, 0.16);
    ::placeholder{
        color: rgba(0, 0, 0, 0.56);
    }

    :focus {
        outline: 2px solid #2B45D4;
    }

    :valid + label, 
    :focus + label {
        transform: translateY(-25px);
        font-size: 12px ;
        color: #000;
    }
    
`