import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    /* gap: 87px; */
    min-width: 817px;
    margin-top: 24px;
    border-radius: 8px; 
`

export const Flex = styled.div`
  display: flex ;
`


export const Left = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column ;
  gap: 16px;
`
export const Right = styled.div`
  display: flex;
  flex-direction: column ;
  flex: 1;
  gap: 16px;
`

export const ContainerRadios = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Tupla = styled.div`
  display: flex;
  flex-direction: column ;
`

export const TextWithIconContainer = styled.div`
`

export const Text = styled.p`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);

  span {
    margin-left: 23px;
  }
`
export const TextResponse = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
`

export const Title = styled.h1`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    color: #2B45D4;
    margin-bottom: 24px;
`
export const Subtitle = styled.h1`
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);
`

export const Content = styled.div`
  max-width: 816px;
`

export const TextInfo = styled.p`
    font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.88);
`

export const Icon = styled.span`
  cursor: pointer;
`

export const TextIdentify = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.64);
`