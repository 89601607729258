export function GET_USER_ME() {
  return {
    url: process.env.REACT_APP_API_URL_TELEHEALTH + `insured/me`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
      },
    },
  };
}
