import styled from 'styled-components';

type opacity = {
  type?: boolean;
}

export const container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #1e1e1e;
  position: relative;
  align-content: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 640px ) {
    padding: 6rem 1rem !important;
    gap: 16px;

  }

  .InfoTop{
    position: absolute;
    top: 2rem;
    left: 2rem;
    color: #FFF;
    z-index: 999;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;

    span {
      display: block;
      color: #FFF;
    }
  }
`;

export const gradienteTop = styled.div`
  width: 100%;
  height: 6.125rem;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
`;

export const localParticipant = styled.div`
  width: 9.5rem;
  height: 5.375rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 9999;

  @media(max-width: 450px){
    top: 4rem;
  }
`;

export const remoteParticipant = styled.div<opacity>`
  opacity: 1;
`;

export const oneLocal = styled.div`
  max-width: 420px;
  max-height: 236px;
  min-width: 302px;
  min-height: 170px;
  position: relative;

  @media (max-width: 640px ) {
    min-width: 150px;
    min-height: 200px;
    flex: 1;
  }
`;

export const oneRemote = styled.div`
  max-width: 420px;
  max-height: 236px;
  min-width: 302px;
  min-height: 170px;
  position: relative;


  @media (max-width: 640px ) {
    min-width: 150px;
    min-height: 200px;
    flex: 1;
  }
`;

export const gradienteBotton = styled.div`
  width: 100%;
  height: 10.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
`;

export const userIconContainer = styled.div`
  width: 115px;
  height: 115px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg path {
    fill: #ddd;
  }

  svg {
    width: 115px;
    height: 115px;
  }
`;

export const localMedia = styled.div`
  z-index: 999;
  position: fixed;

  width: 9.5rem;
  height: 5.375rem;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  svg path {
    fill: #777;
  }
`;

export const localMedia2 = styled.div`
  .info {
    position: absolute;
    width: 100%;
    height: 20%;
    top: 0;
  }
  z-index: 999;
  position: absolute;

  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 226px;
  background: #555;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  svg path {
    fill: #777;
  }

  svg {
    width: 50px;
    height: 50px;
  }
`;

export const localMediaBackground = styled.div`
  z-index: 999;
  position: absolute;
  top: 0;
  opacity: ${({ theme }) => (theme ? '0' : '0')};

  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 226px;
  background: #555;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  svg path {
    fill: #ddd;
  }

  svg {
    width: 50px;
    height: 50px;
  }
`;

export const info = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  display: flex;
  color: #fff;
  flex-direction: column;
  text-transform: capitalize;
  z-index: 99999;

  font-size: 16px;
  line-height: 24px;
  font-weight: 700;

  @media(max-width: 640px){
    bottom: 6rem;
  }
`;

export const trackButtons = styled.div`
  width: 100%;
  /* outline: 1px solid orange; */
  max-width: 320px;
  left: 50%;
  transform: translate(-50%, 0);
  height: 3.5rem;
  position: fixed;
  bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;

  .wrapper-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
  }

  .dropUp {
    position: absolute;
    right: -70px;
    top: -140px;

    @media(max-width: 450px){
      right: 0;
  }
  }
`;

export const containerBtns = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around ;
`;

export const description = styled.p`
  max-width: 416px;
  margin: 24px 0;

  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.56);
`;

export const containerModal = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  margin-top: 8px;
`;

export const banner = styled.div`
  width: 100%;
  padding: 1rem 10%;
  position: fixed;
  top: 0;
  z-index: 9999;

`;


//=========== Container Screen for responsive
export const ContainerScreen = styled.div`
  display: flex;
  flex-direction: row ;

  @media(min-width: 600px){
  }
`