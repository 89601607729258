import styled from 'styled-components'

export const Section = styled.section`
    display: flex;
    justify-content: space-between ;
`
export const Center = styled.section`
    width: 100vw;
    padding: 16px;
    max-width: 800px;
    text-align: center;
    position: relative;
    display: flex;
    overflow: hidden;
`

export const Article = styled.article`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s linear;

  &.activeSlide {
  opacity: 1;
  transform: translateX(0);
  }
  &.lastSlide {
    transform: translateX(-100%);
  }
  &.nextSlide {
    transform: translateX(100%);
  }
`

export const Box = styled.div`
  position: absolute;
  top: 20px;
  transform: translateY(-50%);
  background: hsl(209, 36%, 84%);
  color: #fff;
  width: 8.25rem;
  height: 2.25rem;
 
  border-color: transparent;
  font-size: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s linear;
`

export const List = styled.div`
  height: 100% ;
  display: flex;
  gap: 8px;
`
export const ContainerBtns = styled.div`
  margin-left: 8px;
  width: 48px;
  display:flex;
  gap: 8px;
`
export const Button = styled.button`
  width: 50% ;
  background-color: transparent;
  /* path {
    fill: blue;
  } */
`