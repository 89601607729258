import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 72px;
  margin-bottom: 24px ;

`

export const Div = styled.div`

`

export const Card = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column ;
  gap: 16px ;
  padding: 16px ;
  max-width:  504px;
  border-radius: 8px;
  background-color: #fff;
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2B45D4;
`

export const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
`