import styled from 'styled-components'

export const Container = styled.div`
    height: 100vh;
    width: 100%;
    background-color: var(--fundo);
    /* padding: 12px 14px; */
    
    @media (min-width: 768px){
      /* padding: 24px 67px; */
    }
`

export const Header = styled.div`
  margin-bottom: 32px;
`

export const PrescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (min-width: 768px){
      flex-direction: row;
    }
`

export const Left = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 16px;
    border-radius: 8px;
    width: 100%;

  @media (min-width: 768px){
    width: 76%;
  }
`

export const Title = styled.p`
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 8px;
`

export const ContainerTexts = styled.div`
`
export const Text = styled.p`
  font-weight: 400;
  color: rgba(0, 0, 0, 0.64);
  font-size: 1rem;
`

export const Right = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;

  @media (min-width: 768px){
    /* background-color: var(--fundo); */
    background-color: #fff;
    width: 24%;
  }
`

export const InfoDoctor = styled.div`

`
export const ContainerIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover{
      cursor: pointer;
    }
`

export const ContainerDatas = styled.div`
 display: flex;
 gap: 32px;
flex-direction: column;
`