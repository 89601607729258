import styled  from "styled-components";

export const ContentHome = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-Content: space-between;
  padding-bottom: 50px;
  /* background-color: yellow; */
  padding: 0 1rem;
 
  .main {
    width: 752px;

    section:nth-child(2) {
      height: 144px;
      /* background: yellow; */
    }

    section:nth-child(3) {
      height: 220px;
    }
  }

  .schedule {
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    padding-left:40px ;

    width: 439px;
    display: flex;
    flex-direction: column;
    
    .headerConsult {
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      p {
        padding: 0;
      }

      a {
        text-decoration: none;
        color: #FF0073;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  @media (max-width: 1280px) {
    /* background-color: red; */
    /* padding: 0 24px; */
    .main {
      .contentLevelCard {
        /* padding: 0 24px; */
      }
    }
    
  }


  @media(max-width:870px) {
    .main {
      width: 100%;

    
    }
    .schedule {
      display: none;
    }
  }


  .sectionPrescription {
    @media (max-width: 400px) {
    margin-bottom: 2rem;
  }
  }
`

export const Title = styled.p `
  font-size: 18px;
  font-weight: 700;
  color: var(--primary);
  margin-bottom: 1rem;

  @media (max-width: 1280px) {
    /* padding: 0 24px; */
  }
`