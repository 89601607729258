import Button from 'components/Button/Button';
import CardExams from 'components/CardExams';
import InputSearch from 'components/InputSearch';
import Modal from 'components/Modal';
import NoData from 'components/NoData/NoData';
// import Tag from 'components/Tag';
// import AlertSelect from 'pages/Exams/AlertSelect';
import React from 'react'
import * as S from './styles'
import IconFilter from "../../../../../../assets/Filter.svg";
import LifeLottie from "pages/Home/HomeContent/LifeLottie";
import load from "../../../../../../assets/Animation/load.json";
// import { useNavigate } from 'react-router-dom';
import { ExamProps } from 'pages/Exams/ListExams';
import { useConsultsContext } from 'contexts/ConsultsContext';
import { getExams } from 'api/intancesAxios/docs';
import { GET_DATA_ACCOUNT } from 'api/Services/Profile';
import { ReactSVG } from 'react-svg';

type Props = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleShareExams: (exams: any[]) => void

}


const ShareExam = ({ showModal, setShowModal, handleShareExams }: Props) => {

  const [CRUD, ] = React.useState(false)
  const [, setPulicId] = React.useState('')
  //Exames
  const [examsData, setExamsData] = React.useState<ExamProps[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [search, setSearch] = React.useState("");
  const [filter, ] = React.useState('');
  
  //modal
  const [, setShowModalExames ] = React.useState(false)
  
  //parte de seleção
  const [, setHasExamSelected] = React.useState(false)
  const [examsSelecteds, setExamsSelecteds] = React.useState<ExamProps[]>([])

  //forçar da desmarcacao do checkbox
  const [forceUnmark,] = React.useState<boolean | null>(null)
  const [noData, setNoData] = React.useState(false)


  // Enpoints Exames ========================================
  async function obterExames(search: string, page: number, filter:any, publicId?:string) {
    setLoading(true)
    setNoData(false)
    getExams(search, publicId as string, filter).then((data) => {
      const {data : {fileDto}} = data
      // significa que é o array geral sem dados e SEM FILTRO
      if(!filter && fileDto.length === 0 && !search){
        setNoData(true)
        return
      }
      setExamsData(fileDto)
      return fileDto
    }).finally(() => {
      setLoading(false)
    })
  }

  const checkMarkers = (exam: ExamProps) => {
    if(examsSelecteds.length > 0){
      let res =   examsSelecteds.find(ex => exam.publicId === ex.publicId)
      if(res){
        return true
      }
    }
    return false
  }

 

  React.useEffect(() => {
    // run initial data without filters
    const getPublicId = async() => {
      try {
        const { url, options } =  GET_DATA_ACCOUNT()
        const response = await fetch(url, options)
        const json = await response.json()
        if (response.ok) {
         setPulicId(json.publicId)
         obterExames(search, 1, filter, json.publicId)
        
        }
      } catch(err) {
       console.log(err)
      }
    }
    getPublicId()
  }, [search,filter, CRUD])

  return (
    <div>
      <Modal title={''} isOpen={showModal} style={{ padding: '24px', width: '90%', height: '90%', backgroundColor: '#f6f6f9' }} onClose={() => {
        setShowModal(false)
      }}>
        <S.Wrapper>
          { ((!loading && (examsData.length > 0 || search.length > 0) && !noData)) &&
          <>
            <div className="HeaderContent">
              <h2>Exames</h2>
            </div>

            <div className="contentSearch">
              <div className="ContentInput">
                <InputSearch 
                  onChange={e => setSearch(e.target.value)}
                  placeholder={"Procurar"}
                  backgroundColor="#FFCC00"
                  color="#000"
                  value={search}

              />
              </div>

              <div className="contentFilter" onClick={() => setShowModal(true)}>
                <ReactSVG src={IconFilter} />
                <span>Filtrar</span>
              </div>
            </div>
        
            {/* Cards de exames */}
            <div className='ContentItem'>
              {
            (examsData.length > 0) ? examsData.map(exam => {
            return <CardExams
              showSeeFile={false}
              style={{minWidth: '295px'}}
              exam={exam}
              marked={checkMarkers(exam)}
              forceUnmark={forceUnmark}
              examsSelecteds={examsSelecteds}
              setExamsSelecteds={setExamsSelecteds}
              select={true}
              setSelect={setHasExamSelected}
              remove={() => {}}
              rename={() => {}}
              id={exam.publicId}
              key={exam.publicId}
              archive={exam.documentName}
              laboratorio={''}
              date={exam.registerDate} 
          />})
          :
           ((examsData.length === 0 )|| search) && <div style={{
            gridColumn: "1 / 4",
          }}>
             <p>
               Não encontramos nenhum resultado para esta busca. Que tal tentarmos de novo?
             </p>
           </div>
          }
            </div>

            {/* Botão de compartilhar */}
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button disabled={loading || examsSelecteds.length === 0} 
                onClick={() => {
                  // console.log(examsSelecteds)
                  handleShareExams(examsSelecteds)}} 
                style={{minWidth: '366px', marginTop: '40px'}} 
                variant='primary' 
                children='Compartilhar'/>
            </div>
          </>

          }

          {/* Carregando */}
          {
          !loading && noData && <NoData
            onClickFirstButton={()=> setShowModalExames(true)}
            textFirstButton="Guardar exames"
            showSecondButton={true}
            subDrescription={'Guarde seus exames antigos aqui e tenha acesso fácil a eles sempre que precisar!'}/>  
          }
          {/* Amnimação */}
          {
            loading && <S.Loading>
              <LifeLottie animationData={load} height={65} width={65} />
            </S.Loading> 
          }

        
        </S.Wrapper>
     
      </Modal>

    </div>
  )
}

export default ShareExam