import styled from "styled-components";

export const Wrapper = styled.div`
  height: 600px ;
  display: flex;
  flex-direction: column ;
  gap: 24px;
`

export const Container = styled.div`
  margin: 0 auto;
`
export const ContainerImage = styled.div`
  width: 100%;
  height: 80%;
  object-fit: contain;
`

export const ContainerButtons = styled.div`
  display: flex; 
  justify-content: center;
  gap: 24px;
  padding-bottom: 24px;
`

export const Loading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 600px;
`