import styled from 'styled-components'

export const ContentCard = styled.div`
  background: #fff;
  height: 248px;
  width: 100%; 
  padding: 1rem;
  border-radius: 8px;

  > div {
    display: flex;
    margin-bottom: 1rem;

    .information {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;

      > p {
        font-size: 18px;
        color: var(--primary);
        font-weight: 700;
      }

      strong {
        font-weight: 600;
        margin-bottom: 4px;
      }

      span {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.56);
      }
    }
  }

  .textContent {
    height: 95px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.88);
  }

  .link {
      text-align: end;
      color: #FF0073;
      font-weight: 600;
      cursor: pointer;
    }
`