import React from 'react'
import * as S from './styles'
type Props = {
  children: React.ReactNode
  alignSelf: 'flex-end' | 'flex-start' | undefined
  widthMessage?: string
  style?: React.CSSProperties
}
const ContainerMessage = ({children, style, widthMessage, alignSelf}: Props) => {
  return (
    <S.Container style={style}>
      <S.ContainerMessage style={{alignSelf: alignSelf}}>
        {children}
      </S.ContainerMessage>
    </S.Container>
  )
}

export default ContainerMessage