import React from 'react'
import { useConsultsContext } from 'contexts/ConsultsContext';
import useSessionlStorage from 'hooks/useSessionlStorage';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, Button } from 'storybook-maida';
import { RadioGroup, Title, Subtitle, Text, Wrapper, NoSearch } from './styles';

import Radio from '../../../../components/Radio';
import Modal from 'components/Modal';
import { CHECK_RETURN } from 'api/Services/NextConsult';
import { Link } from 'react-router-dom';
import { useUserContext } from 'contexts/UserContext';

export interface ScheduleAppointment {
  id?: string,
  profissional?: string,
  specialityTxt?: string;
  specialtyId?: string;
  schedulingType?: string;
  dateTxt?: string;
  date?: string;
  hour?: string;
  motivo?: string;
}

let scheduleAppointment: ScheduleAppointment = {
  id: '',
  profissional: '', 
  specialityTxt: '',
  specialtyId: '',
  schedulingType: 'Primeira Consulta',
  dateTxt: '',
  date: '',
  hour: '',
  motivo: ''
};

const Specialty = () => {
  const [active, setActive] = React.useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [select, setSelect] = useState(false);
  const [data, setData] = useSessionlStorage('consulta', scheduleAppointment);

  const { getSpecialty, dataConsult } = useConsultsContext();
  const {userData} = useUserContext()

  const [loading, setLoading] = useState<boolean>(false)

  // return attendance date
  const [returnDate, setReturnDate] = useState({return_date: ""}) 

  // const [ idUser, setIdUser ] = useState(null)

  const token = window.localStorage.getItem('telehealth@token') || '';

  const handleTypeScheduling = async (type: string) => {
    const updatedSchedule = {
      ...data,
      schedulingType: type,
      motivo: type === "Primeira Consulta" && ""
    };
    await setData(updatedSchedule);
    if(type === 'Consulta de Retorno'){
      navigate('/consultas-online/eletiva/agendar-retorno')
    }else{
      navigate('/consultas-online/eletiva/confirmar-telefone')
    }
  }

  useEffect(() => {
    getSpecialty(token);
  }, []);
  

  //check return 
  const checkReturn = async (idUser: string ) => {
    try {
      setLoading(true)
      const { options, url } =  CHECK_RETURN(idUser, data.specialtyId)
      const response = await fetch(url, options);
      if(response.ok) {
        const json = await response.json()
        setReturnDate(json)
        setActive(true)
      } else (
        navigate('/consultas-online/eletiva/confirmar-telefone')
      )
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
   
  }

  const filteredSpecialty = dataConsult
    ? dataConsult.filter((data: any) =>
        data.name.toLowerCase().includes(search.toLowerCase()),
      )
    : [];

  const handleSelect = (item: any) => {
    setSelect(true);
    const updatedSchedule = {
      ...scheduleAppointment,
      specialtyId: item.id,
      specialityTxt: item.name,
    };
    setData(updatedSchedule);
  };

  function handleClick() {
    userData.id && checkReturn(userData.id) 
  }

  const navigate = useNavigate();

  return (
    <Wrapper>
      <Title>Agendar consulta</Title>
      <Subtitle>Escolha a especialidade</Subtitle>
      <Text>Para qual especialidade você quer atendimento?</Text>

      <div className='search'>

        <Search
          style={{width: '100%'}}
          onChange={({ target }) => setSearch(target.value)}
          onClick={(e: any) => {}}
          placeholder="Procurar"
          backgroundColor="#2B45D4"
          color="#fff"
          value={search}
          />
      </div>

      <RadioGroup>
        {filteredSpecialty &&
          filteredSpecialty.length > 0 ?
            filteredSpecialty.filter((item: any) => item.availableForElective)
            .map((item: any) => {
              return (
                <Radio
                  key={item.id}
                  groupName="schedule"
                  id={item.name}
                  label={item.name}
                  value={item.name}
                  style={{
                    minWidth: '100%',
                    marginBottom: '8px',
                  }}
                  
                  onChange={() => handleSelect(item)}
                  checked={data.specialityTxt === item.name}
                />
              );
            })
            :
            <NoSearch>
              <p>
                Não encontramos nenhuma especialidade para esta busca. Que tal tentarmos de novo?
              </p>
            </NoSearch>
          }
      </RadioGroup>

      <Button
        disabled={(!select && !!!data.specialityTxt) || loading}
        children={loading ? "Confirmando..." : "Próximo"}
        onClick={handleClick}
        
      />

      {/* Modal */}
      <Modal isOpen={active} onClose={() => {setActive(!active)}} title="Você tem uma consulta de retorno disponível!">
        <p style={{margin: '10px 0 24px 0'}}>
          Até o dia {returnDate?.return_date} você pode fazer um retorno gratuito para essa especialidade
        </p>
        <Button
          children="Agendar retorno gratuito" 
          onClick={()=> {
            handleTypeScheduling("Consulta de Retorno")
          }
          }
        />

        <div style={{margin: '20px 0 10px 0', textAlign: 'center'}}>
          <Link to={''}
            onClick={() => {handleTypeScheduling("Primeira Consulta")}}
            style={{
              color: 'rgba(0, 0, 0, 0.56)',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '24px'  
            }}
          >Agendar nova consulta</Link>
        </div>
      </Modal>
    </Wrapper>
  );
};

export default Specialty;
