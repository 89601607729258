import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column ;
  gap: 24px;
  height: 100%;
`

export const Wrapper = styled.div`

`