import styled from "styled-components";

export const Wrapper = styled.div`
  text-align: center;
  width: 712px;
  height: 396px;
  background: black; 
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .contentVideo { 
    width: 100%;
    height: 100%;

    video {
      height: 396px;
      width: 100%;
      object-fit: cover;
    }
  }

  .controls {
    bottom: 40px;
    position: absolute;
    width: 138px;
    display: flex;
    justify-content: space-between;

    button {
      background: #fff;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      cursor: pointer;
    }
  }

  @media(max-width:950px) {
    width: 100%;
  }
`

export const userIconCOntainer = styled.div`
  position: absolute;
  width: 130px;
  height: 128px;

  svg path {
    fill: #F6F6F9;
  }

  svg {
    width: 115px;
    height: 115px;
  }

`