import React from 'react'
import * as S from './styles'
import CardFile from 'components/CardFile/CardFile';
import {ReactComponent as IconHelp} from '../../../../../assets/mai-ic-help.mono.svg'
import CardNoFile from 'components/CardNoFile';
import { useConsultsContext } from 'contexts/ConsultsContext';

type Props = {
  title: string, 
  idAttendance: string
}

const Exams = ({title, idAttendance}: Props) => {

  const [attachmentFiles, setAttachmentFiles] = React.useState<any[]>()
  const {getFiles} = useConsultsContext()

    // Arquivos ==================
    React.useEffect(() => {
      getFiles(idAttendance).then((r:any) => {
        setAttachmentFiles(r)
      })
    }, [idAttendance]);

  return (
    <S.Container>
      <div style={{display: 'flex', height: 'fit-content'}}>
        <S.Title>{title}</S.Title>
        <IconHelp style={{marginLeft: '8px'}}/>
      </div>
      <S.Box>
        {/* <CardFile title='Title' subtitle='Subtitle' visible={false}/> */}
        {/* card - arquivos ou nenhum arquivo adcionado */}
        {  ( attachmentFiles && attachmentFiles.length > 0) ? attachmentFiles.map((file: any) => {
          return <CardFile onClick={() => { window.open(`${file.url}`, '_blank')}} key={file.key} title={file.name} subtitle={file.createdAtFormatted} visible={file.viewed}/>
        }) : <CardNoFile />}
      </S.Box>
    </S.Container>
  )
}

export default Exams