import { Routes, Route } from "react-router-dom";

import LoginGuard from "guards/LoginGuard";
// COMPONENTES LOGIN
import Login from "pages/Login";
import Home from "../pages/Home";

// CONSULTAS
import AllConsultations from "pages/Consultations/AllConsultations/";
import { Consultations } from "pages/Consultations/";
//Urgência
import ConsultUrgency from "pages/Consultations/ConsultUrgency";
import Urgency from "pages/Consultations/ConsultUrgency/Urgency";
import Number from "pages/Consultations/ConsultUrgency/Number";
import Teleconsultation from "pages/Consultations/ConsultUrgency/Teleconsultation";
import Video from "pages/Consultations/ConsultUrgency/Teleconsultation/Video";
//Agendada
import ConsultScheduled from "pages/Consultations/ConsultScheduled";
import SchedulingConsult from "pages/Consultations/ConsultScheduled/Scheduling/";
import Specialty from "pages/Consultations/ConsultScheduled/Specialty";

// ERROR
import Error from "pages/Error";
import ConfirmConsult from "pages/Consultations/ConsultScheduled/ConfirmConsult";
import ConfirmPhone from "pages/Consultations/ConsultScheduled/ConfirmPhone";

// Conferencia
import Conference from "pages/Consultations/Conference"
import AppointmentHistory from "pages/AppointmentHistory/index";
import Appointment from "pages/AppointmentHistory/Appointment";
import Details from "pages/AppointmentHistory/Details";


// Prescrição
import PrescriptionDetails from "pages/Prescription/PrescriptionHistory/DetailsPrescription";
import HistoryPrescription from "pages/Prescription/PrescriptionHistory/PrescriptionHistory";
import List from 'pages/Prescription/PrescriptionHistory/List/List'


import Qrcode from "pages/Prescription/Qrcode";
import { routesLogin } from "pages/Login/login.routes";
import InQueueGuard from "guards/InQueueGuard";
import AuthGuest from "pages/Consultations/Conference/AuthGuest";
import FinishCall from "pages/Consultations/Conference/AuthGuest/FinishCall";
import FinishAttendance from "pages/Consultations/Conference/AuthGuest/FinishedAttendance";
import ConsultDetalhes from "pages/Consultations/ConsultDetalhes";
import Avaliation from "pages/Consultations/Avaliation";
import ConferenceGuest from "pages/Consultations/Conference/ConferenceGuest";
import ReturnConsult from "pages/Consultations/ConsultScheduled/ReturnConsult";
import MyProfile from '../pages/MyProfile/index';

// Faq
import Faq from "pages/Faq/Faq";
import ContactUs from "pages/Faq/ContactUs";
import SeeMore from "pages/Faq/SeeMore/SeeMore";
import FullHelp from "pages/Faq/FullHelp/FullHelp";
import Options from "pages/Faq/ContactUs/Options";
import OpenRequest from "pages/Faq/ContactUs/OpenRequest";
import OpenComplaint from "pages/Faq/ContactUs/OpenComplaint";
import TakeQuestions from "pages/Faq/ContactUs/TakeQuestions";
import SendSuggestion from "pages/Faq/ContactUs/SendSuggestion";
import TalkToOmbudsman from "pages/Faq/ContactUs/TalkToOmbudsman";
import FeedbackMessage from "pages/Faq/ContactUs/FeedbackMessage";
import Onboarding from "pages/Onboarding";
import EmailGuard from "guards/EmailGuard";

// Exames
import Exams from "pages/Exams";
import ListExams from "pages/Exams/ListExams";
import ShareExam from "pages/Exams/ShareExam";
import ShowExam from "pages/Exams/ShowExam";
import Auth from "pages/Login/Auth";

// Messages
import Messages from "pages/Messages";
import Avaliacao from "pages/Avaliacao";
import Finaliza from "pages/Avaliacao/Finaliza";

const AppRoutingModule = () => {

  return (
    <Routes>
      {/* <Route path="teste" element={<Avaliacao/>}/>
      <Route path="teste2" element={<Finaliza/>}/> */}
      <Route path="auth/:id" element={<Auth/>}/>
      <Route path="auth" element={<Auth/>}/>

      {/* Login */}
      <Route element={<Login />}>
        {routesLogin}
      </Route>
      <Route path="/" key={"verificacao"} element={<EmailGuard />}>
        <Route path="onboarding" element={<Onboarding ordem={true} />} />
      </Route>

      {/* Fim do Login */}

      <Route path="atendimento-encerrado" element={<FinishAttendance/>}/>
     
      <Route path="convite" >
        <Route index={true} element={<AuthGuest />}/>
        <Route path="conferencia" element={<ConferenceGuest />}/>
        <Route path="finalizarChamada" element={<FinishCall/>}/>
      </Route>

      <Route path="/" element={<LoginGuard />}>
        {/* Home */}
        <Route path="home" element={<Home />}/>
        <Route path="mensagens" element={<Messages />}/>
        
        {/* Atendimento */}
        <Route path="conferencia" element={<Conference/>}/>
        <Route path="finalizarChamada" element={<FinishCall/>}/>
       
        <Route path="avaliacao/:id" element={<Avaliacao/>}/>
        <Route path="avaliacao/finalizada" element={<Finaliza/>}/>

        
        
        {/* Historico de Prescrições */}
        <Route path="prescricoes/*" element={<HistoryPrescription/>}>
          <Route index element={<List/>}/>
          <Route path="detalhes/:id" element={<PrescriptionDetails/>}/>
          <Route path="detalhes/:id/qrcode" element={<Qrcode/>}/>

        </Route>
      
        <Route path="detalhes/:id" element={<ConsultDetalhes/>} />


        {/* Agendamentos */}
        <Route path="consultas-online/*" element={<Consultations />}>
          <Route path="" element={<AllConsultations />} />

          {/* consulta urgente */}

          <Route path="" element={<InQueueGuard/>}>
            <Route path="urgencia/selecionar-especialidade" element={<ConsultUrgency />}>
              <Route path="" element={<Urgency />} />
              <Route path="confirmar-telefone" element={<Number />} />
            </Route>
          </Route>
          
          <Route path="teleconsulta" element={<Teleconsultation />}>
            <Route path="" element={<Video />} />  
          </Route>

          {/* consulta agendada */}
          <Route path="" element={<InQueueGuard />}>
            <Route path="eletiva/*" element={<ConsultScheduled />}>
              <Route path="selecionar-especialidade" element={<Specialty />} />
              <Route path="confirmar-telefone" element={<ConfirmPhone />} />
              <Route path="agendar-consulta" element={<SchedulingConsult />} />
              <Route path="confirmar-agendamento" element={<ConfirmConsult />} />
              <Route path="agendar-retorno" element={<ReturnConsult />} />
            </Route>  
           
          </Route>

          <Route path="*" element={<Error ordem={false} isComponent={true} />}/>
        </Route>

        
        <Route path="profile"  element={<MyProfile />}/>

        <Route path="faq">
          <Route path="" element={<Faq/>}/>
          <Route path="ver-mais" element={<SeeMore/>}/>
          <Route path="fale-conosco" element={<ContactUs/>}/>
          <Route path="help" element={<FullHelp/>}/>
          <Route path="fale-conosco" element={<ContactUs/>}>
            <Route path="" element={<Options/>}/>
            <Route path="solicitacao" element={<OpenRequest/>}/>
            <Route path="reclamacao" element={<OpenComplaint/>}/>
            <Route path="duvida" element={<TakeQuestions/>}/>
            <Route path="sugestao" element={<SendSuggestion/>}/>
            <Route path="ouvidoria" element={<TalkToOmbudsman/>}/>
          </Route>
          <Route path="enviado" element={<FeedbackMessage/>}/>
        </Route>

        {/* Historico */}
        {/* Historico de Consultas*/}
        <Route path="historico/*" element={<AppointmentHistory />}>
          <Route index element={<Appointment />} />
          <Route path="details/:id" element={<Details />} />
        </Route>

        <Route path="exames/*" element={<Exams />}>
          <Route index element={<ListExams />} />
          <Route path="compartilhar" element={<ShareExam />} />
          <Route path="visualizar-documento/:id" element={<ShowExam />} />
          
        </Route>

        {/* Erro */}
        <Route path="*" element={<Error ordem={false} />}></Route>

      </Route>
     
    </Routes>
  );
};

export default AppRoutingModule;
