import React, { useState } from 'react';
import * as S from './styles';
import { ReactSVG } from 'react-svg';
import IconVideo from '../../../assets/mai-ic-video-true.svg';
import IconQuery from '../../../assets/query.svg';
import IconSchedule from '../../../assets/schedule.svg';
import IconCruz from '../../../assets/cruz.svg';
import IconDoctor from '../../../assets/doctor.svg';
import IconCRM from '../../../assets/CRM.svg';
import { ReactComponent as IconDoc } from '../../../assets/ic-document.svg';
import { ReactComponent as IconCardioGram } from '../../../assets/ic-cardiogram-rounded.svg';
import { ReactComponent as Arrow } from '../../../assets/arrowFoward.svg';
import { ReactComponent as ArrowExpanded } from '../../../assets/arrow-expanded.svg';
import { ReactComponent as SendFile } from '../../../assets/send-file-ilustration.svg';
import Badge from 'components/Badge';
import CardNoFile from '../../../components/CardNoFile/index';
import CardFile from 'components/CardFile/CardFile';
import { useParams } from 'react-router-dom';
// import { FIND_ATTENDANCE } from 'api/Services/Attendance';
import { propsData } from './types';
import { BackgroundStatus, textColor, TextStatus } from '../Appointment/types';
import Card from 'pages/Prescription/PrescriptionHistory/DetailsPrescription/Card';
import { FileInput } from 'storybook-maida';
import { useConsultsContext } from 'contexts/ConsultsContext';
import Modal from 'components/Modal';

import Button from '../../../components/Button/Button'
import AddFile from '../../../components/File'
import * as SF from './stylesFile'
import * as SCard from '../../Prescription/PrescriptionHistory/DetailsPrescription/Card/styles' 
import IconLife from 'components/Icon';
import {ReactComponent as IconSuccess} from '../../../assets/mai-ic-success.mono.svg'
import {ReactComponent as IconClose} from '../../../assets/ic-close.svg'
import {ReactComponent as IconHelp} from '../../../assets/mai-ic-help.mono.svg'
import {ReactComponent as IconMessage} from '../../../assets/message.svg'
import AlertBanner from 'components/AlertBanner/AlertBanner';
import { useNavigate } from 'react-router-dom';
import { CANCEL_APPOINTMENT } from 'api/Services/NextConsult';
import { getDifferenceHours } from 'utils/differenceHours';
import { getHoursServer, putPatientOnline } from 'api/intancesAxios/telehealthURL';
import { useHomeContext } from 'contexts/HomeContext';
import ModalCancelConsult from './ModalCancelConsult';
import { CALL_ENPOINT } from 'api/Services/CallEndpoint';
import { START_ROOM } from 'api/Services/Attendance';


const TIME_DIFFERENCE_TO_START_ATTENDANCE = 10 // 10 minutos

const Details = () => {

  const navigate = useNavigate()
  // Receive attendance id to search details and list 
  const { id } = useParams() as { id: string };

  const [attendance, setAttendance] = React.useState<propsData>();
  const [isExpanded, setIsExpanded] = useState(true);
  const [modal, setModal] = useState(false);
  const [showFile, setShowfile] = useState(true);

  const [submitFile, setSubmitFile] = React.useState(false)
  
  
  //Files
  // Array de arquivos
  const [files, setFiles] = React.useState<File[]>([]);
  // Arquivo adicionado que sera add no vetor 
  const [file, setFile] = React.useState<File | null>(null);
  //arquivos anexados
  // Arquivos anexados em um Atendimento X
  const [attachmentFiles, setAttachmentFiles] = React.useState<any[]>()
  const {shareFile, getFiles, errorHook} = useConsultsContext()
  
  // Save shared files 
  const [filenames, setFileNames] = React.useState<string[]>([])

  //Patient online
  //Datas for patient online implementation 
  const [, setBtnStartAttendance] = React.useState(true)
  const [stayOnline, setStayOnline] = React.useState(false)
  const { setAttendanceData } = useConsultsContext();
  const [, setLoading] = React.useState<boolean>(false);
  const {noticeTele } = useHomeContext()

  // cancel consult 
  const [ activeModalCancel, setActiveModalCancel ] = React.useState<boolean>(false);
  const {setCanceledConsult} = useHomeContext()

  // Add file in files array 
  const addFiles = (file:File) => {
    setFiles([...files, file])
  }

  // Arquivos ==================
  React.useEffect(() => {
    getFiles(id).then((r:any) => {
      setAttachmentFiles(r)
    })
  }, [submitFile]);

  React.useEffect(()  => {
    if(file){
      addFiles(file)
    }
  }, [file])


  // Call differenceHours in 10s
  React.useEffect(() => {

    let idTime: NodeJS.Timeout 
    findAttendacce(id)

    // Verificar notices e posicao a cada 10s
    idTime = setInterval(() => {
        findAttendacce(id)
     }, 10000);

    return () => {
      clearInterval(idTime);
    }
  }, [id]);


  // Methods to crud files 
  const removeFile = (nome: string) => {
    setFiles(files.filter(item => item.name !== nome))
  }

  const handleSubmitFiles = () => {
    
    setFileNames([]) //reseta o vetor de adicionados
    if(!files.length) return 
    
    files.forEach(file => {
      const formData = new FormData()
      formData.append('name', file.name);
      formData.append('file', file as any);

      shareFile(id, formData).then((json: any) => {
        if (json !== null) {
          setFileNames([...filenames, json.name])
          setSubmitFile(true)

        }
      })
    })

   setFiles([])
   setIsExpanded(false)
   getFiles(id)

  }

  // end files ==========================

  function expandWrapper() {
    return isExpanded ? (
      <>
        <S.Text>Ocultar</S.Text>
        <ArrowExpanded />
      </>
    ) : (
      <>
        {' '}
        <S.Text>Expandir</S.Text>
        <Arrow />{' '}
      </>
    );
  }

  async function findAttendacce(id: string | undefined) {
    try {
      // const { url, options } = FIND_ATTENDANCE(id);
      const { url, options } = CALL_ENPOINT('TELEHEALTH_URL',`attendance/${id}`,'GET',null, {
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
      });
      
      const response = await fetch(url, options);
      const json = await response.json();

      let minutes = -1

      if (response.ok) {
        setAttendance(json);

        getHoursServer().then(r => {
          let dif = Math.round(getDifferenceHours(new Date(json.schedulingDate), new Date(r.currentDate)))
          minutes = dif
          if(minutes > 0 && minutes <= TIME_DIFFERENCE_TO_START_ATTENDANCE){
            if(stayOnline === false){
              putPatientOnline(id as string)
              .then(r => {
                setStayOnline(true)
              })
              .catch(e => console.log("erro no paciente online ", e))
            }
         
            // Se o PACIENTE FICA ONLINE, ENTAO TERA ALTERACAO NO STATUS DO BTN DE INCIAR CONSULTA
            if(json.status === "WAITING_PATIENT" || json.status === 'WAITING_INSURED' || json.status === "VIDEOCALL_IN_PROGRESS"){
              setBtnStartAttendance(false)
            }

          }
        
        })
        .catch(error => console.log("deu erro no horario ", error))

      }
    } catch (err) {
      console.log(err);
    }
  }

  // handle animation
  const [btnShareDisabled, setBtnShareDisabled] = React.useState(false)

  const cancelConsult = async () => {
    if(!attendance) return
    attendance.id.toString()
    const { options, url } =  CANCEL_APPOINTMENT(attendance.id.toString())
    try {
      const response = await fetch(url, options)
      if (response.ok) {
        setCanceledConsult(true)
        navigate('/')
      }
    } catch(err) {
      console.log(err)
    }
  }
    // Entra na sala de atendimento
    const enterTheRoom = async () => {
      try {
        setLoading(true);
        const { url, options } = START_ROOM(noticeTele?.metadata?.id);
        // const { url, options } = CALL_ENPOINT('TELEHEALTH_URL',`attendance/${noticeTele?.metadata?.id}/start`,'POST',null,{
        //   Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
        // });

        const response = await fetch(url, options);
        const data = await response.json();
        setAttendanceData(data);
        navigate('/conferencia');
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

  return (
    <S.Wrapper>
      {/* AlertBanner */}
      {(filenames.length !== 0) && <AlertBanner type='success' label='Seus arquivos foram compartilhados' seconds={3}/>}
      {errorHook && <AlertBanner type='danger' label='Erro no envio de um ou mais arquivos' seconds={3}/>}
      {/* Fim Alerts */}

      {/* Header */}
      <div className="HeaderContent">
        <h2>Detalhes da consulta</h2>
        {/* button de inicar chat */}
        <S.ChatButton onClick={() => navigate('/mensagens')}>
          <IconMessage/>
          <S.ChatButtonText>Conversar com profissional</S.ChatButtonText>
        </S.ChatButton>
      </div>
      {/* Fim do Header */}

      <S.BodyContent>
        {attendance && (
          <Badge
            text={TextStatus[attendance.status]}
            color={textColor[attendance.status]}
            background={BackgroundStatus[attendance.status]}
          />
        )}

        {/* Details Appoitment */}
        {attendance && (
          <div className="contentItem">
            <div className="item">
              <ReactSVG src={IconCruz} />
              <span>{TextStatus[attendance.status]}</span>
            </div>

            <div className="item">
              <ReactSVG src={IconSchedule} />
              <span>{attendance.prettySchedulingDate}</span>
            </div>

            <div className="item">
              <ReactSVG src={IconQuery} />
              <span>{attendance.specialty.name}</span>
            </div>
          </div>
        )}
        {/* End Details Appoitment */}


        {/* Div oculta, apenas visível para mobile */}
        {attendance && (
          <div className="contentItem mobile">
            <div className="item">
              <ReactSVG src={IconSchedule} />
              <span>{attendance.prettySchedulingDate}</span>
            </div>
          </div>
        )}

        {/* Details Profissional */}
        <div className="professional">
          <p>Profissional de saúde</p>
          <div className="contentIcon">
            <div>
              <ReactSVG src={IconDoctor} />
              <span>{ attendance?.professional?.name ? attendance?.professional?.name : '-' }</span>
            </div>
            <div>
              <ReactSVG src={IconCRM} />
              <span>{ attendance?.professional?.associationNumber ? attendance?.professional?.associationNumber : '-' }</span>
            </div>
          </div>
        </div>
        {/* End Details Profissional */}

        <div style={{display: 'flex', height: 'fit-content'}}>
          <p style={{ marginBottom: '16px', marginRight: '8px'}}>Arquivos adicionados</p>
          <IconHelp/>
        </div>

        {/* card - arquivos ou nenhum arquivo adcionado */}
        {  ( attachmentFiles && attachmentFiles.length > 0) ? attachmentFiles.map((file: any) => {
          return <CardFile onClick={() => { window.open(`${file.url}`, '_blank')}} key={file.key} title={file.name} subtitle={file.createdAtFormatted} visible={file.viewed}/>
        }) : <CardNoFile />}

        {/* Mostra o upload de arquivo só se for consulta AGENDADA */}
        { ( attendance && attendance?.status !== 'FINISHED' &&  attendance?.status !== 'CANCELED') && <S.Wrappe>
          <S.Header onClick={() => setIsExpanded(!isExpanded)}>
            <div>
              <S.BoldText>
                Quer adicionar um arquivo nesse agendamento?
              </S.BoldText>
            </div>
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              {expandWrapper()}
            </div>
          </S.Header>
          <S.Content isExpanded={isExpanded}>
            <S.Description style={{ marginBottom: '16px' }}>
              Adicione arquivos de exames ou fotos de lesões que possam ajudar
              no diagnóstico durante essa consulta!
            </S.Description>
            <S.ContentWrapper>
              {/* Nao adicionou arquivos ainda */}
              { files.length === 0 ? 
                <>
                  <AddFile setFile={setFile} limitSize={0}/>
                  <S.Other>ou</S.Other>
                  <S.Share onClick={() => navigate('/exames')}>Compartilhe um arquivo do seu histórico</S.Share>
                </>
              :
                <>
                  {/* Mostra a listagem */}
                  <div>
                    <SF.FilesContainer scroll={files.length > 3 ? true : false}>
                      {
                      files && files.map((item, index) => {
                        return <SF.FilesData> 
                          <IconLife icon={<IconSuccess/>} color="#2B45D4"/>
                          <SF.ContainerInfo>
                            <SF.FileName>{item.name}</SF.FileName>
                            <SF.ProgressBar onAnimationStart={() => setBtnShareDisabled(true)}  onAnimationEnd={() => setBtnShareDisabled(false)}></SF.ProgressBar>
                          </SF.ContainerInfo>
                          <IconLife icon={<IconClose/>} onClick={() => removeFile(item.name)} color="red" style={{position: 'absolute', top: 0, right: 0, cursor: 'pointer'}}/>
                        </SF.FilesData>
                      })
                      }
                    </SF.FilesContainer>
                    {/* Add file component */}
                    <AddFile setFile={setFile} limitSize={0}/>
                    <Button onClick={handleSubmitFiles} disabled={btnShareDisabled} variant='primary' style={{width: '100%', padding: '12px 24px', marginTop: '24px'}}>Compartilhar</Button>
                  </div>
                </>
              }
            </S.ContentWrapper>
          </S.Content>
        </S.Wrappe>
        }

        {/* Card - Medicamento */}
        <>
          <SCard.Title style={{marginTop: '32px', marginBottom: '8px'}}>Medicamentos</SCard.Title>
          <Card
            labelFirstButton='Comprar medicamentos'
            labelSecondBtn='Ver prescrição'
            title='Amoxil 100mg/mL, Pó para suspensão oral (1un de 150mL) GSK - Glaxosmithkline'
            subtitle='Amoxilina 100mg/mL'
            quantity='1 embalagem'
            recommendation='Diluir um pacote em 1 litro e tomar de 3 em 3 horas durante 7 dias'
            containerInsiderRow={false}

        />
        </>
        {/* Card Solicitação de Exames */}
        { attendance?.hasExamRequest && 
        <>
          <SCard.Title style={{marginTop: '32px', marginBottom: '8px'}}>Solicitações de exames</SCard.Title>
          <Card
            icon={<IconCardioGram/>}
            category='Solicitações de exames'
            labelFirstButton='Ver solicitações de exames' 
            title='Hemograma completo'
            subtitle='TUSS 40304361'
            quantity='1'
            recommendation='Realizar exame em jejum completo'
            containerInsiderRow={true}
        />
        </>}

        {/* Card Relatórios e Atestados */}
        { attendance?.hasSicknote && 
        <>
          <SCard.Title style={{marginTop: '32px', marginBottom: '8px'}}>Relatórios e atestados</SCard.Title>
          <Card 
            icon={<IconDoc/>}
            category='Relatórios e atestados'
            showHeaders={false}
            labelFirstButton='Ver documento' 
            quantity='1'
            recommendation='Atesto que os fins que o sr. John Doe realizou consulta no dia 01/09/2021 apresentando sintomas de covid e precisará ficar afastado das suas atividades presenciais por 7 dias a partir da data da emissão deste relatório'
            containerInsiderRow={true}
              />
        </>}
      </S.BodyContent>

      {/* Botoes ================================= */}
      {/* se for agendado , pode cancelar */}
      <S.Footer>
        {(attendance?.status === 'SCHEDULED') &&
        <>
          <S.Btn style={{backgroundColor: 'transparent'}} onClick={() => setActiveModalCancel(true)}>
            <span>Cancelar consulta</span>
          </S.Btn>
          <S.Btn style={{border: '2px solid #3453FF', backgroundColor: 'transparent'}} onClick={() => navigate(`/detalhes/${id}`)} >
            <span style={{color: '#3453FF'}}>Reagendar consulta</span>
          </S.Btn>
        </> 
        }
        
        {/* se o paciente ficou online e ta perto do atendimento aparece o btn de iniciar consulta */}
        {(attendance?.status === "WAITING_PATIENT" || attendance?.status === 'WAITING_INSURED' || attendance?.status === "VIDEOCALL_IN_PROGRESS") &&
        <S.Btn style={{backgroundColor: '#3453FF'}} onClick={()=> {
          enterTheRoom() 
        }}>
          <ReactSVG src={IconVideo} />
          <span style={{color: '#fff'}}>Iniciar atendimento</span>
        </S.Btn>}

 
      </S.Footer>

      {/* MODAL CANCELAMENTO */}
      
      {/* modal de cancelamento */}
      <ModalCancelConsult active={activeModalCancel} setActive={setActiveModalCancel} cancelConsult={cancelConsult} />
      
      {/* MODAl ADD ARQUIVOS ====================  */}
      <Modal
        isOpen={modal}
        onClose={() => setModal(!modal)}
        style={{ maxWidth: '416px' }}
      >
        <S.ModalWrapper>
          {showFile && (
            <>
              <h1>Preparando arquivos...</h1>
              <p>Por favor, aguarde</p>
              <div className='icon-file'>
                <SendFile />
              </div>
            </>
          )}

          {!showFile && (
            <>
              <h1>Adicionando 1 arquivo...</h1>
              <p>Por favor, aguarde</p>
              <div className='file-input'>
                <FileInput />
              </div>
              <p>Adicione apenas arquivos em JPEG, JPG, PDF ou PNG, com tamanho máximo de 15MB</p>
            </>
          )}
        </S.ModalWrapper>
      </Modal>
    </S.Wrapper>
  );
};

export default Details;


