import styled from "styled-components";
export const Wrapper = styled.div`

  > h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    color: var(--primary);
    margin-bottom: 24px;
  }

  > p {
    color: rgba(0, 0, 0, 0.88);
    font-family: 'Open Sans';
    font-style: normal;
    line-height: 24px;
  }

  .contentLoad {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 600px;
  }
  .contentSearch {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;

    .ContentInput {
      width: 400px;
      /* max-width: 25rem;  */
      margin-bottom:24px;
    } 

    .contentFilter {
      cursor: pointer;
      width: 86px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > span {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #FF0073;
      }
    }
  }
  
  .ContentItem {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    position: relative;
  }

  @media(max-width: 990px) {
    .ContentItem {
      grid-template-columns: repeat(3, 1fr)
    }
  }

  @media(max-width: 780px) {
    h2 {
      font-size: 24px;
    }
    .ContentItem {
      grid-template-columns: repeat(2, 1fr)
    }
  }

  @media (max-width: 510px) {
    .ContentItem {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media(max-width: 600px) {
    header {
      margin: 24px 0px 24px 0px;
    }
  }
`


export const ContainerSelects = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`

export const ContainerCheckboxs = styled.div`
  display: flex;
  gap: 20px; 
  flex-direction: column; 
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 12px;
`
export const FiltrosContainer = styled.div`
  display: flex;
  gap: 8px ;
  margin-bottom: 32px;
`
export const Sentinela = styled.div`
  width: 100%;
  height: 10px;
  background-color: red;
`
