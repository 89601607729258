import React from 'react'
import styled from 'styled-components'
import './styles.css'

type InputProps = {
    criaCodigo: (pos: string, numero: string) => void
    pos: string,
    value: string,
    refe?: React.RefObject<HTMLInputElement> | null | undefined,
    error: any;
    className: string | undefined
}

const InputCode = React.forwardRef<HTMLButtonElement, InputProps>((props, refs) => {
    
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const numero = e.target.value ? e.target.value : ''
        if(e.target.validity.valid){
            props.criaCodigo(props.pos, numero)
        }
    }
    return (
      <Input className={props.className} error={props.error} ref={props.refe}  type="text" value={props.value} pattern="[0-9]" onChange={handleChange}/>
    )
})
type Props = {
    error: any
}
const Input = styled.input<Props>`
    display: flex;
    text-align: center;
    border: none;
    border: ${(props) => props.error ? "2px solid #F44336" : "1px solid rgba(0, 0, 0, 0.16)"};
    background-color: #fff;
    border-radius: 4px;
    width: 88px;
    height: 56px;
    
    font-size: 1rem;
    line-height: 1.5rem;
    margin-right: 16px;

    &:focus {
        outline: ${(props) => props.error ? "1px solid #F44336" : "1px solid var(--primary)"};
    }
  
`

export default InputCode

