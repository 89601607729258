import React from 'react'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import * as S from './styles'

type Props = {
  key: string
  to: string 
  icon: any 
  title: string 
  src: string
  showNumber: boolean
  numberMsg?: string
}
const NavItem = (props: Props) => {
  return (
    <Link key={props.key} to={props.to}>
      <S.LinkContainer>
        <div>
          {props.icon}
          <span>{props.title}</span>
          {props.showNumber && <S.Count className='numero'>
            <S.Number>{props.numberMsg}</S.Number>
          </S.Count>}
        </div>
        <ReactSVG src={props.src} />
      </S.LinkContainer>
    </Link>
  )
}

export default NavItem