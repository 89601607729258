import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 40px 57px;
  background-color: #f6f6f9;
  display: flex;
  align-items: center;
  flex-direction: column ;
`
export const Container = styled.div`
    margin-top: 46px;
    display: flex;  
    flex-direction: column ;

    padding: 0 24px;
    @media(min-width: 400px) {
        padding: 0;
    }
    
`
export const FormContainer = styled.div`
    width: 100%;
    @media(min-width: 400px) {
        width: 608px ;
    }
`
export const TextsContainer = styled.div`
`
export const Info = styled.div`
    margin-top: 16px ;
`

export const Emojis = styled.div`
    margin-top: 38px;
    display: flex;
    justify-content: center ;
    gap: 50px;

    @media(min-width: 400px) {
      gap: 77px; 
    }

`

export const ContainerEmoji = styled.div`
    &.active {
        background: rgba(0, 0, 0, 0.08);
        border-radius: 8px;
    }
    &:hover {
        background: rgba(0, 0, 0, 0.08);
        border-radius: 8px;
    }
`

export const Content = styled.div`
    display: flex;
    flex-direction: column ;
`

export const Header = styled.div`
    display: flex;
    align-self: flex-end;
`

export const Btnpular = styled.p`
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #2B45D4;
`

export const Title = styled.h2`
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    color: #2B45D4;
`
export const Subtitle = styled.p`
    font-size: 16px ;
    line-height: 24px ;
    color: rgba(0, 0, 0, 0.56);
`
