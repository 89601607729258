import * as S from '../PrescriptionHistory/DetailsPrescription/styles'
import { ReactComponent as IconQrcode } from "../../../assets/ic-qrcode-large.svg";
import { ReactComponent as IconDownload } from "../../../assets/mai-ic-download.mono.svg";
import { ReactComponent as IconInfo } from "../../../assets/mai-ic-info.mono.svg";
import AlertBanner from 'components/AlertBanner/AlertBanner';
import {Content, Info, ContainerInfo, QrcodeContainer, CodeText, ContainerOthersInfos, Text, Link, ContainerRow} from './styles'


const Qrcode = () => {
  return (
    <S.Container>
      <AlertBanner
        seconds={5}
        type='success'
        label='Sua consulta foi reagendada!'/> 

      <Content>
        <ContainerInfo>
          <QrcodeContainer>
            <IconQrcode/>
          </QrcodeContainer> 
          <Info>
            <CodeText style={{textAlign: 'center'}}>Código: AWE1234</CodeText>
            <Link href="airmed.com.br/dispensar">Link: airmed.com.br/dispensar</Link>
            <ContainerOthersInfos>
              <ContainerRow>
                <IconDownload/>
                <Text>Baixar receita assinada</Text>
              </ContainerRow>
              <ContainerRow>
                <IconInfo/>
                <Text>Políticas de privacidade</Text>
              </ContainerRow>
            </ContainerOthersInfos>
     
          </Info>
        </ContainerInfo>
      </Content>
    </S.Container>
  )
}

export default Qrcode