import * as S from './styles'
import { ReactSVG } from 'react-svg'
import IconFile from '../../assets/mai-ic-formpack.svg'

type Props = {
    visible: boolean,
    title: string, 
    subtitle: string,
    onClick?: () => void
}
const CardFile = ({visible, title, subtitle, onClick}: Props) => {
  return (
    <S.Wrapper visible={visible} onClick={onClick}>
      <S.Icon>
        <ReactSVG src={IconFile} />
      </S.Icon>
      <S.InfoContainer>
        <S.Title>{title}</S.Title>
        <S.Subtitle>{subtitle}</S.Subtitle>
      </S.InfoContainer>
    </S.Wrapper>
  )
}

export default CardFile;