import styled from 'styled-components'

export const Container = styled.div`
`
export const Title = styled.p`
    font-size: 16px;
    font-weight: 600;
    
    span {
        color: rgba(0, 0, 0, 0.56);
        font-weight: normal;
    }
`



export const Subtitle = styled.p`
    font-size: 14px;
    color: rgba(0, 0, 0, 0.56);

`

export const Recommendations = styled.p`
    text-align: justify;
    color: rgba(0, 0, 0, 0.88);
    font-size: 16px;
    font-weight: 600 ;
`

export const Quantity = styled.span`

`

export const Subcontainer = styled.div`
   display: flex;

   flex-direction: column;
   padding: 16px;
   background-color: #fff;
   border-radius: 8px;
   /* margin-top: 8px; */

   @media (min-width: 768px){
    flex-direction: row;
   }
`

export const Left = styled.div`
    display: flex;
    margin-right: 32px;
    width: 100%;
`

type Props = {
    containerInsiderRow: boolean
}
export const ContainerInsideTexts = styled.div<Props>`
    display: flex;
    flex-direction: column ;
    gap: 8px; 
    
    @media(min-width: 366px){
      flex-direction: ${({containerInsiderRow}) => containerInsiderRow ? 'row' : 'column'};; 
    }


`
type ContentProps = {
    showHeaders: boolean
}
export const Content = styled.div<ContentProps>`
    display: flex;
    flex-direction: column;
    gap: ${({showHeaders}) => showHeaders ? '8px' : ''};
`

export const Icone = styled.div`
   margin-right: 16px;
`
