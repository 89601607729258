import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Radio from 'components/Radio';
import Button from 'components/Button/Button';

import * as S from './styles';

const Options = () => {
  const [option, setOption] = useState<string>('');

  const navigate = useNavigate();

  const handleChanged = (value: string) => {
    setOption(value);
  };

  const navigateToNextPage = () => {
    switch (option) {
      case 'solicitação':
        navigate('solicitacao');
        break;
      case 'reclamação':
        navigate('reclamacao');
        break;
      case 'dúvida':
        navigate('duvida');
        break;
      case 'sugestão':
        navigate('sugestao');
        break;
      case 'ouvidoria':
        navigate('ouvidoria');
        break;
    }
  };

  return (
    <>
      <S.Description>
        <p>
          Por aqui você pode falar com nossa equipe de atendimento, mas para
          começar, conta pra gente o que você precisa:
        </p>
      </S.Description>
      <S.RadioContainer>
        <Radio
          groupName="options"
          checkedColor="#FF0073"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChanged(event.target.value)
          }
          label="Abrir uma solicitação"
          value="solicitação"
        />
        <Radio
          groupName="options"
          checkedColor="#FF0073"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChanged(event.target.value)
          }
          label="Fazer uma reclamação"
          value="reclamação"
        />
        <Radio
          groupName="options"
          checkedColor="#FF0073"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChanged(event.target.value)
          }
          label="Tirar uma dúvida"
          value="dúvida"
        />
        <Radio
          groupName="options"
          checkedColor="#FF0073"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChanged(event.target.value)
          }
          label="Enviar uma sugestão ou elogio"
          value="sugestão"
        />
        <Radio
          groupName="options"
          checkedColor="#FF0073"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChanged(event.target.value)
          }
          label="Falar com a ouvidoria"
          value="ouvidoria"
        />
      </S.RadioContainer>
      <Button
        onClick={navigateToNextPage}
        variant="primary"
        style={{ width: '100%', marginTop: '24px', padding: '12px 40px' }}
        disabled={!option}
      >
        Próximo
      </Button>
    </>
  );
};

export default Options;
