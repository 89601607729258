import React from 'react'
import * as S from './styles'
import {ReactComponent as Icon} from '../../assets/Backgrounds/prescription.svg'
import Button from '../Button/Button'
import {useNavigate} from 'react-router-dom'
import ButtonOutlined from 'components/Button/ButtonOutlined'


interface props {
  description?: string,
  subDrescription?: string
  textFirstButton?: string
  onClickFirstButton?: () => void
  showSecondButton?: boolean
}

const NoData = ({description, subDrescription, onClickFirstButton, showSecondButton=false, textFirstButton='Voltar para o início'}: props) => {
  const navigate = useNavigate()
  return (
    <S.Container>
      <S.Content>
        <Icon/>
        <S.ContentBox>
          <p style={{textAlign: 'center', marginTop: description ? '31px' : '15px', lineHeight: '24px'}}>
            { description }
            <br/>
            <strong>{subDrescription}</strong>
          </p>
          <Button onClick={() => onClickFirstButton ? onClickFirstButton() : navigate('/home')} variant='primary' style={{padding: '12px 24px', width: '100%', marginTop: '48px'}}>{textFirstButton}</Button>
          { showSecondButton && <ButtonOutlined 
            onClick={() => navigate('/home')} 
            variant='outlined' style={{width: '100%', marginTop: '8px'}}>Voltar para o início</ButtonOutlined>}
        </S.ContentBox>
      </S.Content>
    </S.Container>
  )
}

export default NoData