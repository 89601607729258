import styled from 'styled-components'


export const Title = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.88);
`

export const Container = styled.div`
  display: flex;
  flex-direction: column ;
`

export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
`


export const Emojis = styled.div`
    display: flex;
    margin-top: 24px;
    justify-content: center ;
    gap: 50px;

    @media(min-width: 400px) {
      gap: 50px; 
    }

`

export const ContainerEmoji = styled.div`
    &.active {
        background: rgba(0, 0, 0, 0.08);
        border-radius: 8px;
    }
    &:hover {
        background: rgba(0, 0, 0, 0.08);
        border-radius: 8px;
    }
`
