import styled from 'styled-components';

export const ContentNumber = styled.div`
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 400px;

  .Container {
    margin: 0 auto;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;

    .infomation {
      width: 400px;

      h4 {
        font-size: 1.75rem;
        font-weight: 600;
        color: var(--primary);
        margin-bottom: 32px;
      }

      p {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 16px;
      }

      span {
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.56);
      }

      > div {
        margin-top: 48px;
        margin-bottom: 0.2rem;
        width: 100%;
      }

      .erroNumber {
        color: red;
      }

      Button {
        margin-top: 48px;
      }
    }
  }

  .img-container {
    display: flex;
    justify-content: center;
    padding-right:8rem;
  }

  @media (max-width: 830px) {
    grid-template-columns: 1fr;
    .Container {
      width: 100%;

      .infomation {
        width: 100%;
      }
    }

    .img-container {
      display: none;
    }
  }
`;
