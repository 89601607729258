// import { useUserContext } from 'contexts/UserContext'
import { GET_AUTH_TELE } from 'api/Services/AuthTelehealth'
import { useUserContext } from 'contexts/UserContext'
import useQuery from 'hooks/useQuery'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Screen from './Screen'


const Auth = () => {
  const { setObjectRedirect } = useUserContext()
  const navigate = useNavigate()
  // id do atendimento se for eletivo
  const { id } = useParams()

  //Query params da hapvida type = "ELECTIVE" | "URGENCY"
  const query = useQuery()
  const attendanceType = query.get("type")
  
  React.useEffect(() => {
    if(attendanceType === "ELECTIVE" && id){
      setObjectRedirect({attendanceId: id as string, type: attendanceType})
      // console.log(`VAi detalhes ${attendanceType}`);
      // console.log("ID", id);
    }else if(attendanceType === "URGENCY"){
      // console.log(`VAi para sala de espera de ${attendanceType}`);
      setObjectRedirect({attendanceId: null , type: attendanceType})
    }

    navigate('/')

  }, [])
  
  return (
    <Screen/>
  )
}

export default Auth