import ButtonBack from "../ButtonBack";
import { ReactComponent as IconBack } from "../../assets/backIcon.svg";

const Header = () => {
  return (
    <header>
      <ButtonBack icon={<IconBack />}>Voltar</ButtonBack>
    </header>
  );
};

export default Header;
