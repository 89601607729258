import React from 'react'
import * as S from './styles'
import { ReactComponent as IconDoc } from '../../../../../assets/ic-document.svg';
import { ReactComponent as IconCardioGram } from '../../../../../assets/ic-cardiogram-rounded.svg';

import Card from './Card';

type Props = {
  title: string, 
}

const Prescriptions = ({title}: Props) => {
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.Box>
        <Card
          category='Medicamentos'
          title='Amoxil 100mg/mL, Pó para suspensão oral (1un de 150mL) GSK - Glaxosmithkline'
          subtitle='Amoxilina 100mg/mL'
          quantity='1 embalagem'
          recommendation='Diluir um pacote em 1 litro e tomar de 3 em 3 horas durante 7 dias'
          containerInsiderRow={false}
        />

        <Card
          icon={<IconCardioGram/>}
          category='Solicitações de exames'
          title='Hemograma completo'
          subtitle='TUSS 40304361'
          quantity='1'
          recommendation='Realizar exame em jejum completo'
          containerInsiderRow={true}
        />
        <Card 
          icon={<IconDoc/>}
          category='Relatórios e atestados'
          showHeaders={false}
          quantity='1'
          recommendation='Atesto que os fins que o sr. John Doe realizou consulta no dia 01/09/2021 apresentando sintomas de covid e precisará ficar afastado das suas atividades presenciais por 7 dias a partir da data da emissão deste relatório'
          containerInsiderRow={true}
              />

      </S.Box>
    </S.Container>
  )
}

export default Prescriptions