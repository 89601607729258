import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 24px;
  padding: 4px 16px;
  border-radius: 4px;

  > span {
    font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  }
`