import styled from 'styled-components';

type opacity = {
  $type?: boolean;
};

export const container = styled.div<opacity>`
  width: 100%;
  max-height: 5.375rem;
  overflow: hidden;
  background-color: #444;
  opacity: ${({ $type }) => ($type ? 0 : 1)};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  video {
    object-fit: cover;
    @media (max-width: 640px) {
      width: 100% !important;
      height: auto !important;
      min-height: 100%;
      text-align: center;
    }
  }

  .info {
    width: 100%;
    height: 100%;
    z-index: 99;
  }
`;

export const videoOff = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #444;
  top: 0;
  left: 0;

  svg {
    position: absolute;
    top: auto;
    width: 50px;
    height: 50px;
  }

  svg path {
    fill: #fff;
  }
`;

export const userName = styled.div`
  width: 100%;
  height: 100%;
  z-index: 99;

  .bagdeName {
    position: absolute;
    bottom: 10px;
    left: 8px;
    padding: 6px 9px;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    border-radius: 8px;
  }
`;

export const info = styled.div`
  position: absolute;
  top: 10px;
  right: 16px;
  padding: 6px 9px;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-radius: 4px;

  svg path {
    fill: #fff;
  }

  svg {
    width: 20px;
  }
`;
