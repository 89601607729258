import styled from 'styled-components'

export const Tupla = styled.div`
  display: flex;
  flex-direction: column ;
`

export const TextWithIconContainer = styled.div`
`

export const Text = styled.p`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);

  span {
    margin-left: 23px;
  }
`
export const TextResponse = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
`
