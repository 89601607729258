import { Container, Content } from "./styles";
import Header from "./Header";
import { Outlet } from "react-router";

const AppointmentHistory = () => {
  return (
    <>
      <Container>
        <Content>
          <Header />
          <Outlet />
        </Content>
      </Container>
    </>
  );
};

export default AppointmentHistory;
