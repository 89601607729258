import React from 'react'
import * as S from './styles'

type Props = {
  text: string, 
  backgroundColor: string
}

const AlertChat = ({text, backgroundColor}: Props) => {
  return (
    <S.Container backgroundColor={backgroundColor}>
      <S.Text>{text}</S.Text>
    </S.Container>
  )
}

export default AlertChat