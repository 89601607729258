import * as React from 'react';

const useFetch = () => {
    const [dataHook, setDataHook] = React.useState(null)
    const [errorHook, setErrorHook] = React.useState<any>(null)
    const [loading, setLoading] = React.useState<boolean | null>(null)

    const request = async (url:string, options: any) => {
            let response;
            let json;

            try {
                setErrorHook(null)
                setLoading(true)
                response = await fetch(url, options)
                json = await response.json()
                if(!response.ok) throw new Error("Erro na requisição")
            } catch (error) {
                json = null; 
                setErrorHook("Deu erro na request!")
            }finally{
                setDataHook(json)
                setLoading(false)
                return {response, json}
            }
    }
   

    return {dataHook, errorHook, loading, request}

}

export default useFetch