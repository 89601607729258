import React from 'react'
import Screen from '../Screen'
import Background from '../../../../../assets/Backgrounds/social-feed-colour.svg'
import { Header, Title, SubTitle } from '../styles'


const FinishAttendance = () => {
  return (
    <Screen backgroundImage={Background} changeOrientation={false}>
      <Header>
        <Title>Chamada encerrada!</Title>
        <SubTitle style={{paddingLeft: '10px', paddingRight: '10px'}}>Ops! A consulta online que você está tentando acessar
          já foi encerrada.
          <span>😅</span>
        </SubTitle>
      </Header>
    </Screen>

  )
}

export default FinishAttendance