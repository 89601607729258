import React, { HTMLAttributes } from 'react'
import * as S from './styles'

interface Props extends HTMLAttributes<HTMLInputElement> {
  /** Texto placeholder */
  placeholder: string;
  /** Cor de fundo do botão de pesquisa */
  backgroundColor: string,
  /** Cor da lupa dentro do botão de pesquisa */
  color?: string,
   /** Value da caixa de pesquisa */
  value?: string,
   /** Função onChange */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  /** Função onClick (Quando o usuário clica no botão de pesquisa) */
  onClick?: (e?: any) => void
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement> | undefined
}

const InputSearch = ({
  placeholder,
  onChange,
  onClick,
  onKeyUp,
  backgroundColor,
  color = 'black',
  value,
  ...props
}: Props) => {

  return (
    <S.Wrapper>
      <S.Search value={value} onKeyUp={onKeyUp} placeholder={placeholder} onChange={onChange} {...props}/>
      <S.Button type="submit" onClick={onClick} >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
            fill={backgroundColor}
          />
          <path
            d="M14.5333 19.0003C12.0667 19.0003 10 17.0003 10 14.4669C10 12.0003 12 9.93359 14.5333 9.93359C17 9.93359 19.0667 11.9336 19.0667 14.4669C19 17.0003 17 19.0003 14.5333 19.0003ZM14.5333 17.7336C16.3333 17.7336 17.7333 16.2669 17.7333 14.5336C17.7333 12.7336 16.2667 11.3336 14.5333 11.3336C12.7333 11.3336 11.3333 12.8003 11.3333 14.5336C11.2667 16.2669 12.7333 17.7336 14.5333 17.7336ZM19.0667 18.2003L21.8 20.9336C22.0667 21.2003 22.0667 21.6003 21.8 21.8669C21.5333 22.1336 21.1333 22.1336 20.8667 21.8669L18.1333 19.1336C17.8667 18.8669 17.8667 18.4669 18.1333 18.2003C18.4 17.9336 18.8 17.9336 19.0667 18.2003Z"
            fill={color}
            fillOpacity="0.88"
          />
        </svg>
      </S.Button>
    </S.Wrapper>
  )
}

export default InputSearch