import styled, { keyframes } from "styled-components";

export const Wrapper = styled.div`
  .HeaderContent {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
    
    > h2 {
      font-weight: 600;
      font-size: 28px;
      line-height: 40px;
      color: var(--primary);
      
    }
  }
  
  > p {
    color: rgba(0, 0, 0, 0.88);
    font-family: 'Open Sans';
    font-style: normal;
    line-height: 24px;
  }

  .contentLoad {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 600px;
  }
  .contentSearch {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;

    .ContentInput {
      width: 100%;
      margin-bottom:24px;
    } 

    .contentFilter {
      cursor: pointer;
      width: 86px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > span {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #FF0073;
      }
    }
  }
  
  .ContentItem {
    display: flex;
    gap:16px ;
    position: relative;
  }
`

export const ContainerSelects = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`

export const ContainerCheckboxs = styled.div`
  display: flex;
  gap: 20px; 
  flex-direction: column; 
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 12px;
`
export const FiltrosContainer = styled.div`
  display: flex;
  gap: 8px ;
  margin-bottom: 32px;
`

export const AlertSelect = styled.div`
  background: #2B45D4;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 21px;
  height: 56px;
  margin-bottom: 32px;
  color: #fff;

  div:first-child{
    display: flex;
    align-items: center;
    gap: 24px;

    svg, img {
      cursor: pointer;
    }

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
    }
  }

  div:last-child{
    display: flex;
    align-items: center;
    gap: 51px;

    svg, img {
      cursor: pointer;
    }
  }

`

export const ContentModalExames = styled.div`
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.64);
  }
`
export const Loading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 600px;
`

export const ContainerInsideModal = styled.div`
  display: flex ;
  flex-direction: column ;
  gap: 24px;
`

export const TextP = styled.p``
export const ContainerButtons = styled.div`
  display: flex;
  gap: 8px;
`

export const TextInsideButton = styled.p`
  white-space: nowrap;
  color: #fff !important;
  line-height: 1rem;
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 0.56);
`


export const ContentTextModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 24px;

  div {
    margin-bottom: 8px ;
  }

  p {
    outline: none;
    background: rgba(255, 176, 0, 0.16);
    color: rgba(0, 0, 0, 0.4);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  span {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.56);

  }
`

export const ContentBtn = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 24px;
`

// Add arquivos 


type ScrollProps = {
  scroll: boolean
}

export const Content = styled.div`
    display: flex;
    flex-direction: column ;
    gap: 16px ;

    p {
        color: rgba(0, 0, 0, 0.64);
    }
`


export const Title = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.88);
`
export const Saparador = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  margin-bottom: 16px ;
`


export const FilesData = styled.div`
    position: relative ;
    display: flex ;  
    margin: 4px;
`

export const FilesContainer = styled.div<ScrollProps>`
      padding: 0px 16px 0px 0px;
      display: flex ;
      flex-direction: column ;
      max-height: 230px ;
      overflow-y: ${(props) => props.scroll ? "scroll" : undefined} ;
      gap: 30px ;
  
      
    &::-webkit-scrollbar {
      width: 8px;
      background-color: rgba(0, 0, 0, 0.15);
    }
  
    &::-webkit-scrollbar-thumb {
      background: #2b45d4;
      border-radius: 8px;
    }
  
  `

export const ContainerInfo = styled.div`
margin-left: 11px;
width: 100% ;
`
const animation =  keyframes`
    from {width: 0%; background: #E59500;}
    to {width: 100%; background: #38B449;}
`

export const ProgressBar = styled.div`
    border-radius: 4px;
    height: 6px;
    animation: ${animation} 3s forwards;
`


export const FileName = styled.p`
    font-weight: 600 !important;
    color: rgba(0, 0, 0, 0.64);
    margin-bottom: 8px;

`

export const FileAddedWrapper = styled.div`
  display: flex;
  background: rgba(43, 69, 212, 0.04);
  border-radius: 8px;
  margin: 0px 0px 16px 0px;
  padding: 11px;

  svg {
    margin-right: 11px;
  }

   

`;
export const ContainerFilesNames = styled.div<ScrollProps>`
    max-height: 100px;
    overflow-y: ${(props) => props.scroll ? "scroll" : undefined} ;
      
    &::-webkit-scrollbar {
      width: 8px;
      background-color: rgba(0, 0, 0, 0.15);
    }
  
    &::-webkit-scrollbar-thumb {
      background: #2b45d4;
      border-radius: 8px;
    }
`

export const FileFileAddedTitle = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.64);
  margin-bottom: 8px;
`;
export const FileFileAddedText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 3px;
`;
