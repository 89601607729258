export const calendarDate = (date: any) => {
  let dateNew = new Date(date);
  let dates = [];
  let days = 30;
  for (let index = 0; index < days + 1; index++) {
    dates.push({
      day:
        (dateNew.getDate() + 1 > 10
          ? dateNew.getDate()
          : '0' + dateNew.getDate()) +
        '/' +
        (dateNew.getMonth() + 1 > 10
          ? dateNew.getMonth() + 1
          : '0' + (dateNew.getMonth() + 1)) +
        '/' +
        dateNew.getFullYear(),
      fullday:
        dateNew.getFullYear() +
        '-' +
        (dateNew.getMonth() + 1 > 10
          ? dateNew.getMonth() + 1
          : '0' + (dateNew.getMonth() + 1)) +
        '-' +
        (dateNew.getDate() + 1 > 10
          ? dateNew.getDate()
          : '0' + dateNew.getDate()),
      dayWeek: formatDayWeek(
        dateNew.getDate() +
          '/' +
          (dateNew.getMonth() + 1) +
          '/' +
          dateNew.getFullYear(),
      ).replace('.', ''),
    });
    dateNew.setDate(dateNew.getDate() + 1);
  }
  return dates;
};

const formatDayWeek = (str: string) => {
  let partes = str.split('/').map(Number);
  let data = new Date(partes[2], partes[1] - 1, partes[0]);
  return data.toLocaleString('pt-br', { weekday: 'short' });
};
