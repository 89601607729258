import * as React from 'react';
import { Container, Esquerda, ImageBackground, Direita, Cabecalho} from "./styles";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { useUserContext } from "contexts/UserContext";
import Header from "components/Header/Header";

const Login = () => {
  const { pathname } = useLocation();
  const {login, objectRedirect, setObjectRedirect} = useUserContext()


  React.useEffect(() => {
    return () => setObjectRedirect({attendanceId: null, type: null})
  }, [])

  if(login && (!objectRedirect.attendanceId && !objectRedirect.type)){
    return <Navigate to={"/home"}/>
  }

  return (
    <Container>
      <Cabecalho>
        {pathname !== "/" && (
        <Header/>
        )}
      </Cabecalho>
      {/* Lado esquerdo */}
      <Esquerda pathname={pathname}>
        <ImageBackground/>
      </Esquerda>

      {/* Lado direito */}
      <Direita pathname={pathname}>
        <Outlet />
      </Direita>
    </Container>
  );
};

export default Login;
