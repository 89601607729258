import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2,1fr);
  background-color: #fff;
  max-width: 440px;
  padding: 16px;
  border-radius: 8px;

  @media (max-width: 360px) {
    padding: 8px;
  }
`
export const ButtonLink = styled.a`
  display: flex;
  justify-content: center;
  padding: 8px 0;
  background-color: var(--primary);
  color: #fff;
  margin-top: 16px;
  border-radius: 100px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  max-width: 201px;
  width: 100%;
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const Title = styled.p`
  font-size: 14px;
  color: var(--color-maida-ascent);
  font-weight: 600 !important;
  /* margin-bottom: 8px; */
`
export const Text = styled.p`
  /* margin-bottom: 16px; */
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: rgba(0,0,0,0.88)
`

export const Right = styled.div`
  align-self: center;
  justify-self: end;
`