import {
  BoldText,
  Wrapper,
  Text,
  Header,
  Description,
  Content,
  FileAddedWrapper,
  FileFileAddedTitle,
  FileFileAddedText,
  ContainerFilesNames
} from "./styles";
import { ReactComponent as Arrow } from "../../../../../../assets/arrowFoward.svg";
import { ReactComponent as ArrowExpanded } from "../../../../../../assets/arrow-expanded.svg";
import { ReactComponent as IconSuccess } from "../../../../../../assets/ic-success-consult.svg";
// import {ReactComponent as IconSuccess} from '../../../../assets/mai-ic-success.mono.svg'
import {ReactComponent as IconClose} from '../../../../../../assets/ic-close.svg'
import IconLife from '../../../../../../components/Icon/index'

import { useState, useEffect } from "react";
import { useConsultsContext } from "contexts/ConsultsContext";
import AddFile  from '../../../../../../components/File/index'
import * as S from './stylesAddFile'
import Button from '../../../../../../components/Button/Button'
import { useHomeContext } from "contexts/HomeContext";
import { CALL_ENPOINT } from "api/Services/CallEndpoint";
import { propsData } from "pages/AppointmentHistory/Details/types";

import {useNavigate, useParams} from 'react-router-dom'

type Props = {
  filenames: string[] , 
  setFileNames: React.Dispatch<React.SetStateAction<string[]>>
}
const AddFileCard = ({filenames, setFileNames} : Props) => {
  
  const {id} = useParams()
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate()
  const [files, setFiles] = useState<File[]>([]);
  const [file, setFile] = useState<File | null>(null);
  
  const [submitFile, setSubmitFile] = useState(false)
  const {shareFile, errorFile, loading, getFiles} = useConsultsContext()
  const [attachmentFiles, setAttachmentFiles] = useState<any[]>()
  const {attendances} = useHomeContext()

   //get the last attendance for to detail in screen
   const [attendance, setAttendance] = useState<propsData>();
   
  //  const lastAttedance = attendances && attendances[attendances.length - 1];


   //handle animation
   const [btnShareDisabled, setBtnShareDisabled] = useState(false)

  
   async function findAttendace(id: string | undefined) {
    try {
      const { url, options } = CALL_ENPOINT('TELEHEALTH_URL',`attendance/${id}`,'GET',null, {
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
      });
      
      const response = await fetch(url, options);
      
      let atendimento;
      if (response.ok) {
        atendimento = response.json().then(r => {
          return r
        })
      }
      return atendimento

    } catch (err) {
      console.log(err);
    }
  }

  const addFiles = (file:File) => {
    setFiles([...files, file])
  }
  
  const removeFile = (nome: string) => {
      setFiles(files.filter(item => item.name !== nome))
   }

   // Envio dos arquivos para o endpoint
   const handleSubmitFiles =  () => {

    setFileNames([]) //reseta o vetor de adicionados


    if(!files.length) return 
    
    files.forEach(file => {
     const formData = new FormData()
     formData.append('name', file.name);
     formData.append('file', file as any);

     if(attendance){
        shareFile(attendance.id.toString(), formData).then((json: any) => {
          if(json !== null){
            setFileNames([...filenames, json.name])
            setSubmitFile(true)
          }
        })
     }

   })
     setFiles([])
     setIsExpanded(false)

  }

  function expandWrapper() {
    return isExpanded ? (
      <>
        <Text>Ocultar</Text>
        <ArrowExpanded />
      </>
    ) : (
      <>
        {" "}
        <Text>Expandir</Text>
        <Arrow />{" "}
      </>
    );
  }


  useEffect(()  => {
    if(file){
      addFiles(file)
    }
   }, [file])

   useEffect(() => {
    findAttendace(id?.toString()).then(at => {
      if(at){
      setAttendance(at)
      getFiles(at.id.toString()).then((r:any) => {
        setAttachmentFiles(r)
      })
      }
    })
   

    
  }, [submitFile])


  return (
    <>
      {/* Listagem de arquivos adicionados */}
      {/* Lista de arquivos adicionados no atendimento */}
      {(attachmentFiles && attachmentFiles.length !== 0) && (
      <FileAddedWrapper>
        <div>
          <IconSuccess />
        </div>
        <ContainerFilesNames>
          <FileFileAddedTitle>{attachmentFiles.length > 1 ? 
          `${attachmentFiles.length} arquivos adicionados` 
          : `${attachmentFiles.length} arquivo adicionado` } </FileFileAddedTitle>
          {
            attachmentFiles.map((file:any) => {
              return <FileFileAddedText key={file.id}>{file.name}</FileFileAddedText>
            })
          }
        </ContainerFilesNames>
      </FileAddedWrapper>
      )}

      {/* { errorFile === true &&
        <FileAddedWrapper>
          <IconClose />
          <div>
            <FileFileAddedTitle>0 arquivo adicionado</FileFileAddedTitle>
            <FileFileAddedText>Arquivo maior que o esperado</FileFileAddedText>
          </div>
        </FileAddedWrapper>
      } */}

      <Wrapper>
        <Header onClick={() => setIsExpanded(!isExpanded)}>
          <div>
            <BoldText>Quer adicionar um arquivo nesse agendamento?</BoldText>
          </div>
          <div style={{ display: "flex", justifyContent: "right"}}>
            {expandWrapper()}
          </div>
        </Header>
        { files.length === 0 ? <Content isExpanded={isExpanded}>
          <Description style={{ marginBottom: "16px" }}>
            Adicione arquivos de exames ou fotos de lesões que possam ajudar no
            diagnóstico durante essa consulta!
          </Description>
          <AddFile setFile={setFile} style={{width: '100%'}} limitSize={0}/>
          <Description style={{ margin: "16px 0 28px 0" }}>ou</Description>
          <BoldText onClick={() => navigate('/exames')}>Compartilhe um arquivo do seu histórico</BoldText>
        </Content>
        :
        (
          <Content isExpanded={isExpanded}>
            <S.FilesContainer scroll={files.length > 3 ? true : false} style={{width: '100%'}}>
              {
                files.map((item, index) => {
                  return <S.FilesData key={index}> 
                    <IconLife icon={<IconSuccess/>} color="#2B45D4"/>
                    <S.ContainerInfo>
                      <S.FileName>{item.name}</S.FileName>
                      <S.ProgressBar onAnimationStart={() => setBtnShareDisabled(true)}  onAnimationEnd={() => setBtnShareDisabled(false)}></S.ProgressBar>
                    </S.ContainerInfo>
                    <IconLife icon={<IconClose/>} onClick={() => removeFile(item.name)} color="red" style={{position: 'absolute', top: 0, right: 0, cursor: 'pointer'}}/>
                  </S.FilesData>
                })
              }
            </S.FilesContainer>
            <Button onClick={handleSubmitFiles} disabled={(btnShareDisabled || loading)} variant='primary' style={{width: '100%', padding: '12px 24px', marginTop: '24px'}}>Compartilhar</Button>
            {/* Adicioanr Arquivos */}
            <AddFile setFile={setFile} style={{width: '100%', marginTop: '24px'}} limitSize={0}/> 
          </Content>
        )  
      }
      </Wrapper>

    

    
    </>
  );
};

export default AddFileCard;
