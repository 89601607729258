import React from 'react'
import {Screen, Title, Cabecalho, Body} from './styles'
import Header from 'components/Header/Header'

import {ReactComponent as IconAttendance} from '../../../assets/query.svg'
import {ReactComponent as IconPrescription} from '../../../assets/mai-ic-prescription.svg'
import {ReactComponent as IconReceipt} from '../../../assets/ic-receipt.svg'
import {ReactComponent as IconCardio} from '../../../assets/ic-cardiogram.svg'
import {ReactComponent as IconPill} from '../../../assets/pill.svg'

type Props = {
    children: React.ReactNode,
    title: string,
    icon: boolean
}

const ScreenComponent = ({children,icon, title}: Props) => {
  const style = {marginRight: '13px'}
  const icones: any = {
    'Consultas': <IconAttendance style={style}/>,
    'Pedidos': <IconReceipt style={style}/>,
    'Prescrição': <IconPrescription style={style}/>,
    'Medicamentos': <IconPill style={style}/>,
    'Exames': <IconCardio style={style}/>
  }
  return (
    <Screen>
      <Cabecalho>
        <Header/>
        {icon ? <Title><span>{icones[title]}</span>{title}</Title> :  <Title>{title}</Title>}       
      </Cabecalho>
      <Body>
        {children}
      </Body>

    </Screen>
  )
}

export default ScreenComponent