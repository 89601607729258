import { useEffect, useState } from 'react';

const useSessionlStorage = (key: string, inicial?: any) => {
  const [state, setState] = useState(() => {
    const local = window.sessionStorage.getItem(key);
    return local ? JSON.parse(local) : inicial;
  });

  useEffect(() => {
    window.sessionStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};

export default useSessionlStorage;