import NenhumAgendamento from '../../../../assets/agendamento.svg'
import { ContentNoConsutl } from './styles';

const NoAttendance = () => {
  return (
    <ContentNoConsutl>
      <img src={NenhumAgendamento} alt="Nenhum" />
      <p>Você não tem nenhuma consulta para os próximos dias</p>
    </ContentNoConsutl>
  )
}

export default NoAttendance;