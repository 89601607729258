import React from 'react'
import ScreenComponent from '../Screen/Screen'
import { ListCategories, NoSearch, SearchContainer } from '../styles';
import { Search } from 'storybook-maida';
import { useHomeContext } from 'contexts/HomeContext';
import {ReactComponent as NoSearchBg} from '../../../assets/Backgrounds/search.svg'
import CardList from 'components/CardList';
import { useNavigate } from 'react-router-dom';
const SeeMore = () => {
  const navigate = useNavigate()

  const {categories, faqSelected, setFAQSelected} = useHomeContext()
  const [search, setSearch] = React.useState("");
  
  let filteredElements = categories.filter(el => el.title === faqSelected.category)
  const [filtrados, setFiltrados] = React.useState<any>(filteredElements[0].questions)

  // Search Method
  const searchFAQ = (value: any) => { 
    if(value){
        const filtrados = filteredElements[0].questions.filter( (item: string) => {
            const minusculo = item.toLowerCase()
            const itemMinu = value.toLowerCase()
            return minusculo.indexOf(itemMinu) > -1
        })

    
        setFiltrados(filtrados)
        
    }else{
        setFiltrados(filteredElements[0].questions)
    }
}
 

  return (
    <ScreenComponent title={faqSelected.category} icon={true}>
      <SearchContainer style={{width: '100%', marginTop: '32px', marginBottom: '32px'}}>
        <Search
          style={{padding: '8px 16px'}}
          onChange={({ target }) => setSearch(target.value)}
          onKeyUp={(e) => searchFAQ(e.currentTarget.value)}
          onClick={() => {}}
          placeholder="Procurar"
          backgroundColor="var(--primary)"
          color="white"
          value={search}
            />
      </SearchContainer>
      {filtrados.length > 0 ? <ListCategories>
        {
          faqSelected && filtrados.map((q:any) => <CardList onClick={() => {setFAQSelected({...faqSelected, category: faqSelected.category, question: q}); navigate('/faq/help')}} title={q} key={q} />)
        }
      </ListCategories>
      :
      <NoSearch>
        <NoSearchBg/>
        <div style={{width: '512px', marginTop: '28px'}}>
          <p style={{fontWeight: '600', lineHeight: '24px', color: 'rgba(0, 0, 0, 0.64)'}}>Não encontramos sua dúvida, mas você pode tentar procurar com outras palavras ou nos enviar uma mensagem</p>            
        </div>
      </NoSearch> 
      }
    </ScreenComponent>
  )
}

export default SeeMore