import { propsData } from 'pages/AppointmentHistory/Details/types';
import { AttendanceSelected } from 'pages/Messages/RightContent/Attendances';
import React from 'react'
import * as S from './styles'
type Props = {
  label: string,
  onClick?: () => void
  id?: any;
  selected: AttendanceSelected
  setSelected: React.Dispatch<React.SetStateAction<AttendanceSelected>>
  item: propsData
}
const ItemSlider = ({label, id, item, selected, setSelected, onClick}:Props) => {
  return (
    <S.Container  className={selected.textSelected === label ? 'active' : undefined}>
      <S.Text onClick={e => {
        setSelected({textSelected: e.currentTarget.textContent, publicId: item?.publicId, id: item.id})
      }} className={selected.textSelected === label ? 'active' : undefined}>{label}</S.Text>
    </S.Container>
  )
}

export default ItemSlider