import React from 'react'
import * as S from './styles'


import { ReactSVG } from 'react-svg';


type Icon = {
  icon: string
  label: string
}

type Props = {
  title: string, 
  elements: Icon[]
}

const Info = ({title, elements}: Props) => {
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.Box>
        {
          elements.map((item, index) => {
            return <S.Item key={item.label}>
              <ReactSVG src={item.icon} />
              <S.Text>{item.label}</S.Text>
            </S.Item>
          })
        }
      </S.Box>
    </S.Container>
  )
}

export default Info