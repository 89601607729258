import styled from 'styled-components'

export const Header = styled.div`
    text-align: center ;
`
export const Title = styled.h2`
    font-weight: 600;
    margin-bottom: 16px;
    line-height: 40px;
`
export const SubTitle = styled.div`
    font-size: 16px;
    line-height: 24px ;
    color: rgba(0, 0, 0, 0.56);

    span {
        color: #000;
        margin-left: 8px ;
    }
`
export const ContainerInputs = styled.div`
    display: flex;
    flex-direction: column ;
    margin-top: 48px ;
`

export const Footer = styled.div`
    margin-top: 24px ;
`