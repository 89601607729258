import React from 'react';
import Button from '../../../components/Button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import AppleSignin from 'react-apple-signin-auth'
import GoogleLogin from 'react-google-login'
import { useUserContext } from 'contexts/UserContext'
import useQuery from 'hooks/useQuery';
import {Container} from './styles'

const FormRight = () => {

  const navigate = useNavigate()
  const { conectarGoogle, conectarApple} = useUserContext()

  // id do atendimento se for eletivo
  // const { id } = useParams()

  // Token que vem da hapvida
  // const {token} = useParams()

  //Query params da hapvida type = "ELECTIVE" | "URGENCY"
  // const query = useQuery()
  // const attendanceType = query.get("type")



  // Obtem informacoes da hapvida e chama endpoint de autenticacao
  // React.useEffect(() => {
    
    // if(token !== null && attendanceType !== null){
    //   getLoginWithTokenHapvida(token, attendanceType)
    // }

  // }, [])

  return (
    <Container>
      <h1>O cuidado com a sua saúde está só começando!</h1>
      <p>Como você quer entrar?</p>

      <AppleSignin
        authOptions={{
          clientId: 'health.maida.lifeplace.service',
          redirectURI: process.env.REACT_APP_AUTH_APPLE,
          scope: 'email name',
          state: 'state',
          nonce: 'nonce',
          usePopup: true,  
        }}
        uiType="light"
        className="btn-apple"
        buttonExtraChildren="Entrar com Apple"
        onSuccess={conectarApple}
        onError={(error) => console.error(error)}
        iconprop={{ style: { marginTop: '10px' } }}
      />

      <GoogleLogin
        clientId="523875628162-l8dql5ctihq3ck35cr3c045qk1kt2h25.apps.googleusercontent.com" /// Maida
        render={renderProps => (
          <button 
            onClick={renderProps.onClick} 
            disabled={renderProps.disabled} 
            className="btn-login">
            <span>Entrar com Google</span>
          </button>
        )}
        onSuccess={conectarGoogle} 
      /> 

      <Button variant="primary" onClick={() => navigate('email')}>Entrar com e-mail</Button>
    </Container>
  )
}

export default FormRight
