import styled from "styled-components";
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  > button {
    margin: 40px 0 48px 73px;
  }

  @media(max-width:1167px) {
  
    > button {
      margin: 24px 0 24px 0;
    }
  }

`;

export const ContentConsultations = styled.div`
  max-width: 1024px;
  margin: 0 auto;

  h4 {
    font-size: 1.75rem;
    font-weight: 600;
    color: var(--primary);
    margin-bottom: 24px;
    margin-top: 40px;
  }

  > div {
    margin-bottom: 34px;

    p {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 8px;
    }

    span {
      font-size: 1rem;
      color: rgba(0, 0, 0, 0.56);
    }
  }

  .contentCard {
    display: flex;
    gap: 16px;

    .urgency {
      background: rgba(255, 176, 0, 0.16);
    }
  }

  @media (max-width: 770px) {
   .contentCard {
     flex-direction: column;
   } 
  }
`

export const CardConsultation = styled(Link)`
    width: 504px;
    height: 128px;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    background: #fff;
    padding: 24px 8px 0 24px;

    > div {
      p {
        font-size: 1rem;
        font-weight: bold;
        color: #000000;
        line-height: 24px;
        margin-bottom: 8px;
      }

      span {
        font-size: 14px;
        color:  rgba(0, 0, 0, 0.56);
        line-height: 24px;
      }
    }

  @media (max-width: 770px) {
   width: 100%; 
  }
`

