import React from 'react'
import './Button.css'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
   style?: React.CSSProperties,
   variant: "primary",
   icon?: React.ReactComponentElement<'svg'>,
   href?: string
} 
 

const Button2 = ({style, variant ,children, icon, ...rest}: ButtonProps) => {
    return (
      <button style={style} {...rest} className={`button ${variant}`}> 
        {icon && <span>
            {icon}
          </span>}
        {children}
      </button>
    )
}



export default Button2
