import React from 'react'
import * as S from './styles'
import {ReactComponent as IconDrug} from '../../../../../../assets/ic-drug.svg'

type CardProps = {
  category?: string;
  title?: string; 
  subtitle?: string; 
  quantity?: string; 
  recommendation?: string;
  containerInsiderRow: boolean;
  showHeaders?: boolean; 
  icon?: React.ReactComponentElement<'svg'>,
}

const Card = ({icon, title, category, subtitle,quantity,recommendation, containerInsiderRow, showHeaders=true}: CardProps) => {
  return (
    <S.Container>
      <S.Title style={{fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.56)'}}>{category}</S.Title>
      <S.Subcontainer>
        <S.Left>
          <S.Icone>
            {icon ? icon : <IconDrug/>} 
          </S.Icone>
          <S.Content showHeaders={showHeaders}>
            <S.Title>{title}</S.Title>

            <S.ContainerInsideTexts containerInsiderRow={containerInsiderRow}>
              <S.Subtitle>{subtitle}</S.Subtitle>
              { showHeaders && <S.Title>Quantidade:<span>&nbsp;{quantity}</span></S.Title>}
            </S.ContainerInsideTexts> 
           
            <S.Recommendations>
              {recommendation}
            </S.Recommendations>
            {!showHeaders && <S.Title style={{marginTop: '8px'}}>Quantidade:<span>&nbsp;{quantity}</span></S.Title>}
          </S.Content>
        </S.Left>
     
      </S.Subcontainer>
    </S.Container>
  )
}

export default Card