import { Route } from "react-router-dom";

import EmailGuard from "guards/EmailGuard";
import FormRight from "./FormRight";
import FormRightCode from "./FormRightCode/FormRightCode";
import FormRightEmail from "./FormRightEmail";

export const routesLogin = [
  <Route path="/" key={"inicioLogin"} element={<FormRight />}>
  </Route>,
  <Route path="email" key={"email"} element={<FormRightEmail />} />,
  <Route path="/" key={"verificacao"} element={<EmailGuard />}>
    <Route path="code" element={<FormRightCode />} />
  </Route>
];
