import * as React from 'react';
import {Container, Left, Right, ContainerRelative} from './style'
import {ReactComponent as IconAlert} from '../../assets/ic_alert.svg'

type bannerProps = {
  /** Text inside label  */
  label: string; 
  /** Banner type */
  type: 'success' | 'danger' | 'warning',
  /** Time duration to display the banner */
  seconds?: 3 | 5 | 10 ,
  style?: React.CSSProperties
  onEndTime?: () => void

}
const AlertBanner = ({label, type, seconds=3, style, onEndTime }: bannerProps) => {
  
  const [show, setShow] = React.useState(true)

  React.useEffect(() => {
    let id = setTimeout(() => {
      setShow(false)
      if(onEndTime){
        onEndTime()
      }
    }, seconds * 1000)

    return () => {
      clearTimeout(id)
    }
  }, [])

  return (
    <ContainerRelative style={{display: show ? '' : 'none', ...style}}>
      {/* type={type} show={show ? 'open' : 'close'} */}
      <Container type={type}>
        <Left>
          <IconAlert/>
        </Left>
        <Right>
          {label}
        </Right>
      </Container>
    </ContainerRelative>
  )
}

export default AlertBanner