import styled from 'styled-components'


export const NoSearch = styled.div`
    display: flex;
    flex-direction: column ;
    justify-content: center ;
    align-items: center;
`
export const Background = styled.div`
    background-image: url('../../assets/Backgrounds/search.png') ;
    width: 325px;
    height: 313px;
`
export const SeeMore = styled.div`
    display: flex;
    justify-content: center ;
`
export const SeeMoreLink = styled.a`
    color: var(--primary);
    font-weight: 600;
    cursor: pointer;
`

export const ListCategories = styled.section`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 34px ;
`
export const SearchContainer = styled.div`
 @media (min-width: 768px){
    width: 66%;
 }

`

export const HeaderContent = styled.div`
    margin-top: 32px;
    margin-bottom: 32px;

    > button {
        position: fixed ;
        bottom: 10px;
        width: 75% !important;
    }

    @media (min-width: 768px){
        display: flex; 
        justify-content: space-between;
        align-items: center;
        
        > button {
            position: static ;
            width: 32% !important ;
        }
    }

`