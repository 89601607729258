import React from 'react'
import * as S from './styles'
import { Link, useNavigate } from 'react-router-dom'
import { ReactComponent as IconOptions } from '../../assets/ic-3ponts.svg'
import { CALL_ENPOINT } from 'api/Services/CallEndpoint'
import { headerAppToken } from 'api/headers/HeaderAppToken'
import Checkbox from 'components/Checkbox'
import Icon from '../../assets/checkbox-checked.svg'
import { ExamProps } from 'pages/Exams/ListExams'


interface cardProps {
  exam: ExamProps
  id: string
  archive : string
  laboratorio? : string
  date : string
  remove: any
  rename: any
  download?: any
  select: boolean
  showSeeFile?: boolean
  forceUnmark: boolean | null
  marked?: boolean
  // se a opacao de selecao foi iniciada, assim fica o marcador para todos
  setSelect: React.Dispatch<React.SetStateAction<boolean>>
  examsSelecteds: ExamProps[]
  setExamsSelecteds: React.Dispatch<React.SetStateAction<ExamProps[]>>
  style?: React.CSSProperties
}


const CardExams = ({ showSeeFile=true,exam, forceUnmark, marked=false ,id, archive, style, laboratorio, date, remove, rename, select, setSelect, download, examsSelecteds, setExamsSelecteds }: cardProps) => {
  
  // const navigate = useNavigate()
  const [ isOpenCard, setIsOpenCard ] = React.useState(false)
  const [checkboxSelected, setCheckbxSelected] = React.useState(false) 

  const handleClose = () => {
    setIsOpenCard(!isOpenCard)
  }

  const [link, setLink] = React.useState<string | null>(null)

  async function downloadExams(id: string){
    const tokenLifeplace = localStorage.getItem('lifeplace@token')
    if(tokenLifeplace){
    try {
      const { url, options } = CALL_ENPOINT('GESTAO_DOC',`files?ids=${id}`,'GET',null, headerAppToken(tokenLifeplace));
      const response = await fetch(url, options);
      if (response.ok) {
        const {data : {Files}} = await response.json()
        return Files
      }
    } catch (err) {
      console.log("Deu erro na exclusão do exame")
    } 
   }
  }

  function downloadTeste(url:string, filename:string) {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    })
    .catch(console.error);
  }
  
  const handleCheckbox = (exam: ExamProps) => {

    setCheckbxSelected(!checkboxSelected)
    //ha um delay no estado.. conceito de react mesmo
    // Verifico o estado antes dele mudar.. pq n da pra consulta o estador que virá a ser .. entao ele chega falso..
    //ele chega falso -> desmarcado e ao clicar .. no futuro sera true .. entao to MARCANDO
    if(!checkboxSelected){
      setExamsSelecteds([...examsSelecteds, exam])
    }else{
      if(examsSelecteds.length === 1){
        setSelect(false)
      }
      setExamsSelecteds(examsSelecteds.filter(ex => ex.publicId !== exam.publicId))
    }
  }

  
  // Toda vez que tocar no "X" do card AZUL -> marca todos que estiverem selecionados
  //pora FALSE
  React.useEffect(() => {
    setCheckbxSelected(false)
  }, [forceUnmark])

  React.useEffect(() => {
    if(marked){
      setCheckbxSelected(true)
      setSelect(true)
    }
  }, [])

  return (
    <S.Wrapper style={style}  >
      <div className={`contentInfo ${checkboxSelected ? 'bg-color' : undefined}`}>
        {select === false ? <S.Icon>
          <IconOptions onClick={() => setIsOpenCard(!isOpenCard)}/>
        </S.Icon>
        :
        <S.Icon>
          <Checkbox style={{alignSelf: 'flex-end'}} borderColor="#2B45D4" iconColor="#2B45D4" 
            checked={checkboxSelected} pathSvg={Icon} 
            onChange={() => handleCheckbox(exam)}  color={checkboxSelected? "#2B45D4": ''} label={id}></Checkbox>
        </S.Icon>}
        {/* checkbox */}
        <p>{archive.slice(0, 25)}</p>
        <span>{laboratorio}</span>
        <span>{date}</span>
        {showSeeFile && <Link onClick={() => {
          setExamsSelecteds([...examsSelecteds, exam])
        }} to={{
          pathname: `visualizar-documento/${exam.publicId}`,
        }} >Ver arquivo</Link>}

        { isOpenCard &&
        <S.CardSelect>  
          <ul>
            <li onClick={() => {
              handleCheckbox(exam)
              setCheckbxSelected(true)
              setIsOpenCard(false)
              setSelect(true)
            }}>
              <Link to={'#'}>Selecionar</Link>
            </li>
            <li onClick={() => {
              handleCheckbox(exam)
              setCheckbxSelected(true)
              setIsOpenCard(false)
              setSelect(true)
              }}>
              <Link to={'#'}>Compartilhar</Link>
            </li>
            <li onClick={() => {
                downloadExams(id as string).then(json => {
                  setLink(json[0].url)
                  downloadTeste(json[0].url,`${exam.documentName}${json[0].fileExtension}`)
                  setIsOpenCard(false)
                })
               }
            }>
              <Link download={true} to={link ? link : '#'}>Baixar</Link>
            </li>
            {/* Se clicar em renomear */}
            <li onClick={() => {
              rename()
              setIsOpenCard(false)
            }}>
              <Link to={'#'}>Renomear</Link>
            </li>
            <li onClick={() => {
              remove()
              setIsOpenCard(false)
            }}>
              <Link to={'#'}>Excluir</Link>
            </li>
          </ul>
        </S.CardSelect>
        }
      </div>
      <div id={isOpenCard ? 'overlayCard' : undefined} onClick={handleClose}></div>
    </S.Wrapper>
  )
}

export default CardExams