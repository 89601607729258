import styled from "styled-components";
import { ReactComponent as IconWarning } from "../../assets/ic-warning.svg";
import { ReactComponent as IconWhiteSuccess } from "../../assets/ic-success.svg";
import { ReactComponent as IconInfo } from "../../assets/ic-info-notice.svg";
import { ReactComponent as IconClose } from "../../assets/ic-close.svg";

import IconGreenSuccess from "./IconSuccess";
import {useNavigate} from 'react-router-dom'

type NoticeProps = {
  children: any;
  service?: "telehealth" | "airmed";
  type?: "INFO" | "ERROR"| "WARNING"| "SUCCESS";
  icon?: "warning" | "successWhite" | "successGreen" | "info";
  subTitle: string;
  navigateTo?: string;
  onClose?: () => void;
  style?: React.CSSProperties
};

const Notice = ({
  children,
  type,
  service,
  subTitle,
  navigateTo,
  onClose,
  icon,
  style
}: NoticeProps) => {
  
  let navigate = useNavigate();

  function renderType() {
    if (type === "SUCCESS") {
      return "success";
    }else if(type === "WARNING"){
      return "warning"
    } 
    else {
      return "angularInfo";
    }
  }

  function renderIcon() {
    switch (icon) {
      case "successGreen":
        return <IconGreenSuccess/>;
      case "successWhite":
        return <IconWhiteSuccess/>;
      case "warning":
        return <IconWarning />;
      case "info":
        return <IconInfo/>;
      default:
        return <IconWarning/>;
    }
  }

  function renderLink(subTitle: string, link:string) {
    return <p className="link" onClick={() => navigationTo(link)}>
      {subTitle}
    </p>
      
  } 
  

  const navigationTo = (url:string) => {
      navigate(url)
  }

  return (
    <Container className={renderType()} style={{...style}}>
      {renderIcon()}
      <p>{children}</p>
      {navigateTo && renderLink(subTitle,navigateTo)}
      {onClose && <IconClose onClick={() => onClose()} className="iconClose"/>}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  align-self: center;
  margin: 16px 0;
  padding: 16px 19px;
  box-sizing: border-box;
  border-radius: 8px;
  transition: 0.3s ease-in-out;
  width: 100%;
  @media (max-width: 1280px) {
    /* margin: 0 24px    */
  }

  &.telehealth {
    background-color: rgba(255, 228, 175, 1);
  }
  &.airmed {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  &.angularInfo {
    background-color: rgba(255, 228, 175, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  &.success {
    background: #38b449;

    p {
      color: #ffffff;
    }
  }

  &.warning {
    background: #FFB000;

    p {
      color: rgba(0, 0, 0, 0.64);
    }
  }

  .link {
    margin-left: 6px;
    cursor: pointer;
    text-decoration: underline;
  }

  .iconClose{
    position: absolute;
    right: 0;
  }

  p {
    font-size: 0.875rem;
    color: var(--life-neutral-black-64);
    font-weight: 600;
    line-height: 1.5rem;
  }

  svg {
    align-self: center;
    margin-right: 18px;
  }

  a {
    color: rgba(204, 41, 110, 1);
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.5rem;
    margin-left: 16px;
  }
`;

export default Notice;
