import React, { useState} from 'react'
import * as S from './styles'
import Radio from '../../../../components/Radio';
import useSessionlStorage from 'hooks/useSessionlStorage';
import { Button } from 'storybook-maida';
import { useNavigate } from 'react-router-dom';


const ReturnConsult = () => {
  const [storage, setStorage] = useSessionlStorage('consulta');
  const navigate = useNavigate()
  const [ select, setSelect ] = useState<boolean>(false)

  const handleSelect = (e: any) => {
    setSelect(true)
    
    const updatedSchedule = {
      ...storage,
      schedulingType: "Consulta de Retorno",
      motivo: e
    };
    setStorage(updatedSchedule);
  }

  

  return (
    <S.Wrapper>
      <h1>
        Antes de continuar o agendamento, selecione os motivos para o seu retorno:
      </h1>

      <div>
        <Radio
          groupName="retorno"
          id='Continuar tratamento'
          label='Continuar tratamento'
          value='Continuar tratamento'
          onChange={(e) => handleSelect(e.target.value)}
          style={{
            minWidth: '100%',
            marginBottom: '8px',
          }} 
        />

        <Radio
          groupName="retorno"
          id='Mostrar exames'
          label='Mostrar exames'
          value='Mostrar exames'
          onChange={(e) => handleSelect(e.target.value)}
          style={{
            minWidth: '100%',
            marginBottom: '8px',
          }} 
        />

        <Radio
          groupName="retorno"
          id='Renovar receita'
          label='Renovar receita'
          value='Renovar receita'
          onChange={(e) => handleSelect(e.target.value)}
          style={{
            minWidth: '100%',
            marginBottom: '8px',
          }} 
        />

        <Radio
          groupName="retorno"
          id='Trocar medicação'
          label='Trocar medicação'
          value='Trocar medicação'
          onChange={(e) => handleSelect(e.target.value)}
          style={{
            minWidth: '100%',
            marginBottom: '8px',
          }} 
        />

      </div>
      <Button 
        style={{marginTop: '2rem'}}
        children={'Próximo'} 
        disabled={select ? false : true}
        onClick={()=> navigate('/consultas-online/eletiva/agendar-consulta')}
      />
    </S.Wrapper>
  )
}

export default ReturnConsult