import axios from 'axios'



export const axiosTeleChat = axios.create({
  baseURL: process.env.REACT_APP_API_URL_TELEHEALTH,

  headers: {
    'Content-Type': 'application/json',
  }
})





//==================
axiosTeleChat.interceptors.request.use(
  (config) => {
    if(config.headers){
      config.headers.authorization = `Bearer ${localStorage.getItem('telehealth@token')}`  
    }
    return config
  },
  (error) => {
    // console.log(error);
    Promise.reject(error)
  }
)


//######################
// ------- CHAT METHODS ----------
//######################

async function postShareExam(chat_id: string, file: any) {
  const response = await axiosTeleChat.post(`/chat/${chat_id}/message`, file)
  return response.data
}

type PropsChatCreate = {
    chatId: string | null 
    attendanceId: string | null
    participanteId: string | null
    offset: number
    size: number
}

async function postCreateChat(body: PropsChatCreate){
  const response = await axiosTeleChat.post(`/chat`, body)
  return response.data
}

type MessageProps = {
  type: "text" | "attachment",
  body: any
}


async function getParticipant(participant_id: string, params: string){
  const response = await axiosTeleChat.get(`/chat/unread-messages?${params}`)
  return response.data
}

async function getChatParticipants(chat_id: string){
  const response = await axiosTeleChat.get(`/chat/${chat_id}/chat-participants`)
  return response.data
}

async function getAttendances(profissional_id: string){
  const response = await axiosTeleChat.get(`/attendance/finished-history?professionalPublicId=${profissional_id}`)
  return response.data
}

async function postSendMessage(chatId: string, body: MessageProps){
  const response = await axiosTeleChat.post(`/chat/${chatId}/message`, body)
  return response.data
}
async function putReadMessage(chatId: string, offset: number){
  const response = await axiosTeleChat.put(`/chat/${chatId}/message/read?offset=${offset}`)
  return response.data
}

async function postSendFiles(chatId: string, body: any){
  const response = await axiosTeleChat.post(`/chat/${chatId}/message/attachment`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  })
  return response.data
}

async function postSendExamFilesInsideChat(chatId: string, body: any){
  const response = await axiosTeleChat.post(`/chat/${chatId}/message/share-attachments`, body)
  return response.data
}

export function SHARE_FILES_CHAT(body: any, chat_id: string){
  return {
    url: process.env.REACT_APP_API_URL_TELEHEALTH + `chat/${chat_id}/message/attachment`,
    options: {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('telehealth@token')}`
      },
      body: body
    },
  };
}


export { postShareExam, postCreateChat, postSendMessage, postSendFiles, getParticipant, getAttendances, 
  getChatParticipants, putReadMessage, postSendExamFilesInsideChat}
