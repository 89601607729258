import React from 'react';
import * as S from './styles'
import IconLife from 'components/Icon';


type Props = {
  placeholder:string; 
  maxLength?: number | undefined
  error?: string | undefined | null;
  type?:string; 
  value?:any;
  borderColor?: string;
  borderColorOnFocus?: string;
  backgroundColor?: string;
  /**
   * Import iconLeft as ReactComponet from svg
   */
  iconLeft?: any;
  iconRight?: any;
  iconSize?: any;
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onChange?: React.FocusEventHandler<HTMLInputElement> | undefined;            
  labelFixed?: string
  noLabel?: boolean
  disabled?: boolean
  style?: React.CSSProperties
}

const InputNoLabel = ({style, value, error, maxLength, placeholder, type='text', onFocus, onBlur, onChange, iconLeft=null, iconRight=null, disabled}: Props) => {
  return <>
    <S.Label>
      <input style={style} value={value} placeholder={placeholder} maxLength={maxLength} onChange={onChange} type={type} onFocus={onFocus} onBlur={onBlur} />
      {error && <S.ErrorMsg>{error}</S.ErrorMsg>}
    </S.Label>
  </>
}

export default InputNoLabel