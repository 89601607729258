import { InputHTMLAttributes } from 'react';
import { Label, Wrapper } from './styles';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  value?: string;
  children?: React.ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  color?: any;
  pathSvg?: string;
  checked?: boolean | undefined;
  borderColor?: string;
  iconColor?: string;
}

const Checkbox = ({
  label,
  color,
  checked,
  value,
  children,
  onChange,
  borderColor,
  iconColor,
}: CheckboxProps) => {
  return (
    <Wrapper
      className={checked ? 'active' : undefined}
      borderColor={borderColor} iconColor={iconColor}>
      <input
        type="checkbox"
        onChange={onChange}
        id={label}
        checked={checked}
        value={value}
      />
      <Label htmlFor={label}>
        {children && <p style={color ? { color: color, fontWeight: 600 } : {}}>
          {children}
        </p>}
      </Label>
    </Wrapper>
  );
};

export default Checkbox;
