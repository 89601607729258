import * as S from './styles'
import React from 'react'
import { ReactComponent as IconPlus } from '../../assets/ic-plus.svg';
import FormAdress from '../FormAdress/index';
import { v4 as uuidv4 } from "uuid"
import CardAdressAccount from 'components/CardAdressAccount';
// import { GET_ADDRESS } from 'api/Services/Profile';
import AddressFormEdit from 'components/AddressFormEdit';
import { ListAdressProps } from 'contexts/HomeContext';
import { AdressProps, editProps } from './types';
import { CALL_ENPOINT } from 'api/Services/CallEndpoint';
import { headerAppToken } from 'api/headers/HeaderAppToken';


const AddAdress = () => {
  const [adress, setAdress] = React.useState<AdressProps[] | []>([])
  
  const [ isEdit, setIsEdit ] = React.useState<editProps[] | []>([])
  
  // const { listAddress, setListAddress } = useHomeContext()
    //Lista de Endereços dadados da conta
    const [ listAddress, setListAddress ]  = React.useState<ListAdressProps[] | []>([])
  
  //Function add address form
  function addAdress() {
    let newAddress = Object.assign({}, {} as AdressProps)
    newAddress.id = uuidv4()
    setAdress([...adress, newAddress])
  }
  
  
  //remove adress
  function removeAdress(index: string) {
    let result = adress.filter(( item ) => item.id !== index )
    setAdress(result)
  }
  
  //
  async function saveNewAdress() {
    const result = await ListAddressRegistered()
    if(result) setListAddress(()=> result)
  } 
  
  //load address registed
  React.useEffect( () =>{
    //if(listAddress.length > 0) saveNewAdress()
    saveNewAdress()
  }, [])
  
  
  React.useEffect(()=> {
    listAddress.map( item => {
      const data = Object.assign( {}, {id: item.id, value: false} )
      setIsEdit((prevItem) => [...prevItem, data])
    } )
  }, [listAddress])
  
  //list address registed
  const ListAddressRegistered = async() => {
    try {
      // const { url, options } = await GET_ADDRESS()
      const { url, options } = CALL_ENPOINT('ONBOARDING_URL',`api/clientes/enderecos`,'GET',null, headerAppToken(localStorage.getItem('lifeplace@token') as string))

      const response = await fetch(url, options)
      const json = await response.json()
      
      if ( response.ok) {
        
        return json
      }
    } catch(err) {
      //  console.log(err)
    }
  }
  
  //function edit address
  function editar(item: boolean, id: number) {
    const modifyData = isEdit.map( addr => {
      if( addr.id == id ){
        return {id: addr.id, value: item}
      }
      return addr
    } )
    
    setIsEdit(modifyData)
  }
  
  return ( 
    <>
      {/* enderecos cadastrados */}
      {
      listAddress.length > 0 && listAddress.map(( item, index ) =>{
      
        if(isEdit.filter( addr => addr.id === item.id)[0]?.value ) return <AddressFormEdit addr={item} btnClose={editar} saveNewAdress={saveNewAdress}  key={item.id} />
      
        return <CardAdressAccount
          listAddress={listAddress}
          setListAddress={setListAddress} 
          key={index}
          cep ={ item.cep}
          logradouro= {item.logradouro}
          numero = {item.numero}
          bairro = {item.bairro}
          complemento = {item.complemento}
          estado = {item.localidade}
          uf = {item.uf}
          id={item.id}
          enderecoPadrao={item.enderecoPadrao}
          functionEdit={editar}
      />
      })
  
    }
      {
      adress && adress.map((item)=>{
        return <FormAdress saveNewAdress={saveNewAdress}  idForm={item.id}  btnClose={()=> removeAdress(item.id)} key={item.id} />
      })
    }
    
      {/* componente para adcionar endereço */}
      <S.Wrapper>
        <S.Card onClick={addAdress}>
          <IconPlus />
          <p>Adicionar endereço</p>
        </S.Card>
      </S.Wrapper> 
    </>
    
    )
  }
  
  export default AddAdress;