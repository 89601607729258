import styled from "styled-components";

type Props = {
    backgroundColor: string
}
export const Container = styled.div<Props>`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: fit-content;
    padding: 8px;
    background: ${(props) => props.backgroundColor};
    border-radius: 100px;
`
export const Text = styled.p`
    font-size: 14px;
    margin-right: 8px;
`