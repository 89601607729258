import styled from "styled-components";


export const ChatButton = styled.button`
  padding: 12px, 24px, 12px, 24px ;
  height: 48px;
  background: #2B45D4;
  border-radius: 100px;
`
export const ChatButtonText = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF !important;
`

export const Wrapper = styled.div`

  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.88);
  }

  .HeaderContent {
    margin-bottom: 48px;
    display: flex;
    justify-content: space-between;

    h2 {
      font-weight: 600;
      font-size: 28px;
      line-height: 40px;
      color: var(--primary);
    }

    button {
      width: 278px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background-color: var(--primary);
      color: #fff;

      svg {
        margin-right: 10px;
      }

      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 4px;
      }
    }
  }

  @media (max-width: 640px) {
    .HeaderContent {
      flex-direction: column;
      gap: 1rem;
      h2 {
        font-size: 24px;
      }
    }
  }
`


export const Wrappe = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  margin: 40px 0 32px 0;
  display: flex;
  flex-direction: column;
  transition: all 0.2s;
`;

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0 !important;
  transition: all 0.2s;
  cursor: pointer;

  p{
    font-weight: 400;
  }

  div {
    flex: 1;
  }
`;

export const ModalWrapper = styled.div`
  max-width: 416px;

  h1 {
    font-size: 24px;
    line-height: 40px;
    font-weight: 600;
    color: rgba(0,0,0,0.8);
  }

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: rgba(0,0,0,0.64);
  }

  .icon-file{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  
  .file-input {
    margin: 24px 0;
    width: 100%;
  }
`

export const BoldText = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 24px;
  color: #3453ff !important;
`;

export const Content = styled.section<{ isExpanded: boolean }>`
  display: ${(props) => (props.isExpanded ? "flex" : "none")};
  font-family: Open Sans;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px 0 12px 0;
  transition: all 0.2s;

  p{
    font-weight: 400;
  }
`;

export const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.64);
`;

export const Text = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.64);
  margin-right: 10px;
`;


export const ContentWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 150px 1fr;

  @media (max-width:880px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

`
export const Other = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Share = styled.button`
  display: flex;
  align-self: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2B45D4;
  cursor: pointer;
  background-color: transparent;

  @media (max-width:880px) {
      justify-content: center ;
  }
`
export const BodyContent = styled.div`

  .contentItem {
    display: flex;
    margin-top: 1rem;
    height: 56px;
    background: #fff;
    padding: 1rem;
    border-radius: 8px;

    &.mobile {
      display: none;
    }

    .item {
      display: flex;
      align-items: center;
      justify-items: center;
      margin-right: 26px;

      svg {
        margin-right: 10px;
      }

      svg path {
        fill: var(--primary);
      }

      span {
        font-family: Open Sans;
        color: rgba(0, 0, 0, 0.56);
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
      }
    }
  }

  .professional {
    margin: 24px 0;
    height: 96px;
    background: #fff;
    padding: 1rem;
    border-radius: 8px;

    > p {
      margin-bottom: 1rem;
      color: rgba(0, 0, 0, 0.88);
      font-weight: 600;
      font-size: 16px;
    }

    .contentIcon {
      display: flex;
      gap: 26px;

      div {
        display: flex;
        align-items: center;

        svg {
          margin-right: 10px;
        }

        span {
          font-weight: 600;
          font-size: 15px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.56);
        }
      }
    }
  }

  @media (max-width: 600px) {
    .contentItem {
      &.mobile {
        display: block;
      }
      
      .item:nth-child(2) {
        display: none;
      }
    }
  }
`

export const Btn = styled.button`
      width: 278px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      background-color: #3453FF;
      color: #F44336 !important;

      svg {
        margin-right: 10px;
      }

      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 4px;
      }
`

export const Footer = styled.footer`
  display: flex;
  gap: 16px;
  margin-top: 26px;
  margin-bottom: 60px;
  justify-content: flex-end ;
`