import AlertChat from 'components/AlertChat';
import InputWithFile from 'components/InputWithFile';
import React from 'react'
import CardInfoDetails from '../CardInfoDetails';
import { ReactComponent as IconSend} from "../../../assets/mai-ic-send.mono.svg";
import * as S from './styles'
import Message from './Message';
import SubmittedFile from './SubmitedFile';
import { checkIfTheLastMessage, groupMessages, groupMessagesForDate, MessageProps, ParticipanteWithMessagesProps } from 'utils/Chat';
import { PersonChat } from '..';
import { getAttendances, getChatParticipants, getParticipant, postCreateChat, postSendFiles, postSendMessage, putReadMessage } from 'api/intancesAxios/chat';
import LifeLottie from "pages/Home/HomeContent/LifeLottie";
import load from "../../../assets/Animation/load.json";
import ContainerMessage from './ContainerMessage';
import AddFilesInChat from '../AddFilesInChat';
import Attendances, { AttendanceSelected } from './Attendances';
import { propsData } from 'pages/AppointmentHistory/Details/types';
import { month} from 'utils/Date/DayOfWeek';
import { ReactComponent as IconBack } from "../../../assets/backIcon.svg";
import { ReactComponent as IconMessage } from "../../../assets/message.svg";
import ButtonBack from 'components/ButtonBack';
import IconLife from 'components/Icon'

type Props = {
  doctorChat: PersonChat
  mobileLayout: boolean
  onClickBackButton?: () => void
}

const RightContent = ({doctorChat, mobileLayout, onClickBackButton}: Props) => {


  const [attendances, setAttendances] = React.useState<propsData[]>([]);
  const [, setProfessionalId] = React.useState("")

  const [message, setMessage] = React.useState('')
  const [messages, setMessages ] = React.useState<MessageProps[]>([])
  const [numberOfMessages, setNumberOfMessages] = React.useState(() => 10)
  const [loading, setLoading] = React.useState(false)
  const [CRUD, setCRUD] = React.useState(false)

  // Files
  const [filesChat, setFilesChat] = React.useState<File[]>([]);
  const [showModalToSendFile, setShowModalToSendFile] = React.useState<boolean>(false);
  const [showModalToShowAttendance, setShowModalToShowAttendance] = React.useState<boolean>(false);

  //items for modal
  const [selected, setSelected] = React.useState<AttendanceSelected>({} as AttendanceSelected)

  //id
  const [myUuid, setMyUuid] = React.useState("")
  const [quantityMsgs, setQuantityMsgs] = React.useState(0)


 //################## ENVIO DE MENSAGENS
 const handleSubmitMessage = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    postSendMessage(doctorChat.chat_id.toString(), {body: message, type: "text"})
    .then(json => {
      setMessage("")
      setCRUD(!CRUD)
      getMessages()
    })
    .catch(e => console.log("erro no envio da msg ", e))
  }

  const getMessages = () => {
    setLoading(true)
    getParticipant("", `chatInfo=true&chatId=${doctorChat.chat_id}&listAll=true`).then((participante) => {
      postCreateChat({chatId: doctorChat.chat_id.toString()
        , attendanceId: null, participanteId: "",
        offset: 0, size: 10
        }).then((json: any) => {
          //type was ParticipanteWithMessagesProps
          if(json.messages){
            // obtenhos mensagens
            setQuantityMsgs(json.last_viewed_offset)
            setMessages([...json.messages.content.reverse()])
            setMyUuid(json.requester_uuid)
            //ler msgs
            lerMessagem(json.messages.content[json.messages.content.length - 1].offset)

          }
        })
    }).finally(() => {
        setLoading(false)
    })
  }



  //################## ENVIO DE ARQUIVOS
 const getPromises : any = () => {
  let arr = filesChat.map(file => {
    const formData = new FormData()
    formData.append('file', file as any);
    return postSendFiles(doctorChat?.chat_id.toString(), formData)
  })
  return arr
 }

 const shareFiles = async () => {
  let promises = getPromises();
  Promise.all(promises).then(() => {
    setShowModalToSendFile(false)
    setFilesChat([])
    getMessages()
    setCRUD(!CRUD)
  }).catch((e) => console.log("erro no envio do anexo"))
 }



 const filtrarMedico = (arr: any[]) => {
   let profs = arr.filter(item => item.participante_type === 'Profissional')
   return profs[0]
 }

 const buscarAtendimentos = async () => {
    await getChatParticipants(doctorChat.chat_id.toString()).then(res => {
      // dos participantes obtenha o medico
      let profissional = filtrarMedico(res)
      setProfessionalId(profissional.participante_id)
      getAttendances(profissional.participante_id).then(json => {
       setAttendances([...json.content])

       let text = `${json.content[0].specialty.name} ${json.content[0].prettySchedulingDate}`
       setSelected({id: json.content[0].id, publicId: json.content[0].publicId, textSelected: text })

      }).catch(e => console.log("Erro na busca dos atendimentos"))
    }).catch(e => console.log(e))
 }

  const lerMessagem = async (offset: number) => {
    await putReadMessage(doctorChat.chat_id.toString(), offset)
  }

  React.useEffect(() => {
    getMessages()  
    // lerMessagem()
    return () => {
      setMessages([])
    }
  }, [doctorChat.chat_id, numberOfMessages])

   const [scroll, setScroll] = React.useState(false)

   //  Observer para scroll infinito 
   React.useEffect(() => {
    let intersectionObserver = new IntersectionObserver((entries) => {
      if(entries.some(entry => entry.isIntersecting)){ //se tocou 
        if(messages.length <= quantityMsgs){
          console.log(messages.length, quantityMsgs);
         setNumberOfMessages((prev) => prev + 10)
        }
      }
    })
    let el = document.querySelector('.sentinela')
    if(el){
      intersectionObserver.observe(el)
    }
    
    return () => intersectionObserver.disconnect()
  }, [])


  // Automatic scroll down
  React.useEffect(() => {
    const el1 = document.querySelector('.box')
    if(el1 && scroll){
      el1.scrollTop = el1.scrollHeight;
    }
  })


  const getDateFormated = (date: string) => {
    let arr = date.split("-") //dia mes ano
    //22 de julho de 2022
    return `${arr[0]} de ${month[parseInt(arr[1])-1]} de ${arr[2]}`
  }

  React.useEffect(() => {
    buscarAtendimentos()
  }, [doctorChat.chat_id])

  return (
    <>
      <S.Container>
        {/* Header */}
        {mobileLayout && <S.Header>
          <ButtonBack 
            onClick={onClickBackButton} 
            icon={<IconLife color='#FF0073' icon={<IconBack/>}/>}><S.TextHeader>Voltar</S.TextHeader></ButtonBack>
          <S.Title>
            <IconLife icon={<IconMessage/>} color={"#2B45D4"}/>
            Mensagens
          </S.Title>
        </S.Header>}
        {/* Card detalhes da consulta  */}
        <CardInfoDetails onClick={() => {
          setShowModalToShowAttendance(true)
          }} specialty={"-"} numberOfMsgs='2' name={doctorChat.participants[0]}/>
        
        <AlertChat text='Alguns profissionais podem levar mais tempo para responder às mensagens. Consulte nossa seção de Ajuda se tiver dúvidas sobre a troca de mensagens' backgroundColor='rgba(255, 176, 0, 0.16)'/>

        {/* Container Chat */}
        <S.ContainerChat>

          {/* Messages */}
          <S.ContainerMessages className='box'>
            <div className="sentinela"></div>
            {
            messages && messages.map((msg,index) => {
              // Dia da mensagem enviada
              return <div key={`${msg.date_sended+msg.text_or_attachment_name+index}`} >
                {
                !groupMessagesForDate(messages, index)  && <S.ContainerDate>
                  <S.MsgDate><S.TextDate>{getDateFormated(msg.date_sended.split(" ")[0])}</S.TextDate></S.MsgDate>
                </S.ContainerDate>
                }
                {/* verificação do emissor */}

                <ContainerMessage
                  alignSelf={mobileLayout ? undefined : msg.sender_uuid === myUuid ? "flex-end" : "flex-start"} key={`${msg.date_sended+msg.text_or_attachment_name+index}`}>
                  {msg.type === 'text' ?
                    <Message 
                      color={myUuid === msg.sender_uuid ? '#fff' : "rgba(0, 0, 0, 0.88)"} 
                      background={myUuid === msg.sender_uuid ? '#2B45D4' : "#fff"} 
                      emissor={!groupMessages(messages, index) ? 
                        msg.sender_uuid === myUuid 
                        ? 
                       "Você" 
                       : `${msg.senderName}` : ""} 
                      date={checkIfTheLastMessage(messages, index) ? msg.date_sended.split(" ")[1] : ""} 
                      msg={msg.text_or_attachment_name} 
                      />
                      :
                    <SubmittedFile background='#2b45D4' date={checkIfTheLastMessage(messages, index) ? msg.date_sended.split(" ")[1] : ""} msg='1 arquivo adicionado' 
                      fileName={msg.text_or_attachment_name} color='#fff'/>}
                </ContainerMessage>
              
               
              </div>
            })
           }

            { loading &&
            <S.Loading>
              <LifeLottie animationData={load} height={65} width={65} />
            </S.Loading> }
          </S.ContainerMessages>

      
        </S.ContainerChat>
        {/* Bottom */}
        <form onSubmit={(e) => handleSubmitMessage(e)}>
          <S.Footer>
            {/* Input */}
            <InputWithFile value={message} 
              handleClickOnIcon={() => setShowModalToSendFile(true)} onChange={(e) => setMessage(e.target.value)} 
              style={{margin: 0, height: '48px', width: '100%'}} placeholder='Digite sua mensagem'/>
            {/* Icon Send */}
            <S.ContainerIcon>
              <S.Button type='submit' disabled={!message}>
                <IconSend/>
              </S.Button>
            </S.ContainerIcon>
          </S.Footer>
        </form>
      </S.Container>
      <AddFilesInChat 
        shareFiles={shareFiles} 
        files={filesChat} setFiles={setFilesChat} 
        showModal={showModalToSendFile} setShowModal={setShowModalToSendFile} />

      {
        attendances.length > 0 && <Attendances 
          selected={selected}
          setSelected={setSelected}
          mobileLayout={mobileLayout}
          doctorChat={doctorChat} 
          attendances={attendances} 
          setShowModalToShowAttendance={setShowModalToShowAttendance} 
          showModalToShowAttendance={showModalToShowAttendance}/>
      }
    </>
  )
}

export default RightContent