import { putReadMessage } from 'api/intancesAxios/chat'
import React from 'react'
import { PersonChat } from '..'
import CardUser from './Card'
import * as S from './styles'

type Props = {
  setDoctorChat: React.Dispatch<React.SetStateAction<PersonChat>>
  participantes: any
  doctorChat: PersonChat
  callbackOnClick?: () => void
}
const ListOpenChat = ({setDoctorChat, doctorChat, participantes, callbackOnClick}: Props) => {
  const formatDate = (date: string) => {
    let newDate = date.split('-')
    let str = newDate[0].concat('/').concat(newDate[1])
    return str
  }
  return (
    <S.Container>
      {
        participantes && participantes.map((p: PersonChat) => {
          if(p.participants === null){
            return ""
          }
          let name =  p.participants.join()
          if(p.participants.length >= 2){
             name = p.participants.map(nome => {
              return nome.split(" ")[0]
             }).join()

             if(name.length >= 21){
                name = name.slice(0,21).concat('...')
             }
            
          }else{
            name =  p.participants[0].split(" ").splice(0,2).join(" ")
          }
          return <CardUser 
            isGroup={p.participants.length === 1 ? false : true}
            active={doctorChat.chat_id ? doctorChat.participants.join() ===  p.participants.join() : false}
            key={p.chat_id}
            onClick={() => {
              setDoctorChat(p)
              if(callbackOnClick){
                callbackOnClick()
              }
            }}
            dateLastMessage={p.last_message ? formatDate(p.last_message.date_sended) : "" }
            typing=''
            lastMessage={p.last_message ?
              p.last_message.text_or_attachment_name :
              ""
            } name={name} numberOfMsgs={p.unread_messages > 0 ? p.unread_messages?.toString() : null} />
        })
      }
    </S.Container>
  )
}

export default ListOpenChat