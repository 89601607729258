import React from 'react'
import Icon from '../../assets/User.svg'
import {Container, ContainerIcon} from './style'
type Props = {
    icon?: any,
    size?: string,
    backgroundColor?: any;
    radius?: string;
    onClick?: () => void;
    style?: React.CSSProperties;
}
const index = ({icon=Icon, style, size="20px", backgroundColor, radius, onClick}: Props) => {
  return (
    <Container style={style} backgroundColor={backgroundColor} radius={radius} onClick={onClick}>
      <ContainerIcon 
        height={size} 
        width={size}
        icon={icon} 
     />
    </Container>
  )
}

export default index