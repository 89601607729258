import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

  :root {
    --fundo: #F6F6F9;
    --color-maida-ascent: #FF0073;
    --background: #fff;
    --primary: #2B45D4;
    --primary-dark: #2B45D4;
    --primary-light: #3453FF;
    --life-primary: rgba(204, 41, 110, 0.08);
    --life-detail: #E5833C;
    --life-detail-light: #FF9243;
    --life-secondary: #FFE4AF;
    --life-secondary-dark: #E5CD9D;
    --life-secondary-light: #FFD47F;
    --life-alert-success: #38B449;
    --life-alert-warning: #FDB81E;
    --life-alert-error: #F44336;
    --life-neutral-black-88: rgba(0, 0, 0, 0.88);
    --life-neutral-black-56: rgba(0, 0, 0, 0.56);
    --life-neutral-black-64: rgba(0, 0, 0, 0.64);
    --life-neutral-black-40: rgba(0, 0, 0, 0.4);
    --life-neutral-black-16: rgba(0, 0, 0, 0.16);
    --life-neutral-white: #fff;
    --life-neutral-white-background: #F8F8FA;

  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }
    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }
  body {
    background: #F6F6F9;
    -webkit-font-smoothing: antialiased;
    font-family: 'Open Sans', sans-serif;
  }
  body a {
    text-decoration: none;
  }
  body, p, input , button {
    font-family: 'Open Sans', sans-serif;
  }
  
  button {
    cursor: pointer;
    border: none;
  }
  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

.mt20 {
  margin-top: 20px;
}
.mt50 {
  margin-top: 50px;

  @media (max-width: 768px) {
    margin-top: 25px;
  }
}

.component {
  margin: 40px auto;
  height: auto;
}

.notAvailable {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #E5833C;
  margin-left: 12px;
}
.off {
  opacity: 0.56;
  pointer-events: none;
}
`;
