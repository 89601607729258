import Header from './Header/Index';
import { Container } from './styles';
import HomeContent from './HomeContent/Index';

const Index = () => {
  return (
    <div> 
      <Container>
        <Header />
        <HomeContent />
      </Container>  
    </div>
  );
};

export default Index;
