import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column ;
  gap: 16px;
`
export const Box = styled.div`
  display: flex;
  flex-direction: column ;
  gap: 8px;
`

export const Title = styled.h1`
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);
`
export const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
`

export const Info = styled.div`
  display: flex;
  flex-direction: column ;
  gap: 8px;
`

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column ;
  gap: 8px;
`
export const ContainerIcon = styled.div`
  min-width: 40px;
  height: 40px ;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center ;
  background: #2B45D4;

  path {
    fill: #fff;
  }
`