import * as S from './styles'
import { ReactNode } from 'react'
import { ReactComponent as CalendarDraw } from '../../assets/img-festival-calendar.svg';
import { ReactComponent as IconCalendar } from '../../assets/ic-calendar.mono.svg';
import { ReactComponent as IconHistory } from '../../assets/ic-history.mono.svg';
import { ReactComponent as IconDoctor } from '../../assets/ic-doctor.mono.svg';

interface DetailsProps {
  children: ReactNode;
  date: string,
  hour: string,
  doctor: string,
  specialty: string,
}

const CardQueryDatails = ({children, date, hour, doctor, specialty}:DetailsProps) => {
  return (
    <S.Wrapper>
      <CalendarDraw />
      <p>
        Consulta agendada para <strong>{specialty}</strong>
      </p>

      <div className='contentDetails'>
        <ul>
          <li>
            <IconCalendar />
            {date}
          </li>

          <li>
            <IconHistory />
            {hour}
          </li>

          <li>
            <IconDoctor />
            {doctor}
          </li>
        </ul>

        <div>
          {children}
        </div>
      </div>
    </S.Wrapper>
  )
}


export default CardQueryDatails;