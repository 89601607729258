import styled from "styled-components";

export const Content = styled.div`
    display: flex;
    flex-direction: column ;
    gap: 16px ;

    p {
        color: rgba(0, 0, 0, 0.64);
    }
`

export const Container = styled.div`
    display: flex;
    justify-content: center ;
    align-items: center;
`

export const Footer = styled.div`
    cursor: pointer;
    display: flex; 
    justify-content: center ;
    align-items: center;
`

export const Subtitle = styled.h3`
    color: var(--primary-light) !important;
    line-height: 24px;
    font-weight: 600 ;
`

export const Text = styled.p`
`