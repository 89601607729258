import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 100px;
`

export const Card = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  margin-top: 24px;
  display: flex;
  gap: 12px;
  cursor: pointer;

  p {
    color: rgba(0, 0, 0, 0.56);
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
`

export const ContentForm = styled.div`
  
  margin-top: 24px;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  

  .header {
    display: flex;
    justify-content: space-between;

    img, svg {
      cursor: pointer;
    }
  }

  .group {
    display: flex;
    gap: 24px;

    label {
      width: 100%;
    }
  }

  .group-3 {
    display: grid;
    grid-template-columns: 186px 232px 1fr;
    gap: 16px;
  }

  .contentBtn {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {  
      color: rgba(0, 0, 0, 0.56);
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      input {
        margin-right: 19px;
      }
    }
  }

  p {
    color: #2B45D4;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 660px) {
    .group {
      flex-direction: column;
    }
  }

  @media (max-width: 820px) {
    .group-3 {
      grid-template-columns: 1fr;
    }
  }
`