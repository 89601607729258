import React from 'react'
import Icon from '../../assets/ic-close-pink.svg'
import SvgIcon from '../SvgIcon/index'

import {Container, Text} from './styles'


const Tag = (prop: {label:string, backgroundColor: string, onClose: () => void}) => {
  
  const [show, setShow] = React.useState(true)

  return (
    <Container backgroundColor={prop.backgroundColor} style={{display: show ? 'flex' : 'none'}}>
      <Text>{prop.label}</Text>
      <SvgIcon icon={Icon} size="14px" onClick={() => {
        prop.onClose();
        setShow(false)}}/>
    </Container>
  )
}

export default Tag