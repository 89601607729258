import styled from 'styled-components'


export const Wrapper = styled.div`
  display: flex;
  gap: 137px;
  @media(max-width:1074px) {
    flex-direction: column;
  }
`

export const Menu = styled.div`
  max-width: 312px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  button {
    padding: 16px 27px;
    border-radius: 8px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    &.active {
      background-color: #2B45D4;
      color: #fff;
    }

    &:hover {
      background-color: #2B45D4;
      color: #fff;
    }
  }

  @media (max-width: 1074px) {
    max-width: 100%;
    flex-direction: row;
    margin-bottom: 32px;

    button {
      width: 100%;
    }
  }
`

export const Container = styled.div`
  width: 100%;
  height: 100vh;
`;

export const Content = styled.div`
  max-width: 1232px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  header {
    margin-top: 40px;
    margin-bottom: 48px;
  }

  @media(max-width:1280px) {
    padding: 24px;
  }

  @media(max-width: 600px) {
    header {
      margin: 24px 0px 24px 0px;
    }
  }
`;
