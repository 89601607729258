import React from 'react';
import { ReactComponent as IconCamOff } from '../../../../../assets/camera.svg';
import { ReactComponent as UserIcon } from '../../../../../assets/ic-user.svg';
import { ReactComponent as IconCamOn } from '../../../../../assets/cameraOn.svg';
import { ReactComponent as MicOn } from '../../../../../assets/micOn.svg';
import { ReactComponent as MicOff } from '../../../../../assets/micOff.svg';
import * as S from './styles';
import { useConsultsContext } from 'contexts/ConsultsContext';

const Video = () => {
  const [video, setVideo] = React.useState(false);
  const [mic, setMic] = React.useState(false);

  const { setDevice, setVideostream, setAudioStream } = useConsultsContext();

  React.useEffect(() => {
    if (video & mic) {
      setDevice(true);
    } else {
      setDevice(false);
    }
  }, [video, mic]);

  const startVideo = () => {
    setVideo(true);
    navigator.getUserMedia(
      {
        video: true,
      },
      (stream) => {
        let video = document.getElementsByClassName('app__videoFeed')[0];
        if (video) {
          video.srcObject = stream;
          setVideostream(stream);
        }
      },
      (err) => console.error(err),
    );
  };

  const stopVideo = () => {
    setVideo(false);
    let video = document.getElementsByClassName('app__videoFeed')[0];
    video.srcObject.getTracks()[0].stop();
  };

  const startMic = () => {
    setMic(true);
    navigator.mediaDevices.getUserMedia({ audio: 'true' }).then(
      (stream) => {
        setAudioStream(stream);
        
      },
      (err) => {
        console.log(err);
      },
    );
  };

  const stopMic = () => {
    setMic(false);
    navigator.mediaDevices.getUserMedia({ audio: 'false' }).then(
      (stream) => {
        console.log(stream);
      },
      (err) => {
        console.log(err);
      },
    );
  };

  return (
    <S.Wrapper>
      <div className="contentVideo">
        <video audio="false" autoPlay className="app__videoFeed"></video>
      </div>

      <S.userIconCOntainer>
        {video ? undefined : <UserIcon />}
      </S.userIconCOntainer>

      <div className="controls">
        {mic ? (
          <button onClick={stopMic} className="btn_audio">
            <MicOn />
          </button>
        ) : (
          <button onClick={startMic} className="btn_audio">
            <MicOff />
          </button>
        )}
        {video ? (
          <button onClick={stopVideo}>
            <IconCamOn />
          </button>
        ) : (
          <button onClick={startVideo}>
            <IconCamOff />
          </button>
        )}
      </div>
    </S.Wrapper>
  );
};

export default Video;
