import * as S from './styles'
import React from 'react'
import InputLife from 'components/InputLife';
import AddAdress from '../../../components/AddAdress';
import { mask } from 'utils/maskForInput';
import { Button } from 'storybook-maida';
import CardDataAccount from 'components/CardDataAccount';
// import { GET_DATA_ACCOUNT, POST_DATA_ACCOUNT, PUT_DATA_ACCOUNT } from 'api/Services/Profile';
import AlertBanner from 'components/AlertBanner/AlertBanner';
import { useHomeContext } from 'contexts/HomeContext';
import { validation } from '../../Consultations/ConsultUrgency/Number'
import { CALL_ENPOINT } from 'api/Services/CallEndpoint';
import { headerAppToken } from 'api/headers/HeaderAppToken';

interface Telefone {
  id: number,
  tipo: string,
  numero : string
}

interface dataUserProps {
  nomeCompleto : string ,
  nomePreferencia: string,
  cpf: string,
  dataNascimento: string,
  telefones : Telefone[],
  sobreNome: string,
  email: string
}
const AccountData = () => {

  const [nomeCompleto, setNomeCompleto ] = React.useState<string | undefined>('')
  const [nomePreferencia, setNomePreferencia ] = React.useState<string | undefined>('')
  const [cpf, setCpf] = React.useState<string | undefined>('')
  const [dataNascimento, setDataNascimento ] = React.useState<string | undefined>('')
  const [telefones, setTelefones] = React.useState<string | undefined>('')
  const [email, setEmail] = React.useState<string | undefined>('')

  const [ formEditAccount, setFormEditAccount ] = React.useState(false)

  const { alertUpdateAccount, setAlertUpdateAccount } = useHomeContext()

  const [ dataUser, setDataUser ] = React.useState<dataUserProps>()

//Formata Data de nascimento



function FormatDateBirth(date: string) {
  const value = new Date(date)
  const formated = (new Intl.DateTimeFormat('pt-br')).format(value);
  return formated
}

  //Máscara para CPF
  function handleChangeCPF(event: React.FocusEvent<HTMLInputElement, Element>) {
    const { value } = event.target
    setCpf(mask(value))
  }

  //Função para salvar dados da conta
  function handleSubmitDataAccount(e: any) {
    e.preventDefault()
    SaveDataAccount()
  }

  //Salva Dados da conta do usuário
  const SaveDataAccount = async() => {
    try {
      // const { url, options } = POST_DATA_ACCOUNT({nomeCompleto, nomePreferencia, cpf, dataNascimento, telefones, email})
      const { url, options } = CALL_ENPOINT('ONBOARDING_URL','api/clientes', 'POST', {nomeCompleto, nomePreferencia, cpf, dataNascimento, telefones: [{"numero": telefones}], email}, headerAppToken(localStorage.getItem('lifeplace@token') as string))
      
      const response = await fetch(url, options)
      const json = await response.json()
      
      if ( response.ok) {
        // console.log(json)
      }
    } catch(err) {
     console.log(err)
    }
  }

  const dataAccount = async() => {
    try {
      // const { url, options } = await GET_DATA_ACCOUNT()
      const { url, options } = CALL_ENPOINT('ONBOARDING_URL','api/clientes','GET',null, headerAppToken(localStorage.getItem('lifeplace@token') as string)) 
      const response = await fetch(url, options)
      const json = await response.json()
      
      if ( response.ok) {
        setDataUser(json)
        
      }
    } catch(err) {
     console.log(err)
    }
  }

  //editar dados da conta
  function editDataAccount(value: boolean) {
    setFormEditAccount(value)
  }


  const updateDataAccount = async() => {
    try {
      // const { url, options } = PUT_DATA_ACCOUNT({nomeCompleto, nomePreferencia, cpf, dataNascimento, telefones, email})
      const { url, options } = CALL_ENPOINT('ONBOARDING_URL','api/clientes', 'PUT', {nomeCompleto, nomePreferencia, cpf, dataNascimento, telefones: [{"numero": telefones}], email}, headerAppToken(localStorage.getItem('lifeplace@token') as string))
      
      const response = await fetch(url, options)
      const json = await response.json()
      
      if ( response.ok) {
        setDataUser(json)

        setAlertUpdateAccount(true)
    
        setTimeout(()=>{
          setAlertUpdateAccount(false)
        },2000)
        
      }
    } catch(err) {
     console.log(err)
    }
  }

  //update Data Account 
  function updateAccount() {
    updateDataAccount()
    setFormEditAccount(false)
  }

  function formatNumber(e: React.FormEvent<HTMLInputElement>){
    const value = validation(e.currentTarget.value) 
    setTelefones(value)
  }

  React.useEffect(()=>{
    setNomeCompleto(dataUser?.nomeCompleto)
    setNomePreferencia(dataUser?.nomePreferencia) 
    setCpf(dataUser?.cpf)
    setDataNascimento(dataUser?.dataNascimento)
    setTelefones(dataUser?.telefones[0]?.numero)
    setEmail(dataUser?.email)
  },[dataUser])

  React.useEffect(()=> {
    dataAccount()
  },[])

  return (
    <S.Content>
      {alertUpdateAccount && <AlertBanner label={'Dados atualizados!'} type={'success'} />}
      <h1>Dados da conta</h1>
      <p>
        A gente sabe como é chato preencher formulários, mas com esses dados atualizados garantimos atendimentos mais seguros e eficientes 😉
      </p>
      {/* Formulário Dados da conta   */}
      {
          formEditAccount ? ( dataUser && <div className="contentForm">
            <form onSubmit={handleSubmitDataAccount}>
              <div className='group'>
                <InputLife 
                  placeholder ='Digite seu nome'
                  borderColor={'#ddd'}
                  borderColorOnFocus={'#ddd'}
                  labelFixed ={'Nome completo'}
                  value={nomeCompleto}
                  onChange={(e)=> setNomeCompleto(e.target.value)}
                />

                <InputLife 
                  placeholder ='Digite seu nome social ou apelido'
                  borderColor={'#ddd'}
                  borderColorOnFocus={'#ddd'}
                  labelFixed={'Nome de preferência'}
                  value={nomePreferencia}
                  onChange={(e)=> setNomePreferencia(e.target.value)}
                />      
              </div>

              <div className='group'>
                <InputLife
                  value={cpf ? cpf : ''} 
                  maxLength={14} 
                  onChange={handleChangeCPF} 
                  placeholder='Digite apenas os números do seu CPF'
                  labelFixed={'CPF'}
                />

                <InputLife 
                  type={'date'}
                  placeholder ='DD/MM/AAAA'
                  borderColor={'#ddd'}
                  borderColorOnFocus={'#ddd'}
                  labelFixed={'Data de nascimento'}
                  value={dataNascimento}
                  onChange={(e)=> setDataNascimento(e.target.value)}
                />      
              </div>

              <div className='group'>
                <InputLife 
                  placeholder ='Digite o DDD + Número'
                  borderColor={'#ddd'}
                  borderColorOnFocus={'#ddd'}
                  labelFixed={'Celular'}
                  value={telefones}
                  onChange={formatNumber}
                  maxLength={15}
                />

                <InputLife 
                  placeholder ='Digite o seu e-mail'
                  borderColor={'#ddd'}
                  borderColorOnFocus={'#ddd'}
                  labelFixed={'Email'}
                  value={email}
                  onChange={(e)=> setEmail(e.target.value)}
                  disabled={true}
                />      
              </div>

              <div className="contentBtn">
                <div>
                  <Button 
                    disabled={false}  
                    children={'Salvar'}
                    onClick={updateAccount}
                    style={{ 
                    width: 'fit-content !important', 
                  }}
                />
                </div>
              </div>
            </form>
          </div>):(
            dataUser &&
            <CardDataAccount 
              name = {dataUser.nomeCompleto}
              namePreference = {dataUser.nomePreferencia ? dataUser.nomePreferencia : '--' }
              cpf = {dataUser.cpf ? dataUser.cpf : '--'}
              dateBirth = {FormatDateBirth(dataUser.dataNascimento)}
              phone = {dataUser.telefones.length >= 1 ? dataUser.telefones[0].numero : '--' } 
              email = {dataUser.email}
              functionEdit={editDataAccount}
          />
          )
        }
        
      {/* Card dados do endereço */}
        

      {/* Card-buttom para adicionar endereço */}
      <AddAdress />
    </S.Content>
  )
}

export default AccountData