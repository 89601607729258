import styled from 'styled-components'

export const Item = styled.div`
    border-radius: 8px;
`
export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`

export const Header = styled.div`
    padding: 16px;
`

export const ContainerTitle = styled.div`
    display: flex;
    justify-content: center ;
    align-items: center;
`
export const Title = styled.h2`
    font-size: 14px;
    color: #85662b;
    font-weight: 600 ;
    line-height: 24px ;
    color: rgba(0, 0, 0, 0.88);
    margin-left: 12px ;
`
export const Subtitle = styled.p`
    margin-top: 8px;
    line-height: 16px;
    font-size: 14px ;
    font-weight: 400px;
    color: rgba(0, 0, 0, 0.64);
`
export const Content = styled.div`
    overflow: hidden ;
    margin-top: 16px ;
    display: flex ;
    flex-direction: column ;
    gap: 16px;
`

export const ContainerIcon = styled.div`
    display: flex;
    justify-content: center ;
    align-items: center;
`

export const ToggleText = styled.p`
    font-size: 12px ;
    font-weight: 400 ;
    margin-right: 10px ;
    color: rgba(0, 0, 0, 0.64);
`