import IconLife from 'components/Icon'
import React from 'react'
import * as S from './styles'
import { ReactComponent as IconSuccess } from '../../assets/mai-ic-success.mono.svg'
import { ReactComponent as IconClose } from '../../assets/ic-close.svg'
type Props = {
  onAnimationStart: React.AnimationEventHandler<HTMLDivElement> | undefined
  onAnimationEnd: React.AnimationEventHandler<HTMLDivElement> | undefined
  filename: string, 
  handleRemove: () => void
}
const FileProgress = ({filename, onAnimationEnd, onAnimationStart, handleRemove}: Props) => {
  return (
    <S.FilesData>
      <IconLife icon={<IconSuccess />} color="#2B45D4" />
      <S.ContainerInfo>
        <S.FileName>{filename}</S.FileName>
        <S.ProgressBar onAnimationStart={onAnimationStart} onAnimationEnd={onAnimationEnd}></S.ProgressBar>
      </S.ContainerInfo>
      <IconLife icon={<IconClose />} onClick={handleRemove} color="red" style={{ position: 'absolute', top: 0, right: 0, cursor: 'pointer' }} />
    </S.FilesData>
  )
}

export default FileProgress