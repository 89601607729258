import React from 'react'
import * as S from './styles'
import LifeLottie from '../../../Home/HomeContent/LifeLottie';
import consult from '../../../../assets/Animation/consult.json'
import Checkbox from 'components/Checkbox';
import Icon from '../../../../assets/checkbox-checked.svg'

interface Props {
  id: string;
  professional: string
  specialty: string
  date: string
  setSelect?: React.Dispatch<React.SetStateAction<boolean>>
  appointmentsSelecteds: string[]
  setAppointmentsSelecteds: React.Dispatch<React.SetStateAction<string[]>>
}

function Card({professional, date, specialty, appointmentsSelecteds, setAppointmentsSelecteds, setSelect, id}: Props) {
  
  const [checkboxSelected, setCheckbxSelected] = React.useState(false) 
  
  const handleCheckbox = (idAppointment: string) => {

    setCheckbxSelected(!checkboxSelected)
    //ha um delay no estado.. conceito de react mesmo
    // Verifico o estado antes dele mudar.. pq n da pra consulta o estador que virá a ser .. entao ele chega falso..
    //ele chega falso -> desmarcado e ao clicar .. no futuro sera true .. entao to MARCANDO
    if(!checkboxSelected){
      setAppointmentsSelecteds([...appointmentsSelecteds, idAppointment])
    }else{
      setAppointmentsSelecteds(appointmentsSelecteds.filter(id => id !== idAppointment))
    }
  }
  
  return (
    <S.Container style={{backgroundColor: checkboxSelected ? ' rgba(255, 176, 0, 0.16)':'#fff'}}>
      <S.Content>
        <LifeLottie animationData={consult} height={88} width={88} />
        <S.Info>
          <S.Title>{specialty}</S.Title>
          <S.Subtitle>Dra. {professional}</S.Subtitle>
          <S.Text>{date}</S.Text>
        </S.Info>
        <S.Checkbox>
          <Checkbox style={{alignSelf: 'flex-end'}} onChange={() => handleCheckbox(id)} borderColor="#2B45D4" iconColor="#2B45D4" checked={checkboxSelected} pathSvg={Icon}  color={checkboxSelected? "#2B45D4": ''} label={id}></Checkbox>
        </S.Checkbox>
      </S.Content>
    </S.Container>
  )
}

export default Card