import { CodeProps } from 'api/types/Auth/userAuth'
import axios from 'axios'

export const axiosAPIURL = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-ILY-API-appKey': process.env.REACT_APP_HEADER_APPKEY as string
  }
})


// axiosAPIURL.defaults.headers.common["Authorization"] = "Bearer " +  decrypt(read("telehealth@token"));

// // refresh token 
// // helper method to refresh token
// async function refreshToken() {
//   // place request to backend service to refresh token
//   const response = await axiosAPIURL.post("/auth/refresh-token", {});
//   // update stored instance
//   storeExpiry("telehealth@token", response.data.token, true);
//   // update axios instance with new token
//   axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.token}`;
// }

// axiosAPIURL.interceptors.request.use(
//   (config) => {
//     if(config.method){
//       console.log(`${config.method.toUpperCase()} Request made to ${config.url} with data:`, config.data);
//     }
//     if(config.headers){
//       config.headers.authorization = `Bearer ${localStorage.getItem('telehealth@token')}`  
//     }
//     return config
//   },
//   (error) => {
//     console.log(error);
//     Promise.reject(error)
//   }
// )

// axiosAPIURL.interceptors.response.use(
// 	function (response) {
// 		const { status, data, config } = response;
// 			console.log(`Response from ${config.url}:`, {
// 				code: status,
// 				...data,
// 			});
// 		return response;
// 	},
//   async function (error) {
// 		if (error.response) {
// 			const { status, data } = error.response;
		
// 			switch (status) {
// 			case 403:
// 				// check if 401 error was token
// 				if (data.message === "") {
// 					// token has expired;
// 					try {
// 						// attempting to refresh token;
// 						await refreshToken();
// 						// token refreshed, reattempting request;
// 						const config = error.config;
// 						// configure new request in a new instance;
// 						return await axios({method: config.method, url: config.url, data: config.data});
// 					} catch (e) {
// 						// console.log(e);
// 						return window.location.href = "/";
// 					}
// 				} else {
// 					return window.location.href = "/";
// 				}
// 			default:
// 				return Promise.reject(error);
// 			}
// 		} else if (error.request) {
// 			// The request was made but no response was received
// 			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
// 			// http.ClientRequest in node.js
// 			return Promise.reject(error);
// 		} else {
// 			// Something happened in setting up the request that triggered an Error
// 			return Promise.reject(error);
// 		}
// 	}
//   )



async function postAuthenticationGoogle(body:any) {
  const response = await axiosAPIURL.post(`auth/google`, body)
  return response.data
}
async function postAuthenticationApple(body:any) {
  const response = await axiosAPIURL.post(`auth/apple`, body)
  return response.data
}

async function postAuthenticationEmail(email:string) {
  const response = await axiosAPIURL.post(`auth/email`, {email})
  return response.data
}

async function postVerificationCode(code: CodeProps) {
  const response = await axiosAPIURL.post(`auth/checkCode`, code)
  return response.data
}


export { postAuthenticationGoogle, postAuthenticationEmail, postVerificationCode, postAuthenticationApple}
