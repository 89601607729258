import styled from "styled-components";

export const LinkContainer = styled.div`
        background: #f6f6f9;
        width: 100%;
        height: 56px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px 0 18px;
        border-radius: 8px;

        &:hover {
          background: var(--primary);

          span {
            color: #fff;
          }

          svg path {
            fill: #f6f6f9;
          }
        }

        > div {
          display: flex;
          align-items: center;

          span {
            color: rgba(0, 0, 0, 0.88);
            font-size: 1rem;
            font-weight: 600;
            margin-left: 18px;
          }
        }

        &:hover .numero {
          background: #fff;
          p {
            color: #2B45D4;
          }
        }
`

export const Count = styled.div`
  width: 19px ;
  height: 19px ;
  border-radius: 50% ;
  background: #2B45D4;
  margin-left: 16px ;

  display: flex;
  justify-content: center ;
  align-items: center ;
`

export const Number = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
`