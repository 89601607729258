import * as React from 'react';
import { useHomeContext } from 'contexts/HomeContext'
import ScreenComponent from '../Screen/Screen'
import {ContainerHelp, Question, Response, ContainerAvaliation, QuestionAvaliation, ContainerBtns,OptionContainer, OptionText, Title} from './styles'
import ButtonOutlined from 'components/Button/ButtonOutlined'
import Button from '../../../components/Button/Button'
import {ReactComponent as IconMsg} from '../../../assets/message.svg'
import { useNavigate } from 'react-router-dom';

const FullHelp = () => {
  const {faqSelected, categories} = useHomeContext()
  const filteredElement: any = categories.filter(el => el.title === faqSelected.category)[0]
  const responseIndex = categories.filter(el => el.title === faqSelected.category)[0].questions.findIndex((q:string) => q === faqSelected.question)
  const navigate = useNavigate()
  const [avaliou, setAvaliou] = React.useState<null | string>(null)

  return (
    <ScreenComponent title={faqSelected.category} icon={faqSelected.category}>
      <ContainerHelp>
        <Question>{faqSelected.question}</Question>
        <Response>{filteredElement.responses[responseIndex]}</Response>
      </ContainerHelp>
      { !avaliou && <><ContainerAvaliation>
        <QuestionAvaliation>
          Essa resposta te ajudou?
        </QuestionAvaliation>
      </ContainerAvaliation>
        <ContainerBtns>
          <ButtonOutlined variant='outlined' onClick={() => setAvaliou('sim')}>Sim</ButtonOutlined>
          <ButtonOutlined style={{ marginLeft: '24px'}} variant='outlined' onClick={() => setAvaliou('nao')}>Não</ButtonOutlined>
        </ContainerBtns></>}
      {avaliou === 'sim' && <OptionContainer>
        <Title>Agradecemos a avaliação!</Title>
        <OptionText>Sua resposta nos ajuda a melhorar os conteúdos para outras pessoas<span>😊</span></OptionText>

        </OptionContainer>}
      {avaliou === 'nao' && <OptionContainer>
        <Title>Agradecemos a avaliação!</Title>
        <OptionText>É uma pena que essa resposta não tenha ajudado, mas você ainda pode enviar sua dúvida para nossa equipe:</OptionText>
        <Button onClick={() => navigate('/faq/fale-conosco')} variant='primary' style={{width: '100%', marginTop: '36px'}}><span><IconMsg/></span>Fale com a gente</Button>
        
        </OptionContainer>}
    </ScreenComponent>
  )
}

export default FullHelp