import * as S from './styles';
import onboarding from '../../assets/onboarding.svg';
import FormLeftNome from '../Login/FormLeftNome';
import ButtonBack from 'components/ButtonBack';

import { ReactComponent as IconBack } from '../../assets/backIcon.svg';

type OrderProps = {
  ordem: boolean;
};

const Onboarding = ({ ordem }: OrderProps) => {
  return (
    <S.Container>
      <S.Content>
        <S.Header>
          <ButtonBack to={-1} icon={<IconBack />}>
            Voltar
          </ButtonBack>
        </S.Header>

        <S.ContentBox>
          <S.BoxLeft ordem={ordem}>
            <img src={onboarding} alt="Imagem de onboarding do Lifeplace" />
          </S.BoxLeft>

          <S.BoxRight ordem={ordem}>
            <FormLeftNome />
          </S.BoxRight>
        </S.ContentBox>
      </S.Content>
    </S.Container>
  );
};

export default Onboarding;
