import styled from 'styled-components'


export const ContentButton = styled.button`
  background: none;
  display: flex;
  font-size: 1rem;
  font-weight: 600;

  svg, img {
    margin-right: 14px;
  }
`