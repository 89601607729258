import axios from 'axios'

export const axiosDOCS = axios.create({
  baseURL: process.env.REACT_APP_API_DOCS,
  headers: {
    'Content-Type': 'application/json',
    'X-ILY-API-appKey': process.env.REACT_APP_HEADER_APPKEY as string,
  }
})

axiosDOCS.interceptors.request.use(async (config) => {
  if (config.headers) {
      config.headers['X-ILY-API-appToken'] = `Bearer ${localStorage.getItem('lifeplace@token')}`;
      return config;
  }
},
  error => {
      return Promise.reject(error);
  }
);

async function getExams(search: string, publicId:string, filter:any) {
  const response = await axiosDOCS.get(`files/history?publicIdUser=${publicId}&name=${search}${filter}&category=EXAMS`)
  return response.data
}

async function deleteExams(id:string) {
  const response = await axiosDOCS.delete(`files?ids=${id}`)
  return response.data
}

async function postExam(file: any, publicId: string) {
  const response = await axiosDOCS.post(`files?publicIdUser=${publicId}&category=EXAMS`, file)
  return response.data
}

async function putExam(id: string, body: any) {
  const response = await axiosDOCS.put(`files/${id}`, body)
  return response.data
}

async function getQuestions() {
  const response = await axiosDOCS.get(`opcoes-avaliacao`)
  return response.data
}






export { getExams, deleteExams, postExam, putExam, getQuestions}
