import styled from "styled-components";


export const Wrapper = styled.form<{}>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`

export const Search = styled.input<{}>`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  border-radius: 100px;
  padding: 8px 16px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
  min-width: 300px;

  &::placeholder {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.56);
  }

  &:focus {
    border-color: #2b45d4;
    outline: none;
  }
`;

export const Button = styled.button<{}>`
  display: block;
  width: fit-content;
  height: 32px;
  position: absolute;
  right: 16px;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;

  & svg {
    display: block;
  }
`;




export const SearchBar = styled.input `

`