import * as React from 'react';
import * as S from "./styles";
import ButtonBack from 'components/ButtonBack';
import { ReactComponent as IconBack } from '../../assets/backIcon.svg'
import AccountData from './AccountData';
import HealthData from './HealthData';
type pageType = 'accountData' | 'healthData'
const MyProfile = () => {
  const [pageType, setPageType] = React.useState<pageType>('accountData')
  
  return (
    <>
      <S.Container>

        <S.Content>
          <header>
            <ButtonBack icon={<IconBack />}>Voltar</ButtonBack>
          </header>
          <S.Wrapper>
            <S.Menu>
              <button onClick={() => setPageType('accountData')} className={pageType === 'accountData' ? 'active' : undefined}>Dados da Conta</button>
              <button onClick={() => setPageType('healthData')} className={pageType === 'healthData' ? 'active' : undefined}>Dados de Saúde</button>
            </S.Menu>
            {pageType === 'accountData' && <AccountData/>}
            {pageType === 'healthData' && <HealthData/>}
          </S.Wrapper>
        </S.Content>
      </S.Container>
    </>
  )
}

export default MyProfile