import styled from "styled-components";

export const Container = styled.div`
  /* background: var(--background); */
  background-color: var(--fundo);
  width: 100%;
  height: 100vh;
`;

export const Content = styled.div`
  max-width: 1232px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  header {
    margin-top: 40px;
    margin-bottom: 48px;
  }

  @media(max-width:1280px) {
    padding: 24px;
  }

  @media(max-width: 600px) {
    header {
      margin: 24px 0px 24px 0px;
    }
  }
`;
