import styled from 'styled-components'

export const Label = styled.label`
  position: relative ;
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2B45D4;

  input {
    padding: 16px;
    margin-top: 16px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    border-radius: 4px;
    outline: none ;
    font-size: 16px;
    height: 56px;
    width: 100%;

    ::placeholder{
        color: rgba(0, 0, 0, 0.56);
    }
  }
`

export const ErrorMsg = styled.p`
  position: absolute ;
  font-size: 12px !important ;
  line-height: 16px !important ;
  bottom: -12px;
  left: 3px;
  color: #F44336 !important;
  margin-top: 4px ;
`