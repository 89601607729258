import styled from "styled-components";

type Props = {
    size?: number;
    backgroundColor?: string;
    color?: string; 
    radius?: string;
}
export const Container = styled.div<Props>`
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.radius ? props.radius : 0};
`
export const Icon = styled.span<Props>`
  svg {
    width: ${(props) => props.size+"px"} ;
    height: ${(props) => props.size+"px"} ;
    vertical-align: middle ;

  }
  path {
    fill: ${(props) => props.color ? props.color : 'black'} ;
  }
`