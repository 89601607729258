import { Button } from 'storybook-maida'
import * as S from './styles'

interface AccountProps {
  name: string,
  namePreference: string,
  cpf: string,
  dateBirth: string, 
  phone: string,
  email: string 
  functionEdit: (value:boolean) => void
}

const CardDataAccount = ({ name , namePreference, cpf, dateBirth, phone, email, functionEdit}: AccountProps) => {
  return (
    <S.Wrapper >
      <h3>Seus dados</h3>
      <div className="group">
        <div>
          <p>Nome completo</p>
          <span>{name}</span>
        </div>
        <div>
          <p>Nome de preferência</p>
          <span>{namePreference}</span>
        </div>
      </div>

      <div className="group">
        <div>
          <p>CPF</p>
          <span>{cpf}</span>
        </div>
        <div>
          <p>Data de nascimento</p>
          <span>{dateBirth}</span>
        </div>
      </div>

      <div className="group">
        <div>
          <p>Celular</p>
          <span>{phone}</span>
        </div>
        <div>
          <p>E-mail</p>
          <span>{email}</span>
        </div>
      </div>

      <div className="contentBtn">
        <div>
          <Button children={'Editar'} variant={'outlined'} onClick={()=> functionEdit(true)}
          />
        </div>
      </div>
    </S.Wrapper>
  )
}

export default CardDataAccount