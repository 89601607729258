
//Cadastrar endereço
// export function POST_ADDRESS(body: any) {
//   return {
//     url: process.env.REACT_APP_ONBOARDING_URL + `api/clientes/enderecos`, 
//     options: {
//       method: "POST",
//       headers: {
//         'Content-Type': 'application/json',
//         'X-ILY-API-appKey': 'ily-appkey-front-UiGypZVy' ,
//         'X-ILY-API-appToken': `Bearer ${localStorage.getItem('lifeplace@token')}`,
//       },
//       body: JSON.stringify(body)
//     }
//   };
// }

//Atualizar endereço
// export function PUT_ADDRESS(id: number, body: any) {
//   return {
//     url: process.env.REACT_APP_ONBOARDING_URL + `api/clientes/${id}/enderecos`, 
//     options: {
//       method: "PUT",
//       headers: {
//         'Content-Type': 'application/json',
//         'X-ILY-API-appKey': 'ily-appkey-front-UiGypZVy' ,
//         'X-ILY-API-appToken': `Bearer ${localStorage.getItem('lifeplace@token')}`,
//       },
//       body: JSON.stringify(body)
//     }
//   }
// }

// //Excluir endereço
// export function DELETE_ADDRESS(id: number) {
//   return {
//     url: process.env.REACT_APP_ONBOARDING_URL + `api/clientes/${id}/enderecos`, 
//     options: {
//       method: "DELETE",
//       headers: {
//         'Content-Type': 'application/json',
//         'X-ILY-API-appKey': 'ily-appkey-front-UiGypZVy' ,
//         'X-ILY-API-appToken': `Bearer ${localStorage.getItem('lifeplace@token')}`,
//       },
//     }
//   }
// }

//Lista endereços Cadastrados 
// export function GET_ADDRESS() {
//   return {
//     url: process.env.REACT_APP_ONBOARDING_URL + `api/clientes/enderecos`, 
//     options: {
//       method: "GET",
//       headers: {
//         'Content-Type': 'application/json',
//         'X-ILY-API-appKey': 'ily-appkey-front-UiGypZVy' ,
//         'X-ILY-API-appToken': `Bearer ${localStorage.getItem('lifeplace@token')}`,
//       }
//     }
//   };
// }

// //Cadastrar Dados do Usuário
// export function POST_DATA_ACCOUNT(body: any) {
//   const formatTelefones = [
//     {
//       "numero": body.telefones
//     }
//   ]

//   body.telefones = formatTelefones
  
//   return {
//     url: process.env.REACT_APP_ONBOARDING_URL + `api/clientes`, 
//     options: {
//       method: "PUT",
//       headers: {
//         'Content-Type': 'application/json',
//         'X-ILY-API-appKey': 'ily-appkey-front-UiGypZVy' ,
//         'X-ILY-API-appToken': `Bearer ${localStorage.getItem('lifeplace@token')}`,
//       },
//       body: JSON.stringify(body)
//     }
//   };
// }

// //trazer dados do usuario
export function GET_DATA_ACCOUNT() {
  return {
    url: process.env.REACT_APP_ONBOARDING_URL + `api/clientes`, 
    options: {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'X-ILY-API-appKey': 'ily-appkey-front-UiGypZVy' ,
        'X-ILY-API-appToken': `Bearer ${localStorage.getItem('lifeplace@token')}`,
      }
    }
  };
}

// //Atualizar dados da conta
// export function PUT_DATA_ACCOUNT(body: any) {
//   const formatTelefones = [
//     {
//       "numero": body.telefones
//     }
//   ]

//   body.telefones = formatTelefones
  
//   return {
//     url: process.env.REACT_APP_ONBOARDING_URL + `api/clientes`, 
//     options: {
//       method: "PUT",
//       headers: {
//         'Content-Type': 'application/json',
//         'X-ILY-API-appKey': 'ily-appkey-front-UiGypZVy' ,
//         'X-ILY-API-appToken': `Bearer ${localStorage.getItem('lifeplace@token')}`,
//       },
//       body: JSON.stringify(body)
//     }
//   };
// }

export const b = {}