import { ReactNode } from 'react'
import * as S from './styles'

type ModalProps = {
    children: ReactNode;
    containerStyle?: React.CSSProperties;
    /**Open the modal*/
    isOpen?:boolean;
    /**Define the modal title */
    title?: string;
    titleStyle?: React.CSSProperties;
    /**Callback function when close modal*/
    onClose: () => void;
    /**Show overlay (optional) */
    showOverlay?: boolean;
    overlayColor?: string
    style?: any
    className?: string | undefined
}
const Modal = ({children, className, titleStyle, isOpen, overlayColor, title, onClose, showOverlay=true, style}:ModalProps) => {
  
  return (
    <>
      <S.Content  overlayColor={overlayColor}>
        <S.Wrapper className={`modal ${isOpen ? "active" : "none"} ${className ? className : "none"}`} id="modal" style={style}>
          <S.Header>
            <h2 style={titleStyle}>{title}</h2>
          </S.Header>
          <S.Body>
            {children}
          </S.Body>
        </S.Wrapper>
        { showOverlay && <div id={isOpen ? 'overlay' : 'none'} onClick={() => onClose()} style={{opacity: isOpen ? 1 : 0}}></div>}
      </S.Content>
    </>
  )
}

export default Modal;