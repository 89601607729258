import { Wrapper, FormWrapper, ImageWrapper } from "./styles";
import { ReactComponent as Draw } from "../../../assets/drawConsultScheduled.svg";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";

const ConsultScheduled = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    renderImage();
  }, [pathname]);

  function renderImage() {
    if (pathname.includes("/confirmar")) {
      return;
    } else if (pathname.includes("/especialidade/agendar")) {
      return (
        <ImageWrapper style={{ alignSelf: "flex-start", marginTop: "40px" }}>
          <Draw />
        </ImageWrapper>
      );
    } else {
      return (
        <ImageWrapper style={{ marginTop: "40px" }}>
          <Draw />
        </ImageWrapper>
      );
    }
  }

  return (
    <Wrapper>
      <FormWrapper>
        <Outlet />
      </FormWrapper>
      {renderImage()}
    </Wrapper>
  );
};

export default ConsultScheduled;
