import styled from 'styled-components'

export const Screen = styled.div`
    width: 100%;
    height: 100%;
    padding: 40px 67px;
    
`

export const Cabecalho = styled.header`
`

export const Title = styled.h2`
    margin-top: 48px;
    color: #2B45D4;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
`

export const Body = styled.section`

`