import { Link } from "react-router-dom";
import styled from "styled-components";

// index
export const Container = styled.div`
  max-width: 1232px;
  margin: 0 auto;
`;

// header menu
export const List = styled.ul`
  display: flex;

  & li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
  }

  & li a {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: var(--life-neutral-black-88);
    transition: all 0.3s;
  }

  & li a:hover,
  & li a.active {
    color: var(--primary);
  }

  & li a::after {
    margin-top: 8px;
    content: "";
    display: block;
    width: 100%;
    background-color: transparent;
    height: 4px;
    border-radius: 2px;
    transition: all 0.3s;
  }
  & li a.active::after,
  & li a:hover::after {
    background-color: var(--life-detail);
  }

  & li a.icon:hover::after {
    background-color: transparent;
  }

  &.responsive {
    display: none;
  }
`;

// menu responsive
export const ListResponsive = styled.ul`
  display: none;

  &.responsive {
    display: flex;
    padding: 5px 0;
  }
`;

export const MenuButtonResponsive = styled.div`
  display: none;

  &.responsive {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 30px;
    top: 370px;
    background-color: var(--primary-dark);
    padding: 20px;
    height: 56px;
    width: 56px;
    z-index: 9;
    border-radius: 50%;
    cursor: pointer;

    & > svg > path {
      fill: #fff;
      fill-opacity: 1;
    }
  }
`;

// content
export const WrapperItens = styled.main`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;
  transition: all 0.3s;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const DefaultItens = styled.div`
  width: 100%;
  transition: all 0.3s;

  &.default {
    & > div {
      display: flex;
      gap: 16px;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
  }

  &.active {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 16px;

    @media (max-width: 768px) {
        flex-direction: column;
        grid-template-columns: 1fr;
      }

    & > div {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
`;

export const WrapperCard = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  flex: 50%;

  &.pink {
    background-color: var(--life-primary);
  }
  &.white {
    background-color: #fff;
  }
`;

export const Texto = styled.main`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--life-neutral-black-88);

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  &.text64 {
    color: var(--life-neutral-black-64);
  }
  &.textPrimary {
    color: var(--primary);
  }

  &.maxWidth {
    max-width: 235px;
  }
`;

export const StoreCardWrapper = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1 1 50%;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  background: center no-repeat;
  background-size: cover;
`;

export const WrapperConsultationCard = styled.section`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
`;

export const TitleConsultation = styled.h3`
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--life-neutral-black-88);
  font-weight: 600;
`;

export const Status = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--life-alert-warning);
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--life-neutral-black-88);
  font-weight: 500;
  border-radius: 4px;
  padding: 4px 16px;
  box-sizing: border-box;
  margin: 8px 0px;
  max-width: 96px;
`;

export const Category = styled.h4`
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: var(--life-neutral-black-88);
  font-weight: 600;
`;

export const WrapperDate = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const Info = styled.p`
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--life-neutral-black-88);
  font-weight: normal;
`;

// footer
export const FooterWrapper = styled.section`
  background-color: var(--primary);
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 40px 0 50px 0;
  box-sizing: border-box;
  margin-top: 32px;

  @media (max-width: 768px) {
    margin-top: ${window.location.href.includes("agendar-consulta")
      ? "50px"
      : "20px"};
    padding: ${window.location.href.includes("agendar-consulta")
      ? "16px 16px 40px 16px"
      : "16px 16px 100px 16px"};
  }
  @media (max-width: 450px) {
    margin-top: ${window.location.href.includes("agendar-consulta")
      ? "50px"
      : "20px"};
    padding: ${window.location.href.includes("agendar-consulta")
      ? "16px 0 40px 0"
      : "16px 0 100px 0"};
  }
`;

export const FooterContent = styled.footer`
  color: #fff;
  background-color: var(--primary);

  & a {
    color: #fff;
  }
`;
export const TopContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;

    & section {
      margin: 10px 0;
    }
  }
`;
export const FooterTitle = styled.h2`
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 600;
`;
export const SocialList = styled.ul`
  display: flex;
  align-items: center;
  margin: 10px 0;

  & li {
    margin-right: 20px;
  }
`;
export const AppLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #fff;
  padding: 6px 12px;
  box-sizing: border-box;
`;
export const AppDownloadList = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;

  & li {
    margin-right: 8px;
  }
`;
export const MenuFooter = styled.ul`
  margin-top: 10px;

  & li {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: normal;
    margin-bottom: 8px;
  }
`;
export const LastFooterSection = styled.section`
  max-width: 185px;
`;
export const AdressText = styled.p`
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: normal;

  @media (max-width: 768px) {
    max-width: 300px;
  }
`;
export const ButtonCustomerService = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 30px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  margin: 10px 0;
  background-color: #fff;

  & div:first-child {
    margin-right: 15px;
  }
`;
export const ButtonText = styled.p`
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: normal;
  color: var(--life-neutral-black-64);
`;
export const BottomContentWrapper = styled.div`
  &::before {
    margin: 18px 0 14px 0;
    display: block;
    content: "";
    background-color: rgba(231, 231, 231, 0.22);
    height: 1px;

    @media (max-width: 768px) {
      background-color: transparent;
      margin: 18px 0 0 0;
    }
  }
`;
