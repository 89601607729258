import * as React from 'react';
import { TextContent, SubtitleGreeting, TitleGreeting } from "./styles";
import {useUserContext} from '../../../../contexts/UserContext'
// import AddFiles from 'pages/Consultations/Conference/AddFiles';

const TextGreetings = () => {
  const {userData} = useUserContext()
  
  return (
    <TextContent>
      <TitleGreeting>
        <h4>Oi, {userData.firstName}!</h4>
     
      </TitleGreeting>
      <SubtitleGreeting>
        <p>Como vamos cuidar da sua saúde hoje?</p>
      </SubtitleGreeting>
    </TextContent>
  );
};

export default TextGreetings;

