import React from 'react'
import { Button } from 'storybook-maida'
import Modal from 'components/Modal'
import * as S from './styles'

type Props = {
  active: any
  setActive: any
  cancelConsult: any
}
const ModalCancelConsult = ({active, setActive, cancelConsult}: Props) => {
  return (
    <Modal isOpen={active} onClose={() => {setActive(!active)}} title="Quer mesmo cancelar a consulta?">
      <p>
        Lembre-se que na lista de compromissos, nossa saúde precisa estar sempre em primeiro lugar!
      </p>

      <S.ContainerBtn className="containerBtn">
        <Button 
          children={'Cancelar consulta'}
          onClick={cancelConsult} 
          variant="outlined"
          style={{
          fontSize: '16px',
          padding: '12px 40px',
          color: 'rgba(0, 0, 0, 0.56)',
          borderColor: 'rgba(0, 0, 0, 0.56)',
        }}
        />
        <Button 
          children={'Voltar'} 
          onClick={()=> setActive(false)}
          style={{
          
        }}  
      />
      </S.ContainerBtn>
    </Modal>
  )
}

export default ModalCancelConsult