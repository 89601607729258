import { EmailProps } from 'api/types/Auth/userAuth'
import axios from 'axios'


export type UserAndTermsProps = {
  aceiteComunicacao: boolean, 
  aceiteTermosUso: boolean
  email: string, 
  nomeCompleto: string,
}

export const axiosLife = axios.create({
  baseURL: process.env.REACT_APP_ONBOARDING_URL,

  headers: {
    'Content-Type': 'application/json',
    'X-ILY-API-appKey': 'ily-appkey-front-UiGypZVy'
  }
})

axiosLife.interceptors.request.use(async (config) => {
  if (config.headers) {
      config.headers['X-ILY-API-appToken'] = `Bearer ${localStorage.getItem('lifeplace@token')}`;
      return config;
  }
},
  error => {
      return Promise.reject(error);
  }
);


// Prescrições
async function getPrescriptions(cpf: string) {
  const response = await axiosLife.get(`/api/clientes/${cpf}/prescription`)
  return response.data
}

async function getPrescriptionDetails(cpf: string, idPrescription:string) {
  const response = await axiosLife.get(`/api/clientes/${cpf}/prescription/${idPrescription}`)
  return response.data
}
// Fim Prescrições ==========================================================================


// Autenticação 

async function getCheckEmail(email:string) {
  const response = await axiosLife.get(`api/onboarding?email=${email}`)
  return response.data
}

async function getUserInfo(body:EmailProps) {
  const response = await axiosLife.get(`api/onboarding?email=${body.email}`)
  return response.data
}

async function postConfirmationOnboarding(body: UserAndTermsProps) {
  const response = await axiosLife.post(`api/onboarding`, body)
  return response.data
}

// /me 
async function newGetMe(){
  const response = await axiosLife.get(`api/clientes`)
  return response.data
}

//avaliacao

async function getQuestions() {
  const response = await axiosLife.get(`opcoes-avaliacao`)
  return response.data
}

async function sendAvaliation(body: any) {
  const response = await axiosLife.post(`avaliacao`, body)
  return response.data
}

async function updateAvaliation(body: any) {
  const response = await axiosLife.put(`avaliacao`, body)
  return response.data
}
async function getPendingAvaliation() {
  const response = await axiosLife.get(`avaliacao/pendente`)
  return response.data
}

async function skipAvaliation(idAvaliation: string) {
  const response = await axiosLife.put(`avaliacao/pendente/${idAvaliation}/pular`)
  return response.data
}


export { getCheckEmail, getPrescriptions, getPrescriptionDetails, getUserInfo, newGetMe, postConfirmationOnboarding,
   getQuestions, sendAvaliation, getPendingAvaliation, skipAvaliation, updateAvaliation }
