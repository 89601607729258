import React from 'react'
import * as S from './styles'
import File from 'components/File'
import { useNavigate } from 'react-router-dom'

type Props = {
    addFiles: (file:File) => void
    showFooter?: boolean
    handleClickShareFileText: () => void
}
const SearchFileInDispositiveChat = ({addFiles,showFooter=true, handleClickShareFileText}: Props) => {
  
  const navigate = useNavigate()
  const [file, setFile] = React.useState<File | null>(null);

  React.useEffect(() => {
      if(file){
        addFiles(file)
      }
  }, [file])
  
  return (
    <S.Content>
      <S.Subtitle>Quer anexar um arquivo?</S.Subtitle>
      {file && <p>Arquivo carregado {file.name}</p>}
      <S.Text>Adicione arquivos de exames ou fotos de lesões que possam ajudar no diagnóstico durante essa consulta!</S.Text>
      <File setFile={setFile} limitSize={0}/>
      { showFooter && 
      <>
        <S.Container>
          <p>ou</p>
        </S.Container>
        <S.Footer>
          <S.Text onClick={handleClickShareFileText} style={{color: 'var(--primary)', fontWeight: 600}}>Compartilhe um arquivo do seu histórico</S.Text>
        </S.Footer>
      </>
      }
    </S.Content>
  )
}

export default SearchFileInDispositiveChat