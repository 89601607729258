import styled from "styled-components";

export const Wrapper = styled.div`
  height: 80px;
  padding: 20px 16px;
  background: #fff;
  display: flex;
  align-items: center;
  border-radius: 8px;

  > div {
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      display: flex;
      svg {
        padding: 0;
        margin: 0;
      }
    }
  }

  span {
    margin-left: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.56);
  }
`