import styled from 'styled-components';

export const CheckboxLife = styled.div<Props>`
  position: relative;
  display: flex;

  input + label {
    &:before {
      content: '';
      width: 1.6rem;
      height: 1.6rem;
      box-sizing: border-box;
      border-radius: 5px;
      background-color: var(--background);
      border: ${(props) => `3px solid ${props.color}`};
      display: inline-block;
      vertical-align: middle;
      margin-right: 12.38px;
    }
  }
`;
type Props = {
  borderColor?: string;
  iconColor?: string;
};

type PropsLabel = {
  color?: string;
};

export const Wrapper = styled.div<Props>`
  width: 100%;
  display: flex;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  input + label {
    position: relative;
    cursor: pointer;
  }

  p {
    margin-left: 1rem;
  }

  input[type='checkbox'] + label::before {
    content: '';
    width: 18px;
    height: 18px;
    left: 0;
    bottom: 0px;
    border: solid 3px ${({borderColor}) => borderColor ? borderColor : '#ff0073'};
    border-radius: 4px;
    vertical-align: bottom;
    @media(max-width: 400px){
      width: 10px;
      height: 10px; 
      border-width: 2px;
    }
  }

  input[type='checkbox']:checked + label::after {
    content: '';
    position: absolute;
    left: 7px;
    top: translateY(50%);
    width: 6px;
    height: 11px;
    border-right: solid 4px ${({iconColor}) => iconColor ? iconColor : '#ff0073'};
    border-bottom: solid 4px ${({iconColor}) => iconColor ? iconColor : '#ff0073'};
    transform: rotate(45deg);

    @media(max-width: 400px){
      left: 4px;
      top: auto;
      width: 3px;
      height: 6px;
      border-width: 2px;

    }
  }
`;

export const Label = styled.label<PropsLabel>`
  position: relative;
  font-size: 16px;
  display: flex;
  align-items: center;
  line-height: 16px;
  font-weight: 400;
  color: var(--life-neutral-black-64);
  line-height: 1rem;
  width: 100%;
  cursor: pointer;

  p {
    max-width: 317px;
    font-weight: 600 ;
  }

  span {
    color: var(--primary);
    font-weight: 400;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #222;
    border-radius: 2px;
  }
`;

export const OptionText = styled.p``;
