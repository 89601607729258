import { useState } from 'react';

import InputLife from 'components/InputLife';
import SelectComponent from 'components/Select';
import Button from 'components/Button/Button';

import * as S from './styles';
import { useNavigate } from 'react-router-dom';

const optionData = [
  { label: 'Agendamento de consulta' },
  { label: 'Cadastro' },
  { label: 'Cancelamento de assinatura' },
  { label: 'Cancelamento de consulta online agendada' },
  { label: 'Cancelamento de pedido' },
  { label: 'Cartão de crédito' },
  { label: 'Estorno e reembolso' },
  { label: 'Nota fiscal' },
  { label: 'Pagamento' },
  { label: 'Prescrição' },
  { label: 'Produto em falta' },
  { label: 'Troca e devolução de produto errado' },
  { label: 'Troca e devolução por avaria' },
  { label: 'Troca e devolução por defeito' },
  { label: 'Troca e devolução por insatisfação' },
  { label: 'Outro motivo' },
];

const OpenRequest = () => {
  const [selectOption, setSelectedOption] = useState('Selecione o motivo');
  const [selected, setSelected] = useState(false);
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');

  const navigate = useNavigate()

  const navigateToNextPage = () => {
    navigate('/faq/enviado')
  };

  const handleSubject = (value: any) => {
    setSubject(value);
  };

  const handleTextarea = (value: any) => {
    setContent(value);
  };

  const handleSelectChange = (value: any) => {
    setSelected(true);
  };

  const enabledButton = () => {
    if (selected && subject && content) {
      return false;
    }
    return true;
  };

  return (
    <>
      <S.Subtile>Agora selecione o motivo da sua solicitação:</S.Subtile>
      <S.SelectWrapper>
        <SelectComponent
          selected={selectOption}
          setSelected={setSelectedOption}
          data={optionData}
          onChange={(event: any) => handleSelectChange(event.target.value)}
        />
      </S.SelectWrapper>

      <S.Description>
        Certo, então é só escrever a sua mensagem! Lembre-se de colocar todas as
        informações relevantes, assim o atendimento será ainda mais rápido.
      </S.Description>

      <InputLife
        placeholder="Digite o assunto"
        noLabel={true}
        onChange={(event) => handleSubject(event.target.value)}
        value={subject}
      />

      <S.TextArea
        placeholder="Escreva aqui sua solicitação"
        onChange={(event) => handleTextarea(event.target.value)}
        value={content}
      ></S.TextArea>

      <Button
        onClick={navigateToNextPage}
        variant="primary"
        style={{ width: '100%' }}
        disabled={enabledButton()}
      >
        Enviar
      </Button>
    </>
  );
};

export default OpenRequest;
