import React, { ReactNode, useCallback, useContext } from "react";
import { Attendance } from "./types/Attendance";

//Icones
import {ReactComponent as IconAttendance} from '../assets/query.svg'
import {ReactComponent as IconPrescription} from '../assets/mai-ic-prescription.svg'
import {ReactComponent as IconReceipt} from '../assets/ic-receipt.svg'
import {ReactComponent as IconCardio} from '../assets/ic-cardiogram.svg'
import {ReactComponent as IconPill} from '../assets/pill.svg'
import { CALL_ENPOINT } from "api/Services/CallEndpoint";
import { HeaderNotices } from "api/headers/HeaderNotices";
import { HeaderTelehealth, HeaderTelehealth2 } from "api/headers/HeaderTeleHealth";

interface ContextValue {
  nextConsult: any;
  hasNextConsult: boolean;
  loading: boolean;
  noticeTele: any,
  setNoticeTele: any,
  noticeAirmed: any,
  getAPIContent: () => void;
  hasAvailableConsults: (email: any, token: any) => void;
  getNextAttendances: () => void,
  hasNoticesTele: () => void; 
  hasAirmedNotices: () => void; 
  attendances: Attendance[] | null,
  fila: boolean,
  setFila: React.Dispatch<React.SetStateAction<boolean>>,
  attendanceId: number,
  setAttendanceId: React.Dispatch<React.SetStateAction<number | any>>,
  position: number, 
  setPosition: React.Dispatch<React.SetStateAction<number | any>>,
  getPosition: () => void
  canceledConsult: boolean, 
  setCanceledConsult: React.Dispatch<React.SetStateAction<boolean>>
  alertScheduling: boolean, 
  setAletScheduling: React.Dispatch<React.SetStateAction<boolean>>
  alertSchedulingReturn: boolean, 
  setAletSchedulingReturn: React.Dispatch<React.SetStateAction<boolean>>
  alertUpdateAccount: boolean
  setAlertUpdateAccount: React.Dispatch<React.SetStateAction<boolean>>,
  listAddress: ListAdressProps[],
  setListAddress: React.Dispatch<React.SetStateAction<ListAdressProps[] | []>>,
  categories: any[],
  faqSelected: any, 
  setFAQSelected: React.Dispatch<React.SetStateAction<any>>
}

interface Props {
  children: ReactNode;
}

export type NoticePrescriptionProps = {
  code: number,
  createdAt: string, 
  publicId: string
}

//props lista de endereços do dados da conta
export interface ListAdressProps {
  cep: string,
  logradouro: string,
  numero: string,
  complemento: string
  bairro: string,
  localidade: string,
  rua?: string | undefined,
  uf: string,
  id: number,
  enderecoPadrao: boolean

}

//Type position consult urgency
interface positionProps {
  attendance: any,
  position: number
}

export const HomeContext = React.createContext<ContextValue | undefined>(
  {} as ContextValue
);

export const HomeContextProvider = ({ children }: Props) => {

  
  const [nextConsult, setNextConsult] = React.useState(null);
  const [fila, setFila] = React.useState(false);
  
  const [hasNextConsult, setHasNextConsult] = React.useState(false);

  // telehealth notice 
  const [noticeTele, setNoticeTele] = React.useState(null);
  const [noticeAirmed, setNoticeAirmed] = React.useState<NoticePrescriptionProps | null>(null)
  
  //Position
  const [position, setPosition] = React.useState<number | any>();
  //Attendance Id
  const [attendanceId, setAttendanceId] = React.useState<number | any>();
  
  //all attendances - the last attendance
  const [attendances, setAttendances] = React.useState<Attendance[] | null>(null)

  //variables use during call requests
  const [loading, setLoading] = React.useState<boolean>(false)
  const [, setError] = React.useState<boolean | null>(null)

  //estado para armazenar se consulta foi cancelada
  const [ canceledConsult, setCanceledConsult ] = React.useState<boolean>(false);
  const [ alertScheduling, setAletScheduling] = React.useState<boolean>(false)
  const [ alertSchedulingReturn, setAletSchedulingReturn] = React.useState<boolean>(false)

  //estado para armazenar feedback de update dos dados da conta
  const[ alertUpdateAccount, setAlertUpdateAccount ] = React.useState(false)


  //Lista de Endereços dadados da conta
  const [ listAddress, setListAddress ]  = React.useState<ListAdressProps[] | []>([])

  //FAQdatas
  const categories: any = [
    {title: 'Consultas', subtitle: 'Tudo sobre agendamentos, retornos e cancelamentos', icon: <IconAttendance/>, questions: ['Qual o prazo para fazer uma consulta de retorno?','Regras de cancelamento de consultas','Tive problemas durante a consulta online', 'A consulta online é indicada para  emergência?'], responses: ["1Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "2Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "3Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "4Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    ] }, 
    {title: 'Pedidos', subtitle: 'Tire suas dúvidas de entrega, troca ou devolução', icon: <IconReceipt/>, questions: [], responses: [] }, 
    {title: 'Prescrições', subtitle: 'Tudo sobre agendamentos, retornos e cancelamentos', icon: <IconPrescription/>, questions: [], responses: [] }, 
    {title: 'Medicamentos', subtitle: 'Entenda as restrições da loja e nossos descontos', icon: <IconPill/>, questions: [], responses: [] }, 
    {title: 'Exames', subtitle: 'Veja como  compartilhar seus exames com um profisisonal', icon: <IconCardio/>, questions: ['Q14l o prazo para fazer uma consulta de retorno?','R24ras de cancelamento de consultas','Tive problemas durante a consulta online', 'A consulta online é indicada para  emergência?'], responses: ["14Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "24Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "34Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "44Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    ] }, 
 ]
 const [faqSelected, setFAQSelected] = React.useState<any>({category: '',question: '', response: ''})



  async function getPosition() {
    try {
      const { url, options } = CALL_ENPOINT('TELEHEALTH_URL','queue/position','GET', null, {
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
      });

      const response = await fetch(url, options);
      const { position } = await response.json();


      if (response.ok) {
        if (position === -1 && !noticeTele) {
          setPosition(-1); 
          return
        }

        setPosition(position);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }

  // const hasNoticesTele = async () => {
  //   checkNotices().then(json => {
  //     setNoticeTele(json)
  //   }).catch(e => {
  //     console.log("Deu erro ao obter Notice ", e);
  //   })
  // }

  const hasNoticesTele = async () => {
    const telehealthToken = localStorage.getItem("telehealth@token");
    if(telehealthToken){
      try {
        // const {url, options} = GET_NOTICES_TELE()
        const { url, options } = CALL_ENPOINT('TELEHEALTH_URL','notice','GET', null, {
          Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
        });
     
        const response = await fetch(url, options)

        if(response.ok){
          const responseText = await response.text()
          
          if(responseText){
            //Só atribue notice se tiver uma resposta em json
            const json = JSON.parse(responseText)
            // console.log("RESPOSTA ENDPOINT ",json)
            setNoticeTele(json)
            
          } else {
            setNoticeTele(null)
          }
        }
        
        
      } catch (error) {
        console.log("Deu erro ao obter Notice ", error);
      }
    }
  }

  // Airmed Notices 
  const hasAirmedNotices = async () => {
    const telehealthkAuthenticationKey = localStorage.getItem("telehealth@key");
    if(telehealthkAuthenticationKey){
      try {
        // const {url, options} = GET_NOTICES_AIRMED(telehealthkAuthenticationKey)
        const { url, options } = CALL_ENPOINT('TELEHEALTH_URL',`client/notice/airmed/${telehealthkAuthenticationKey}`,'GET', null, HeaderNotices);
     
        const response = await fetch(url, options)

        if(response.ok){
          const responseText = await response.text()
          
          if(responseText){
            //Só atribue notice se tiver uma resposta em json
            const json = JSON.parse(responseText)
            const {metadatas} = json
            // console.log("RESPOSTA ENDPOINT ",json)
            setNoticeAirmed(metadatas[metadatas.length-1])
          }
        }
        
        
      } catch (error) {
        console.log("Deu erro ao obter Notice ", error);
      }
    }
  }


  const hasAvailableConsults = useCallback(async (email: any, token: any) => {

    try {
      
      // const { url, options } = GET_NEXT_CONSULT(email, token);
      const { url, options } = CALL_ENPOINT('TELEHEALTH_URL',`api/agendamento?email=${email}`,'GET', null, {
        'Content-Type': 'application/json',
        'X-ILY-API-appKey': process.env.REACT_APP_HEADER_APPKEY as string,
        'X-ILY-API-appToken': `Bearer ${token}`,
      });

      const response = await fetch(url, options);
      const consults = await response.json();

      if (response.ok) {
        
        if (
          consults.length > 0 &&
          consults[0].type === "Eletiva"
          ) {
          setHasNextConsult(true);
          setNextConsult(consults[consults.length - 1]);
        } else {
          setNextConsult(null);
          setHasNextConsult(false);
        }
      } else {
        setNextConsult(null);
        setHasNextConsult(false);
        const body = await response.json();
        throw new Error(body.message);
      }
    } catch (error) {
      return;
    }
  }, []);

    //Attendances
  const getNextAttendances = async () => {

    const tokenTele = window.localStorage.getItem('telehealth@token')
    if(tokenTele){
      // const {url, options} = GET_ATTENDANCES(tokenTele)
      const {url, options} = CALL_ENPOINT('TELEHEALTH_URL',`attendance/incoming`,'GET', null, HeaderTelehealth2(tokenTele));
      try {
        setError(false)
        setLoading(true)
        const response = await fetch(url, options)
        if(response.ok){
          const responseText = await response.text()
          if(responseText){
            const arr = JSON.parse(responseText)
            let newArr = arr.filter((att: Attendance) => att.type !== 'URGENCY')
            setAttendances(newArr)
          }
        }
      } catch (error) {
        console.log(error);
        setError(true)
      }finally{
        setLoading(false)
      }

    }
  }

  const getAPIContent = useCallback(() => {
    hasAvailableConsults(localStorage.getItem("lifeplace@email"), localStorage.getItem("lifeplace@token"));
  }, [hasAvailableConsults]);

  const value = {
    hasAvailableConsults,
    nextConsult,
    hasNextConsult,
    noticeTele,
    noticeAirmed,
    setNextConsult,
    getAPIContent,
    hasNoticesTele,
    hasAirmedNotices,
    getNextAttendances,
    attendances,
    setNoticeTele,
    loading,
    fila,
    setFila,
    position,
    setPosition,
    attendanceId,
    setAttendanceId,
    getPosition,
    canceledConsult,setCanceledConsult,
    alertScheduling, setAletScheduling,
    alertSchedulingReturn, setAletSchedulingReturn,
    alertUpdateAccount, setAlertUpdateAccount,
    listAddress, setListAddress,
    categories,
    setFAQSelected,
    faqSelected,
    
  };

  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>;
};

export function useHomeContext() {
  const context = useContext(HomeContext);

  if (typeof context === "undefined") {
    throw new Error("useHomeContext must be used within an HomeContext");
  }

  return context;
}
