import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import TrackReplyRounded from 'components/TrackReplyRounded';
import ButtonOutlined from 'components/Button/ButtonOutlined';
import AlertBanner from 'components/AlertBanner/AlertBanner';
import InputLifeplace from 'components/InputLifeplace';
import Participant from '../Participant/Participant';
import Button2 from 'components/Button/Button';
import DropUp from 'components/DropUp';
import Modal from 'components/Modal';

import Video from 'twilio-video';

import { useConsultsContext } from 'contexts/ConsultsContext';
import AddFiles from '../AddFiles';

import * as S from './styles';

import { ReactComponent as AttachmentIcon } from '../../../../assets/mai-ic-attachment.mono.svg';
import { ReactComponent as UserPlusIcon } from '../../../../assets/user-plus.svg';
import { ReactComponent as UserIcon } from '../../../../assets/ic-user.svg';
import { ReactComponent as ChatIcon } from '../../../../assets/mai-ic-chat.mono.svg';

import { formatDayWeek } from 'utils/formatDayWeek';
import { useRoom } from 'hooks/useRoom';
import useWindowDimensions from 'hooks/useWindowDimensions';
import Chat from './Chat/Chat';
import VideoSide from '../VideoSide';
import VideoSideN2N from '../VideoSideN2N';

let dropUpData = [
  { icon: <ChatIcon />, description: 'Mensagens' },
  { icon: <AttachmentIcon />, description: 'Adicionar arquivos' },
  { icon: <UserPlusIcon />, description: 'Convidar pessoas' },
];

const ConferenceGuest = () => {
  const [iconCamState, setIconCamState] = useState<boolean>(false);
  const [iconMicState, seticonMicState] = useState<boolean>(false);
  const [participants, setParticipants] = useState<any[]>([]);
  const [room, setRoom] = useState<Video.Room | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [roomName, setRoomName] = useState<string>('');

  //DropUp
  const [dropUp, setDropUp] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);

  //Clipboard
  const [modalClipboard, setModalClipboard] = useState<boolean>(false);
  const [clipboard, setClipboard] = useState('');
  const [clipboardBanner, setClipboardBanner] = useState(false);

  //AddFiles
  const [showModal, setShowModal] = useState(false);

  //Timer
  const [timer, setTimer] = useState(1);

  //Dados da consulta
  const [docData, setDocData] = useState({
    professionalName: '',
    specialtyName: '',
  });

  //Pega os dados do convidado que veem de um contexto
  const { guestData } = useConsultsContext();


  //chat 
  const [showChat, setShowChat] = useState(false)


  //Seta od dados da sala (token e roomName)
  const { connect, disconnect } = useRoom({
    token: guestData.roomData.roomToken,
    roomName: guestData.roomData.roomName,
  });

  const [disconnectedParticipant, setDisconnectedParticipant] = useState('');

  const navigate = useNavigate();

  const containerRef = useRef<any>()

  useEffect(() => {
    const startRoom = async () => {
      if (guestData) {
        setRoomName(guestData.roomData.roomName);
        setToken(guestData.roomData.roomToken);
        setDocData({
          ...docData,
          professionalName: guestData.roomData.attendance.professional.name,
          specialtyName: guestData.roomData.attendance.specialty.name,
        });
      }
    };

    startRoom();
  }, []);

  //Faz a conexão com a sala usando o connect do useRoom
  useEffect(() => {
    connect().then((room) => {
      setRoom(room);
      room.localParticipant.setNetworkQualityConfiguration({
        local: 2,
        remote: 1,
      });

      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      room.participants.forEach(participantConnected);
      room.once('disconnected', (room, error) => {
        if (error) {
          console.log('Disconnected ', error.code, error.message);
        }
        room.participants.forEach((participant: any) =>
          participantDisconnected(participant),
        );
      });
    });

    //Adiciona o participante a um array de participantes
    const participantConnected = (participant: any) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    //Remove o participante de um array de participantes
    const participantDisconnected = (participant: any) => {
      setDisconnectedParticipant(participant.identity);

      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant),
      );
    };

    return () => {
      disconnect();
    };
  }, []);

  //Verifica se o medico saiu da sala
  //Se sim, os paciente é desconectado e encaminhado para home
  useEffect(() => {
    const professional = guestData.roomData.attendance.professional.name;
    if (disconnectedParticipant === professional) {
      navigate('/convite/finalizarChamada');
      disconnect();
    }
  }, [disconnectedParticipant]);

   //Verifica se não tem mais participantes na sala
  useEffect(() => {
    if (room?.participants.size === 0) {
      finishedAttendance();
    }
  }, [participants]);

   //Cria o interval referente ao tempo de atendimento
  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  //Renderiza o tempo de atendimento
  const renderFormattedTimer = () => {
    const min = Math.floor(timer / 60);
    const sec = timer % 60;
    return `${min > 9 ? min : `0${min}`}:${sec > 9 ? sec : `0${sec}`}`;
  };

  //Toggle para ligar e desligar camera
  const toggleShowCam = () => {
    setIconCamState(!iconCamState);

    if (iconCamState) {
      try {
        room?.localParticipant?.videoTracks?.forEach((publication) => {
          publication.track.enable();
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        room?.localParticipant?.videoTracks?.forEach((publication) => {
          publication.track.disable();
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  //Toggle para ligar e desligar microfone
  const toggleMic = () => {
    seticonMicState(!iconMicState);
    if (iconMicState) {
      try {
        room?.localParticipant?.audioTracks?.forEach((publication) => {
          publication.track.enable();
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        room?.localParticipant?.audioTracks?.forEach((publication) => {
          publication.track.disable();
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  //Verifica a opção e faz a acção no menu
  const handleChoiceOptionMenu = (event: any) => {
    const optionSelected = event.target.innerText;

    if (optionSelected === 'Convidar pessoas' && guestData.roomData.attendance) {
      setModalClipboard(true);
      setClipboard(
        `${process.env.REACT_APP_URL_LIFEPLACE}convite?invite=${guestData.roomData.attendance.inviteToken}&attendanceId=${guestData.roomData.attendance.id}`,
      );
    }

    if (optionSelected === 'Adicionar arquivos') {
      setShowModal(true);
    }

    
    if (optionSelected === 'Mensagens') {
      setShowChat(!showChat)
    }

    setDropUp(false);
    setClipboardBanner(false);
  };

   //Faz a ação de copiar texto formatado
  const copyToClipboard = () => {
    const date = formatedDate(guestData.roomData.attendance?.createdAt);
    const text = `
    Esse é o convite para participar de uma consulta online!

    Quando: ${date}
    Especialidade: ${guestData.roomData.attendance?.specialty?.name}
    Paciente: ${guestData.roomData.attendance?.insured?.name}
    Link do atendimento: ${clipboard}`;
    navigator.clipboard.writeText(text);
    setClipboardBanner(true);
    setModalClipboard(false);
  };

  //Retorna a data formatada 
  const formatedDate = (date: string) => {
    const newDate = new Date(date);
    let formatedDate = formatDayWeek(
      `${newDate.getDay()}/${newDate.getMonth()}/${newDate.getUTCFullYear()}`,
    );
    formatedDate = formatedDate = `${formatedDate}, às ${newDate.getHours()}h${
      (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes()
    }`;

    return formatedDate[0].toUpperCase() + formatedDate.substr(1);
  };

  //Desconeta paciente e vai para home 
  const finishedAttendance = async () => {
    const authRoute = `/convite?invite=${guestData.roomData.attendance.inviteToken}&attendanceId=${guestData.roomData.attendance.id}`;
    disconnect();
    navigate(authRoute);
  };

  const handleCloseDropUp = () => {
    setDropUp(false)
  } 

  const calculateIdealVideoSize = () => {
    let widthContainer = containerRef.current?.clientWidth
    let totalParticipants = participants.length + 1 
    let measure = ((widthContainer / totalParticipants) - 100)
    return {width:(measure * 1.77778)+'px', height: measure+'px'}
  }

    // code for responsive
    const { width } = useWindowDimensions();
    const [showLayoutSide, setShowLayoutSide] = useState("")
    const [mobileLayout, setMobileLayout] = useState(false)
  
    useEffect(() => {
      if(width <= 850 && showChat){
        setMobileLayout(true)
        setShowLayoutSide("right")
      }else{
        setMobileLayout(false)
        setShowLayoutSide("both")
      }
    }, [width])

  //Renderiza o parcipantes remotos
  const renderRemoteParticipants = participants.map((participant, index) =>
    participants.length <= 1 ? (
      <Participant
        styleVideo={
          participants.length <= 1
            ? {
                top: 0,
                position: 'absolute',
                width: '100vw',
                height: '100vh',
                objectFit: 'cover',
                objectPosition: 'center',
              }
            : {}
        }
        key={participant.sid}
        participant={participant}
        hasRemote={true}
      />
    ) : (
      <S.oneRemote key={participant.sid} style={calculateIdealVideoSize()}>
        <Participant
          style={{ minHeight: '100%' }}
          key={participant.sid}
          participant={participant}
          showInfo={true}
        />

        <S.localMediaBackground>
          <UserIcon />
        </S.localMediaBackground>
      </S.oneRemote>
    ),
  );

  
  return (
    <>
      {participants.length <= 1 && (
      <VideoSide 
        room={room}
        participantData={docData}
        renderFormattedTimer={renderFormattedTimer}
        renderRemoteParticipants={renderRemoteParticipants}
        styleContainer={{width: showChat ? (mobileLayout && (showLayoutSide === 'left')) ? '100%' : '70%' : '100%', transition: "all 0.3s",
         display: ((mobileLayout && showLayoutSide === 'left') || showLayoutSide === "both") ? 'inline' : 'none'
          }}
        iconCamState={iconCamState}
       />
      )}

      {participants.length > 1 && (
        <div style={{display: 'flex'}}>
          <VideoSideN2N 
            renderRemoteParticipants={renderRemoteParticipants}
            containerRef={containerRef}
            room={room}
            renderFormattedTimer={renderFormattedTimer}
            calculateIdealVideoSize={calculateIdealVideoSize}
            styleContainer={{width: showChat ? (mobileLayout && (showLayoutSide === 'left')) ? '100%' : '70%' : '100%', transition: "all 0.3s",
            display: ((mobileLayout && showLayoutSide === 'left') || showLayoutSide === "both") ? 'inline' : 'none'
             }}/>
          {
                (showChat && guestData ) && <div 
                  style={{
                width: (mobileLayout && showLayoutSide === 'right') ? '100%' : '30%', backgroundColor: 'green', position: 'relative',
                display: ((mobileLayout && showLayoutSide === 'right') || showLayoutSide === "both") ? 'inline' : 'none',
            }}>
                  <Chat idAtendimento={guestData.roomData.attendance.id} publicId_attendance={guestData.roomData.attendance.publicId} onCloseChat={() => setShowChat(false)}/>
                </div>
            }
        </div>
      )}

      
      <S.trackButtons>
        { showLayoutSide !== 'right' && <div className="wrapper-buttons">
          <TrackReplyRounded
            icon="mic"
            marked={iconMicState}
            onClick={toggleMic}
          ></TrackReplyRounded>
          <TrackReplyRounded
            icon="phone"
            onClick={() => setActive(true)}
          ></TrackReplyRounded>
          <TrackReplyRounded
            icon="cam"
            marked={iconCamState}
            onClick={toggleShowCam}
          ></TrackReplyRounded>

          <TrackReplyRounded
            icon="more"
            onClick={() => setDropUp(!dropUp)}
          ></TrackReplyRounded>
        </div>}

        {dropUp && (
          <div className="dropUp">
            <DropUp
              data={dropUpData}
              onClick={(event: any) => handleChoiceOptionMenu(event)}
              onClose={handleCloseDropUp}
              isOpen={dropUp}
            />
          </div>
        )}
      </S.trackButtons>

      <Modal
        isOpen={active}
        onClose={() => setActive(!active)}
        containerStyle={{width: '400px'}}
        title="Você quer mesmo sair da sala?"
      >
        <S.description>
          Lembrando que sair da sala não encerra a consulta. Nossa equipe médica
          é quem encerra o seu atendimento
        </S.description>

        <S.containerBtns>
          <ButtonOutlined
            onClick={finishedAttendance}
            style={{
              padding: '12px 40px',
              color: 'rgba(0, 0, 0, 0.56)',
              borderColor: 'rgba(0, 0, 0, 0.56)',
            }}
            variant="outlined"
          >
            Sair da sala
          </ButtonOutlined>
          <Button2
            onClick={() => setActive(false)}
            variant="primary"
            style={{
              padding: '12px 40px',
              marginLeft: '8px',
              backgroundColor: '#FFCC00',
              color: 'rgba(0, 0, 0, 0.88)',
            }}
          >
            Ficar na sala
          </Button2>
        </S.containerBtns>
      </Modal>

      <Modal
        style={{ maxWidth: '300px' }}
        isOpen={modalClipboard}
        onClose={() => setModalClipboard(!modalClipboard)}
        title="Convidar pessoas"
      >
        <S.containerModal>
          <p>
            Compartilhe o link da consulta com quem você quer que acompanhe esse
            atendimento:
          </p>
          <InputLifeplace label="" type="text" value={clipboard} disabled />
          <ButtonOutlined
            variant="outlined"
            style={{ width: '100%' }}
            onClick={() => copyToClipboard()}
          >
            Copiar link{' '}
          </ButtonOutlined>
        </S.containerModal>
      </Modal>

      {clipboardBanner && (
        <S.banner>
          <AlertBanner
            label="Link do atendimento copiado!"
            type="success"
            seconds={3}
          ></AlertBanner>
        </S.banner>
      )}

      <AddFiles showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default ConferenceGuest;
