import React, { useState } from 'react';
import * as S from './styles';
import { Button } from 'storybook-maida';
import { useNavigate } from 'react-router-dom';
import InputLifeplace from 'components/InputLifeplace';
import { POST_NUMBER } from 'api/Services/NextConsult';
import { useUserContext } from 'contexts/UserContext';

export function validation(phone: any) {
  phone = phone.replace(/[^\d]/g, '');

  if (phone.length > 2) {
    phone = phone.replace(/^(\d{2})(\d*)/, '($1) $2');
  }
  if (phone.length > 9) {
    phone = phone.replace(/^(.*)(\d{4})$/, '$1-$2');
  }
  return phone;
}

const UrgencyNumber = () => {
  const navigate = useNavigate();
  const {userData} = useUserContext()
  const [number, setNumber] = React.useState<string>(() => validation(userData.telefone as string))
  const [error, setError] = React.useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let phone = validation(e.target.value);
    setNumber(phone);
  };

  // maks number
 

  const handleClick = () => {
    const token = window.localStorage.getItem('telehealth@token') || '';
    ValidateNumber(token, number);
  };

  //validate number
  function ValidateNumber(token: string, number: string) {
    setLoading(true);
    try {
      const { url, options } = POST_NUMBER(token, number);
      fetch(url, options)
        .then((response) => {
          if (response.ok) {
            navigate('/consultas-online/teleconsulta');
          } else {
            setError('O número informado é inválido');
          }
        })
        .then(() => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <S.ContentNumber>
      <div className="Container">
        <div className="infomation">
          <h4>Agendar Consulta</h4>
          <p>Confirme o seu celular</p>
          <span>
            Precisamos do número do seu celular para enviar as prescrições que
            poderão ser geradas durante a consulta
          </span>

          <InputLifeplace
            label="(DDD) + número"
            id="number"
            type="text"
            value={number}
            onChange={handleChange}
            maxLength={15}
          />

          <span className="erroNumber">{error}</span>
          <Button
            disabled={loading || number.length !== 15}
            children={loading ? 'Confirmando...' : 'Próximo'}
            onClick={handleClick}
          />
        </div>
      </div>
    </S.ContentNumber>
  );
};

export default UrgencyNumber;
