import styled from "styled-components";
import { darken } from 'polished'


export const Wrapper = styled.div`
  background-color: #fff;
  width: 100%;
  border-radius: 8px;
  padding: 24px;
  
  > svg {
    margin-bottom: 24px;
  }

  p {
    color: rgba(0, 0, 0, 0.56);
    font-weight: 600;

    strong {
      font-weight: 600;
      color: black;
    }
  }

  .contentDetails {
    margin-top: 15px;

    ul {
      list-style: none;

      li {
        color: rgba(0, 0, 0, 0.88);
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 8px;
        display: flex;
        align-items: center;

        > svg {
          margin-right: 18px;
        }
      }
    }

    button {
      cursor: pointer;
      margin-top: 24px;
      margin-bottom: 28px;
      background: #2B45D4;
      border-radius: 100px;
      width: 100%;
      color: #fff;
      padding: 12px 0;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;

      &:hover {
        background: ${darken(0.03, '#2B45D4')};
      }
    }

    > div {
      text-align: center;

      a {
        color: #F44336;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
`