import * as S from './styles'
type Props = {
  title: string
  text: string
}
const NoMessages = (props: Props) => {
  return (
    <S.Container>
      <S.Title>{props.title}</S.Title>
      <S.TextContainer>
        <S.Text>
          {props.text}
        </S.Text>
      </S.TextContainer>
    </S.Container>
  )
}

export default NoMessages