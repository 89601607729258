import styled from "styled-components";

type Props = {
    visible: boolean
}
export const Wrapper = styled.div<Props>`
  cursor: pointer;
  height: 80px;
  padding: 16px !important;
  background: #fff;
  display: flex;
  align-items: center;
  border: ${(props) => props.visible ? "none" : "1px dashed #FF0073"};
  border-radius: 8px;
  margin-bottom: 16px;
  min-width: 100% ;

  span {
    margin-left: 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.56);
  }
`

export const Icon = styled.div`
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      display: flex;
      svg {
        padding: 0;
        margin: 0;
      }
    }
`

export const Title = styled.h2`
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.88) !important;
`

export const Subtitle = styled.p`
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: rgba(0, 0, 0, 0.56) !important;
`

export const InfoContainer = styled.div`
    margin-left: 24px ;
`