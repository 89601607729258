import { Text } from '../Scheduling/styles';
import { Title } from '../Specialty/styles';
import { ConsultDetails, List, TextTypeConsult, Wrapper } from './styles';
import { Button } from 'storybook-maida';
import { ReactComponent as CalendarDraw } from '../../../../assets/img-festival-calendar.svg';
import { ReactComponent as IconCalendar } from '../../../../assets/ic-calendar.mono.svg';
import { ReactComponent as IconHistory } from '../../../../assets/ic-history.mono.svg';
import { ReactComponent as IconDoctor } from '../../../../assets/ic-doctor.mono.svg';
import { useNavigate } from 'react-router-dom';
import useSessionlStorage from 'hooks/useSessionlStorage';
import { SAVE_SCHEDULE_APPOINTMENT } from 'api/Services/NextConsult';
import { useState } from 'react';
import { useHomeContext } from 'contexts/HomeContext';

const ConfirmConsult = () => {
  const [storage] = useSessionlStorage('consulta');
  const [loading, setLoading] = useState(false);
  const {setAletSchedulingReturn , setAletScheduling } = useHomeContext()

  const navigate = useNavigate();

  const save = async () => {
    const { url, options } = SAVE_SCHEDULE_APPOINTMENT(storage);
    try {
      setLoading(true);
      const response =  await fetch(url, options);
      const responseJson = await response.json();

      if(response.ok) {
        
        if (storage.schedulingType === 'Consulta de Retorno') {
          setAletSchedulingReturn(true) 
          navigate(`/detalhes/${responseJson.id}`);
        } else {
          setAletScheduling(true)
          navigate('/home');
        }
        sessionStorage.removeItem('consulta')
      } else {
        return
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
 
  };

  return (
    <Wrapper>
      {storage.schedulingType === 'Consulta de Retorno'? (
        <Title style={{ fontSize: '24px', marginBottom: '24px' }}>Confirme o agendamento de retorno</Title>
      ):(
        <Title style={{ marginBottom: '24px' }}>Confirme o agendamento</Title>

      )}
      <Text>
        Quase lá! Confira abaixo a especialidade, data e horário escolhidos e
        confirme o agendamento
      </Text>

      <ConsultDetails>
        <div>
          <CalendarDraw />
        </div>
        <div>
          {storage.schedulingType === 'Consulta de Retorno'? (
            <TextTypeConsult>
              Retorno agendado para <strong>{storage.specialityTxt}</strong>
            </TextTypeConsult>
          ):(
            <TextTypeConsult>
              Consulta agendada para <strong>{storage.specialityTxt}</strong>
            </TextTypeConsult>
          )
          }    
        </div>
        <div>
          <ul>
            <List>
              <IconCalendar />
              {storage.dateTxt[0].toUpperCase() + storage.dateTxt.substr(1)}
            </List>
            <List>
              <IconHistory />
              {storage.hour.replace(':', 'h')}
            </List>
            <List>
              <IconDoctor />
              {storage.profissional}
            </List>
          </ul>
        </div>
      </ConsultDetails>

      <Button style={{marginBottom: '30px'}} children={loading ?' Confirmando...' : 'Confirmar agendamento'} disabled={loading} onClick={save} />
    </Wrapper>
  );
};

export default ConfirmConsult;
