import * as S from './styles'
import React from 'react'
import InputLife from 'components/InputLife';
import {ReactComponent as IconHelp} from '../../../assets/mai-ic-help.mono.svg'
import SelectComponent from 'components/Select';
import SimpleRadio from '../SimpleRadio';
import Button2 from 'components/Button/Button';
import { CALL_ENPOINT } from 'api/Services/CallEndpoint';
import { headerAppToken } from 'api/headers/HeaderAppToken';
import HealthDataEdit from './Edit';
import HealthDataDetails from './Details';
import AlertBanner from 'components/AlertBanner/AlertBanner';
import { useConsultsContext } from 'contexts/ConsultsContext';

export type UserData = {
  sexo: string | null,
  altura: string, 
  peso: string,
  deficiencia: string | null,
  alergia: string | null,
  genero: string | null,
  medicacao: string | null, 
  doenca_pre: string | null 
}

const HealthData = () => {
  
  const [healthData, sethealthData] = React.useState<UserData>({} as UserData)
  const [editar , setEditar] = React.useState(false)
  const [alertUpdateHealthData, setAlertUpdateHealthData] = React.useState(false)
  const { alertOnAction, setAlertOnAction} = useConsultsContext()
  
  const getHealthData = async() => {
    try {
      const { url, options } = CALL_ENPOINT('ONBOARDING_URL','api/clientes/saude', 'GET',null, headerAppToken(localStorage.getItem('lifeplace@token') as string))
      
      const response = await fetch(url, options)
      const json = await response.json()
      sethealthData(json)
    } catch(err) {
     console.log(err)
    }
  }

  React.useEffect(() => {
    getHealthData()
  }, [])


  return (
    <S.Content>
      {/* {alertUpdateHealthData && <AlertBanner onEndTime={() => setAlertUpdateHealthData(false)} label={'Dados da Saúde atualizados!'} type={'success'} seconds={3} />} */}
      {alertOnAction && <AlertBanner
        label={alertOnAction.label}
        type={alertOnAction.type} 
        seconds={alertOnAction.seconds}
        onEndTime={() => { alertOnAction.onEndTime()}}
      />}
      
      
      <S.Title>Dados de saúde</S.Title>
      <S.TextInfo>
        Você pode editar o campo que quiser, e depois é só clicar no botão "Salvar" para confirmar as alterações
      </S.TextInfo>
      {
         editar ? <HealthDataEdit getHealthData={getHealthData} setEditar={setEditar} healthData={healthData} setAlertUpdateHealthData={setAlertUpdateHealthData}/> 
         : 
         <HealthDataDetails setEditar={setEditar} healthData={healthData}/>
       }
    </S.Content>
  )
}

export default HealthData