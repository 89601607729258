import styled from 'styled-components'


export const Container = styled.div`
  height: 425px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  padding: 0px 24px;

  button {
    margin-top: 2rem;
  } 

  @media(min-width: 666px){
    padding: 0;
  }
`

export const Titulo = styled.h1`
  font-size: 28px;
  line-height: 40px;
  font-weight: 600;
  color: var(--primary);
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
`

export const Texto = styled.p`
  font-weight: 600;
  font-size: 16px;
  color: var(--life-neutral-black-88);
  line-height: 24px;
  margin-bottom: 8px;
  margin-top: 2rem;
`

export const TextoInfo = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
  margin-top: 1rem;
  margin-bottom: 2rem;
`

export const ContainerBtns = styled.div`
  margin-top: 56px ;
  @media(min-width: 666px){
    margin-top: 0;
  }
`