import * as S from './styles'
import { ReactComponent as IconPrescription} from '../../../../assets/ic-prescription.svg'
import { useHomeContext } from 'contexts/HomeContext'

const Prescription = () => {
  const {noticeAirmed} = useHomeContext()
  let url = `https://airmed-dev.maida.health/receita/${noticeAirmed.publicId}?accessCode=${noticeAirmed.code}`
  return (
    <S.Container>
      <S.Left>
        <S.Title>Prescrição recebida!</S.Title>
        <S.Text>Confira as orientações médicas geradas a partir do seu atendimento</S.Text>
        <S.ButtonLink href={url}>Ver prescrição</S.ButtonLink>
        {/* <Button onClick={() => navigate('/prescription')} style={{width: '100%'}} variant='primary'>Ver prescrição</Button> */}
      </S.Left>
      <S.Right>
        <IconPrescription/>
      </S.Right>
    </S.Container>
  )
} 

export default Prescription