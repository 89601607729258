import styled from 'styled-components'


export const ContentNoConsutl = styled.div`
  margin-top: 100px;
  width: 100%;
  height: 248px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img, svg {
    width: 229px;
    height: 153px;
    margin-bottom: 34px;
  }

  p {
    font-size: 1rem;
    
  }
`