import React from 'react'
import * as S from './styles'
import {ReactComponent as IconEmoji0} from '../../assets/avaliation-0.svg'
import {ReactComponent as IconEmoji1} from '../../assets/avaliation-1.svg'
import {ReactComponent as IconEmoji2} from '../../assets/avaliation-2.svg'
import Form from './Forms'
import { useNavigate, useParams } from 'react-router-dom'
import { getQuestions, sendAvaliation, skipAvaliation } from 'api/intancesAxios/onboardingURL'
import useQuery from 'hooks/useQuery'

type AvaliacaoProps = "0" | "1" | "2" | undefined

export type MotivosProps = {
  descricao: string 
  id: number 
  possuiComentario: boolean
  marked?: boolean
  comment?: string
}
type QuestionsProps = {
  descricao: string 
  emojiUnicode: string 
  id: number 
  motivos: MotivosProps[]
  ordem: number
}

const Avaliacao = () => {
  const navigate = useNavigate()
  let { id } = useParams();
  const query = useQuery()

  const objEmojiCode: any = {
    "0": "U+2639",
    "1": "U+1F610",
    "2": "U+1F603"
  }

  // Parameters for get emoji selected
  const emojiCode = query.get("emojiCode") as AvaliacaoProps
  const idAvaliacao = query.get("idAvaliacao")

  const [avaliacao, setAvaliacao] = React.useState<AvaliacaoProps>(emojiCode)
  const [questionsApi, setQuestionsApi] = React.useState<QuestionsProps[] | undefined>()
  // const [arrQstComent, setArrQstComment] = React.useState<MotivosCommentProps[]>([]) 
  
  const obterPerguntas = async () => {
    const res = await getQuestions()
    setQuestionsApi(res)
  }
  React.useEffect(() => {
    obterPerguntas()
  }, [])

  const selectQuestions = (arr: QuestionsProps[], emojiCode: string) => {
    let element = arr.find(el => el.emojiUnicode === emojiCode)
    let newArr = element?.motivos.map((el:MotivosProps) => {
      let newObj = el
      // if(el.possuiComentario){
      //   newObj = {...newObj, comment: ""}
      // }
      return {
        ...newObj, marked: false, 
      }
    })
    return newArr
  } 

  const noAvaliation = async () => {
    if(emojiCode && idAvaliacao){
      //skip pending avaliation
      await skipAvaliation(idAvaliacao)
    }else{
      //skip avaliation for first time
      let objFinal = { uuidAtendimento: id}
      await sendAvaliation(objFinal)
    }
    navigate("/")
  }

  const getTitle = (avaliation: string) => {
    if(avaliation === "U+1F603"){
      return "Oba! E o que você mais gostou?"
    }
    return "O que podemos melhorar?"
  }


  return (
    <S.Wrapper>
      <S.Header>
        <S.Btnpular onClick={() => noAvaliation()}>Pular</S.Btnpular>
      </S.Header>
      <S.Container>
        <S.TextsContainer>
          <S.Title>Avaliação</S.Title>
          <S.Info>
            <S.Title style={{color: 'rgba(0, 0, 0, 0.88)'}}>Como foi a sua consulta?</S.Title>
            <S.Title style={{color: 'rgba(0, 0, 0, 0.88)', fontWeight: 400, fontSize: '16px'}}>Escolha um dos emojis para avaliar</S.Title>
          </S.Info>
        </S.TextsContainer>
        <S.Emojis>
          <S.ContainerEmoji 
            className={avaliacao === "0" ? "active" : undefined} style={{cursor: 'pointer'}} onClick={() => setAvaliacao("0")}>
            <IconEmoji0  />
          </S.ContainerEmoji>
          <S.ContainerEmoji 
            className={avaliacao === "1" ? "active" : undefined}
            onClick={() => setAvaliacao("1")} style={{cursor: 'pointer'}}>
            <IconEmoji1 /> 
          </S.ContainerEmoji>
          <S.ContainerEmoji 
            className={avaliacao === "2" ? "active" : undefined}
            style={{cursor: 'pointer'}} onClick={() => setAvaliacao("2")}>
            <IconEmoji2  />
          </S.ContainerEmoji>
        </S.Emojis>
        <S.FormContainer>
          {/* formulario */}
          {/* // slightly frowning face - 0 ruim*/}
          {/* // neutral face - 1  normal*/}
          {/* //	grinning face with big eyes - 2 bom*/}
          {
            (questionsApi && avaliacao) && <Form
              idAvaliacao={idAvaliacao}
              firstTime={emojiCode ? false : true}
              uuidAtendimento={id}
              emojiUnicode={objEmojiCode[avaliacao]} title={getTitle(objEmojiCode[avaliacao])}
              newQuestions={selectQuestions(questionsApi, objEmojiCode[avaliacao])}
            />
          }
          
        </S.FormContainer>
      </S.Container>
    </S.Wrapper>
  )
}

export default Avaliacao