import ButtonBack from "components/ButtonBack";
import * as S from './styles'

import { ReactComponent as IconBack } from "../../assets/backIcon.svg";
import { Outlet } from 'react-router-dom';


const Exams = () => {
  return (
    <>
      <S.Container>
        <S.Content>
          <header>
            <ButtonBack icon={<IconBack />}>Voltar</ButtonBack>
          </header>

          <Outlet />
        </S.Content>
      </S.Container>
     
    </>
  )
}

export default Exams;