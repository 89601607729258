import styled from 'styled-components'

export const Container = styled.div`
  background: #fff;
  height: 100vh ;
  /* padding: 24px; */
  
  @media(min-width: 500px){
    /* padding: 24px 67px; */
  }
`
export const ContainerTexts = styled.div`
  display: flex;
  flex-direction: column ;
  gap: 24px;
  width: 300px;
  @media(min-width: 500px){
    width: 100%;

  }

`
export const Text = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
`

export const Wrapper = styled.div`
  display: flex;
  /* flex-direction: column ; */

  width: 100% ;
  height: 100% ;
/* 
  @media(min-width: 1333px){
    flex-direction: row ;
  } */
`

export const Header = styled.header`
  display: flex;
  flex-direction: column ;
  gap: 24px;
`

export const TextHeader = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
`

export const Title = styled.h1`
  display: flex;
  gap: 18px;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.88);
`

export const IconContainer = styled.span`
`

export const Left = styled.div`
  width: 36%;
  height: 100% ;
  background-color: #f6f6f9;
  padding: 24px;

  @media(min-width: 500px){
   padding: 40px 16px 40px 67px;
  }

`

export const Box = styled.div`
`
export const Nav = styled.nav`
  display: flex ;
  margin-top: 32px;
`

export const Right = styled.div`
  width: 64% ;
  height: 100% ;
`