export const months = [{label: 'Janeiro'},{label: 'Fevereiro'},{label: 'Março'}, {label: 'Abril'}, {label: 'Maio'},
{label: 'Junho'}, {label: 'Julho'}
, {label: 'Agosto'}, {label: 'Setembro'}, {label: 'Outubro'}, {label: 'Novembro'}, {label: 'Dezembro'}]
export const years = [{label: '2021'},{label: '2022'},{label: '2023'}, {label: '2024'}]

export const DayOfWeek: any = {
  0: 'Domingo',
  1: 'Segunda-feira',
  2: 'Terça-feira',
  3: 'Quarta-feira',
  4: 'Quinta-feira',
  5: 'Sexta-feira',
  6: 'Sábado'
}

export const monthNumber : any = {
  "Janeiro": '1',
  "Fevereiro": '2',
  "Março": '3',
  "Abril": "4",
  "Maio":'5',
  "Junho":'6',
  "Julho":'7',
  "Agosto":'8',
  "Setembro":'9',
  "Outubro":'10',
  "Novembro":'11',
  "Dezembro":'12'
}

export const month = ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"];
  //Dados mocados para renderizar na tela quando não há horarios disponiveis
export const mockedHoursStatic = [
    ['1:00', '1:45', '2:00', '3:00', '3:30', '23:40', '23:50'],
];

export const dayOfWeekNames = [
  "Domingo", "Segunda", "Terça",
  "Quarta", "Quinta", "Sexta", "Sábado"
];

export function formatDate(date:any){
  let day = date.getDate(),
      mes = date.getMonth(),
      year = date.getFullYear(),
      hour = date.getHours(),
      h = hour % 12,
      aaa = hour < 12 ? 'AM' : 'PM',
      EEEE = dayOfWeekNames[date.getDay()],
      MMMM = month[mes]
   
  ;
 
  return `${day} de ${MMMM} de ${year}`;
}
