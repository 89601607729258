import React from 'react'
import {ReactComponent as IconUser} from '../../../assets/mai-ic-user.mono.svg'
import { ReactComponent as IconArrowRight } from "../../../assets/mai-ic-chevron-single-right.mono.svg";

import IconLife from 'components/Icon'
import * as S from './styles'
import useWindowDimensions from 'hooks/useWindowDimensions';

type Props = {
  specialty: string
  name: string
  numberOfMsgs: string
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined 
}

const CardInfoDetails = ({specialty, name, onClick}: Props) => {

  const [mobileLayout, setMobileLayout] = React.useState(false)
  const { height, width } = useWindowDimensions();

  React.useEffect(() => {
    if(width <= 400){
      setMobileLayout(true)
    }else{
      setMobileLayout(false)
    }
  }, [width])

  return (
    <S.Container typing={specialty} style={{flexDirection: mobileLayout ? 'column': 'row'}} >
      <S.Row >
        <S.ContainerIcon>
          <IconLife color='#ffffff' icon={<IconUser/>}/>
        </S.ContainerIcon>

        <S.Container2 typing={specialty}>
          <S.ContainerTexts>
            <S.Title>{name}</S.Title>
            {specialty && <S.Subtitle>{specialty}</S.Subtitle>}
          </S.ContainerTexts>
        </S.Container2>
      </S.Row>
      <S.ContainerLink onClick={onClick}>
        <S.Link>Ver detalhes da consulta</S.Link>
        <IconArrowRight/>
      </S.ContainerLink>


    </S.Container>
  )
}

export default CardInfoDetails