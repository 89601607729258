import { ContentCard } from "./styles";
import consult from '../../../../assets/Animation/consult.json'
import LifeLottie from '../LifeLottie';
// import { Attendance } from 'contexts/types/Attendance';
import { Link } from 'react-router-dom';

type props = {
  attendance: any,
}

const NewConsult = ({attendance}: props) => {
  return (
    <ContentCard>
      <div>
        <LifeLottie animationData={consult} height={88} width={88} />
        <div className="information">
          {attendance &&(
            <>
              <p>{attendance?.specialty?.name}</p>
              <strong>{attendance?.professional?.name}</strong>
              <span>{attendance?.prettySchedulingDate}</span>
            </>
          )}
          
        </div>
      </div>

      <div className="textContent">
        <p>
          Você poderá iniciar a consulta 5 minutos antes do horário agendado
        </p>
        <Link className='link' to={`/detalhes/${attendance.id}`} >Ver detalhes</Link>
      </div>
    </ContentCard>
  )
}

export default NewConsult;