import * as React from 'react';
import { CardConsultation, ContentConsultations, Container } from "./styles";
import urgency from '../../../assets/urgencyIcon.svg'
import consultIcon from '../../../assets/consultIcon.svg'
import { useUserContext } from "contexts/UserContext";

const Index = () => {
  sessionStorage.clear()
  const {getMeInsured} = useUserContext()

  React.useEffect(() => {
    getMeInsured()
  }, [])
  
  return (
    <Container>
      <ContentConsultations>
        <h4>Consultas online</h4>
        <div>
          <p>Atendimento quando quiser</p>
          <span>Você pode escolher se quer falar com um profissional agora ou 
            agendar um atendimento
          </span>
        </div>

        <div className="contentCard">
          <CardConsultation to="urgencia/selecionar-especialidade" className="urgency">
            <div>
              <p>Consulta de Urgência</p>
              <span>Para casos urgentes, fale com um profissional agora</span>
            </div>
            <img src={urgency} alt="Urgency icon" />
          </CardConsultation>

          <CardConsultation to="eletiva/selecionar-especialidade">
            <div>
              <p>Consulta Agendada</p>
              <span>Escolha a especialidade, o dia e horário para a consulta</span>
            </div>
            <img src={consultIcon} alt="Scheduled icon" />
          </CardConsultation>
        </div>
      </ContentConsultations>
    </Container>
  );
};

export default Index;
