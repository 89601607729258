import styled from 'styled-components'
import ImageMain from "../../assets/Lifeplace.svg";
import Smallboy from "../../assets/Backgrounds/smallboy.svg";


export const Container = styled.div`
  display: flex;
  /* Alinha os itens ao centro */
  align-items: center;
  justify-content: center ;
  /* mobile em column */
  flex-direction: column;
  /* altura */
  height: 100vh;

  background: var(--fundo);

  header {
      margin-top: 45px;
    }

  @media(min-width: 666px){
    flex-direction: row ;
  }
`
type PropsRoute = {
  pathname: string
}
export const Esquerda = styled.div<PropsRoute>`

  display: ${(props) => props.pathname === '/' ? 'block' : 'none'} ;
  flex: 30%;
  /* outline: 1px solid red ; */
  width: 100% ;
  height: 100% ;


  @media(min-width: 666px){
    display: flex;
    flex: 50%;
  }

`
export const ImageBackground = styled.div`
     /* outline: 1px solid orange ; */
     height:  100% ;
     width: 100% ;
     background-image: url(${Smallboy});
     object-fit: contain;
     background-position: center;
     background-repeat: no-repeat;

    @media(min-width: 666px){
      background-image: url(${ImageMain});
    }
`

export const Direita = styled.div<PropsRoute>`
  display: flex;
  flex: 70% ;
  /* justify-content: center ; */

  margin-top: ${(props) => props.pathname === '/' ? '0px' : '102px'} ;


  @media(min-width: 666px){
    flex: 50%;
    margin-top: 0px;
  }
`

export const Cabecalho = styled.div`
  top: 0;
  position: absolute ;
  left: 24px;

  @media(min-width: 666px){
    left: 90px;
  }
`