import React from 'react'
import Lottie from 'react-lottie';

type Props = {
  animationData: any
  height?: number | string
  width?: number | string
}
const LottieComponent = ({animationData, height, width} : Props) => {

  const [animationState,] = React.useState({
    isStopped: false, isPaused: false
  })

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <Lottie options={defaultOptions}
      height={height}
      width={width}
      style={{margin: 0}}
      isStopped={animationState.isStopped}
      isPaused={animationState.isPaused}/>
  )
}

export default LottieComponent;