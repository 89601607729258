import styled, { keyframes } from "styled-components";

export const Content = styled.div`
    display: flex;
    flex-direction: column ;
    gap: 16px ;

    p {
        color: rgba(0, 0, 0, 0.64);
    }
`
type ScrollProps = {
  scroll: boolean
}
export const FilesContainer = styled.div<ScrollProps>`
    padding: 24px 0;
    padding-right: 16px ;
    display: flex ;
    flex-direction: column ;
    /* max-height: 230px ; */
    overflow-y: ${(props) => props.scroll ? "none" : undefined} ;
    gap: 30px ;

    
  &::-webkit-scrollbar {
    width: 8px;
    background-color: rgba(0, 0, 0, 0.15);
  }

  &::-webkit-scrollbar-thumb {
    background: #2b45d4;
    border-radius: 8px;
  }

`
export const FilesData = styled.div`
    position: relative ;
    display: flex ;
    margin: 4px;
`

export const ContainerInfo = styled.div`
    margin-left: 11px;
    width: 100% ;
`

const animation =  keyframes`
    from {width: 0%; background: #E59500;}
    to {width: 100%; background: #38B449;}
`

export const ProgressBar = styled.div`
    border-radius: 4px;
    height: 6px;
    animation: ${animation} 3s forwards;
`


export const FileName = styled.p`
    font-weight: 600 !important;
    color: rgba(0, 0, 0, 0.64);
    margin-bottom: 8px;

`


export const Container = styled.div`
    display: flex;
    justify-content: center ;
    align-items: center;
`

export const Footer = styled.div`
    cursor: pointer;
    display: flex; 
    justify-content: center ;
    align-items: center;
`

export const Subtitle = styled.h3`
    color: var(--primary-light) !important;
    line-height: 24px;
    font-weight: 600 ;
`

export const Text = styled.p`
`