import React from 'react'
import * as S from './styles'
type Props = {
  children: React.ReactNode
  alignSelf: 'flex-end' | 'flex-start' | undefined
  widthMessage?: string
}
const ContainerMessage = ({children, widthMessage, alignSelf}: Props) => {
  return (
    <S.Container>
      <S.ContainerMessage style={{alignSelf: alignSelf}}>
        {children}
      </S.ContainerMessage>
    </S.Container>
  )
}

export default ContainerMessage