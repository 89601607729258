import { ReactComponent as SignlaIcon0 } from '../../../../assets/mai-ic-signal-0.svg';
import { ReactComponent as SignlaIcon1 } from '../../../../assets/mai-ic-signal-1.svg';
import { ReactComponent as SignlaIcon2 } from '../../../../assets/mai-ic-signal-2.svg';
import { ReactComponent as SignlaIcon3 } from '../../../../assets/mai-ic-signal-3.svg';
import { ReactComponent as IconCamOn } from '../../../../assets/cameraOn.svg';
import { ReactComponent as IconCamOff } from '../../../../assets/camera.svg';
import { ReactComponent as MicOff } from '../../../../assets/micOff.svg';
import { ReactComponent as MicOn } from '../../../../assets/micOn.svg';

import * as S from './styles';

interface InfoProps {
  cam?: boolean;
  mic?: boolean;
  signal?: number | null | undefined;
}

const InfoIcon = ({ cam, signal, mic = true }: InfoProps) => {
  const returnIconSignal = (level: any) => {
    if (level > 4) {
      return <SignlaIcon3 />;
    } else if (level > 3) {
      return <SignlaIcon2 />;
    } else if (level > 0) {
      return <SignlaIcon1 />;
    } else if (level === 0) {
      return <SignlaIcon0 />;
    }
    if (level === undefined || level === null) {
      return <SignlaIcon1 />;
    }
  };

  return (
    <S.Wrapper>
      {cam ? <IconCamOn /> : <IconCamOff />}
      {mic ? <MicOn /> : <MicOff />}
      {returnIconSignal(signal)}
    </S.Wrapper>
  );
};

export default InfoIcon
