import styled from "styled-components";

export const Container = styled.div`
  background-color: #f6f6f9;
  top: 0;
  left: 0;
  right: 0 ;
  bottom: 0;
  position: absolute ;
  display: flex;
  justify-content: space-between;
  flex-direction: column ;
  height: 100%;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column ;
  padding: 24px 40px 16px 16px;
  background: #FFFFFF;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16);
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.88);
`

export const Text = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`


export const Footer = styled.div`
  display: flex;
  align-items: center; 
  justify-content: space-between ;
  margin-top: 16px;
`

export const ContainerInput = styled.div`
  width: 86%;
  margin-right: 6px;
` 
export const ContainerIcon = styled.div`
  cursor: pointer;
` 

export const Button = styled.button`
  background: #2B45D4;
  display: flex;
  align-items: center;
  justify-content: center ;
  border-radius: 8px;
  padding: 10px;
  :disabled {
    opacity: 0.64;
  }
`
export const Content = styled.div`
  box-sizing: border-box ;
  padding: 16px 24px 16px 16px;
  height: 65%;
  display: flex;
  flex-direction: column ;
  justify-content: space-between ;
`

export const ContainerMessages = styled.div`
  overflow-y: scroll ;
  overflow-x: hidden ;
  padding: 4px;

  &::-webkit-scrollbar {
    width: 4px;
    /* background-color: rgba(0, 0, 0, 0.15); */
  }

  &::-webkit-scrollbar-thumb {
    background: #2b45d4;
    border-radius: 8px;
  }
`


export const ContainerDate = styled.div`
  display: flex;
  justify-content: center ;
  margin-bottom: 20px;
  margin-top: 20px;
`

export const MsgDate = styled.div`
  padding: 0px 8px;
  gap: 10px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
`

export const TextDate = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: rgba(0, 0, 0, 0.56);
`

export const ParticipantsTitle = styled.h1`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
`