import styled from 'styled-components'

export const Container = styled.div`
  padding: 32px 67px 32px 48px;
  display: flex;
  flex-direction: column ;
  gap: 16px;
  height: 100%;
  background-color: #fff;
  width: 100% ;
`
export const Header = styled.header`
  display: flex;
  flex-direction: column ;
  gap: 24px;
`
export const Title = styled.h1`
  display: flex;
  gap: 18px;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.88);
`


export const TextHeader = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
`

export const ContainerChat = styled.div`
  display: flex;
  flex-direction: column ;
  justify-content:end ;
  height: 73%;
`
export const ContainerMessages = styled.div`
  overflow-y: auto ;
  padding-right: 8px;
  height: 100%;

  /* Alinhar msgs no fim */
  /* display: flex;
  flex-direction: column ;
  justify-content: flex-end ; */

  &::-webkit-scrollbar {
    width: 4px;
    background-color: rgba(0, 0, 0, 0.15);
  }

  &::-webkit-scrollbar-thumb {
    background: #2b45d4;
    border-radius: 8px;
  }
`

export const ContainerDate = styled.div`
  display: flex;
  justify-content: center ;
  margin-bottom: 20px;
  margin-top: 20px;
`

export const MsgDate = styled.div`
  padding: 0px 8px;
  gap: 10px;
  width: fit-content ;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
`

export const TextDate = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: rgba(0, 0, 0, 0.56);
`

export const Loading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`
export const Footer = styled.div`
  display: flex;
  align-items: center; 
  gap: 8px;  
`
export const ContainerIcon = styled.div`
  cursor: pointer;
` 

export const Button = styled.button`
  background: #2B45D4;
  display: flex;
  align-items: center;
  justify-content: center ;
  border-radius: 8px;
  padding: 10px;

  :disabled {
    opacity: 0.64;
  }
`