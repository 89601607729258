import * as S from './styles'

interface BadgerProps {
  text:string,
  background: string,
  color: string
}

const Badge = ({ text, background, color }: BadgerProps) => {
  return (
    <S.Wrapper style={{ backgroundColor: `${background}`, color:`${color}`}}>
      <span>{text}</span>
    </S.Wrapper>
  )
}

export default Badge;