import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContainerMessage = styled.div`
  width: 100%;

  @media(min-width: 550px){
    width: 45%;
  }
`