import React from 'react'
import * as S from './styles';
import { Button } from "storybook-maida"
import { useNavigate } from 'react-router-dom';
import { useConsultsContext } from 'contexts/ConsultsContext';
import Radio from '../../../../components/Radio'
import useSessionlStorage from 'hooks/useSessionlStorage';

const ConsutlUrgency = () => {
  const navigate = useNavigate()
  
  let urgencyAppointment;
  const [data, setData] = useSessionlStorage('consulta', urgencyAppointment);

  const handleChange = (id: string)=> {
    setData(id)
  }
  const token = window.localStorage.getItem("telehealth@token") || ""
  const { getSpecialty, dataConsult } = useConsultsContext()

  React.useEffect(()=>{
    getSpecialty(token) 
  },[])

  return (
    <>
      <S.ContentUrgency>
        <div className='Container'>
          <div className='information'>
            <h4>Agendar Consulta</h4>
            <p>Escolha a especialidade</p>
            <span style={{'marginBottom': '48px'}}>Para qual especialidade você quer atendimento?</span>

            { dataConsult && dataConsult.filter((item: any ) => 
            item.availableForUrgency === true)
            .map((item : any)=> {
              return (
                <Radio key={item.id}
                  groupName="urgency"
                  id={item.name}
                  label={item.name}
                  onChange={() => handleChange(item.id)}
                  value={item.name}
                  style={{
                    minWidth: '100%',
                    marginBottom: '8px'
                  }}
                />
              )
            })}

            {/* <Radio label="Exemplo" name="consult" /> */}

            {data ? <Button children="Próximo" style={{'marginTop': '48px'}} disabled={false} onClick={() => navigate("confirmar-telefone")}/> : <Button  onClick={() => navigate("numero")} style={{'marginTop': '48px'}} disabled={true} children="Próximo"/>}  
          </div>
        </div>
      </S.ContentUrgency>
    </>
  )
}

export default ConsutlUrgency;