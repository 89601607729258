import Photo from '../../../../assets/bg.svg'
import { ReactSVG } from 'react-svg'
import closeIcon from '../../../../assets/closeSidebar.svg'
import iconArrow from '../../../../assets/ArrowNext.svg'
import { ProfileContent, PhotoContent } from './styles';
import { SidebarData } from '../Navbar/SidebarData';
import IconSair from '../../../../assets/ic-logout.svg'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useUserContext } from 'contexts/UserContext';
import NavItem from '../Navbar/NavItem/NavItem';

type Props = {
  unreadMsg: string
}

const Profile = ({unreadMsg}:Props) => {
  const [ sidebar, setSidebar ] = useState(false);
  const {userLogout, userData } = useUserContext();

  const showSidebar = () => {
    setSidebar(!sidebar)
  }


  return (
    <ProfileContent>
      
      <PhotoContent onClick={showSidebar}>
        <img src={Photo} alt="Profile user" />
      </PhotoContent>

      <nav className={sidebar ? 'nav-menu active': 'nav-menu'}>
        <ul className='nav-menu-item'>
          <li className='btn-close' onClick={showSidebar}>
            <img src={closeIcon} alt="" />
            Fechar
          </li>

          <li className="user">
            <img src={Photo} alt="Profile user" />
            <div>
              <strong>{userData.firstName}</strong>
              <span>{userData.email}</span>
            </div>
          </li>

          {SidebarData.map((item, index)=>{
          return (
            <NavItem key={index.toString()}
              showNumber={(item.path === '/mensagens' && unreadMsg) ? true : false} 
              numberMsg={(item.path === '/mensagens') ? unreadMsg : ""} 
              to={item.path} 
              icon={item.icon} title={item.title} src={iconArrow} />
          )
          })}

          <Link to="/" onClick={userLogout}>
            <li className='nav-item'>
              <div>
                <ReactSVG src={IconSair} />
                <span>Sair</span>
              </div>
              <ReactSVG src={iconArrow} alt="Icon Arrow" />
            </li>
          </Link>
        </ul>
      </nav>
      <div id={sidebar ? 'overlay' : 'none'} onClick={() => setSidebar(!sidebar)}></div>
    </ProfileContent>
  )
}

export default Profile;