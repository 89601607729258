import { ContentButton } from './styles';
import { useNavigate } from 'react-router-dom';

type ButtonProps = {
  icon?: React.ReactComponentElement<'svg'>;
  style?: React.CSSProperties | undefined;
  to?: any;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
} & React.ComponentProps<'button'>;

const ButtonBack = ({ children, style, icon, to, onClick }: ButtonProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    to ? navigate(to) : navigate(-1);
  };

  return (
    <ContentButton style={style} onClick={onClick ? onClick : handleClick}>
      {icon && <span>{icon}</span>}
      {children}
    </ContentButton>
  );
};

export default ButtonBack;
