import React, { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import { decodeToken } from "react-jwt";
import { UserProps, ProfileObjsProps, TokenObjProps, AppleLogin, ProfileApplePros } from "./types/UserTypes";
import { GET_AUTH_TELE } from '../api/Services/AuthTelehealth';
import { useHomeContext } from "./HomeContext";
import { ATTENDANCE_FEEDBACK } from "api/User/Feedback";
import useFetch from "hooks/useFetch";
import { GET_USER_ME } from "api/Services/User";
import { UserAndTermsProps } from "api/types/Auth/onboarding";
import { CodeProps } from "api/types/Auth/userAuth";
import { postAuthenticationApple, postAuthenticationEmail, postAuthenticationGoogle, postVerificationCode } from "api/intancesAxios/lifeplaceAPI_URL";
import { getCheckEmail, getUserInfo, newGetMe, postConfirmationOnboarding } from "api/intancesAxios/onboardingURL";
import { getAuthInTelehealth} from "api/intancesAxios/telehealthURL";
import { GET_DATA_ACCOUNT } from "api/Services/Profile";


type AttendanceType = 'ELECTIVE' | 'URGENCY'

//#######################################################
// CRIACAO DO CONTEXTO E SUAS PROPRIEDADES
//#######################################################
type ContextProps = {
  userData: UserProps;
  login: boolean;
  error: boolean | string | null;
  loading: boolean;
  conectarGoogle:         (response: any)   => void;
  conectarApple:          (response: any)   => void;
  conectarEmail:          (email: string)   => void;
  authWithEmail:          (email: string)   => void;
  validationCODE:         (code: CodeProps) => void;
  confirmationOnboarding: (infoETermos: UserAndTermsProps) => void;
  userLogout: () => void;
  getLoginWithTokenHapvida: (token:string, type: AttendanceType) => void;
  sendFeedback: (idAttendance: string, obj: {grade: string, comment: string}) => void;
  errorHook: any;
  dataHook: any
  getMeInsured: () => void
  newUserData: any
  publicId: string
  objectRedirect: ObjectRedirect
  setObjectRedirect: React.Dispatch<React.SetStateAction<ObjectRedirect>>
};

type Props = {
  children: ReactNode;
};

type ObjectRedirect ={ 
  attendanceId: string | null
  type: 'ELECTIVE' | 'URGENCY' | null
}


//criacao de um contexto tipado para que seja possivel armazenar os dados a serem compartilhados
const UserContext = React.createContext<ContextProps | undefined>(
  {} as ContextProps
);

// Criando o Componente Para que seja possível compartilhar as informações com os componentes filhos ..
export const UserProvider = ({ children }: Props) => {

  const navigate = useNavigate();
  const {getPosition} = useHomeContext()

  //cria o estado para as informacoes de um usuario que fez login com Google
  const [userData, setUserData] = useState<UserProps>({} as UserProps);
  const [newUserData, setNewUserData] = useState(null)
  
  const [publicId,] = React.useState('')
  const [login, setLogin] = useState(false);
  const [error, setError] = React.useState<boolean | string | null>(null);
  const [loading, setLoading] = React.useState(false);
  const {hasNoticesTele, hasAirmedNotices} = useHomeContext()
  const {errorHook, dataHook} = useFetch()
  const [objectRedirect, setObjectRedirect] = React.useState<ObjectRedirect>({} as ObjectRedirect)
  const [token, setToken] = React.useState(null)


  const dataAccount = async() => {
    newGetMe().then(json => {
      setNewUserData(json)
    }).catch(e => {
      // console.log(e)
    })
    // try {
    //   const { url, options } =  GET_DATA_ACCOUNT()
    //   const response = await fetch(url, options)
    //   const json = await response.json()
    //   if ( response.ok) {
    //     setNewUserData(json)
    //   }
    // } catch(err) {
    //  console.log(err)
    // }
  }
  

  //#######################################################
  // METODO DE LOGOUT 
  //#######################################################

    function userLogout() {
      window.localStorage.removeItem("lifeplace@token");
      window.localStorage.removeItem("telehealth@token");
      window.localStorage.removeItem("lifeplace@email");
      window.localStorage.removeItem("telehealth@airmed");
      setNewUserData(null)
      setError(false);
      setLoading(false);
      setLogin(false);
      navigate("/");
    }

    // /me 
    // traz dados do usuário, nele o insuredId
   async function getMeInsured() {
      const { options, url }  = GET_USER_ME()
      const response = await fetch(url, options);
      const { id, phoneNumber, ddd } = await response.json();
      setUserData({...userData, id: id, telefone: (ddd && phoneNumber) ? ddd+phoneNumber : ""})
    }

  //#######################################################
  // MÉTODO QUE OBTEM INFORMACOES BASICAS DO USUARIO
  //#######################################################
  async function getUser(email: string) {
    
    setLoading(true);
    setError(false);

    getUserInfo({email}).then(async (json) => {
      const { nomeCompleto, email, teleHealth} = json;
      let nome = nomeCompleto.split(" ");
      setUserData({ ...userData, email: email, firstName: nome[0], lastName: nome[1] });

      window.localStorage.setItem("telehealth@key", teleHealth)
      await getAuthTelehealth(teleHealth)
      dataAccount()
      setLogin(true);

      // if(objectRedirect.type === 'ELECTIVE' && objectRedirect.attendanceId){
      //   return navigate(`/detalhes/${objectRedirect.attendanceId}`)//2036
      // }

      // if(objectRedirect.type === 'URGENCY'){
      //   return navigate('/consultas-online/teleconsulta')
      // } else{
      //   return navigate("/home");
      // }

    }).catch((e) => {
      setError(true)
    }).finally(() => {
      setLoading(false)
    })

    // const token = localStorage.getItem('lifeplace@token') as string
    // const { url, options } = GET_ME({ email: email }, token);

    // try {
      // setLoading(true);
      // setError(false);
      // const response = await fetch(url, options);

      // const json = await response.json();
      // const { nomeCompleto, email, teleHealth} = json;
      // let nome = nomeCompleto.split(" ");
      // setUserData({ ...userData, email: email, firstName: nome[0], lastName: nome[1] });

      // window.localStorage.setItem("telehealth@airmed", teleHealth)
      // await getAuthTelehealth(teleHealth)
      // pegando novos dados do usuario que inclui CPF
      // dataAccount()
      // fim  account

      // ############# LOGOU ###############################
    //   setLogin(true);
      
    //   if(objectRedirect.type === 'ELECTIVE' && objectRedirect.attendanceId){
    //     return navigate(`/detalhes/${objectRedirect.attendanceId}`)//2036
    //   }

    //   if(objectRedirect.type === 'URGENCY'){
    //     return navigate('/consultas-online/teleconsulta')
    //   } else{
    //     return navigate("/home");
    //   }
     
    //   // ###################################################


    // } catch (error) {
    //   setError(true);
    //   // console.log(error);
    // } finally {
    //   setLoading(false);
    // }
  }

  async function getMe(email: string) {
    
    setLoading(true);
    setError(false);

    getUserInfo({email}).then(async (json) => {

      const { nomeCompleto, email} = json;
      let nome = nomeCompleto.split(" ");
      setUserData({ email: email, firstName: nome[0], lastName: nome[1] });


    }).catch((e) => {
      setError(true)
    }).finally(() => {
      setLoading(false)
    })
   
    // const token = localStorage.getItem('lifeplace@token') as string
    // const { url, options } = GET_ME({ email: email }, token);
    

    // try {
    //   setLoading(true);
    //   setError(false);
    //   const response = await fetch(url, options);

    //   const json = await response.json();

    //   const { nomeCompleto, email} = json;
    //   let nome = nomeCompleto.split(" ");
    //   setUserData({ email: email, firstName: nome[0], lastName: nome[1] });

    // } catch (error) {
    //   setError(true);
    // } finally {
    //   setLoading(false);
    // }
  }


  //auth telehealth  - se autentica no telehealth mandando a chave
  async function getAuthTelehealth(token:string) {

    getAuthInTelehealth(token).then(json => {
      const { token }= json
      //save telehealth token
      window.localStorage.setItem("telehealth@token", token)
      if(objectRedirect.type === 'ELECTIVE' && objectRedirect.attendanceId){
        return navigate(`/detalhes/${objectRedirect.attendanceId}`)//2036
      }

      if(objectRedirect.type === 'URGENCY'){
        return navigate('/consultas-online/teleconsulta')
      } else{
        return navigate("/home");
      }
    }).catch(() => {
      setError(true)
    })

    // const { url, options } = GET_AUTH_TELE(token);
    // try {
    //   const response = await fetch(url, options);
    //   const json = await response.json();
    //   const { token }= json
      
    //   window.localStorage.setItem("telehealth@token", token)

    // } catch(error){
    //   console.log(error)
    // }
  }

  function goToHapvidaTypeAttendance(type:string){
    type === 'URGENCY' ? navigate('agendar-consulta/urgencia') : navigate('agendar-consulta/especialidade')
  }

  async function getLoginWithTokenHapvida(token:string, type: AttendanceType){
   
    const {url, options} = GET_AUTH_TELE(token)

    setLogin(false)
    setLoading(true)
    setError(false)

    try {
      const response = await fetch(url, options)
      const {token} = await response.json()
      if(response.ok){
        window.localStorage.setItem("telehealth@token", token)
        setLogin(true)
        goToHapvidaTypeAttendance(type)
      }
    } catch (error) {
      console.log("deu erro no login HAPVIDA link ", error);
      setError(true)
    }finally{
      setLoading(false)
    }
  }

  

  
  //#######################################################
  // AUTO LOGIN
  //#######################################################
  function isTokenExpired(token:string) {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp
    return Math.floor(new Date().getTime() / 1000) >= expiry
  }

  async function autoLogin() {
    const token = window.localStorage.getItem("lifeplace@token");
    const email = window.localStorage.getItem("lifeplace@email");

    if (token && email && !isTokenExpired(token)) {
      setLogin(true)
      getMe(email)
    }
  }



  //#######################################################
  // MÉTODOS DE SALVAR NO ESTADO O EMAIL DEPOIS DA TELA INICIAL
  //####################################################### 

  const conectarEmail = (email: string) => {
    if (email) {
      setUserData({ ...userData, email: email });
    }
  };


  //#######################################################
  // MÉTODOS DE LOGIN
  //####################################################### 
  async function authWithEmail(email: string) {

      setError(false);
      setLoading(true);
  
      postAuthenticationEmail(email).then(() => {
        conectarEmail(email); // salva o email no setData
        window.localStorage.setItem("lifeplace@email", email);
        navigate("/code");
      }).catch((error) => {
        setError(String(error));
        setLogin(false);
      }).finally(() => {
        setLoading(false);
      })
    // try {
    //   setError(false);
    //   setLoading(true);

    //   const { url, options } = CALL_ENPOINT("API_URL",`auth/email`,"POST",{ email: email }, HeaderAuth);
    
    //   const response = await fetch(url, options);
    //   // console.log(response);

    //   if (!response.ok) {
    //     const body = await response.json();
    //     throw new Error(body.message);
    //   }

    //   conectarEmail(email); // salva o email no setData
    //   window.localStorage.setItem("lifeplace@email", email);

    //   navigate("/code");
    // } catch (error) {
    //   setError(String(error));
    //   setLogin(false);
    // } finally {
    //   setLoading(false);
    // }
  }

  async function checkEmail(email: string) {
    let replaceEmail = email.replace("@", "%40");
    getCheckEmail(replaceEmail).then(async () => {
      window.localStorage.setItem("lifeplace@email", email);
      await getUser(replaceEmail);
    }).catch(() => {
      navigate("/onboarding");
    }).finally(() => {
      setLoading(false)
    })
    // try {
    //   setError(false);
    //   setLoading(true);

    //   const token = localStorage.getItem('lifeplace@token') as string

    //   let replaceEmail = email.replace("@", "%40");

    //   const { url, options } = CALL_ENPOINT("ONBOARDING_URL",`api/onboarding?email=${replaceEmail}`,"GET",null,headerAppToken(token));
    //   const response = await fetch(url, options);

    //   //Se deu algum erro, o usuario é NOVO - então vai para /onboarding
    //   if (!response.ok) {
    //     navigate("/onboarding");
    //     return;
    //   }

    //   //usuario existente vai para home
    //   window.localStorage.setItem("lifeplace@email", email);

    //   await getUser(replaceEmail);
      
    // } catch (error) {
    //   // console.log(error);
    // } finally {
    //   setLoading(false);
    // }
  }


  //#######################################################
  // LOGIN SOCIAIS
  //####################################################### 
  const conectarGoogle = async (response: any) => {
    const {
      profileObj: { name, email },
      tokenId,
    } = response;

    setUserData({ ...userData, firstName: String(name), email: String(email) });

    const Profile: ProfileObjsProps = {
      name,
    };

    const ProfileToken: TokenObjProps = {
      id_token: tokenId,
    };

    const body = { profileObj: Profile, tokenObj: ProfileToken };

    await authWithGoogle(body, name);
  };

  async function authWithGoogle(body: any, name: string) {
      postAuthenticationGoogle(body).then(async (json) => {
        const { jwttoken, userData } = json;
        const { email } = userData;

        window.localStorage.setItem("lifeplace@token", jwttoken);
        await checkEmail(email); //vai pra home ou onboarding

        const nome = name.split(" ");
        // setPublicId(userData.publicId)
        setUserData({ email: email, firstName: nome[0], lastName: nome[1], publicId: userData.publicId});

        window.localStorage.setItem("lifeplace@email", email);
      //fim do bloco =============================================
      }).catch(() => {

      })

            // const { url, options } = POST_AUTH_GOOGLE(body);
      // VariavelAmbienteProps , url: string, method: MethodProps, body: any, headers:any
      // const { url, options } = CALL_ENPOINT("API_URL","auth/google","POST",body,HeaderAuth);
      // const {json} = await request(url, options);
    
      // const { jwttoken, userData } = json;
      // const { email } = userData;

      // //##############################################
      // // Segundo endpoint que retorna o TOKEN
      // //##############################################
      // window.localStorage.setItem("lifeplace@token", jwttoken);

      // await checkEmail(email); //vai pra home ou onboarding

      // // Nao se sei se esse bloco de código abaixo é necessário 
      // // pois nao sei se ele chega a ser exucutado, mas acredito que sim
      // const nome = name.split(" ");
      // // setPublicId(userData.publicId)
      // setUserData({ email: email, firstName: nome[0], lastName: nome[1], publicId: userData.publicId});

      // window.localStorage.setItem("lifeplace@email", email);
      // window.localStorage.setItem("lifeplace@token", jwttoken);
      //fim do bloco =============================================
  }


  const conectarApple = async (response: any) => {
    const { authorization } = response;
    const { id_token } = authorization;
    const { code } = authorization;


    const myDecodedToken = decodeToken(id_token);
    
    //fazendo casting implícito que o dev garante que ele será do formato AppleLogin
    const email = (myDecodedToken as AppleLogin).email;

    const firstName = "firstName";
    const lastName = "lastName";

    const Profile: ProfileApplePros = {
      code,
      email,
      firstName,
      lastName,
    };
    await authWithApple(Profile);
  };
  async function authWithApple(body: any) {

    postAuthenticationApple(body).then(async (json) => {
      const { jwttoken, userData } = json;
      const { firstName, lastName, publicId } = userData;
      window.localStorage.setItem("lifeplace@email", body.email);
      window.localStorage.setItem("lifeplace@token", jwttoken);

      setUserData({
        email: body.email,
        firstName: firstName,
        lastName: lastName,
        publicId
      });
    
      await checkEmail(body.email); //vai pra home ou onboarding
    }).catch((error) => {
      setError(String(error));
      setLogin(false);
    }).finally(() => {
      setLoading(false)
    })

    // try {
    //   setError(false);
    //   setLoading(true);
    //   // const { url, options } = POST_AUTH_APPLE(body);
    //   const { url, options } = CALL_ENPOINT("API_URL","auth/apple","POST",body,HeaderAuth);
    //   const response = await fetch(url, options);

    //   if (!response.ok) {
    //     // const body = await response.json()
    //     throw new Error("Erro na API");
    //   }

    //   const json = await response.json();
    //   const { jwttoken, userData } = json;
    //   const { firstName, lastName, publicId } = userData;

    //   window.localStorage.setItem("lifeplace@email", body.email);
    //   window.localStorage.setItem("lifeplace@token", jwttoken);
      
    //   setUserData({
    //     email: body.email,
    //     firstName: firstName,
    //     lastName: lastName,
    //     publicId
    //   });
    
    //   await checkEmail(body.email); //vai pra home ou onboarding

      
    //   //###############################################
    //   // Terceiro lugar que obtem token
    //   //###############################################

      


    // } catch (error) {
    //   setError(String(error));
    //   setLogin(false);
    // } finally {
    //   setLoading(false);
    // }
  }

  // ######################################################
  //  MÉTODO DE VERIFICAÇÃO DE CÓDIGO DE 4 DÍGITOS
  // ######################################################

  async function validationCODE(code: CodeProps) {
      setLoading(true)
      setError(false)

      postVerificationCode(code).then(async (json) => {
      const { jwttoken, userData } = json;
      const { firstName, lastName } = userData;
      window.localStorage.setItem("lifeplace@email", code.email);

      setUserData({
        email: code.email,
        firstName: firstName,
        lastName: lastName,
      });

      window.localStorage.setItem("lifeplace@token", jwttoken);

      // Checa se o usuário é novo ou não
      await checkEmail(code.email);
      }).catch(() => {
        setError(true)
      }).finally(() => {
        setLoading(false)
      })
      // const { url, options } = CALL_ENPOINT("API_URL",`auth/checkCode`,"POST",code, HeaderAuth);
      // const {json} = await request(url, options);

      // const { jwttoken, userData } = json;
      // const { firstName, lastName } = userData;

      // //Add o email no local storage
      // window.localStorage.setItem("lifeplace@email", code.email);

      // setUserData({
      //   email: code.email,
      //   firstName: firstName,
      //   lastName: lastName,
      // });

      // //##############################################
      // // Primeiro endpoint que retorna o TOKEN
      // //##############################################
      // window.localStorage.setItem("lifeplace@token", jwttoken);

      // // Checa se o usuário é novo ou não
      // await checkEmail(code.email);

  }

  // ######################################################
  //  MÉTODO DE CONFIRMAÇÃO DO ONBOARDING
  // ######################################################

  async function confirmationOnboarding(infoETermos: UserAndTermsProps) {
    setError(false);
    setLoading(true);
    postConfirmationOnboarding(infoETermos).then(async json => {
      await getUser(infoETermos.email.replace("@", "%40"));
    }).catch(e => {
      setError(String(error));
      setLogin(false);
    }).finally(() => {
      setLoading(false);
    })

    // try {
    //   setError(false);
    //   setLoading(true);

    //   const token = localStorage.getItem('lifeplace@token') as string

    //   const { url, options } = POST_ONBOARDING(infoETermos,token);

    //   const response = await fetch(url, options);

    //   if (!response.ok) {
    //     const body = await response.json();
    //     throw new Error(body.message);
    //   }

    //   await getUser(infoETermos.email.replace("@", "%40"));

    // } catch (error) {
    //   setError(String(error));
    //   setLogin(false);
    // } finally {
    //   setLoading(false);
    // }
  }


  async function sendFeedback(idAttendance: string, obj: {grade: string, comment: string}){
    try {
      const {url, options} = ATTENDANCE_FEEDBACK(idAttendance, obj)
      const response = await fetch(url, options)
      if(response.ok){
        // deu sucesso no envio
        console.log("feedback enviado com sucesso!!");
      }
    } catch (error) {
      console.log("Deu erro no envio do feedback");
    }
  }

  React.useEffect(() => {
      autoLogin()
  }, [login]);

  React.useEffect(() => {
    let id: NodeJS.Timeout 
    if(login){
    let token = window.localStorage.getItem('telehealth@token')
     
     //execucao inicial para n precisar esperar 10s de inicio
     hasNoticesTele()
     hasAirmedNotices()
    //  getPosition()

      if(token){
        getPosition()
      }
      // Verificar notices e posicao a cada 10s
      id = setInterval(() => {
      hasAirmedNotices()
      hasNoticesTele()
      if(token){
        getPosition()
      }
     }, 5000);}


    return () => {
      clearInterval(id);
    }

  }, [login]);

  //pode passar varias coisas aqui, de inicio so eh passado o "userData"
  const value = {
    userData,
    conectarGoogle,
    conectarApple,
    conectarEmail,
    login,
    userLogout,
    error,
    loading,
    authWithEmail,
    authWithGoogle,
    validationCODE,
    confirmationOnboarding,
    getLoginWithTokenHapvida,
    sendFeedback,
    errorHook,
    dataHook,
    getMeInsured,
    newUserData,
    publicId,
    objectRedirect, 
    setObjectRedirect
  };

  //retorna o provedor de dados realmente
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export function useUserContext() {
  const context = React.useContext(UserContext);

  if (typeof context === "undefined") {
    throw new Error("useUserContext must be used within an UserContext");
  }

  return context;
}
