import React from 'react'
import * as S from './styles'
import { ReactComponent as IconCloseWhite } from '../../../assets/ic-close-white.svg'
import { ReactComponent as IconDownload } from '../../../assets/ic-download.svg'
import { ReactComponent as IconTrash } from '../../../assets/ic-trash.svg'
import { ReactComponent as IconShare } from '../../../assets/new_union.svg'

type Props = {
  label: string;
  onClose: () => void;
  onDelete: () => void;
  download: () => void;
  onShare: () => void;
}

const AlertSelect = ({label, onClose, onDelete, download, onShare}: Props) => {
  return (
    <S.AlertSelect>
      <div>
        <IconCloseWhite onClick={onClose} />
        <span>{label}</span>
      </div>
      <div>
        <IconShare onClick={onShare}/>
        <IconDownload onClick={download} />
        <IconTrash  onClick={onDelete}/>
      </div>
         
    </S.AlertSelect>
  )
}

export default AlertSelect