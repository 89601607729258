import styled from 'styled-components';

export const SelectBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 300px;
`;

export const OptionsContainer = styled.div`
  position: absolute;
  top: 110%;
  z-index: 99;
  background: #fff;
  color: rgba(0, 0, 0, 0.56);

  width: 100%;
  opacity: 0;
  display: none;
  transition: all 0.2s ease;
  border-radius: 8px;
  overflow-y: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  order: 1;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: rgba(0, 0, 0, 0.15);
  }

  &::-webkit-scrollbar-thumb {
    background: #2b45d4;
    border-radius: 8px;
  }
`;
export const Option = styled.div`
  padding: 0 16px;
  
  cursor: pointer;
  &:hover {
    color: #2b45d4;
  }
`;

export const Selected = styled.div`
  
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);
  display: flex;
  align-items: center;
  gap: 14px;
  cursor: pointer;
  background: #fff;
  border-radius: 8px;
  margin-bottom: 8px;
  position: relative;
  order: 0;
`;

export const Input = styled.input`
  display: none;
`;
export const Label = styled.label`
  cursor: pointer;
  width: 100%;
  display: block;
  padding: 10px 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);
`;
