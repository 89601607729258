import React from 'react'
import Screen from '../Screen'
import Background from '../../../../../assets/Backgrounds/social-feed-colour.svg'
import { Header, Title, SubTitle } from '../styles'


const FinishCall = () => {
  return (
    <Screen backgroundImage={Background} changeOrientation={false}>
      <Header>
        <Title>Chamada encerrada!</Title>
        <SubTitle style={{paddingLeft: '10px', paddingRight: '10px'}}>Agradecemos a sua participação nesse atendimento
          <span>😊</span>
        </SubTitle>
      </Header>
    </Screen>

  )
}

export default FinishCall