import * as S from './styles';


import {ReactComponent as ArrowForward} from '../../assets/ic-arrow-foward.svg'

interface CardListProps {
  title: string;
  subtitle?: string;
  iconLeft?: any;
  iconRight?: any;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  borderColor?: string;
  hoverColor?: string;
}

const CardList = ({
  iconLeft,
  iconRight,
  title,
  subtitle,
  onClick,
  hoverColor,
  borderColor
}: CardListProps) => {
  return (
    <S.Wrapper onClick={onClick}  $hoverColor={hoverColor} $boderColor={borderColor}>
      <div className='iconLeft' style={iconLeft ?{ display: 'flex'}: {}}>
        {iconLeft}
      </div>
      <S.Content>
        {title}
        <span className='subTitle'> 
          {subtitle}
        </span> 
      </S.Content>
      <div className='iconRight'>
        {iconRight ? iconRight : <ArrowForward/>}
      </div>
    </S.Wrapper>
  );
};

export default CardList;
