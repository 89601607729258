import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 400px;

  h1 {
    color: #2B45D4;
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    font-family: 'Open Sans';
    margin-bottom: 32px;
  }
`