import React from 'react'
import * as S from './styles'
import {ReactComponent as IconDrug} from '../../../../../assets/ic-drug.svg'
import Button from '../../../../../components/Button/Button'
import ButtonOutlined from '../../../../../components/Button/ButtonOutlined'

type CardProps = {
  category?: string;
  title?: string; 
  subtitle?: string; 
  quantity?: string; 
  recommendation?: string;
  containerInsiderRow: boolean;
  labelSecondBtn?: string;
  showHeaders?: boolean; 
  labelFirstButton?: string;
  icon?: React.ReactComponentElement<'svg'>,
  onClickFirstButton?: () => void,
  link?:string
  linkSecondBtn?:string
}

const Card = ({icon, title, subtitle,quantity,recommendation, onClickFirstButton, link, linkSecondBtn, containerInsiderRow, labelSecondBtn, labelFirstButton, showHeaders=true}: CardProps) => {
  
  const classRow : React.CSSProperties | undefined = {
    flexDirection: 'row',
    alignItems: 'center', 
    gap: '24px'
   }

  return (
    <S.Container>
      {/* <S.Title>{category}</S.Title> */}
      <S.Subcontainer>
        <S.Left>
          <S.Icone>
            {icon ? icon : <IconDrug/>} 
          </S.Icone>
          <S.Content showHeaders={showHeaders}>
            <S.Title>{title}</S.Title>

            <S.ContainerInsideTexts containerInsiderRow={containerInsiderRow}>
              <S.Subtitle>{subtitle}</S.Subtitle>
              { showHeaders && <S.Title>Quantidade:<span>&nbsp;{quantity}</span></S.Title>}
            </S.ContainerInsideTexts> 
           
            <S.Recommendations>
              {recommendation}
            </S.Recommendations>
            {!showHeaders && <S.Title style={{marginTop: '8px'}}>Quantidade:<span>&nbsp;{quantity}</span></S.Title>}
          </S.Content>
        </S.Left>
        <S.Right>
          {!containerInsiderRow && <Button variant='primary' style={{width: '100%', color: '#fff'}}><a style={{color: '#fff'}} href={link}>{labelFirstButton}</a></Button>}
          {containerInsiderRow && <Button variant='primary' style={{width: '100%'}}><a style={{color: '#fff'}} href={link}>{labelFirstButton}</a></Button>}
          {labelSecondBtn && <ButtonOutlined variant='outlined' style={{width: '100%'}}><a style={{color: '#2B45D4'}} href={linkSecondBtn}>{labelSecondBtn}</a></ButtonOutlined>}
        </S.Right>
      </S.Subcontainer>
    </S.Container>
  )
}

export default Card