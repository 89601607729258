import * as S from './styles';

interface Options {
  description: string;
  icon: JSX.Element;
}

interface DropUpProps {
  data: any[];
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  isOpen?: boolean;
  onClose: () => void;
  unreadMsg?: string
}

const DropUp = ({ data, onClick, isOpen, onClose, unreadMsg }: DropUpProps) => {
  return (
    <S.Content>
     
      <S.container>
        <ul>
          {data.map((opt: Options) => (
            <li key={opt.description} onClick={onClick}>
              <div style={{display: 'flex'}}>
                {opt.icon ? opt.icon : ''}
                <p>{opt.description}</p>
              </div>
              {(opt.description === 'Mensagens' && unreadMsg) && <S.Count>
                {unreadMsg}
                </S.Count>}
            </li>
          ))}

        </ul>
      </S.container>

      <div
        id={isOpen ? 'overlay' : 'none'}
        onClick={() => onClose()}
        style={{ opacity: isOpen ? 1 : 0 }}
      ></div>
    </S.Content>
  );
};

export default DropUp;
