import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column ;
  gap: 16px;
`
export const Title = styled.h1`
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);
`
export const Box = styled.div`
  display: flex ;
  flex-direction: column ;
  gap: 26px;

  @media(min-width: 370px) {
    flex-direction: row ;
  }
`
export const Item = styled.div`
  display: flex;
  gap: 10px;
`

export const Text = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
`