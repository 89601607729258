import React, { useState } from 'react'
import Button from '../../../components/Button/Button'
import ButtonOutline from '../../../components/Button/ButtonOutlined'
import {Container, ContainerNumeros, Texto, Titulo, MsgError, FormContainer, Header, ContainerBtns, ContainerAlert} from './styles'
import InputCode from '../../../components/InputCode/InputCode'
import "./styles"
import { useUserContext } from 'contexts/UserContext'
import AlertBanner from 'components/AlertBanner/AlertBanner'


const TimeToResendCode = 14 //tempo em segundos para reenvio do codigo

const FormRightCode = () => {
    
  const {userData, loading, validationCODE, authWithEmail, error} = useUserContext()


 
  const maskEmail = (email: string) : string => {
    let format = email.split('@') //corta string
    let firstEmail = format[0].slice(0, format[0].length-3) //pega as três primeiras letras
    return `${firstEmail}***@${format[1]}`
  }

  let email:string = userData.email;  

  let inputRef = React.createRef<HTMLInputElement>()
  // ref for 4 digits
  let inputRef1 = React.createRef<HTMLInputElement>()
  let inputRef2 = React.createRef<HTMLInputElement>()
  let inputRef3 = React.createRef<HTMLInputElement>()
  let inputRef4 = React.createRef<HTMLInputElement>()
  
  const [, setError] = useState(false)
  const [CODE, setCODE] = React.useState( {
    1: '', 
    2: '',
    3: '',
    4: ''
  })

  const [confirmCode, setConfirmCode] = React.useState(false)
  const [resendCode, setResendCode] = React.useState(false)

  const [counter, setCounter] = React.useState(TimeToResendCode);
  
  const handleCodigo = ( pos: string, numero: string | '') => {
    // console.log(`posicao ${pos} numero ${numero}`)
    if(pos === '1'){
      if(numero === ''){
        inputRef1.current?.focus()
      }else{
      inputRef2.current?.focus()
      }
    }else if(pos === '2'){
      if(numero === ''){
        inputRef1.current?.focus()
      }else{
       inputRef3.current?.focus()
      }
    }else if(pos === '3'){
      if(numero === ''){
        inputRef2.current?.focus()
      }else{
      inputRef4.current?.focus()
      }
    }else if(pos === '4'){
      if(numero === ''){
        inputRef3.current?.focus()
      }else{
        inputRef4.current?.focus()
      }
    
    }
    setCODE({...CODE, [pos]: numero})
  }

  const handleSubmitCode = (e: React.FormEvent<HTMLFormElement>) => { 
    e.preventDefault()
    if(CODE['1'] && CODE['2'] && CODE['3'] && CODE['4']){
      const finalCode = String(CODE['1']) + String(CODE['2']) + String(CODE['3']) + String(CODE['4'])

      if(finalCode.toString().length === 4){
          validationCODE({email: userData.email, codigoVerificador: finalCode})
      }else{
        setError(true)
      }
    }
  }

  const handleResendCode = () => {
    setResendCode(true)
    authWithEmail(userData.email)
    setCounter(TimeToResendCode)
  }

  React.useEffect(() => {
    if(inputRef1.current !== null){
      inputRef1.current.focus()
    }
  }, [])


  React.useEffect(() => {
    if(CODE['1'] && CODE['2'] && CODE['3'] && CODE['4']) setConfirmCode(true)
    else setConfirmCode(false)
  }, [CODE])

  React.useEffect(() => {
    //se counter for > 0 então diminue o Timer
    const timer:any = counter > 0 && setInterval(() => {
      setCounter(counter - 1)
    }, 1000);
    // Se o counter zerar, some com o alerta de reenvio
    counter === 0 && setResendCode(false)

    return () => clearInterval(timer);
  }, [counter]);
  
  function fmtMSS(s:any) { return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s }

  return (  
    <>
      { resendCode && <ContainerAlert>
        <AlertBanner type='success' label='Seu código foi reenviado. Por favor, confira seu email.' seconds={5}/>
      </ContainerAlert>}
      <FormContainer  onSubmit={handleSubmitCode}>
        <Container>
          <Header>
            <Titulo>Passo 2</Titulo>
            <Texto>Agora digite o código de 4 dígitos enviado para {maskEmail(email)}</Texto>
            <p style={{color: 'rgba(0, 0, 0, 0.56)', fontSize: '14px', fontStyle: 'normal', lineHeight: '24px'}}>
              Além da caixa de entrada, cheque também a lixeira do seu e-mail
            </p>
          </Header>
            
          <ContainerNumeros marginBottom={error}>
            <InputCode className='1' refe={inputRef1} pos="1" value={CODE[1]} error={error} criaCodigo={handleCodigo}/>
            <InputCode className='2' refe={inputRef2} pos="2" value={CODE[2]} error={error} criaCodigo={handleCodigo}/>
            <InputCode className='3' refe={inputRef3} pos="3" value={CODE[3]} error={error} criaCodigo={handleCodigo}/>
            <InputCode className='4' refe={inputRef4} pos="4" value={CODE[4]} error={error} criaCodigo={handleCodigo}/>
          </ContainerNumeros>
          {error && <MsgError>Código inválido. Confira o que foi digitado e tente mais uma vez</MsgError>}
          
          <ContainerBtns>
            {!loading ? <Button style={{width: '100%'}} type="submit" variant="primary" disabled={(confirmCode) ? false : true}>Continuar</Button>
                            :
            <Button style={{width: '100%'}} type="submit" variant="primary" disabled={true}>Carregando</Button>
            }        
            <ButtonOutline onClick={handleResendCode} disabled={counter === 0 ? false : true } 
              style={{marginTop: '8px', fontSize: '14px', width: '100%'}} 
              variant="outlined">Reenviar código {counter > 0 && `(${fmtMSS(counter)})`}</ButtonOutline> 
          </ContainerBtns>
        </Container>
      </FormContainer>
    </>
  )
}

export default FormRightCode

