import styled from 'styled-components'

export const Container = styled.label`
  position: relative;
  font-size: 14px;
  padding-top: 20px;
  margin-bottom: 5px;
`
type BorderProps = {
  borderColorOnFocus: string;
  borderColor?: string;
  backgroundColor?: string;
  error?:string | null | undefined; 
}
export const ContainerGroup = styled.div<BorderProps>`
  border-radius: 4px;
  border: ${(props) => props.error ? '2px solid #F44336' : `2px solid ${props.borderColor ? props.borderColor : '#ddd'}`} ; 
  background-color: ${(props) => props.backgroundColor ? props.backgroundColor : ''} ;
  
  :focus-within{
      border-color: ${(props) => props.borderColorOnFocus ? props.borderColorOnFocus : 'blue'} ;
  }

  :focus-within svg path{
      fill: ${(props) => props.borderColorOnFocus ? props.borderColorOnFocus : 'blue'} ;
  }
`
export const LeftIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 14px;
  margin-right: 10px;

  :hover{
      cursor: pointer;
  }
`

export const RightIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 14px;
  :hover{
      cursor: pointer;
  }
`

type Props = {
  icon: any;
  backgroundColor?: string;
}

type BackgroundProps = {
  backgroundColor: string;
}
export const InputContainer = styled.div<BackgroundProps>`
  display: flex; 
  flex-grow: 1;
  background-color: ${(props) => props.backgroundColor ? props.backgroundColor : ''} ;
`

export const Input = styled.input<Props>`
  flex-grow: 1;
  border: none; 
  appearance: none;
  background: none;
  padding: ${(props) => props.icon ? '14px 0' : '14px 0px 14px 18px'} ;
  border-radius: 3px;
  outline: none ;
  font-size: 16px;

    :valid + span,
    :focus + span{
        transition-delay: 0.1s;
        font-size: 12px;
        transform: ${(props) => props.icon ? 'translate(-40px,-34px)' : 'translate(0px,-34px)'} ;
        background-color: ${(props) => props.backgroundColor ? props.backgroundColor : '#ffffff'} ;

    }
`  
export const Placeholder = styled.span<Props>`
  font-size: 16px ;
  position: absolute;
  top: calc(50% + 10px);
  transform: translateY(-50%);
  color: #aaa;
  transition: border-color 0.3s ease;
  padding: 0 5px; 
  margin-left: ${(props) => props.icon ? '0' : '14px'} ;
  transition:
    top 0.3s ease,
    font-size 0.3s ease,
    color 0.3s ease
  ;
`
export const ErrorMsg = styled.p`
  position: absolute ;
  font-size: 12px ;
  line-height: 16px ;
  color: #F44336;
  margin-top: 4px ;
`

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  position: relative ;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2B45D4;

  input {
    padding: 16px;
    margin-top: 16px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    border-radius: 4px;
    outline: none ;
    font-size: 16px;
    height: 56px;
    width: 100%;

    ::placeholder{
        color: rgba(0, 0, 0, 0.56);
    }
  }
`