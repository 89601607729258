import styled, { keyframes } from 'styled-components'


export const FilesData = styled.div`
    position: relative ;
    display: flex ;  
    margin: 4px;
`

export const ContainerInfo = styled.div`
    margin-left: 11px;
    width: 100% ;
`

const animation =  keyframes`
    from {width: 0%; background: #E59500;}
    to {width: 100%; background: #38B449;}
`

export const ProgressBar = styled.div`
    border-radius: 4px;
    height: 6px;
    animation: ${animation} 3s forwards;
`


export const FileName = styled.p`
    font-weight: 600 !important;
    color: rgba(0, 0, 0, 0.64);
    margin-bottom: 8px;

`
