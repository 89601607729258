import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 100%;

  max-width: 400px;


  .search {
    /* max-width: 250px;
    overflow: hidden; */
  }
`

export const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  color: var(--primary);
  margin-bottom: 32px;

  @media (max-width: 410px) {
    font-size: 24px;
  }
`;

export const Subtitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: var(--life-neutral-black-88);
  margin-bottom: 16px;

  @media (max-width: 410px) {
    font-size: 18px;
    margin-bottom: 8px;
  }
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #0000008f;
  margin-bottom: 32px;

  @media (max-width: 410px) {
    font-size: 14px;
    margin-bottom: 16px;
  }
`;

export const RadioGroup = styled.section`
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    /* background-color: #FFF; */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #949495;
  }
  
  margin: 16px 0 32px 0;
  max-height: 192px;
  max-width: 399px;
  width: 100%;
  overflow-y: scroll;
  padding-right: 3px;

  @media (max-width: 410px) {
    margin: 16px 0 24px 0;
  }

  label {
    margin-bottom: 8px;
  }
`;

export const NoSearch = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);
`