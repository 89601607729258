import styled from 'styled-components'

export const ContentUrgency = styled.div`
  width: 100%;
  margin: 0 auto;
  
  .Container {
    margin: 0 auto;
    max-width: 1024px;
    display: flex;
    justify-content: space-between;
    
    .information {
      width: 100%;
      max-width: 400px;

      h4 {
        font-size: 1.75rem;
        font-weight: 600;
        color: var(--primary);
        margin-bottom: 32px;
      }

      p {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 16px;
      }

      span {
        display: block;
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.56);
      }
    }
  }

  @media(max-width: 830px) {
    .infomation {
      width: 100%;
      
    }

  }
`