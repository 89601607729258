export function START_ROOM(attendanceId: number) {
  return {
    url: process.env.REACT_APP_API_URL_TELEHEALTH + `attendance/${attendanceId}/start`,
    options: {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
      },
    },
  };
}

// //share files during attendance
export function SHARE_FILES(attendanceId: string, formData: any){
  return {
    url: process.env.REACT_APP_API_URL_TELEHEALTH + `attendance/${attendanceId}/attachment-file`,
    options: {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
      },
      body: formData
    },
  };
}

export function SHARE_FILES_EXAMS(formData: any, token:string, publicId: string){
  return {
    url: process.env.REACT_APP_API_DOCS + `files?publicIdUser=${publicId}&category=EXAMS`,
    options: {
      method: 'POST',
      headers: {
        'X-ILY-API-appKey': process.env.REACT_APP_HEADER_APPKEY as string,
        'X-ILY-API-appToken': `Bearer ${token}`
      },
      body: formData
    },
  };
}


type Obj = {
  specialtyId: string, date:string, schedulingType: string, hour: string
}

export function SELF_SCHEDULE_VACANCY(obj: Obj){
  return {
    url: process.env.REACT_APP_API_URL_TELEHEALTH + `attendance/self-schedule-vacancy`,
    options: {
      method: 'POST',
      headers: {
       'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,

      },
      body: JSON.stringify(obj)
    },
  };
}
type ObjReSchedule = {
  id: string
}
export function SELF_RESCHEDULE(obj: ObjReSchedule, idAttendance: string, ){
  return {
    url: process.env.REACT_APP_API_URL_TELEHEALTH + `attendance/self-reschedule/${idAttendance}`,
    options: {
      method: 'POST',
      headers: {
       'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
      },
      body: JSON.stringify(obj)
    },
  };
}

export const c = {}