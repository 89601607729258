import styled from 'styled-components'

export const Container = styled.div`
    height: 100vh ;
    display: flex;
    flex-direction: column ;
    background: #F6F6F9;
    align-items: center;

    gap: 20px ;
    padding-top: 16px ;

    @media (min-width: 768px){
      flex-direction: row ;
      padding-top: 0px ;
      gap: 0px;
    }
`