import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  display: flex;
  .content {
    width: 100%;
    display: flex;
    justify-content: end;
    padding-bottom: 50px;
  }

  .contentReagendamento {
    margin: 16px 0;
    background-color: #fff;
    padding: 16px;
    border-radius: 8px;

    .title {
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.88);
    }

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      svg path {
        cursor: pointer;

        fill: #2B45D4;
      }
    }
  }

  .main {
    padding-top: 40px;
    width: 504px;
    margin-right: 112px;

    header {
      margin-bottom: 34px;
    }

    .help {
      display: flex;
      flex-direction: column;
      gap: 24px;

      p {
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }

      a {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #2B45D4;
      }
    }
  }
  
  .content-info {
    width: 100%;
    display: flex;
    justify-content: start ;
    background-color: #fff;
  }
  .info {
    max-width: 620px;
    padding: 96px 0 0 67px;
    
    .title {
      font-family: Open Sans;
      margin-bottom: 32px;
      font-size: 18px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.88);
      line-height: 24px;
    }

    p {
      font-family: Open Sans;
      font-size: 1rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.88);
      margin-bottom: 32px;
      line-height: 24px;

      strong {
        font-weight: 600;
        color: #000;

      }
    }
    height: 100vh ;
  }

  .help-mobile {
    display: none !important;
    padding: 0 24px;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;

      p {
        color: rgba(0, 0, 0, 0.88);
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }

      a {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #2B45D4;
      }

  }

  /* Modal */
  .containerBtn {
    display: flex;
    justify-content: end;
    gap: 8px;
    height: 48px;
    margin-top: 24px;

    button {
      flex: none;
      width:fit-content;
    }

  }

  @media(max-width:1290px) {
    .content {
      padding-left: 24px;
    }

    .content-info {
      padding-right: 24px;
    }
  }

  @media(max-width:1170px) {
    .main {
      padding-top: 24px;
      width: 100%;
      margin-right: 50px;

      header {
        margin-bottom: 32px;
      }
    }
  }

  @media(max-width: 1000px) {
    flex-direction: column;
    .content {
      padding-bottom: 0;
    }
    .main {
      margin-right: 0;
      padding-right: 24px;

      .help {
        display: none;
      }
    }
    
    .content-info {
      padding: 0 24px ;
      background-color: transparent ;
      .info {
        max-width: 100%;
        background-color: #fff;
        border-radius: 8px;
        padding: 16px;
        height:fit-content;
      }
    }

    .help-mobile {
      display: flex !important;
      margin-bottom: 50px;
    }
  }

  @media(max-width: 500px) {
    .containerBtn {
      height: auto ;
      flex-direction: column ;

      button {
        width: 100%;
      }
    }
  }
`

export const Text = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);
  margin: 8px 0 24px 0;

  @media (max-width: 410px) {
    font-size: 14px;
  }
`;

export const CalendarWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  
  gap: 8px;
  max-width: 400px;
`;

export const CalendarItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px !important;
  flex: 1;
  flex-direction: column;
  text-align: center;
  margin-bottom: 20px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: 16px 25px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s;
 
  &:hover,
  &.selected {
    background: #2b45d4;

    p {
      color: #fff;
    }
  }

  &.activo {
    opacity: 0.3;
  }
`;

export const HourItem = styled.span`
  display: flex;
  width: 128px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: 16px 25px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s;

  &:hover,
  &.selected {
    background: #2b45d4;

    p {
      color: #fff;
    }
  }
`;

export const HoursWrapper = styled.div`
 max-width: 400px;
 display: grid;
 grid-template-columns: repeat(1fr, 3);
 
`

export const Name = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);
`;

export const Day = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);
  margin-top: 8px;
`;

export const WrapperNextStep = styled.div<{ isDisabled: boolean }>`
  opacity: ${(props) => props.isDisabled && "0.4"};
  cursor: ${(props) => props.isDisabled && "not-allowed"};
  pointer-events: ${(props) => props.isDisabled && "none"};
`;

export const ContentCardExame = styled.div`
  height: 150px;
  max-width: 100%;
  overflow-x: hidden;
  
  .swiper-container {
      width: 100%;
      
      padding: 0 40px 0 0;

    .swiper-wrapper {
      /* background: blue; */
      display: flex;
    }
    .swiper-slide {
      width: 100%;
    /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
  } 

  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 24px   
  }
`
export const ContainerAlert = styled.div`
    top: 24px;
    position: absolute;
    z-index: 3000;
    width: 1232px;
`