

const IconConsult = () => {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.8 3C7.7 3 7.6 3 7.5 3C6.7 3 6 2.3 6 1.5C6 0.7 6.7 0 7.5 0C8.2 0 8.69999 0.4 8.89999 1H9C9.6 1 10 1.4 10 2V6C10 8.4 8.3 10.4 6 10.9V15C6 16.7 7.3 18 9 18C10.7 18 12 16.7 12 15V14.8C10.8 14.4 10 13.3 10 12C10 10.3 11.3 9 13 9C14.7 9 16 10.3 16 12C16 13.3 15.2 14.4 14 14.8V15C14 17.8 11.8 20 9 20C6.2 20 4 17.8 4 15V10.9C1.7 10.4 0 8.4 0 6V2C0 1.4 0.4 1 1 1H1.10001C1.30001 0.4 1.9 0 2.5 0C3.3 0 4 0.7 4 1.5C4 2.3 3.3 3 2.5 3C2.4 3 2.3 3 2.2 3C2.1 3 2.1 3 2 3V6C2 7.7 3.3 9 5 9C6.7 9 8 7.7 8 6V3C7.9 3 7.8 3 7.8 3ZM13 13C13.6 13 14 12.6 14 12C14 11.4 13.6 11 13 11C12.4 11 12 11.4 12 12C12 12.6 12.4 13 13 13Z" fill="#2B45D4"/>
    </svg>
  )
}

export default IconConsult;