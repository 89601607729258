import * as S from './styles'
import { ReactSVG } from 'react-svg'
import IconNoFile from '../../assets/noFile.svg'

const CardNoFile = () => {
  return (
    <S.Wrapper>
      <div>
        <ReactSVG src={IconNoFile} />
      </div>
      <span>Nenhum arquivo foi adicionado</span>
    </S.Wrapper>
  )
}

export default CardNoFile;