import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 40px 57px;
  background-color: #f6f6f9;
  height: 100vh;
  display: flex;
  justify-content: center ;
  align-items: center;
`
export const AnimationBox = styled.div`
    display: flex;
    flex-direction: column ;
    justify-content: center ;
    align-items: center;
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
`
export const Container = styled.div`
    display: flex;
    flex-direction: column ;
    gap: 24px;
    max-width: 608px;
`
export const Title = styled.h1`
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    color: #2B45D4;
`
export const Text = styled.h1`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.88);
`