import styled from 'styled-components'

export const ContainerForm = styled.form`
    display: flex;
    align-items: center;
    padding: 16px;
    gap: 24px;
    background: #F6F6F9;
    border-radius: 8px;
    border: 1px dashed rgba(0, 0, 0, 0.16);
`
export const Input = styled.input`
    display: none ;
`

export const ContainerIcon = styled.div`
    display: flex;
    justify-content: center ;
    align-items: center;

    width: 56px ;
    height: 56px ;
    border-radius: 50%;
    background: #3453FF;
`

export const Label = styled.label`
    font-size: 16px;
    font-weight: 600;
    color: #3453FF;
    cursor: pointer;
    max-width: 270px ;
    line-height: 24px;

    strong {
        font-weight: 600 ;
        color: #000;
    }
`

export const Error = styled.p`
    position: absolute ;
    font-size: 12px !important ;
    font-weight: 500;
    line-height: 16px ;
    color: #F44336 !important;
    margin-top: 4px ;
    margin-left: 2px ;
`