import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    gap: 87px;
    margin-top: 24px;
    padding: 16px 0;
    border-radius: 8px; 
`
export const Left = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column ;
  gap: 16px;
`
export const Right = styled.div`
  display: flex;
  flex-direction: column ;
  flex: 1;
`

export const Tupla = styled.div`
  display: flex;
  flex-direction: column ;
`

export const TextWithIconContainer = styled.div`
`

export const Text = styled.p`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;

  span {
    margin-left: 23px;
  }
`

export const Content= styled.div`
  max-width: 816px;

  div:nth-child(1) {
    height: 60px;
  }

  h1 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    color: #2B45D4;
    margin-bottom: 24px;

    p {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.88);
    }
  }

  .contentForm {
    margin-top: 24px;
    padding: 16px;
    background-color: #fff;
    border-radius: 8px; 

    .group {
      display: flex;
      gap: 16px;
      width: 100%;
      margin-bottom: 24px;
      height: fit-content;

      label {
        width: 100%;
      }
    }

    .contentBtn {
      display: flex;
      justify-content: flex-end;
    }

  }

  @media (max-width: 1074px) {
    max-width: 100%;
  }

  @media ( max-width: 660px) {
    .contentForm {
      .group {
        flex-direction: column;
      }
    }
  }
`
