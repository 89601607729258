import React from 'react'
import * as S from './styles'
import {ReactComponent as IconSpecialty} from '../../../../../assets/roundeds/specialty-rounded.svg';


type Props = {
  title: string, 
}

const Promptuary = ({title}: Props) => {
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.Box>
        <div>
          <IconSpecialty/>
        </div>
        <S.ContainerInfo>
          <S.Info>
            <S.Title>Sintomas</S.Title>
            <S.Text>Falta de ar no repouso ou no esforço. Tosse seca e persistente. Suspeita de contaminação por Covid-19, já que não sente gosto dos alimentos nem qualquer cheiro.</S.Text>
          </S.Info>
          <S.Info>
            <S.Title>Orientação</S.Title>
            <S.Text>Falta de ar no repouso ou no esforço. Tosse seca e persistente.</S.Text>
          </S.Info>
        </S.ContainerInfo>
      </S.Box>
    </S.Container>
  )
}

export default Promptuary