import { useConsultsContext } from 'contexts/ConsultsContext';
import {
  CalendarWrapper,
  Name,
  HourItem
} from '../../styles';

interface TimetablesProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  array?: any[];
}

const Timetables = ({ array }: TimetablesProps) => {
  const { handleTime, consultTime } = useConsultsContext();
  return (
    <>
      <CalendarWrapper>
        {array?.map((item, index) => {
          return (
            <div className='wrapper' key={index}>
              <HourItem
                style={{ padding: '16px 55px', flex: '1' }}
                onClick={() => handleTime(item)}
                className={
                consultTime && (item === consultTime ? 'selected' : '')
              }
                key={index}
              >
                <Name>{item}</Name>
              </HourItem>
            </div>
              
          );
        })}
      </CalendarWrapper>
    </>
  );
};

export default Timetables;
