import styled from 'styled-components'

export const FormContainer = styled.form`
`
export const Header = styled.div`
  padding: 0px 24px;
  @media(min-width: 666px){
      padding: 0;
  }
`
export const Container = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;

  @media(min-width: 666px){
      padding: 0;
  }
`
type Props = {
  marginBottom: any;
}
export const ContainerNumeros = styled.div<Props>`
  display: flex;
  margin: ${(props) => props.marginBottom ? "2rem 0 8px 0" : "2rem 0"};

  padding: 14px;
  
  input {
    width: 75px;
    height: 88px ;
    margin-right: 8px ;
  }

  &input:last-child{
    margin-right: 0 ;
  }

  

  @media(min-width: 666px){
    padding: 0;
    input {
      width: 88px;
      height: 56px ;
      margin-right: 16px ;
    }
  }
`

export const Titulo = styled.h1`
  font-size: 28px;
  line-height: 40px;
  line-height: 24px;
  font-weight: 600;
  color: var(--primary);
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
`

export const Texto = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);
  margin-bottom: 8px;
  margin-top : 32px ;
`


export const Content = styled.div`
  max-width: 1366px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 1rem;


`

export const MsgError = styled.p`
  font-family: 'Open Sans';
  color: #DB3C31;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 2rem;
`
export const ContainerBtns = styled.div`
  padding: 0 24px; 
  @media(min-width: 666px){
    padding: 0;
  }
`

export const ContainerAlert = styled.div`
  position: absolute;
  top: 0px;
  width: 100vw;

  @media(min-width: 666px){
    width: 90vw;
    left: 20px;
    top: 20px;
  }
`