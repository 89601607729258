import styled from 'styled-components'

export const Container = styled.div<Props>`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
  background: ${(props) => props.typing ? 'rgba(43, 69, 212, 0.04)' : '#fff'};
  outline: ${(props) => (props.typing || props.active) && '2px solid #2B45D4'};
  padding: 12px 16px;
  border-radius: 8px;
`

type Props = {
  typing: string
  active?: boolean
}

export const Container2 = styled.div<Props>`
  display: flex;
  width: 100% ;
  align-items: ${(props) => !props.typing && 'center'};
  /* flex-wrap: wrap ; */
  .last-message{
    display: none ;
  }

  @media(min-width: 1200px){
    width: 80%;
    justify-content: space-between ;
    .last-message{
      display: inline ;
    }
  }
`
export const Container3 = styled.div`
  display: none;
  flex-direction: column ;
  gap: 8px;

  @media(min-width: 1200px){
    display: flex ;
  }
`

export const Number = styled.div`
  display: flex;
  justify-content: center ;
  align-items: center;
  color: #fff;
  width: 24px;
  height: 24px;
  background: #FF0073;
  border-radius: 32px;
`

export const ContainerIcon = styled.div`
display: flex;
align-items: center; 
justify-content: center;
min-width: 40px;
height: 40px;
border-radius: 20px;
background-color: #2B45D4;
`
export const ContainerTexts = styled.div`
  display: flex;
  flex-direction: column ;
`


export const Title = styled.h1`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);
`
export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
`
export const Date = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.56);
`