import React from 'react'
import * as S from './styles'
import {ReactComponent as IconPlus} from '../../assets/ic-plus.svg'
import IconLife from '../Icon/index'

type Props = {
  style?: React.CSSProperties;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  limitSize: number
}
const File = ({setFile, style, limitSize}: Props) => {
 
  const inputRef = React.useRef<any>(null)
  const [error, setError] = React.useState(false)
  const handleSubmit = (event:any) => {
    event.preventDefault();
    
    let size = inputRef.current.files[0].size/1024/1024

    if(size > limitSize && limitSize !== 0){
      setError(true)
      return 
    }

    if(inputRef.current.files[0]){
        setFile(inputRef.current.files[0])
        setError(false)
    }
  }

  return (
    <div style={{position:'relative', ...style}}>
      <S.ContainerForm action="#" style={{borderColor: error ? '#F44336' : undefined}} >
        <S.ContainerIcon>
          <IconLife icon={<IconPlus/>} color="white" size={38}/>
        </S.ContainerIcon>
        <S.Label >Clique para adicionar&nbsp;<br/><strong>um arquivo do dispositivo</strong>
          <S.Input ref={inputRef} type="file" accept='.jpeg,.jpg,.png,.pdf' onChange={(e) => handleSubmit(e)}/>
        </S.Label>
      </S.ContainerForm>
      {error && <S.Error>Arquivo excedeu {limitSize}MB</S.Error>}
    </div>
  )
}

export default File