import styled from 'styled-components'  

export const Container = styled.div`
  display: flex;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;

  background: #F6F6F9;

  &.active{
    background: #2B45D4;
  }
`

export const Text = styled.p`
  white-space: nowrap ;
  text-align: left ;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.56);
  &.active {
    color: #FFFFFF;
  }

`

// export const Input = styled.input`
//   :checked + label {
//     background-color: #2B45D4;
//   }
// `

// export const Label = styled.label`
//   font-weight: 600;
//   font-size: 12px;
//   line-height: 16px;
//   color: rgba(0, 0, 0, 0.56);
// `