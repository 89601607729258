import * as S from './styles';

import { ReactComponent as IconCamOff } from '../../assets/camera.svg';
import { ReactComponent as IconCamOn } from '../../assets/cameraOn.svg';
import { ReactComponent as MicOn } from '../../assets/micOn.svg';
import { ReactComponent as MicOff } from '../../assets/micOff.svg';
import { ReactComponent as IconPhone } from '../../assets/ic-phone.svg';
import { ReactComponent as IconMenu } from '../../assets/mai-ic-more.mono.svg';

interface TrackReplyRoundedProps {
  icon: string;
  marked?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties
  unreadMessage?: string
}

const TrackReplyRounded = ({
  icon,
  marked,
  onClick,
  style,
  unreadMessage
}: TrackReplyRoundedProps) => {
  return (
    <>
      {icon === 'cam' && marked && (
        <S.replyRounded onClick={onClick}>
          <IconCamOff />
        </S.replyRounded>
      )}
      {icon === 'cam' && !marked && (
        <S.replyRounded onClick={onClick}>
          <IconCamOn />
        </S.replyRounded>
      )}

      {icon === 'mic' && marked && (
        <S.replyRounded onClick={onClick}>
          <MicOff />
        </S.replyRounded>
      )}
      {icon === 'mic' && !marked && (
        <S.replyRounded onClick={onClick}>
          <MicOn />
        </S.replyRounded>
      )}

      {icon === 'phone' && (
        <S.replyRounded theme={'phone'} onClick={onClick}>
          <IconPhone />
        </S.replyRounded>
      )}
      {icon === 'more' && (
        <S.replyRounded  style={style}  onClick={onClick}>
          <IconMenu />
          {unreadMessage && <span className="badge">{unreadMessage}</span>}
        </S.replyRounded>
      )}
    </>
  );
};

export default TrackReplyRounded;
