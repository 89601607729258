import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { ContentProgress } from './styles';

interface ICircleProgressProps {
  value: number,
}

const CircleProgressbar = ({ value }:ICircleProgressProps) => {
  return (
    <ContentProgress>
      <CircularProgressbarWithChildren value={value}>
        <strong style={{marginTop: '-15px'}}>{value}%</strong>
      </CircularProgressbarWithChildren>
    </ContentProgress>
  )
}


export default CircleProgressbar;