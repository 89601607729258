import React from 'react'
import { OptionsContainer, SelectBox, Option, Selected, Input, Label } from './styles'
import {ReactComponent as IconArronDown} from '../../assets/arrow-down.svg'
import IconLife from '../../components/Icon/index'

type Props = {
  selected:any,
  setSelected: any, 
  data?: any[],
  onChange?: any
  placeholder?: string
}
const SelectComponent = ({selected, data, setSelected,placeholder='placeholder', onChange}: Props) => {
  
  const [show, setShow] = React.useState(false)
  
  const style: React.CSSProperties = {
    maxHeight: '240px',
    opacity: 1,
    display: 'block',
    // overflowY: 'scroll'
  }

  const rotateIcon: React.CSSProperties = {
    transform: 'rotateX(180deg)', 
    top: '-6px'
  }

  return (
    <SelectBox>
      <OptionsContainer style={show ? style : undefined}>
        {data && data.map((d, index) => <Option key={index} onClick={() => {setSelected(d.label); setShow(false)}}>
          <Input type="radio" className="radio" id={d.label} name="category" value={d.label} onChange={onChange}/>
          <Label htmlFor={d.label}>{d.label}</Label>
        </Option>)}
      </OptionsContainer>
      <Selected onClick={() => setShow(!show)}>
        {selected ? selected : placeholder}
        <IconLife style={ show ? rotateIcon : undefined} color="#FF0073" icon={<IconArronDown/>} size={28} />
      </Selected>
    </SelectBox>
  )
}

export default SelectComponent