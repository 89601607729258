import React, { useEffect } from 'react';
import { useConsultsContext } from 'contexts/ConsultsContext';
import { Outlet, useNavigate } from 'react-router-dom';
import { Button } from 'storybook-maida';
import * as S from './styles';
import {
  POS_LOGIN_FILA,
  LEAVE_QUEUE,
} from 'api/Services/NextConsult';
import Modal from '../../../../components/Modal';
import ButtonOutlined from 'components/Button/ButtonOutlined';
import Button2 from '../../../../components/Button/Button';
import { ContainerBtns } from './styles';
import { useHomeContext } from 'contexts/HomeContext';
// import { START_ROOM } from 'api/Services/Attendance';
import Notice from 'components/Notice/Notice';
import { CALL_ENPOINT } from 'api/Services/CallEndpoint';
import ExitQueue from '../ExitQueue/ExitQueue';

let loopToRequest: any = null;

const Teleconsultation = () => {
  const { device, setAttendanceData } = useConsultsContext();

  const { noticeTele, setNoticeTele, fila, setFila, position, setPosition } =
    useHomeContext();
  const navigate = useNavigate();
  const pessoas = position > 1 ? 'pessoas' : 'pessoa';

  const [active, setActive] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  //indica que é o usuario entrou na fila e ta no fluxo normal
  //no momento que o medico chamar e o usuario n entrou nada fila
  //sera mudado pra false e eh util para noshow 
  const [ firstAttendance, setFirstAttendance ] = React.useState(true)

  const [ alertNoShow, setAlertNoShow ] = React.useState(true)

  const token = window.localStorage.getItem('telehealth@token') || '';
  const id = window.sessionStorage.getItem('consulta') || '';

  // const [doctorCalled, setDoctorCalled] = React.useState(false)

  const handleClick = () => {
    setLoading(true);
    EntrarNaFila(token, id);
    //setFirstAttendance(false)
    getPosition();
  };

  useEffect(() => {
    forceNotice();
  }, []);

  // Nao espera 10s do Notice
  const forceNotice = async () => {
    try {
      const { url, options } = CALL_ENPOINT('TELEHEALTH_URL','notice','GET', null, {
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
      });
      const response = await fetch(url, options);
      if (response.ok) {
        const responseText = await response.text();
        if (responseText) {
          const json = JSON.parse(responseText);
          setNoticeTele(json);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setFila(true);
    }
  };

  const enterTheRoom = async () => {
    try {
      setLoading(true);
      // const { url, options } = START_ROOM(noticeTele?.metadata?.id);
      const { url, options } = CALL_ENPOINT('TELEHEALTH_URL',`attendance/${noticeTele?.metadata?.id}/start`,'POST',null,{
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
      });
      const response = await fetch(url, options);
      const data = await response.json();
      setAttendanceData(data);
      navigate('/conferencia');
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // 1 na fila 
  // 0 medico chamou - VIDEOCALL_IN_PROGRESS || WAITING_INSURED 
  // -1 nao ta na fila
  async function getPosition() {
    try {
      // const { url, options } = GET_POSITION_IN_QUEUE();
      const { url, options } = CALL_ENPOINT('TELEHEALTH_URL','queue/position','GET', null, {
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
      });
      const response = await fetch(url, options);
      const { position } = await response.json();

      if (response.ok) {
        if (position === -1 && !noticeTele) {
          setPosition(-1); 
          return
        }
        setPosition(position);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }

  useEffect(() => {
    getPosition();
    loopToRequest = setInterval(() => {
      getPosition();
      if (position === -1) {
        clearInterval(loopToRequest);
        //LeaveQueue();
      }
    }, 5000);
    
    return () => {
      clearInterval(loopToRequest);
    };
  }, []);

  async function EntrarNaFila(token: string, number: string) {
    try {
      const { url, options } = POS_LOGIN_FILA(token, number);
      const response = await fetch(url, options);
      const json = await response.json();
      setFila(true);
      setPosition(json.position);
      forceNotice();
    } catch (err) {
      console.log(err);
    } finally {
    }
  }

  async function LeaveQueue() {
    try {
      const { url, options } = LEAVE_QUEUE();
      const response = await fetch(url, options);
      if (response.ok) {
        setNoticeTele(null);
        setActive(false);
        setFila(false);
      }
    } catch (err) {
      setNoticeTele(null);
    }
  }
  

  function renderLayoutBasedOnStatus() {
    if (position > 1 && noticeTele) {
      return (
        <>
          <p>
            {position-1} {pessoas} na sua frente...
          </p>
          <span>
            É só esperar por aqui que a gente avisa quando chegar a sua vez!
          </span>
          {/* botão Desabilitado  */}
          <Button
            children={'Iniciar consulta'}
            disabled={true}
            className="btnEntrar"
          />
          <ButtonOutlined
            variant="outlined"
            style={{
              border: '2px solid #F44336',
              width: '100%',
              background: 'transparent',
              color: '#F44336',
            }}
            onClick={() => setActive(true)}
            disabled={false}
          >
            Sair da fila
          </ButtonOutlined>
        </>
      );
    } else if (fila && position === 1) {
      return (
        <>
          <p>Aguarde mais um momento....</p>
          <span>
            É só esperar por aqui que a gente avisa quando chegar a sua vez!
          </span>
          {/* Botão desabilitado */}
          <Button
            children={'Iniciar consulta'}
            disabled={true}
            onClick={() => handleClick()}
            className="btnEntrar"
          />
          <ButtonOutlined
            variant="outlined"
            style={{
              border: '2px solid #F44336',
              width: '100%',
              background: 'transparent',
              color: '#F44336',
            }}
            onClick={() => setActive(true)}
            disabled={false}
          >
            Sair da fila
          </ButtonOutlined>
        </>
      );
    } else if (position === 0 || (noticeTele && 
      (noticeTele.typeAction === 'WAITING_INSURED' || noticeTele.typeAction === 'ATTENDANCE_IN_PROGRESS'))){
      // momento que o médico chama
      return (
        <>
          <p>É hora de começar a consulta!</p>
          <span>{noticeTele?.title}</span>

          <Button
            children={loading ? 'Iniciando...' : 'Iniciar consulta'}
            disabled={!device || loading}
            onClick={enterTheRoom}
            className="btnEntrar"
          />
        </>
      );
    } else if(!firstAttendance && position === -1){
      return (
        <>
          <p>Tempo de espera excedido!</p>
          <span>Se tiver tido algum problema, é só entrar em contato com nosso suporte</span>
        </>
      )
    }
  };

  React.useEffect(()=>{
    //o medico chamou
    if(position === 0) {
      setFirstAttendance(false)
      setAlertNoShow(false)
    }
  },[position])

  const exitQueue= () => {
    LeaveQueue().then(() => {
      navigate('/home');
    })
  
  }

  return (
    <>
      { !alertNoShow && !noticeTele && !firstAttendance && position === -1 &&
        <Notice type='WARNING' subTitle='' style={{ margin : '16px 0'}}>
          Ops! O tempo para entrar na sala acabou. Remarque sua consulta ou entre novamente na fila para ter atendimento
        </Notice> 
      }
      <S.Wrapper style={{margin: firstAttendance || position >= 0 ? '83px auto 0 auto': ''}}>
        <S.VideoWrapper>
          <Outlet />
        </S.VideoWrapper>
        <S.InfoWrapper>
          { firstAttendance && !noticeTele ? 
          (<>
            <p>Habilite sua câmera e microfone</p>
            <span>
              Clique nos ícones para habilitar a sua câmera e microfone. Se tiver
              algum problema, entre em contato com o nosso suporte!
            </span>

            <Button
              children={loading ? 'Entrando...' : 'Entrar na fila'}
              disabled={loading || !device}
              onClick={() => handleClick()}
              className="btnEntrar"
            />
          </>) : (
            <div>
              {/* Renderiza o layout baseado no position e notice */}
              {renderLayoutBasedOnStatus()}
            </div>
          )  
        }
        </S.InfoWrapper>
        {/* MODAL ================= */}
        <ExitQueue active={active} setActive={setActive}
          onClick={exitQueue}
        />
        {/* FIM MODAL ================= */}
      </S.Wrapper>
    </>
  );
};

export default Teleconsultation;
