import styled from 'styled-components'

export const ContainerHelp = styled.div`
    margin-top: 32px ;
    display: flex;
    flex-direction: column ;
    gap: 24px;

`

export const Question = styled.h2`
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    color: rgba(0, 0, 0, 0.56);
`

export const Response = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.56);
`

export const ContainerAvaliation = styled.div`
    margin-top: 24px ;
`
export const QuestionAvaliation = styled.h3`
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.56);
`

export const ContainerBtns = styled.div`
    margin-top: 24px;
    display: flex;

    button {
        background-color: var(--fundo) !important ;
        color: rgba(0, 0, 0, 0.56) !important ;
        border: 2px solid rgba(0, 0, 0, 0.56) !important;
        width: 197px;
    }
`

export const OptionContainer = styled.div`
    margin-top: 24px ;
    width: 336px ;
`
export const Title = styled.h2`
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    color: #2B45D4;
`

export const OptionText = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.56);
    margin-top: 24px ;

    span {
        color: black;
    }
`