import React from 'react'
import {ReactComponent as IconUser} from '../../../../assets/mai-ic-user.mono.svg'
import {ReactComponent as IconGroup} from '../../../../assets/chat-group.svg'

import IconLife from 'components/Icon'
import * as S from './styles'

type Props = {
  typing: string
  lastMessage: string
  name: string
  numberOfMsgs: string
  onClick: () => void
  active?: boolean
  isGroup?: boolean
  dateLastMessage: string
}

const CardUser = ({typing, dateLastMessage, isGroup, name, numberOfMsgs, active, lastMessage, onClick}: Props) => {
  return (
    <S.Container onClick={onClick} typing={typing} active={active}>
      <S.ContainerIcon>
        <IconLife color='#ffffff' icon={isGroup ? <IconGroup/> : <IconUser/>}/>
      </S.ContainerIcon>

      <S.Container2 typing={typing}>
        <S.ContainerTexts>
          <S.Title>{name}</S.Title>
          {lastMessage && <S.Subtitle className='last-message'>{
            lastMessage.length >= 17 ? lastMessage.slice(0,17).concat('...') : lastMessage
          }</S.Subtitle>}
        </S.ContainerTexts>

        <S.Container3>
          {dateLastMessage && <S.Date>{dateLastMessage}</S.Date>}  
          {numberOfMsgs && <S.Number>{numberOfMsgs}</S.Number>}
        </S.Container3>
      </S.Container2>


    </S.Container>
  )
}

export default CardUser