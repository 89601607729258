import Lottie from 'react-lottie';
import * as S from './styled';
import Button from 'components/Button/Button';

import { useNavigate } from 'react-router-dom';
import send from '../../../../assets/Animation/send.json';

const FeedbackMessage = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: send,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const navigate = useNavigate();
  const navigateToNextPage = () => {
    navigate('/home');
  };

  return (
    <S.Container>
      <S.Wrapper>
        <S.Confirm>
          <Lottie
            options={defaultOptions}
            height={150}
            width={150}
            style={{ margin: 0 }}
          />
          <S.Title>Mensagem Enviada</S.Title>
        </S.Confirm>

        <S.Message>
          Em breve nossa equipe entrará em contato com você por e-mail 😉
        </S.Message>
        <Button
          onClick={navigateToNextPage}
          variant="primary"
          style={{ width: '100%' }}
        >
          Voltar para o início
        </Button>
      </S.Wrapper>
    </S.Container>
  );
};

export default FeedbackMessage;
