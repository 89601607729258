import Modal from 'components/Modal'
import React from 'react'
import * as S from './styles'

import {ReactComponent as IconEmoji0} from '../../../assets/avaliation-0.svg'
import {ReactComponent as IconEmoji1} from '../../../assets/avaliation-1.svg'
import {ReactComponent as IconEmoji2} from '../../../assets/avaliation-2.svg'
import { PendingAvaliation } from '../Header/Index'

type AvaliacaoProps = "0" | "1" | "2" | undefined
type Props = {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  pendingAval: PendingAvaliation
  avaliacao: AvaliacaoProps
  setAvaliacao: React.Dispatch<React.SetStateAction<AvaliacaoProps>>
  redirectToAvaliation: (emojiCode: string) => void
}

const PendAvaliation = ({showModal, setShowModal, pendingAval, avaliacao, setAvaliacao, redirectToAvaliation}: Props) => {
  return (
    <Modal
      isOpen={showModal}
      onClose={() => setShowModal(!showModal)}
      title=""
      style={{width: '366px', height: '352px', padding: '24px', overflow: 'hidden'}}
      titleStyle={{fontSize: '24px'}}
      >
      <S.Container>
        <S.Title>
          Ainda dá pra contar como foi sua última consulta!
        </S.Title>
        <S.Text style={{marginTop: '16px'}}>
          {`${pendingAval.nomeProfissional}, ${pendingAval.especialidade}
            ${pendingAval.prettyDate}
            `}
        </S.Text>
        <S.Text style={{color: 'rgba(0, 0, 0, 0.88)', marginTop: '24px'}}>
          Escolha um dos emojis para avaliar
        </S.Text>
        {/* emojis */}
        <S.Emojis>
          <S.ContainerEmoji 
            className={avaliacao === "0" ? "active" : undefined} style={{cursor: 'pointer'}} onClick={() => {
                setAvaliacao("0")
                redirectToAvaliation('0')
                }}>
            <IconEmoji0  />
          </S.ContainerEmoji>
          <S.ContainerEmoji 
            className={avaliacao === "1" ? "active" : undefined}
            onClick={() => {setAvaliacao("1")
              redirectToAvaliation('1')
              }} style={{cursor: 'pointer'}}>
            <IconEmoji1 /> 
          </S.ContainerEmoji>
          <S.ContainerEmoji 
            className={avaliacao === "2" ? "active" : undefined}
            style={{cursor: 'pointer'}} onClick={() => {setAvaliacao("2")
              redirectToAvaliation('2')
              }}>
            <IconEmoji2  />
          </S.ContainerEmoji>
        </S.Emojis>
      </S.Container>
    </Modal>
  )
}

export default PendAvaliation