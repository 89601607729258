import React from 'react';
import * as S from './styles'
import IconLife from 'components/Icon';


type Props = {
  placeholder:string; 
  maxLength?: number | undefined
  error?: string | undefined | null;
  type?:string; 
  value?:any;
  borderColor?: string;
  borderColorOnFocus?: string;
  backgroundColor?: string;
  /**
   * Import iconLeft as ReactComponet from svg
   */
  iconLeft?: any;
  iconRight?: any;
  iconSize?: any;
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onChange?: React.FocusEventHandler<HTMLInputElement> | undefined;            
  labelFixed?: string
  noLabel?: boolean
  disabled?: boolean
  style?: React.CSSProperties
}

const InputLife = ({noLabel, style, labelFixed, value, error, maxLength, placeholder, type='text', borderColor="blue", borderColorOnFocus='blue', backgroundColor='#ffff', iconSize=35, onFocus, onBlur, onChange, iconLeft=null, iconRight=null, disabled}: Props) => {
  if(labelFixed) {
    return <S.Label>
      {labelFixed}
      <input value={value} placeholder={placeholder} maxLength={maxLength} onChange={onChange} type={type} required onFocus={onFocus} onBlur={onBlur} disabled={disabled}/>
    </S.Label>
  }else if (noLabel) {
    return <>
      <S.Label>
        <input style={style} value={value} placeholder={placeholder} maxLength={maxLength} onChange={onChange} type={type} onFocus={onFocus} onBlur={onBlur} />
      </S.Label>
      {error && <S.ErrorMsg>{error}</S.ErrorMsg>}
    </>
  }else {
    return <S.Container>
      <S.ContainerGroup borderColor={borderColor} error={error} borderColorOnFocus={borderColorOnFocus} backgroundColor={backgroundColor} style={{display: 'flex', alignItems: 'center'}}>
        {iconLeft && <S.LeftIcon>
          <IconLife size={iconSize} color={borderColor} icon={iconLeft}/>
          </S.LeftIcon>}
        <S.InputContainer backgroundColor={backgroundColor}>
          <S.Input value={value} maxLength={maxLength} onChange={onChange} icon={iconLeft} type={type} backgroundColor={backgroundColor} required onFocus={onFocus} onBlur={onBlur}/>
          <S.Placeholder icon={iconLeft}>{placeholder}</S.Placeholder>
        </S.InputContainer>
        {iconRight && <S.RightIcon>
          <IconLife size={iconSize} color={borderColor} icon={iconRight}/>
          </S.RightIcon>}
      </S.ContainerGroup>
      {error && <S.ErrorMsg>{error}</S.ErrorMsg>}
    </S.Container>
  }
    
  
}

export default InputLife