import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { ContentCardExame, CardItem, IconContent, Title, Text} from "./styles";
import { Link, useNavigate } from 'react-router-dom';
import medicalConsultation from '../../../../assets/Animation/medical-consultation.json'
import store from '../../../../assets/Animation/store.json'
import exams from '../../../../assets/Animation/exams.json'
import LifeLottie from "../LifeLottie";


const ExamCard = () => {
  const [ mobile, setMobile] = React.useState<boolean>(false)
  const navigate = useNavigate()

  function changeMath() {
    const { matches } = window.matchMedia("(max-width: 38rem)"); //600px
    setMobile(matches);
  }

  window.addEventListener('resize', changeMath);
 
  React.useEffect(() => {
    changeMath()
  }, []);

  return (
    <ContentCardExame>
      { mobile ? (
        <Swiper slidesPerView={'auto'} spaceBetween={0} pagination={{"clickable": true}} className="mySwiper">
          <SwiperSlide>
            <CardItem>
              <IconContent>
                <LifeLottie animationData={medicalConsultation} height={55} width={55} />
              </IconContent>
              <Title>Consultas</Title>
              <Text>Veja as especialidades disponíveis!</Text>
              <Link to="/consultas-online">Faça sua consulta</Link>
            </CardItem>
          </SwiperSlide>

          <SwiperSlide>
            <CardItem>
              <IconContent>
                <LifeLottie animationData={store} height={55} width={55} />
              </IconContent>
              <Title>Loja</Title>
              <Text>Tudo o que você precisa da farmácia</Text>
              <a href="#">Conheça a loja</a>
            </CardItem>
          </SwiperSlide>

          <SwiperSlide>
            <CardItem>
              <IconContent>
                <LifeLottie animationData={exams} height={55} width={55} />
              </IconContent>
              <Title>Exames</Title>
              <Text>Tenha seus exames na palma da mão</Text>
              <a style={{cursor: 'pointer'}} onClick={() => navigate("/exames")}>Guarde seus exames</a>
            </CardItem>
          </SwiperSlide>
        </Swiper>
        ) : (
          <>
            <CardItem>
              <IconContent>
                <LifeLottie animationData={medicalConsultation} height={55} width={55} />
              </IconContent>
              <Title>Consultas</Title>
              <Text>Veja as especialidades disponíveis!</Text>
              <Link to="/consultas-online">Faça sua consulta</Link>
            </CardItem>

            <CardItem>
              <IconContent>
                <LifeLottie animationData={store} height={55} width={55} />
              </IconContent>
              <Title>Loja</Title>
              <Text>Tudo o que você precisa da farmácia</Text>
              <a href="#">Conheça a loja</a>
            </CardItem>

            <CardItem>
              <IconContent>
                <LifeLottie animationData={exams} height={55} width={55} />
              </IconContent>
              <Title>Exames</Title>
              <Text>Tenha seus exames na palma da mão</Text>
              <a style={{cursor: 'pointer'}} onClick={() => navigate("/exames")}>Guarde seus exames</a>
            </CardItem>
          </>
        )
      } 
    </ContentCardExame>
  )
}

export default ExamCard;