import React from 'react';
import Button from '../../../components/Button/Button';
import { Container, Titulo, TextoInfo } from './styles';
import Checkbox from '../../../components/Checkbox/index';
import { useUserContext } from 'contexts/UserContext';
import InputLife from 'components/InputLife';
import { UserAndTermsProps } from 'api/intancesAxios/onboardingURL';

// import * as CheckboxStyles from '../../../components/Checkbox/styles'

const FormLeftNome = () => {
  const { userData, confirmationOnboarding } = useUserContext();

  // const primeiroNome = userData.firstName === "firstName" ? "": userData.firstName;
  // const segundoNome = userData.lastName === "lastName" ? "": userData.lastName;

  const [nome, setNome] = React.useState('');
  const [termPromos, setTermPromos] = React.useState<boolean>(false);
  const [termsAgree, setTermsAgree] = React.useState<boolean>(false);

  const [error, setError] = React.useState<string | null>(null);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const UserETerms: UserAndTermsProps = {
      aceiteComunicacao: termPromos,
      aceiteTermosUso: termsAgree,
      email: userData.email,
      nomeCompleto: nome,
    };

    confirmationOnboarding(UserETerms);
  };

  const handleNome = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (error) validateNome(e.target.value);
    const isValidName = e.target.value.match(
      /^[a-zA-ZöüóőúéáàűíÖÜÓŐÚÉÁÀŰÍçÇ\s]{0,100}$/,
    );
    if (isValidName) setNome(e.target.value);
    validateNome(e.target.value);
  };

  const handleTermsPromo = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermPromos(e.target.checked);
  };
  const handleTerms = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAgree(e.target.checked);
  };

  const validateNome = (value: string) => {
    if (value.length === 0) {
      setError('Preencha o campo nome.');
      return false;
    } else if (value.length < 3) {
      setError('Digite um nome com no minimo 3 caracteres');
      return false;
    } else {
      setError(null);
      return true;
    }
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>,
  ) => {
    validateNome(e.target.value);
  };

  return (
    <Container>
      <Titulo>É muito bom ter você aqui!</Titulo>
      <TextoInfo>
        {/* Pra começar, conta como você quer que a gente te chame: */}
        Pra começar, queremos saber o seu nome:
      </TextoInfo>

      <InputLife
        placeholder="Digite seu nome completo"
        onChange={handleNome}
        onBlur={handleBlur}
        error={error}
        value={nome}
      />

      <div className="contentCheckbox">
        <Checkbox id={'promoçoes'} onChange={handleTermsPromo} label="teste">
          Quero receber promoções, descontos exclusivos e novidades!
        </Checkbox>

        <Checkbox id={'termos'} onChange={handleTerms} label="teste2">
          Li e concordo com os <span>Termos de Uso</span> e{' '}
          <span>Política de Privacidade.</span>
        </Checkbox>
      </div>

      <Button
        variant="primary"
        disabled={nome.length > 2 && termsAgree ? false : true}
        onClick={handleSubmit}
      >
        Próximo
      </Button>
    </Container>
  );
};

export default FormLeftNome;
