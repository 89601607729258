import styled from "styled-components";

export const ContainerBtn = styled.div`
    display: flex;
    justify-content: end;
    gap: 8px;
    height: 48px;
    margin-top: 24px;

    button {
      flex: none;
      width:fit-content;
    }

    @media(max-width: 500px) {
    .containerBtn {
      height: auto ;
      flex-direction: column ;

      button {
        width: 100%;
      }
    }
  }
`