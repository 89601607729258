import React from 'react'
import * as S from './styles'

type Props = {
  text: string
  barColor: string
  textColor: string
  active: boolean
  onClick?: () => void
}
const Tab = ({text, barColor, active, textColor, onClick}: Props) => {
  return (
    <S.Container onClick={onClick} active={active} barColor={barColor} textColor={textColor}>
      <p>{text}</p>
      <S.Bar active={active} barColor={barColor}  className='tab'/>
    </S.Container>
  )
}

export default Tab