import { useCallback, useState } from 'react';
import Video, { Room } from 'twilio-video';

interface Params {
  token: string;
  roomName: string;
}

export const useRoom = ({ token, roomName }: Params) => {
  const [room, setRoom] = useState<Room | null>(null);

  const connect = useCallback(async () => {
    const roomResponse = await Video.connect(token, {
      name: roomName,
      networkQuality: {
        local: 1,
        remote: 2,
      },
    });

    setRoom(roomResponse);

    return roomResponse;
  }, []);

  const disconnect = () => {
    if (room && room.localParticipant.state === 'connected') {
      room.localParticipant.videoTracks.forEach((trackPublication) => {
        trackPublication.track.stop();
        trackPublication.unpublish();
      });
      room.localParticipant.audioTracks.forEach((trackPublication) => {
        trackPublication.track.stop();
        trackPublication.unpublish();
      });
      room?.disconnect();

      setRoom(null);
    }
  };

  return { room, connect, disconnect };
};
