import styled from 'styled-components'

export const Container = styled.div<Props>`
  cursor: pointer;
  display: flex;
  flex-direction: row ;
  justify-content: space-between ;
  gap: 16px;
  background: #fff;
  padding: 12px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
`

export const Row = styled.div`
  display: flex;
  gap: 8px;
`

type Props = {
  typing: string
}

export const Container2 = styled.div<Props>`
  display: flex;
  justify-content: space-between ;
  width: 80% ;
  align-items: ${(props) => !props.typing && 'center'};
`

export const ContainerIcon = styled.div`
display: flex;
align-items: center; 
justify-content: center;
min-width: 40px;
height: 40px;
border-radius: 20px;
background-color: #2B45D4;
`
export const ContainerTexts = styled.div`
  display: flex;
  flex-direction: column ;
`


export const Title = styled.h1`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);
  white-space: nowrap ;
`
export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
`
export const ContainerLink = styled.div`
  white-space: nowrap ;
  display: flex;
  gap: 8px;
  align-items: center;
`
export const Link = styled.a`
  text-decoration: none ;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2B45D4;
`