import React from 'react'
import * as S from './styles'
import {ReactComponent as IconSuccess} from '../../../../../../assets/mai-ic-success.mono.svg'
import Icon from '../../../../../../components/Icon'
type PropsMessage = {
  background: string
  msg: string
  fileName: string
  date: string
  color: string
  msgStyle?: React.CSSProperties
}
const SubmittedFile = ({background, msg, fileName, date, color="#fff", msgStyle}: PropsMessage) => {
  return (
    <S.Container >
      <S.MessageContainer style={{backgroundColor: background}}>
        <S.Row>
          <Icon icon={<IconSuccess/>} color='#fff'/>
          <S.Message style={{color: color, whiteSpace: 'nowrap', ...msgStyle}}>{msg}</S.Message>          
        </S.Row>
        <S.Filename>{fileName}</S.Filename>
      </S.MessageContainer>

      <S.Data>{date}</S.Data>
    </S.Container>
  )
}

export default SubmittedFile