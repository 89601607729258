import styled from 'styled-components'

export const ContentForm = styled.div`
  
  margin-top: 24px;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;

  .component-radio{
      background-color: #fff;
      padding: 16px;

      input[type="checkbox"]{
        display: none;
      }

      label {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.88);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    label::before{
      content: "";
      height: 18px;
      width: 18px;
      display: inline-block;
      border: 2px solid rgba(0, 0, 0, 0.4);
      margin: -8px 20px;
      margin-left: 0;
      border-radius: 50%;
    }

    label::after{
      content: "";
      display: inline-block;
      position: absolute;
      width: 12px;
      height: 12px;
      background: hsla(231, 66%, 50%, 0);
      left: 5px;
      top: 14px;
      margin: -8px 20px;
      margin-left: 0px;
      border-radius: 50%;
      transition: all 0.4s;
    }

    input[type="checkbox"]:checked + label::after {
      background: hsla(231, 66%, 50%, 1);
    }
      
    }
  

  .header {
    display: flex;
    justify-content: space-between;

    img, svg {
      cursor: pointer;
    }
  }

  .group {
    display: flex;
    gap: 24px;
    margin-bottom: 24px;

    input {
      margin-top: 0;
    }

    label {
      width: 100%;
    }
  }

  .group-3 {
    display: grid;
    grid-template-columns: 186px 232px 1fr;
    gap: 16px;
    margin-bottom: 24px;

    input {
      margin-top: 0;
    }
  }

  .contentBtn {

    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {  
      position: relative ;
      color: rgba(0, 0, 0, 0.56);
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      input {
        margin-right: 19px;
      }
    }
  }

  p {
    color: #2B45D4;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 660px) {
    .group {
      flex-direction: column;
    }
  }

  @media (max-width: 820px) {
    .group-3 {
      grid-template-columns: 1fr;
    }
  }
`