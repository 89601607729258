import InputLife from 'components/InputLife';
import Button from 'components/Button/Button';

import * as S from './styles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const TalkToOmbudsman = () => {
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');

  const navigate = useNavigate()

  const navigateToNextPage = () => {
    navigate('/faq/enviado')
  };

  const handleSubject = (value: any) => {
    setSubject(value);
  };

  const handleTextarea = (value: any) => {
    setContent(value);
  };

  const enabledButton = () => {
    if (subject && content) {
      return false;
    }
    return true;
  };

  return (
    <>
      <S.Subtile>
        Certo, então é só escrever a sua mensagem! Lembre-se de colocar todas as
        informações relevantes, assim o atendimento será ainda mais rápido.
      </S.Subtile>

      <InputLife
        placeholder="Digite o assunto"
        noLabel={true}
        onChange={(event) => handleSubject(event.target.value)}
        value={subject}
      />

      <S.TextArea
        placeholder="Escreva aqui sua mensagem para a ouvidoria"
        onChange={(event) => handleTextarea(event.target.value)}
        value={content}
      ></S.TextArea>

      <Button
        onClick={navigateToNextPage}
        variant="primary"
        style={{ width: '100%' }}
        disabled={enabledButton()}
      >
        Enviar
      </Button>
    </>
  );
};

export default TalkToOmbudsman;
