

const IconUser = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 6C4 4.3 4.7 2.7 6 1.5C6.4 1.1 6.99999 1.19998 7.39999 1.59998C7.79999 1.99998 7.7 2.6 7.3 3C6.5 3.8 6 4.8 6 6C6 8.2 7.8 10 10 10C12.2 10 14 8.2 14 6C14 3.8 12.2 2 10 2C9.4 2 9 1.6 9 1C9 0.4 9.4 0 10 0C13.3 0 16 2.7 16 6C16 9.3 13.3 12 10 12C6.7 12 4 9.3 4 6ZM10 13C4.4 13 0 15.6 0 19C0 19.6 0.4 20 1 20C1.6 20 2 19.6 2 19C2 17.1 5.3 15 10 15C14.7 15 18 17.1 18 19C18 19.6 18.4 20 19 20C19.6 20 20 19.6 20 19C20 15.6 15.6 13 10 13ZM10 9C11.3 9 12.4 8.2 12.8 7H7.10001C7.60001 8.2 8.7 9 10 9Z" fill="#2B45D4"/>
    </svg>

  )
}

export default IconUser;