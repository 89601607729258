import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  padding: 16px;
  margin-top: 16px;

  h3 {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.88);
    margin-bottom: 24px;
  }

  .group {
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    > div {
      p {
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.88);
      }

      span {
        color: rgba(0, 0, 0, 0.56);
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  .contentBtn {
    
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;

    div {
      width: 180px;
      
      button {
        color: #2B45D4;
      }
    }
  }

  @media (max-width: 600px) {
    .group {
      grid-template-columns: 1fr;
    }

    .contentBtn {
      > div {
        width: 100%;
      }
    }
  }
`