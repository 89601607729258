import styled from "styled-components";

export const Content = styled.div`
    width: 100%;
    display: flex;
    justify-content: start ;
    background-color: #fff;
`

export const Info = styled.div`
    max-width: 620px;
    padding: 96px 0 0 67px;
    
    .title {
      font-family: Open Sans;
      margin-bottom: 32px;
      font-size: 18px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.88);
      line-height: 24px;
    }

    p {
      font-family: Open Sans;
      font-size: 1rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.88);
      margin-bottom: 32px;
      line-height: 24px;

      strong {
        font-weight: 600;
        color: #000;

      }
    }
    height: 100vh ;

    @media(max-width:1290px) {
    .content {
      padding-left: 24px;
    }

    .content-info {
      padding-right: 24px;
    }
  }

  @media(max-width: 1000px) {
    flex-direction: column;
    .content-info {
      padding: 0 24px ;
      background-color: transparent ;
      .info {
        max-width: 100%;
        background-color: #fff;
        border-radius: 8px;
        padding: 16px;
        height:fit-content;
      }
    }
  }
`

