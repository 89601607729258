import React from "react";
import CardQuery from "../../../components/CardQuery";
import * as S from "./styles";
import { BackgroundStatus, TextStatus, textColor } from "./types";
import IconFilter from "../../../assets/Filter.svg";
import { ReactSVG } from "react-svg";
// import { LIST_ATTENDANCE } from "../../../api/Services/Attendance";
import LifeLottie from "pages/Home/HomeContent/LifeLottie";
import load from "../../../assets/Animation/load.json";
import Modal from "components/Modal";
import SelectComponent from "components/Select";
import Checkbox from "components/Checkbox";
import Button from "components/Button/Button";
import Icon from '../../../assets/checkbox-checked.svg'
import Tag from "components/Tag";
import NoPrescription from "components/NoData/NoData";
import InputSearch from "components/InputSearch";
import { CALL_ENPOINT } from "api/Services/CallEndpoint";
import { getPendingAvaliation } from "api/intancesAxios/onboardingURL";
import { PendingAvaliation } from "pages/Home/Header/Index";
import { useNavigate } from "react-router-dom";

const Appointment = () => {
  const navigate = useNavigate()
  const [attendance, setAttendance] = React.useState<any[]>([]);
  const [filteredAttendance, setFilteredAtendance] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentPage, setCurrentPage] = React.useState(1)

  const [search, setSearch] = React.useState("");
  // Filter
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = React.useState("");
  const [selectedYear, setSelectedYear] = React.useState("");
  const [checkboxSelecteds, setCheckbxSelecteds] = React.useState({agendadas: false, canceladas: false, realizadas: false }) 
  const [appliedFilters, setAppliedFilters] = React.useState<string[]>()
  const months = [{label: 'Junho'},{label: 'Julho'},{label: 'Maio'}, {label: 'Março'}]
  const years = [{label: '2021'},{label: '2022'},{label: '2023'}, {label: '2024'}]

  //avaliacao
  const [pendingAval, setPendingAval] = React.useState<PendingAvaliation | null>()
  // const [ isAttendance, setIsAttendance ] = React.useState(false)
 
  
  async function getAttendance(search: string, page: number) {
    
    try {
      const { url, options } = CALL_ENPOINT('TELEHEALTH_URL',`attendance?filter=${search}`,'GET',null,{
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
      });

      const response = await fetch(url, options);
      const json = await response.json();
      if (response.ok) {
        return json
      }
    } catch (err) {
    } finally {
      setLoading(false)
    }
  }


  async function getInitialData(){
    const data = await getAttendance(search, currentPage)
    const {content} = data
    setAttendance(content)
  }

  
  React.useEffect(()=> {
    getInitialData()
  },[])

  const verifyFilters = () => {
    if(!selectedYear && !selectedMonth && !checkboxSelecteds.agendadas && !checkboxSelecteds.canceladas && !checkboxSelecteds.realizadas){
      return true 
    }
    return false
  }

  const checkSelectedFilters = () => {
    let arr: string[] = []
    if(selectedYear){
      arr.push(selectedYear)
    }
    if(selectedMonth){
      arr.push(selectedMonth)
    }
    if(checkboxSelecteds.agendadas){
      arr.push("Agendadas")
    }

    if(checkboxSelecteds.canceladas){
      arr.push("Canceladas")
    }

    if(checkboxSelecteds.realizadas){
      arr.push("Realizadas")
    }
    return arr
  }

  const handleCloseTag = (tag: string) => {

    setAppliedFilters(appliedFilters?.filter(t => t !== tag))
   
    if(tag === 'Agendadas'){
      setCheckbxSelecteds({...checkboxSelecteds, agendadas: false})
    }
    if(tag === 'Canceladas'){
      setCheckbxSelecteds({...checkboxSelecteds, canceladas: false})
    }
    if(tag === 'Realizadas'){
      setCheckbxSelecteds({...checkboxSelecteds, realizadas: false})
    }

    let arr = months.find(m => m.label === tag)
    if(arr){
      setSelectedMonth("")
    }

    let arrY = years.find(m => m.label === tag)
    if(arrY) setSelectedYear("")
  }

  async function getSearchData(){
    const data = await getAttendance(search, currentPage);
    const {content} = data
    setFilteredAtendance(content)
  }

  React.useEffect(() => {
    getSearchData()
  }, [search]);


  React.useEffect(() => {
  }, [checkSelectedFilters])

  React.useEffect(() => {
    getPendingAvaliation().then(json => {
      setPendingAval(json)
    })
    .catch(e => console.log(e))
  }, [])

  //################## [NAO APAGUE!!] #############################
  //  Observer para scroll infinito 
  // React.useEffect(() => {
  //   let intersectionObserver = new IntersectionObserver((entries) => {
  //     if(entries.some(entry => entry.isIntersecting)){
  //       setCurrentPage((prev) => prev + 1)
  //     }
  //   })
  //   let el = document.querySelector('.sentinela')
  //   if(el){
  //     intersectionObserver.observe(el)
  //   }
    
  //   return () => intersectionObserver.disconnect()
  // }, [])

  return (
    <S.Wrapper>
      <h2>Histórico de consultas</h2>
      {/* nao ta carregando e existe consultas  */}
      {
        !loading && attendance.length > 0 ?
          <>
            <div className="contentSearch">
              <div className="ContentInput">
                <InputSearch 
                  placeholder={"Procurar"}
                  backgroundColor="#FFCC00"
                  color="#000"
                  value={search}
                  onChange={(e) => setSearch(e.currentTarget.value)}
                />
              </div>

              <div className="contentFilter" onClick={() => setShowModal(true)}>
                <ReactSVG src={IconFilter} />
                <span>Filtrar</span>
              </div>
            </div>

            {/* Filtros aplicados  */}
            {(appliedFilters && appliedFilters.length > 0) && <S.FiltrosContainer> 
              {
            appliedFilters.map((tag) => <Tag key={tag} onClose={() => handleCloseTag(tag)} label={tag} backgroundColor="rgba(255, 176, 0, 0.16)"/>)
          }
            </S.FiltrosContainer>}

            <div className="ContentItem"> 
              {filteredAttendance.length > 0 && filteredAttendance.map((item: any) => {
                return (
                  <CardQuery
                    type="appointment"
                    key={item.id}
                    color={textColor[item.status]}
                    background={BackgroundStatus[item.status]}
                    badge={TextStatus[item.status]}
                    date={item.prettySchedulingDate}
                    specialty={item?.specialty?.name}
                    nome={item?.professional?.name}
                    link={`details/${item.id}`}
                    hasAvaliation={pendingAval?.uuidAtendimento === item.publicId ? true : false}
                    onClickAvaliation={() => {
                      if(pendingAval){
                      navigate(`/avaliacao/${pendingAval.uuidAtendimento}?emojiCode=2&idAvaliacao=${pendingAval.idAvaliacaoAtendimento}`)

                      }
                    }}
                  />            
                );
              })    
              }      

              <div className="sentinela"></div>
            </div> 
            {
            // isAttendance && attendance.length === 0 &&
            filteredAttendance.length === 0 && search !== "" &&
            <p>
              Não encontramos nenhum resultado para esta busca. Que tal tentarmos de novo?
            </p>
          } 
          </>  : !loading && attendance.length === 0 && <NoPrescription
            description="Você ainda não fez uma consulta online."
        />
      }
      
      { loading &&
      <div className="contentLoad">
        <LifeLottie animationData={load} height={65} width={65} />
      </div> }
    
        
      
      {/* MODAl ==================================== */}
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(!showModal)}
        title="Filtrar por"
        style={{width: '328px', height: '544px', padding: '16px', overflow: 'hidden'}}
        titleStyle={{fontSize: '24px'}}
      >
        <S.ContainerSelects>
          <SelectComponent placeholder="Mês"  selected={selectedMonth} setSelected={setSelectedMonth} data={months}/>
          <SelectComponent placeholder="Ano" selected={selectedYear} setSelected={setSelectedYear} data={years}/>
          <p>Você pode selecionar mais de um item para a busca</p>
        </S.ContainerSelects>

        <S.ContainerCheckboxs>
          <Checkbox borderColor="#2B45D4" iconColor="#2B45D4" checked={checkboxSelecteds.agendadas} pathSvg={Icon} onChange={() => setCheckbxSelecteds({...checkboxSelecteds, agendadas: !checkboxSelecteds.agendadas})}  color={checkboxSelecteds.agendadas ? "#2B45D4": ''} label="Agendadas">Agendadas</Checkbox>
          <Checkbox borderColor="#2B45D4" iconColor="#2B45D4" checked={checkboxSelecteds.canceladas} pathSvg={Icon} onChange={() => setCheckbxSelecteds({...checkboxSelecteds, canceladas: !checkboxSelecteds.canceladas})} color={checkboxSelecteds.canceladas ? "#2B45D4": ''} label="Canceladas">Canceladas</Checkbox>
          <Checkbox borderColor="#2B45D4" iconColor="#2B45D4" checked={checkboxSelecteds.realizadas} pathSvg={Icon} onChange={() => setCheckbxSelecteds({...checkboxSelecteds, realizadas: !checkboxSelecteds.realizadas})}color={checkboxSelecteds.realizadas ? "#2B45D4": ''} label="Realizadas">Realizadas</Checkbox>

        </S.ContainerCheckboxs>
        
        <Button variant="primary" 
          disabled={verifyFilters()}
          onClick={() => {
          let arr= checkSelectedFilters()
          setAppliedFilters(arr)
          setShowModal(false)
        }} style={{width: '100%', padding: '12px 24px'}}>Buscar</Button>
      </Modal>


    </S.Wrapper>
  );
};

export default Appointment;
