import styled from 'styled-components'  

export const Container = styled.section`
   grid-column: 1 / 5;
`

export const Content = styled.div`
    display: flex;
    justify-content: center ;
    align-items: center;
    flex-direction: column ;
`
export const ContentBox = styled.div`
    max-width: 400px;
`