import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 56px;
  position: relative;
  
  input {
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 1rem;
    border: 2px solid rgba(0, 0, 0, 0.16);
    outline: none;
    padding: 1rem;
    /* z-index: 1; */
    border-radius: 4px;
  }

  input:focus + label{
    top: -.5rem;
    left: .8rem;
    color: var(--first-color);
    font-size: 12px;
    font-weight: 500;
    z-index: 10;
    border-radius: 4px;
    /* background-color: #f3f3f3;  */
    background:  linear-gradient( #f3f3f3 50%, #fff 50%);

  }

  /*Input focus sticky top label*/
  .input:not(:placeholder-shown).input:not(:focus)+ .label{
    top: -.5rem;
    left: .8rem;
    font-size: 12px;
    font-weight: 500;
    z-index: 10;
    /* padding: 0 5px; */
    /* background-color: #f3f3f3;  */
    background:  linear-gradient( #f3f3f3 40%, #fff 80%);
    border-radius: 4px;
  }

  input:focus{
    border: 2px solid #2B45D4;
  }

  label {
    background-color: transparent; 
    position: absolute;
    left: 1rem;
    top: 1rem;
    padding: 0 .25rem;
    color: rgba(0, 0, 0, 0.56);
    font-size: var(--normal-font-size);
    transition: .3s;
  }
  
`