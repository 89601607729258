import styled from 'styled-components'


export const Wrapper = styled.div`
   position: absolute;
   top: 8px;
   right: 8px;
   padding: 6px 9px;
   background: rgba(0, 0, 0, 0.4);
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 15px;
   border-radius: 8px;
   max-height: 35px;

   svg path {
    fill: #FFF;
  }

  svg {
    max-height: 20px;
    max-width: 20px;
    
  }
`