import styled from "styled-components";

export const Container = styled.div`
  /* background: var(--background); */
  width: 100%;
  height: 100vh;
`;

export const Content = styled.div`
  max-width: 1366px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 5rem;

  header {
    margin-top: 40px;
  }

  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 220px;

    .header-main {
      margin-top: 114px;
      margin-bottom: 24px;
      max-width: 1000px;
      width: 100%;

      h2 {
        font-size: 1.5rem;
        line-height: 2.5rem;
        font-weight: 600;
        color: var(--life-neutral-black-88);
      }

      p {
        font-size: 0.875rem;
        font-weight: 500;
        color: var(--life-neutral-black-64);

        > span {
          color: var(--life-neutral-black-88);
          font-weight: 600;
        }
      }
    }

    .content-box {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 1rem;
      align-items: center;

      .box {
        width: 492px;
        height: 184px;
        display: flex;
        flex-direction: column;

        align-items: start;
        padding: 1rem;
        min-height: 184px;
        border-radius: 8px;
        background: #fff;

        .header-consulta {
          display: flex;

          img,
          svg {
            margin-right: 18px;
          }

          > p {
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 600;
            color: var(--life-neutral-black-88);
            height: 48px;
          }
        }

        > p {
          margin: 1rem 0;
          font-size: 0.875rem;
          line-height: 1.5rem;
          font-weight: normal;
          color: var(--life-neutral-black-64);
          height: 48px;
        }

        a.btn-consulta {
          text-align: center;
          width: 100%;
          border-radius: 8px;
          padding: 12px 24px;
          background-color: var(--primary);
          color: var(--life-neutral-white);
          font-size: 1rem;
          font-weight: 600;
          line-height: 24px;
        }
      }

      .box.active {
        background-color: rgba(204, 41, 110, 0.08);
      }
    }
  }

  @media (max-width: 880px) {
    .main {
      margin-bottom: 50px;
      .header-main {
        text-align: center;
      }
      .content-box {
        flex-direction: column;
      }
    }
  }

  @media (max-width: 600px) {
    padding: 0 1rem;
    .main {
      margin-bottom: 20px;
      height: auto;
      .header-main {
        margin-top: 50px;
        text-align: start;
      }
      .content-box {
        .box {
          width: 100%;
        }
      }
    }
  }
`;
