import styled from "styled-components";
import apple from '../../../assets/Apple_logo.png'
import google from '../../../assets/Google_icon.png'

export const Container = styled.div`
    max-width: 400px;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0 24px;
    /* outline:  2px solid dodgerblue; */
    
    h1 {
      font-size: 24px;
      line-height: 40px;
      font-weight: 600;
      color: var(--primary);
    }

    p {
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.56);
      margin: 24px 0;
    }

    .btn-apple {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--background);
      border: 2px solid var(--primary) !important;
      box-sizing: border-box;
      width: 100%;
      height: 48px;
      border-radius: 64px !important;
      margin-bottom: 1rem;
      cursor: pointer;
      box-shadow: none !important;
      font-size: 1rem !important; 
      color: var(--life-neutral-black-88);
      font-weight: 600 !important;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 1.8rem;
        height: 1.8rem;
        background-image: url(${apple});
        background-position: center;
        background-repeat: no-repeat, repeat;
        top: 15%;
        left: 22%;
      }

      svg {
        display: none;
      }
    }

    .btn-login {
      background-color: var(--background);
      border: 2px solid var(--primary) !important;
      box-sizing: border-box;
      width: 100%;
      height: 48px;
      /* padding: 12px 24px; */
      border-radius: 64px !important;
      margin-bottom: 1rem;
      cursor: pointer;
      box-shadow: none !important;
      font-size: 1rem !important; 
      color: var(--life-neutral-black-88);
      font-weight: 600 !important;
      position: relative;

      span {
        display: inline-block;
        padding-left: 6px;
      }

      &:after {
        content: '';
        position: absolute;
        width: 1.8rem;
        height: 1.8rem;
        background-image: url(${google});
        background-position: center;
        background-repeat: no-repeat, repeat;
        top: 19%;
        left: 22%;
      }
    }

    @media(min-width: 666px){
      padding: 0;

      h1{
        line-height: 24px ;
      }

      p {
      font-size: 16px;
      font-weight: 400;
      font-style: normal ;
    }
    }
`