import { HeaderTelehealth, HeaderTelehealth2 } from "api/headers/HeaderTeleHealth";
import { SELF_SCHEDULE_VACANCY, SHARE_FILES } from "api/Services/Attendance";
import { CALL_ENPOINT } from "api/Services/CallEndpoint";
import useFetch from "hooks/useFetch";
import { ExamProps } from "pages/Exams/ListExams";
import React, { ReactNode, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

interface ContextValue {
  filteredSpecialtys: Array<string>;
  search: string;
  consultScheduledSpecialty: string | null;
  handleScheduledSpecialty: (e?: any) => void;
  handleSearch: (e?: any) => void;
  setSearch: any;
  setConsultScheduledSpecialty: any;
  handleDay: (day: any) => void;
  consultDay: any;
  consultTime: any;
  handleTime: (time: any) => void;
  handleNewFile: (file: any) => void;
  consultScheduledFile: any;
  addFileModalOpen: boolean;
  handleFileDelete: () => void;
  handleModalClose: () => void;
  device: boolean;
  setDevice: boolean | any;
  getSpecialty: (token:string) => void;
  handleLoginFila: (token:string) => void;
  dataConsult: any;
  setDataConsult: any;
  hiddenVideo: any
  setHiddenVideo: any
  audioStream: any
  setAudioStream: any
  videoStream: any
  setVideostream: any
  files: File[]; 
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  getFiles: (attendanceId: string) => Promise<any>; 
  shareFile: (attendanceId: string, formData: any) => any;
  updateStorage: boolean | any
  setUpdateStorage: any
  attendanceData: any; 
  setAttendanceData: any;
  guestData: any; 
  setGuestData: any;
  reschedule: (obj: Obj, idAttendance: string) => void;
  alertScheduling : boolean, 
  setAlertScheduling : React.Dispatch<React.SetStateAction<boolean>>
  idAttendance: string | undefined, 
  setIdAttendance: React.Dispatch<React.SetStateAction<string | undefined>>
  errorFile: boolean | null;
  dataHook:any;
  errorHook:any;
  loading: any;
  examsSelecteds: ExamProps[]
  setExamsSelecteds: React.Dispatch<React.SetStateAction<ExamProps[]>>
  alertOnAction: AlertProps | null
  setAlertOnAction: React.Dispatch<React.SetStateAction<AlertProps | null>>
}

interface Props {
  children: ReactNode;
}

type Obj = {
  specialtyId: string, date:string, schedulingType: string, hour: string
}

export const ConsultsContext = React.createContext<ContextValue | undefined>(
  {} as ContextValue
);

interface AlertProps {
  label: string
  onEndTime: () => void
  seconds: 3 | 5 | 10
  type: 'success' | 'danger' | 'warning'
}

export const ConsultsContextProvider = ({ children }: Props) => {
  let navigate = useNavigate();

  const [alertOnAction, setAlertOnAction] = React.useState< AlertProps | null>(null)
  const [search, setSearch] = useState<string>("");
  const [consultScheduledSpecialty, setConsultScheduledSpecialty] = useState(null);
  const [ dataConsult, setDataConsult ] = useState(null);
  const [consultDay, setConsultDay] = useState(null);
  const [consultTime, setConsultTime] = useState(null);
  const [consultScheduledFile, setConsultScheduledFile] = useState(null);
  const [ updateStorage, setUpdateStorage] = useState<boolean>(false);

  //Alert attendance
  const [ alertScheduling, setAlertScheduling ] = useState<boolean>(false)

  //Id da nova vaga de atendimento de retorno
  const [ idAttendance, setIdAttendance ] = useState<string | undefined>()

  const [addFileModalOpen, setAddFileModalOpen] = useState(false);
  const [device, setDevice ] = useState(false) //check if camera and audio are able

  const [hiddenVideo, setHiddenVideo ] = useState(null) 
  const [audioStream, setAudioStream ] = useState(null) 
  const [videoStream, setVideostream ] = useState(null) 

  // Arquivos a serem adicionados
  const [files, setFiles] = React.useState<File[]>([]);
  // Arquivos anexados em um Atendimento X

  //Dado do convidado
  const [guestData, setGuestData] = React.useState(null)
  // Dados do Atendimento
  const [attendanceData, setAttendanceData] = React.useState(null)

  // using hook use fetch to upload files 
  const {dataHook, errorHook, loading, request} = useFetch()

  //Exams selecteds 
  const [examsSelecteds, setExamsSelecteds] = React.useState<ExamProps[]>([])

  const mockedSpecialtys = [
    "Alergologia e Imunologia",
    "Clínica médica",
    "Endocrinologia e Metabologia",
  ];
  

  const filteredSpecialtys = mockedSpecialtys.filter((specialty) =>
    specialty.toLowerCase().includes(search.toLowerCase())
  );

  const handleScheduledSpecialty = (e?: any) => {
    setConsultScheduledSpecialty(e.target.value);
  };

  const handleSearch = (e?: any) => {
    setSearch(e.target.value);
  };

  const handleDay = (day: any) => {
    setConsultDay(day);
  };

  const handleTime = (time: any) => {
    setConsultTime(time);
    setUpdateStorage(true)

  };

  const handleNewFile = (file: any) => {
    setAddFileModalOpen(true);
    setConsultScheduledFile(file[0]);
  };

  const handleFileDelete = () => {
    setConsultScheduledFile(null);
  };

  const handleModalClose = () => {
    setAddFileModalOpen(false);
  };



  //Speciality
  async function getSpecialty(token: string) {
    
    // const { url, options } = GET_LIST_SPECEIALTY(token);
    const { url, options } = CALL_ENPOINT('TELEHEALTH_URL','specialty', 'GET', null, {Authorization: `Bearer ${token}`} );
    try {
      const response = await fetch(url, options);
      const json = await response.json();
      setDataConsult(json);
    } catch(error) {
      // console.log(error)
    }
  }


  //Entrar na fila
  async function handleLoginFila (token: string) {
    try {
    // const { url, options }  = POST_LOGIN_FILA(token)
    const { url, options } = CALL_ENPOINT('TELEHEALTH_URL','queue', 'POST', null, {Authorization: `Bearer ${token}`} );
    await fetch(url, options);
    } catch(err){
      console.log("Erro na fila ",err)
    }
  }

  const [errorFile, setErrorFile] = React.useState<boolean>(false)


  async function shareFile(attendanceId: string, formData: any) {
     
      const {url , options} = SHARE_FILES(attendanceId, formData)
      
      const {json} = await request(url, options)
     
      return json
    }

  async function getFiles(attendanceId: string) {
    try {
      setFiles([])
      const tokenTele = window.localStorage.getItem('telehealth@token')
      if(tokenTele){
      const {url , options} = CALL_ENPOINT("TELEHEALTH_URL", `attendance/${attendanceId}/attachments`, "GET", null, HeaderTelehealth2(tokenTele))
      
      const response = await fetch(url, options)

      let files
      if(response.ok){
        files = response.json().then(r => {
          return r
        })
      }

      return files
    }
      
    } catch (error) {
    }
  }



  async function reschedule(obj: Obj, idAttendance: string){
    try {
      const {url , options} = CALL_ENPOINT("TELEHEALTH_URL", `attendance/self-schedule-vacancy`, "POST", obj, {
        'Content-Type': 'application/json',
         Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
       })

      const response = await fetch(url, options)

      if(response.ok){
        const json = await response.json()

        //chamar outro endpoint
        selfReSchedule({id: json.id}, idAttendance)
      }
    } catch (error) {
      // console.log("Erro no reagendamento")
    }
  }

  type ObjReSchedule = {
    id: string
  }

  async function selfReSchedule(obj: ObjReSchedule, idAttendance: string){
    try {
      // const {url, options} = SELF_RESCHEDULE(obj, idAttendance)

      const {url , options} = CALL_ENPOINT("TELEHEALTH_URL", `attendance/self-reschedule/${idAttendance}`, "POST", obj, {
        'Content-Type': 'application/json',
         Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
       })

      const response = await fetch(url, options)

      if(response.ok){
        const json = await response.json()
        navigate(`detalhes/${json.id}`)
        setIdAttendance(json.id)
        setAlertScheduling(true)  
      }
    } catch (error) {
      // console.log()
    }
  }

  const value = {
    consultScheduledSpecialty,
    handleScheduledSpecialty,
    handleSearch,
    search,
    filteredSpecialtys,
    setSearch,
    setConsultScheduledSpecialty,
    handleDay,
    consultDay,
    consultTime,
    handleTime,
    consultScheduledFile,
    handleNewFile,
    addFileModalOpen,
    handleFileDelete,
    handleModalClose,
    device,
    setDevice,
    getSpecialty,
    handleLoginFila,
    dataConsult,
    setDataConsult,
    hiddenVideo,
    setHiddenVideo,
    audioStream,
    setAudioStream,
    videoStream,
    setVideostream,
    files, 
    setFiles,
    shareFile,
    getFiles,
    updateStorage,
    setUpdateStorage,
    guestData,
    setGuestData,
    reschedule,
    errorFile,
    attendanceData, 
    setAttendanceData,
    alertScheduling, 
    setAlertScheduling,
    idAttendance, setIdAttendance,
    errorHook,
    dataHook,
    loading,
    examsSelecteds, 
    setExamsSelecteds,
    alertOnAction, 
    setAlertOnAction
  };

  return (
    <ConsultsContext.Provider value={value}>
      {children}
    </ConsultsContext.Provider>
  );
};

export function useConsultsContext() {
  const context = useContext(ConsultsContext);

  if (typeof context === "undefined") {
    throw new Error(
      "useConsultsContext must be used within an ConsultsContext"
    );
  }

  return context;
}
