import React from 'react'
import Checkbox from '../Checkbox'
import * as S from './styles'
import Icon from '../../../assets/checkbox-checked.svg'
import Button from 'components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { MotivosProps } from '..'
import { sendAvaliation, updateAvaliation } from 'api/intancesAxios/onboardingURL'

export type QuestionProps = {
  question: string
  marked: boolean 
  hasText: boolean
}

type FormsProps = {
  title: string
  newQuestions?: MotivosProps[]
  emojiUnicode:string
  uuidAtendimento: string | undefined
  firstTime: boolean
  idAvaliacao?: string | null
}

export type MotivosCommentProps = {
  question: MotivosProps
  comment: string
}

const Form = ({title, newQuestions, emojiUnicode, uuidAtendimento, idAvaliacao, firstTime}: FormsProps) => {

  const [checkboxSelecteds, setCheckbxSelecteds] = React.useState<MotivosProps[]>([]) 
  const navigate = useNavigate()

  const handleSelecteds = (q: MotivosProps) => {
    if(hasmarked(q)){
      setCheckbxSelecteds(checkboxSelecteds.filter(qst => qst.descricao !== q.descricao))
      setArrQstComment(arrQstComent.filter(qst => qst.question.descricao !== q.descricao))
    }else{
      setCheckbxSelecteds([...checkboxSelecteds, {...q, marked: !q.marked}])
    }
  }

  const hasmarked = (q: MotivosProps) => {
    let qst =  checkboxSelecteds.find(question => question.descricao === q.descricao)
    if(qst){
      return true
    }
    return false
  }

  const [arrQstComent, setArrQstComment] = React.useState<MotivosCommentProps[]>([]) 
  
  const handleComment = (comment: string, question: MotivosProps, arr: MotivosCommentProps[]) => {
      let item = arrQstComent.find(el => el.question.descricao === question.descricao)
      if(!item){
        setArrQstComment([...arrQstComent, {question, comment}])
      }else{
        let restItens = arrQstComent.filter(el => el.question.descricao !== question.descricao)
        item.comment = comment
        setArrQstComment([...restItens, {question, comment}])
      }
  }

  const sendData = async (arr: MotivosCommentProps[]) => {
    let objFinal = { id: idAvaliacao ? idAvaliacao : "",  uuidAtendimento: uuidAtendimento, emojiUnicode, motivos: arr.map(el => {
      return {comentario: el.comment, id: el.question.id}
    })}

    if(firstTime){
      let res = await sendAvaliation(objFinal).catch(e => {
        console.log(e)
      })
      if(res){
         navigate("/avaliacao/finalizada")
      }
     }else{
      let res = await updateAvaliation(objFinal).catch(e => {
        console.log(e)
      })
      if(res){
         navigate("/avaliacao/finalizada")
      }
     }

    
  }

  return (
    <S.Container>
      <S.Title>
        {title}
      </S.Title>
      <S.Questions>
        {
      newQuestions && newQuestions.map((q,i) => {
        return <div key={q.descricao}>
          <Checkbox 
            iconColor={hasmarked(q) ? "#FF0073": "rgba(0, 0, 0, 0.4)"}
            checked={hasmarked(q)} 
            pathSvg={Icon} 
            onChange={() => handleSelecteds(q)} 
            borderColor={hasmarked(q) ? "#FF0073": "rgba(0, 0, 0, 0.4)"} label={q.descricao}>
            {q.descricao}
          </Checkbox>
          {(q.possuiComentario && hasmarked(q)) && <S.TextAreaContainer>
            <S.TextArea
              required
              aria-expanded={false}
              onChange={e => handleComment(e.target.value, q, arrQstComent)}
              id="avaliacao"
          />
            <S.Placeholder htmlFor="avaliacao">
              Deixe aqui seu comentário
            </S.Placeholder>
          </S.TextAreaContainer>}
        </div> 
      })
    }
      </S.Questions>
      <Button
        variant="primary"
        disabled={!checkboxSelecteds.length}
        onClick={() => {
          sendData(arrQstComent)
        // navigate('/teste2')
        }}
        style={{ width: '100%', marginTop: '22px', padding: '12px 40px' }}
          >
        Avaliar
      </Button>
    </S.Container>
  )
}

export default Form