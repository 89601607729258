import React from 'react'
import {Icon, Container} from './styles'
type Props = {
    icon: any;
    size?: number;
    backgroundColor?: string;
    color?: string;  
    radius?: string;
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
    style?: React.CSSProperties;
    onMouseEnter?: React.MouseEventHandler<HTMLDivElement> | undefined;
    onMouseLeave?: React.MouseEventHandler<HTMLDivElement> | undefined;
}
const IconLife = ({style, onClick, onMouseEnter, onMouseLeave,backgroundColor='transparent', size, color, radius, icon}: Props) => {
  return (
    <Container onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={style} onClick={onClick} radius={radius} backgroundColor={backgroundColor}>
      <Icon size={size}  color={color}>
        {icon}
      </Icon>
    </Container>
  )
}

export default IconLife