import styled from "styled-components";

export const LinkAvaliation = styled.a`
  cursor: pointer;
  left: 18px !important;
  color: #2B45D4 !important;
`

export const Wrapper = styled.div`
  max-width: 295px;
  height: 188px;
  background: #fff;
  display: grid;
  padding: 16px;
  position: relative;
  border-radius: 8px;

  > a {
    position: absolute;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FF0073;
    bottom: 1rem;
    right: 27px;
  }

  @media(max-width: 780px) {
    max-width: 100%;
  }

  @media(max-width:570px) {
    width: 100%;
  }
`

export const Agenda = styled.div`
  
  margin-bottom: 8px;
  padding: 4px 16px;
  width: 91px;
  border-radius: 4px;

  > span {
    font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  }
`

export const Date = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
  margin-bottom: 8px;
`

export const Specialty = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);
  margin-bottom: 4px;
`

export const Name = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
  margin-bottom: 16px;
`
export const IconsContainer = styled.div`
  display: flex;
  gap: 17px;
`

export const Content = styled.div`
  margin-bottom: 16px;
`