import React, {useState} from 'react'
import * as S from './styles'
import {ReactComponent as IconLeft } from '../../assets/arrow-left.svg'
import {ReactComponent as IconRight } from '../../assets/arrow-right.svg'
import {ReactComponent as IconClose } from '../../assets/ic-close2.svg'

import ItemSlider from 'components/ItemSlider'
import { propsData } from 'pages/AppointmentHistory/Details/types'
import { AttendanceSelected } from 'pages/Messages/RightContent/Attendances'

type Props = {
  handleClose: () => void,
  style?: React.CSSProperties
  selected: AttendanceSelected
  setSelected: React.Dispatch<React.SetStateAction<AttendanceSelected>>
  data: propsData[]
  mobileLayout?: boolean
}

const SliderChat = ({data, handleClose, mobileLayout, style, selected, setSelected}: Props) => {
  
  const [numberAttendanceForItemCarrousel, setNumberAttendanceForItemCarrousel] = React.useState(() => mobileLayout ? 1 : 3)

  // posicao no carrosel
  const [index, setIndex] = useState(0);
  // um array fake para criar um carrosel de um quantidade de elementos multiplo de 3
  const [items, setItems] = useState<any[]>([])

  React.useEffect(() => {

    if(mobileLayout){
      setNumberAttendanceForItemCarrousel(1)
    }else{
      setNumberAttendanceForItemCarrousel(3)
    }
    // criando e preenchendo o vetor fake
    //definindo qtd de elementos do carrossel
    var mynumber = Math.round(data.length/numberAttendanceForItemCarrousel);
    var arr = new Array(mynumber);
  
    for (var i = 0; i < mynumber; i++) {
        arr[i] = (i + 1).toString();
    }
    setItems(arr)
  }, [data, mobileLayout])

  // useEffect(() => {
  //   const lastIndex = items.length - 1;
  //   if (index < 0) {
  //     setIndex(lastIndex);
  //   }
  //   if (index > lastIndex) {
  //     setIndex(0);
  //   }
  // }, [index]);


  return (
    <S.Section style={style}>
      { items.length > 0 && 
      <>
        <S.Center>
          {/* vetor quebrado para conjuntos de 3 */}
          {items.map((item, indexPeople) => {
          let position = "nextSlide";
          if (indexPeople === index) {
            position = "activeSlide";
          }
          if (
            indexPeople === index - 1 ||
            (index === 0 && indexPeople === items.length - 1)
          ) {
            position = "lastSlide";
          }
          return (
            <S.Article className={position} key={indexPeople+1}>
              <S.List>
                {
                  // [0 - 3] , [3 - 6] , [6 - 9]
                  data.slice(numberAttendanceForItemCarrousel * index, numberAttendanceForItemCarrousel*(index+1))
                  .map((item, index) => {
                    return <ItemSlider item={item} selected={selected} setSelected={setSelected}  label={`${item.specialty.name} ${item.prettySchedulingDate}`} 
                      key={index+2}/>})
                }
              </S.List>
            </S.Article>
          );
        })}
        </S.Center>
        <S.ContainerBtns>
          <S.Button style={{opacity: index <= 0 ? 0.4 : 1}} onClick={() => {
          if(index > 0){
            setIndex(index - 1)
          }
        }}>
            <IconLeft/>
          </S.Button>
          <S.Button style={{opacity: index >= items.length - 1 ? 0.4 : 1}}  onClick={() => {
          if(index < items.length - 1){
            setIndex(index + 1)
          }
        }}>
            <IconRight/>
          </S.Button>        
          <S.Button  onClick={handleClose}>
            <IconClose/>
          </S.Button>        
        </S.ContainerBtns>
      </>
      
      }
    </S.Section>
  )
}

export default SliderChat