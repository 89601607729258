import React from 'react'
import { useHomeContext } from 'contexts/HomeContext';
import Profile from './Profile';
import { Heading } from './styles';
import TextGreetings from './TextGreetings/index';
import Notice from 'components/Notice/Notice';
import AlertBanner from 'components/AlertBanner/AlertBanner';
import { getParticipant } from 'api/intancesAxios/chat';
import { getPendingAvaliation } from 'api/intancesAxios/onboardingURL';
import Modal from 'components/Modal';
import * as S from './styles'
import {ReactComponent as IconEmoji0} from '../../../assets/avaliation-0.svg'
import {ReactComponent as IconEmoji1} from '../../../assets/avaliation-1.svg'
import {ReactComponent as IconEmoji2} from '../../../assets/avaliation-2.svg'
import { useNavigate } from 'react-router-dom';
import PendAvaliation from '../PendingAvaliation';


export type PendingAvaliation = {
  especialidade: string 
  idAvaliacaoAtendimento: number
  nomeProfissional: string
  opcoesAvaliacao: any[]
  prettyDate: string
  uuidAtendimento: string
}

const Header = () => {

  // const objEmojiType = {
  //   "0": "U+2639",
  //   "1": "U+1F610",
  //   "2": "U+1F603",
  // }
  const navigate = useNavigate()
  const [unreadMsg, setUnreadMsg] = React.useState("")
  const [pendingAval, setPendingAval] = React.useState<PendingAvaliation | null>()
  const [showModal, setShowModal] = React.useState<boolean>(true);

  const {noticeTele, 
    canceledConsult, setCanceledConsult, 
    alertScheduling, setAletScheduling,
    
  } = useHomeContext()



  React.useEffect(() =>{
    return () => {
      setCanceledConsult(false)
      setAletScheduling(false)
    }
  },[])

  React.useEffect(() => {
    getParticipant("", "").then(json => {
      setUnreadMsg(json["unread_messages_total"])
    }).catch(e => console.log("erro ao obter unread-messages"))

    getPendingAvaliation().then(json => {
      setPendingAval(json)
    })
    .catch(e => console.log(e))

  }, [])

  type AvaliacaoProps = "0" | "1" | "2" | undefined
  const [avaliacao, setAvaliacao] = React.useState<AvaliacaoProps>()

  const redirectToAvaliation = (emojiCode: string) => {
    if(pendingAval){
      navigate(`/avaliacao/${pendingAval.uuidAtendimento}?emojiCode=${emojiCode}&idAvaliacao=${pendingAval.idAvaliacaoAtendimento}`)
    }
  }

  return (
    <>
      {
       noticeTele &&  (
         noticeTele.typeAction &&
         <Notice type='WARNING' navigateTo='/consultas-online/teleconsulta' subTitle={noticeTele.subtitle}>{noticeTele.title}</Notice>
       )
        
      }

      {/* alert de cancelamento de reagendamento de consulta */}
      {
        canceledConsult && (
          <AlertBanner type='success' label={'Agendamento cancelado com sucesso'} seconds={3} />
          
        )
      }


      {/* alert de consulta agendada com sucesso */}
      {
        alertScheduling && (
          <AlertBanner type='success' label={'Sua consulta foi agendada!'} seconds={3} />
        )
      }

      {/* <AlertMessage doctorName='Dra. Lívia Komatsu' label={'Olá, Filipe. Nesse caso, é melhor agendar uma consulta de...'} seconds={3}/> */}



      <Heading>
        <TextGreetings />
        <Profile unreadMsg={unreadMsg}/>
      </Heading>
      {pendingAval && <PendAvaliation
        setShowModal={setShowModal}
        pendingAval={pendingAval}
        redirectToAvaliation={redirectToAvaliation}
        showModal={showModal}
        setAvaliacao={setAvaliacao}
        avaliacao={avaliacao}
      
      />}

    </>
  );
};

export default Header;
