import React from 'react'
import * as S from './styles'
import PDFViewer from 'pdf-viewer-reactjs'
import { useNavigate, useParams } from 'react-router-dom'
import { CALL_ENPOINT } from 'api/Services/CallEndpoint'
import { headerAppToken } from 'api/headers/HeaderAppToken'
import Button from 'components/Button/Button'
import ButtonOutlined from 'components/Button/ButtonOutlined'
import { ReactComponent as IconShare } from    '../../../assets/new_union.svg'
import { ReactComponent as IconDownload } from '../../../assets/ic-download.svg'
import SvgIcon from '../../../components/Icon'
import { useConsultsContext } from 'contexts/ConsultsContext'
import LifeLottie from "pages/Home/HomeContent/LifeLottie";
import load from "../../../assets/Animation/load.json";

type ExamDetails = {
  fileExtension: string, 
  fileType: string 
  publicId: string
  url: string
}


const ShowExam = () => {
  const navigate = useNavigate()
  // Receive exam id to search  
  const { id } = useParams() as { id: string };
  const [detailsExam, setDatailsExam] = React.useState<ExamDetails>()
  const {setExamsSelecteds, examsSelecteds} = useConsultsContext()


  async function downloadExams(id: string){
    const tokenLifeplace = localStorage.getItem('lifeplace@token')
    if(tokenLifeplace){
    try {
      const { url, options } = CALL_ENPOINT('GESTAO_DOC',`files?ids=${id}`,'GET',null, headerAppToken(tokenLifeplace));
      const response = await fetch(url, options);
      if (response.ok) {
        const {data : {Files}} = await response.json()
        return Files
      }
    } catch (err) {
      console.log("Deu erro na exclusão do exame")
    } 
   }
  }

  function downloadTeste(url:string, filename:string) {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    })
    .catch(console.error);
  }


  React.useEffect(() => {
    downloadExams(id as string).then(json => {
      setDatailsExam(json[0])
    })

    return () => {
      setExamsSelecteds([])
    }
  }, [id])

  return <S.Wrapper>
    {detailsExam && 
    <>
      <S.Container>
        {
        // é PDF ======================
        detailsExam.fileExtension === '.pdf' ? <PDFViewer
          loader={<S.Loading>
            <LifeLottie animationData={load} height={65} width={65} />
          </S.Loading>}
          hideNavbar={true}
          externalInput={false}
          canvasCss={'pdf-viewer'}
          document={{
                url: detailsExam?.url,
            }}
        /> :
        // é imagem ======================
        <S.ContainerImage>
          <img loading='lazy' src={detailsExam.url} alt={"Visualização do exame"} width="100%" height={"100%"}/>
        </S.ContainerImage>
       }
      </S.Container>
      {/* Buttons */}
      <S.ContainerButtons>
        <ButtonOutlined 
          onClick={() => navigate('/exames/compartilhar')}
          style={{width: '30%', padding: '12px 40px', borderColor: '#2B45D4', color: '#2B45D4'}}
          variant={'outlined'}>
          <SvgIcon icon={<IconShare/>} color="#2B45D4"/>
          Compartilhar
        </ButtonOutlined>
        <Button 
          onClick={() =>  downloadTeste(detailsExam.url,`${examsSelecteds[0].documentName}${detailsExam.fileExtension}`)}
          variant='primary' style={{ width: '30%', padding: '12px 24px' }}>
          <SvgIcon icon={<IconDownload/>} color="#fff"/>
          Baixar arquivo</Button>
      </S.ContainerButtons>
    </>
    }
  </S.Wrapper>
}

export default ShowExam