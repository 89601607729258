import { GlobalStyle } from "./styles/global";
import { HomeContextProvider} from "contexts/HomeContext";
import { ConsultsContextProvider } from "contexts/ConsultsContext";
import AppRoutingModule from "routes/AppRoutingModule";
import { UserProvider } from 'contexts/UserContext';

function App() {

  return (
    <>
      <HomeContextProvider>
        <UserProvider>
          <ConsultsContextProvider>
            <GlobalStyle />
            <AppRoutingModule />
          </ConsultsContextProvider>
        </UserProvider>
      </HomeContextProvider>
    </>
  );
}

export default App;
