import React from 'react'
import Modal from 'components/Modal'
import SearchFileInDispositiveChat from './SearchFileInDispositiveChat/index'
import * as S from './styles'
import File from '../../../components/File'
import { useConsultsContext } from 'contexts/ConsultsContext'
import Button from '../../../components/Button/Button'
import { ReactComponent as IconSuccess } from '../../../assets/mai-ic-success.mono.svg'
import FileProgress from 'components/FileProgress'


type Props = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  files:File[]
  setFiles: React.Dispatch<React.SetStateAction<File[]>>
  shareFiles: () => void

}
const AddFilesInChat = ({ showModal, setShowModal, shareFiles, files, setFiles}: Props) => {

  const {loading} = useConsultsContext()
  const [attachmentFiles,] = React.useState<any[]>()
  const [file, setFile] = React.useState<File | null>(null);
  // const [files, setFiles] = React.useState<File[]>([]);
  
  // handle animation
  const [btnShareDisabled, setBtnShareDisabled] = React.useState(false)

  //arquivos para upload - Listagem
  const [, setFileNames] = React.useState<string[]>([])

  const addFiles = (file: File) => {
    setFiles([...files, file])
  }

  React.useEffect(() => {
    if (file) {
      addFiles(file)
    }
  }, [file])

  const removeFile = (nome: string) => {
    setFiles(files.filter(item => item.name !== nome))
  }

  
  return (
    <>
      <Modal title={''} isOpen={showModal} style={{ padding: '16px' }} onClose={() => {
        setShowModal(false)
        setFileNames([])
      }}>
        {/* Lista de arquivos adicionados no atendimento */}
        {(attachmentFiles && attachmentFiles.length !== 0) && (
          <S.FileAddedWrapper >
            <div>
              <IconSuccess />
            </div>
            <div style={{width: '100%'}}>
              <S.FileFileAddedTitle>{attachmentFiles.length > 1 ? 
                `${attachmentFiles.length} arquivos adicionados` : `${attachmentFiles.length} arquivo adicionado` } 
              </S.FileFileAddedTitle>

              <S.ContainerFilesNames scroll={attachmentFiles.length > 3 ? true : false}>
                {
                    attachmentFiles.map((file:any) => {
                      return <S.FileFileAddedText key={file.id}>{file.name}</S.FileFileAddedText>
                    })
                }
              </S.ContainerFilesNames>
            </div>
          </S.FileAddedWrapper>
        )}
        {(attachmentFiles && attachmentFiles.length !== 0) && <S.Saparador/> }
        {/* Upload do 1 arquivo OU Lista de arquivos para upload  */}
        {(files && files.length === 0) ?
          //#######################
          // clicar para compartilhar exames e abrir MODAL 
          // Nao tem arquivos 
          <SearchFileInDispositiveChat addFiles={addFiles} />
          // Fim do Nao ter arquivos
          : (
            <S.Content>
              <S.Title>{files.length} arquivos adicionados!</S.Title>
              <S.FilesContainer scroll={files.length > 3 ? true : false}>
                {
                  files.map((item, index) => {
                    return <FileProgress handleRemove={() => removeFile(item.name)} filename={item.name} key={index} onAnimationStart={() => setBtnShareDisabled(true)} onAnimationEnd={() => setBtnShareDisabled(false)}/>
                  })
                }
              </S.FilesContainer>

              <Button disabled={(btnShareDisabled || loading)} onClick={() => {
                shareFiles()
                }} variant='primary' style={{ width: '100%', padding: '12px 24px' }}>Compartilhar</Button>
              <File setFile={setFile} limitSize={0} />
            </S.Content>
          )
        }
      </Modal>
    </>
  )
}

export default AddFilesInChat