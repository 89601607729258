import IconLife from 'components/Icon'
import ButtonBack from "../../components/ButtonBack";
import { ReactComponent as IconBack } from "../../assets/backIcon.svg";
import { ReactComponent as IconMessage } from "../../assets/message.svg";
import Tab from 'components/Tab'
import React from 'react'
import * as S from './styles'
import NoMessages from './NoMessages';
import ListOpenChat from './ListChatOpen';
import RightContent from './RightContent';
import { getParticipant } from 'api/intancesAxios/chat';
import { MessageProps } from 'utils/Chat';
import useWindowDimensions from 'hooks/useWindowDimensions';


export type PersonChat = {
  participant_uuid?: string
  chat_id: number
  last_message: MessageProps
  participants: string[]
  unread_messages: any
}

const Messages = () => {

  const [tabActive, setTabActive] = React.useState('Abertas')
  const [messages, setMessages ] = React.useState([])
  const [messagesFinish, ] = React.useState([])
  const [doctorChat, setDoctorChat] = React.useState({} as PersonChat)
  const [loading, setLoading] = React.useState(true)
  //Layout
  const [showLayoutSide, setShowLayoutSide] = React.useState("")
  const [mobileLayout, setMobileLayout] = React.useState(false)
  const { width } = useWindowDimensions();


  const [participantes, setParticipantes] = React.useState([])

  React.useEffect(() => {
    if(width <= 900){
      setMobileLayout(true)
      setShowLayoutSide("left")
    }else{
      setMobileLayout(false)
      setShowLayoutSide("both")
    }
  }, [width])



  React.useEffect(() => {
    // esse metodo vai ter que ir para raiz para poder passar o unread_messages para o menu
    getParticipant("", 'chatInfo=true&listAll=true').then((participantes) => {
      setLoading(false)
      setDoctorChat(participantes.chats[0])
      setParticipantes(participantes.chats)
    }).finally(() => {
      setLoading(false)
    })
   }, []) 

  //  console.log(showLayoutSide)
  //  console.log(width)
  //  console.log(doctorChat)

  return (
    <S.Container>
      <S.Wrapper>
        {/* mobile dimensions on style */}
        <S.Left style={{width: mobileLayout ? '100%' : '36%',
          display: ((mobileLayout && showLayoutSide === 'left') || showLayoutSide === "both") ? 'inline' : 'none',
          marginRight: mobileLayout ? '0' : '6px'
        }}>
          {/* Header */}
          <S.Header>
            <ButtonBack icon={<IconLife color='#FF0073' icon={<IconBack/>}/>}>
              <S.TextHeader>Voltar</S.TextHeader>
            </ButtonBack>
            <S.Title>
              <IconLife icon={<IconMessage/>} color={"#2B45D4"}/>
              Mensagens
            </S.Title>
          </S.Header>
          {/* Nav */}
          <S.Nav>
            <Tab text='Abertas' onClick={() => setTabActive('Abertas')} active={tabActive === 'Abertas'} textColor="#243AB2" barColor='#FF0073'/>
            <Tab text='Finalizadas' onClick={() => setTabActive('Finalizadas')} active={tabActive === 'Finalizadas'} textColor="#243AB2" barColor='#FF0073'/>
          </S.Nav>

          <S.Box>
            {/* Content when do not have Messages */}
            {/* NoMessages Open */}
            {(!loading && (participantes.length === 0 && tabActive === 'Abertas')) && <S.ContainerTexts>
              <NoMessages 
                text={"Depois de uma consulta, você tem até 30 dias para trocar mensagens com profissionais que te atenderam. Dá para tirar dúvidas rápidas sobre a medicação ou até o compartilhar um exame."}
                title='Você não tem conversas em andamento com nossos profissionais!'/>
              <S.Text>
                É só acessar seu  <strong style={{color: '#FF0073', cursor: 'pointer'}}>Histórico de Consultas</strong>, escolher o atendimento e iniciar a conversa!
              </S.Text>
            </S.ContainerTexts>}

            {/* NoMessages Finish */}
            {(messagesFinish.length === 0 && tabActive === 'Finalizadas') && <NoMessages
              text='Inimigos do fim! Por enquanto você não tem conversas finalizadas'
              title='Sem mensagens por aqui'/>}

            {/* Content we have Messages - OPEN */}
            {((participantes.length !== 0 && tabActive === 'Abertas')) && <ListOpenChat
              callbackOnClick={() => {
              if(mobileLayout){
                setShowLayoutSide('right')
              }
            }} 
              doctorChat={doctorChat}
              participantes={participantes} setDoctorChat={setDoctorChat}/> }
          </S.Box>
        </S.Left>

        {/* Right Side */}
        {/* List of messages */}
        <S.Right style={{
          display: ((mobileLayout && showLayoutSide === 'right') || showLayoutSide === "both") ? 'inline' : 'none',
          width: mobileLayout ? '100%' : '100%',
      }}>
          {doctorChat.chat_id && <RightContent
            onClickBackButton={() => setShowLayoutSide('left')}
            mobileLayout={mobileLayout}
            doctorChat={doctorChat}/>}
        </S.Right>
      </S.Wrapper>
    </S.Container>
  )
}

export default Messages