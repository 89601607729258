import React from "react";
import ExamCard from './ExamCard';
import JourneyCard from './JourneyCard';
import LevelCard from './LevelCard';
import NewConsult from './NewConsult';
import { ContentHome, Title } from './styles';
import { useHomeContext } from './../../../contexts/HomeContext';
import NoAttendance from "./NoAttendance";
import PrescriptionCard from "./PrescriptionCard";
import { useNavigate } from "react-router-dom";
import { CALL_ENPOINT } from "api/Services/CallEndpoint";
import { Attendance } from "contexts/types/Attendance";
import { HeaderTelehealth2 } from "api/headers/HeaderTeleHealth";
import Messages from './Messages'

const HomeContent = () => {
  
  const { noticeAirmed} = useHomeContext();
  
  const [attendances, setAttendances] = React.useState([])
  const [, setLoading] = React.useState<boolean>(false)
  const [, setError] = React.useState<boolean | null>(null)

  const navigate = useNavigate()
  React.useEffect(()=> {

    let id: NodeJS.Timeout 
    getNextAttendances()  
    id = setInterval(() => {
      getNextAttendances()  
    }, 5000)

    return () => {
      clearInterval(id)
    }
  },[])
 
   //Attendances
   const getNextAttendances = async () => {
    const tokenTele = window.localStorage.getItem('telehealth@token')
    if(tokenTele){
      const {url, options} = CALL_ENPOINT('TELEHEALTH_URL',`attendance/incoming`,'GET', null, HeaderTelehealth2(tokenTele));
      try {
        setError(false)
        setLoading(true)
        const response = await fetch(url, options)
      
        if(response.ok){
          const responseText = await response.text()
          if(responseText){
            const arr = JSON.parse(responseText)
            let newArr = arr.filter((att: Attendance) => att.type !== 'URGENCY')
            setAttendances(newArr)
          }
        }
      } catch (error) {
        console.log(error);
        setError(true)
      }finally{
        setLoading(false)
      }

    }
  }

  return (
    <>
      <ContentHome>
        {/* Left Side */}
        <div className='main'>
          <div className="contentLevelCard">
            <LevelCard />
          </div>

          <div className="sectionJourney">
            <Title>Jornadas do Cuidado</Title>          
            <section> 
              <JourneyCard />
            </section >
            { noticeAirmed && <section className="sectionPrescription">
              <PrescriptionCard/>
            </section>
            }
            <section >
              <Title>Aproveite nossos serviços!</Title>
              <ExamCard />
            </section>
            <section >
              <Messages/>
            </section>
          </div>
        </div>

        
        {/* Right Side */}
        <div className="schedule">
          <div className='headerConsult'>
            <Title>Seus agendamentos</Title>
            <a onClick={()=> navigate('/historico')}>Ver histórico</a>
          </div>

          { attendances?.length > 0 ? <NewConsult attendance={attendances[attendances.length - 1]}/> : <NoAttendance />} 
    
        </div>
      </ContentHome>
    </>
  );
};

export default HomeContent;
