import styled from 'styled-components';

type Props = {
  borderColor?: string;
  iconColor?: string;
};

type PropsLabel = {
  color?: string;
};

export const Wrapper = styled.div<Props>`
  width: 100%;
  display: flex;
  padding: 16px;
  background: #FFFFFF;
  border-radius: 8px;

  @media(min-width: 360px) {
      padding: 16px 10px 16px 10px;
    }

  &.active {
    outline: 2px solid #2B45D4;
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  input + label {
    position: relative;
    cursor: pointer;
  }

  p {
    margin-left: 1rem;
  }

  input[type='checkbox'] + label::before {
    content: '';
    width: 18px;
    height: 18px;
    left: 0;
    bottom: 0px;
    border: solid 3px ${({borderColor}) => borderColor ? borderColor : '#ff0073'};
    border-radius: 4px;
    vertical-align: bottom;
    @media(max-width: 400px){
      width: 10px;
      height: 10px; 
      border-width: 2px;
    }
  }

  input[type='checkbox']:checked + label::after {
    content: '';
    position: absolute;
    left: 7px;
    top: 3px;
    width: 6px;
    height: 10px;
    border-right: solid 4px ${({iconColor}) => iconColor ? iconColor : '#ff0073'};
    border-bottom: solid 4px ${({iconColor}) => iconColor ? iconColor : '#ff0073'};
    transform: rotate(45deg);

    @media(max-width: 400px){
      left: 4px;
      top: auto;
      width: 3px;
      height: 6px;
      border-width: 2px;

    }
  }
`;

export const Label = styled.label<PropsLabel>`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: var(--life-neutral-black-64);
  line-height: 1rem;
  width: 100%;
  cursor: pointer;

  p {
    max-width: 317px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.88);
  }

  span {
    color: var(--primary);
    font-weight: 400;
  }

`;

export const OptionText = styled.p``;
