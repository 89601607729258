import Modal from 'components/Modal'
import SliderChat from 'components/SliderChat'
import React from 'react'
import * as S from './styles'
import Info from './Info'


import IconSpecialty from '../../../../assets/query.svg';
import IconSchedule from '../../../../assets/schedule.svg';
import IconCruz from '../../../../assets/cruz.svg';
import IconDoctor from '../../../../assets/doctor.svg';
import IconCRM from '../../../../assets/CRM.svg';


import Promptuary from './Promptuary'
import Exams from './Exams'
import Prescriptions from './Prescriptions'
import { propsData } from 'pages/AppointmentHistory/Details/types'
import { CALL_ENPOINT } from 'api/Services/CallEndpoint'
import { TextStatus } from 'pages/AppointmentHistory/Appointment/types'
import { PersonChat } from 'pages/Messages'

type Props = {
  attendances: propsData[]
  showModalToShowAttendance: boolean
  setShowModalToShowAttendance: React.Dispatch<React.SetStateAction<boolean>>
  doctorChat: PersonChat
  mobileLayout?: boolean
  selected: AttendanceSelected
  setSelected: React.Dispatch<React.SetStateAction<AttendanceSelected>>

}

export type AttendanceSelected = {
  id: string
  textSelected: string | null
  publicId: string
}

const Attendances = ({showModalToShowAttendance, selected, setSelected, attendances, mobileLayout, setShowModalToShowAttendance}: Props) => {

  const [attendance, setAttendance] = React.useState<propsData | null>();


  
  async function findAttendacce(id: string | undefined) {
    try {
      const { url, options } = CALL_ENPOINT('TELEHEALTH_URL',`attendance/${id}`,'GET',null, {
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
      });

      const response = await fetch(url, options);
      const json = await response.json();

      if (response.ok) {
        setAttendance(json);
      }

    } catch (err) {
      console.log(err);
    }
  }

  React.useEffect(() => {
    if(selected.id){
      findAttendacce(selected.id)
    }
  }, [selected.id])

  return (
    <Modal className='scroll' isOpen={showModalToShowAttendance} style={{ padding: '24px', width: mobileLayout ? '366px': '810px', minHeight: '532px', maxHeight: '632px' }} onClose={() => {
      setShowModalToShowAttendance(false)
    }}>
      { attendance && <S.Wrapper>
        <SliderChat
          mobileLayout={mobileLayout}
          data={attendances}
          selected={selected} setSelected={setSelected}
          style={{marginBottom: '24px'}} handleClose={() =>  setShowModalToShowAttendance(false)}/>
        {/* show if has attendance selected */}
        <S.Container>
          {/* Header */}
          <Info title='Dados da consulta' elements={[{icon: IconCruz, label: TextStatus[attendance.status]},
          {icon: IconSchedule, label: attendance.prettySchedulingDate},
          {icon: IconSpecialty, label: attendance.specialty.name}]}/>

          <Info title='Profissional de saúde' elements={[
          {icon: IconDoctor, label: attendance?.professional?.name ? attendance?.professional?.name : '-' },
          {icon: IconCRM, label: attendance?.professional?.associationNumber ? attendance?.professional?.associationNumber : '-'}]}/>
          {/* Fim Header */}

          {/* Promptuary */}
          <Promptuary title='Prontuário'/>
          {/* Exams */}
          <Exams idAttendance={attendance.id} title='Arquivos adicionados'/>
          {/* Prescriptions */}
          <Prescriptions title="Prescrições"/>

        </S.Container>
      </S.Wrapper>}

    </Modal>
  
  )
}

export default Attendances