import styled from 'styled-components'

type Props = {
  barColor: string
  textColor: string
  active: boolean
}

type PropsBar = {
  active: boolean
  barColor: string
}

export const Container = styled.div<Props>`
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center ;
  color: ${(props) => props.active ? props.textColor : "rgba(0, 0, 0, 0.56)"};
  min-width: 100px;
  
  @media(min-width: 1064px){
    width: 148px;
  }
`
export const Bar = styled.div<PropsBar>`
  height: 2px;
  width: 100% ;
  background-color: ${(props) => props.active ? props.barColor : "transparent"};
  margin-top: 12px;
`
