import Badge from 'components/Badge';
import { Link } from 'react-router-dom';
import * as S from './styles'

import {ReactComponent as IconPill} from '../../assets/pill.svg'
import {ReactComponent as IconExame} from '../../assets/Ic-Exame.svg'
import {ReactComponent as IconDoc} from '../../assets/shape.svg'

import IconLife from '../../components/Icon/index'

interface CardProps {
  type: "appointment" | "prescription",
  badge: string,
  date: string,
  specialty: string,
  nome: string,
  color: string,
  background: string,
  link: string
  hasAvaliation?: boolean
  onClickAvaliation?: () => void
}

const CardItem = ({ hasAvaliation,type, badge, date, onClickAvaliation, specialty, nome, color, background, link}: CardProps) => {
  return (
    <S.Wrapper style={{display: type === 'prescription' ? 'block': 'grid'}}>
      {type === "appointment" && <Badge text={badge} color={color} background={background} />}
      <S.Date style={{marginBottom: type === 'prescription' ? '16px': '8px', fontSize: type === 'prescription' ? '16px': '14px' }}>
        {date}
      </S.Date>
      <S.Specialty style={{marginTop: type === 'prescription' ? '16px': '8px'}}>
        {specialty}
      </S.Specialty>
      <S.Content>
        <S.Name style={{marginBottom: type === 'prescription' ? '8px': ''}}>
          {nome}
        </S.Name>
        { type === 'prescription' && <S.IconsContainer>
          <IconLife icon={<IconPill/>} color={"#2B45D4"}/>
          <IconLife icon={<IconExame/>} color={"#2B45D4"}/>
          <IconLife icon={<IconDoc/>} color={"#2B45D4"}/>
        </S.IconsContainer>}
      </S.Content>
      {hasAvaliation && <S.LinkAvaliation onClick={onClickAvaliation}>Avalie consulta</S.LinkAvaliation>}
      <Link to={link}>Ver detalhes</Link>
    </S.Wrapper>
  )
}

export default CardItem;