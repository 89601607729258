import ButtonBack from 'components/ButtonBack';
import { ReactComponent as IconBack } from '../../assets/backIcon.svg';
import {useLocation } from 'react-router-dom';
import { useHomeContext } from 'contexts/HomeContext';
import { useEffect, useState } from 'react';

const Header = () => {
  const { pathname } = useLocation();
  const { noticeTele, setNoticeTele } = useHomeContext();

  const [link, setLink] = useState<any>(null);

  useEffect(() => {
    if (noticeTele && pathname === '/consultas-online/teleconsulta') {
      setLink('/home'); 
    } else if(!noticeTele && pathname === '/consultas-online/teleconsulta') {
      setLink('/home')
    } else {
      setLink(-1);
    }
  }, [noticeTele, pathname]);

  return (
    <header>
      <ButtonBack to={link} icon={<IconBack />}>
        Voltar
      </ButtonBack>
    </header>
  );
};

export default Header;
