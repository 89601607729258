import { useHomeContext } from "contexts/HomeContext";
import { Navigate, Outlet } from "react-router-dom";

const InQueueGuard = () => {
  
  const { noticeTele } = useHomeContext();
  return noticeTele ? <Navigate to="teleconsulta" /> : <Outlet />;
};

export default InQueueGuard;
