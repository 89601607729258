import { ReactComponent as IconClose} from '../../assets/ic-close-pink.svg' 
import React from 'react'
// import { PUT_ADDRESS } from '../../api/Services/Profile';
import * as S from './styles'
import InputLife from 'components/InputLife'
import { Button } from 'storybook-maida'
import { useHomeContext, ListAdressProps } from 'contexts/HomeContext';
import { CALL_ENPOINT } from 'api/Services/CallEndpoint';
import { headerAppToken } from 'api/headers/HeaderAppToken';


const AddressFormEdit = ( {btnClose, saveNewAdress, addr }: { addr: ListAdressProps, btnClose:(item: boolean, id: number)=> void,  saveNewAdress:()=> void}) => {
  const [ cep, setCep] = React.useState('')
  const [ logradouro, setLogradouro] = React.useState('')
  const [ numero, setNumero] = React.useState('')
  const [ complemento, setComplemento] = React.useState('')
  const [ bairro, setBairro] = React.useState('')
  const [ localidade, setLocalidade] = React.useState('')
  const [ uf, setUf] = React.useState('')
  const [ enderecoPadrao, setEnderecoPadrao ] = React.useState(false)
  
  const { setAlertUpdateAccount } = useHomeContext()
  
  
  //store json request from API to CEP
  const [ dataCep, setDataCep ] = React.useState<ListAdressProps>({} as ListAdressProps)
  
  //Uptade data form
  async function handleEdit(e: any){
    e.preventDefault()
    setAlertUpdateAccount(true)
    setTimeout(()=>{
      setAlertUpdateAccount(false)
    },2000)
    
    await editAddress()
    saveNewAdress()
    closeForm()
  }

  
  //check CEP (máscara)
  const validaCep = (e: string) => {
    let cepMask = e.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2').replace(/(-\d{3})\d+?$/, '$1')
    setCep(cepMask)
  }
  
  
  //Seach o CEP typed
  const buscaCep =  async(cep: string) => {
    const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
    const json = await response.json()
    setDataCep(json)
  }
  
  //Function to updat address
  const editAddress = async() => {
    try {
      // const { url, options } = PUT_ADDRESS(addr.id, {cep, logradouro, numero, complemento, bairro, localidade, uf, enderecoPadrao })
      const { url, options } = CALL_ENPOINT('ONBOARDING_URL',`api/clientes/${addr.id}/enderecos`,'PUT',{cep, logradouro, numero, complemento, bairro, localidade, uf, enderecoPadrao }, headerAppToken(localStorage.getItem('lifeplace@token') as string))
      
      const response = await fetch(url, options)
      const json = await response.json()
      
      if ( response.ok) {
        return json
      }
    } catch(err) {
      console.log(err)
    }
  }
  
  function closeForm() {
    btnClose(false, addr.id)
  }
  
  //set values in input's
  React.useEffect(()=>{
    setCep(addr.cep)
    setBairro(addr.bairro)
    setComplemento(addr.complemento)
    setLogradouro(addr.logradouro)
    setLocalidade(addr.localidade)
    setNumero(addr.numero)
    setUf(addr.uf)
    setEnderecoPadrao(addr.enderecoPadrao)
  },[])


  //UseEffect used to update CEP when user change
  React.useEffect(()=>{
    if(dataCep.cep){
      setCep(dataCep.cep)
      setBairro(dataCep.bairro)
      setComplemento(dataCep.complemento)
      setLogradouro(dataCep.logradouro)
      setLocalidade(dataCep.localidade)
      setNumero(dataCep.numero ? dataCep.numero[0] : '')
      setUf(dataCep.uf)
      setEnderecoPadrao(dataCep.enderecoPadrao)
    }
  },[dataCep])
  
  //só executa se digtar o CEP completo
  React.useEffect(() =>{
    if(cep.length === 9 ) buscaCep(cep) 
  },[cep])
  
  return (
    <S.ContentForm >
    
      <div className='header'>
        <p>Editar endereço</p>
        <IconClose onClick={closeForm} />
      </div>
      <form onSubmit={handleEdit}>
        <div className='group'>
          <InputLife
            // error={'CEP inválido'}         
            placeholder ='Digite seu CEP'
            borderColor={'#ddd'}
            borderColorOnFocus={'#ddd'}
            noLabel = {true}
            value={cep}
            onChange={(e)=> validaCep(e.target.value)}
          />
    
          <InputLife 
            placeholder ='Digite seu Rua'
            borderColor={'#ddd'}
            borderColorOnFocus={'#ddd'}
            noLabel = {true}
            value={logradouro}
            onChange={(e)=> setLogradouro(e.target.value)}
          />
        </div>
    
        <div className="group-3">
          <InputLife 
            placeholder ='Digite seu Número'
            borderColor={'#ddd'}
            borderColorOnFocus={'#ddd'}
            noLabel = {true}
            value={numero}
            onChange={(e)=> setNumero(e.target.value)}
          />
    
          <InputLife 
            placeholder ='Digite seu Complemento'
            borderColor={'#ddd'}
            borderColorOnFocus={'#ddd'}
            noLabel = {true}
            value={complemento}
            onChange={(e)=> setComplemento(e.target.value)}
          />
    
          <InputLife 
            placeholder ='Digite seu Bairro'
            borderColor={'#ddd'}
            borderColorOnFocus={'#ddd'}
            noLabel = {true}
            value={bairro}
            onChange={(e)=> setBairro(e.target.value)}
          />
        </div>
    
        <div className="group">
          <InputLife 
            placeholder ='Digite sua Cidade'
            borderColor={'#ddd'}
            borderColorOnFocus={'#ddd'}
            noLabel = {true}
            value={localidade}
            onChange={(e)=> setLocalidade(e.target.value)}
          />
    
          <InputLife 
            placeholder ='Digite seu Estado'
            borderColor={'#ddd'}
            borderColorOnFocus={'#ddd'}
            noLabel = {true}
            value={uf}
            onChange={(e)=> setUf(e.target.value)}
          />
        </div> 
    
        <div className='contentBtn'>
          <div>
            <label htmlFor="adress">
              <input type="checkbox" name='adress' id='adress' onChange={() => setEnderecoPadrao(!enderecoPadrao)} checked={enderecoPadrao} />
              Este é o meu endereço padrão
            </label>
          </div>
          <div>
            <Button 
              disabled={ cep ? false : true}  
              children={'Editar'}
              style={{ 
                width: 'fit-content !important', 
              }}
          />
          </div>
        </div>
      </form>
    </S.ContentForm>
    )
  }
  
  export default AddressFormEdit