import styled from 'styled-components'

export const replyRounded = styled.button`
  position: relative ;
  height: 56px;
  width: 56px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.theme === 'phone' ? '#F44336' : '#fff')};
  cursor: pointer;

  svg path {
    fill:${(props) => (props.theme === 'phone' ? '#FFF' : '#777')}
  }

  .badge {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 5px 10px;
    border-radius: 50%;
    background:  #2B45D4;
    color: white;
}
  
`

