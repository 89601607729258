import styled from 'styled-components'

type Props = {
  backgroundColor: string
}
export const Container = styled.div<Props>`
  min-width: 100%;
  padding: 16px;
  background: ${(props) => props.backgroundColor};
  border-radius: 8px;
`

export const Text = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.56);
`