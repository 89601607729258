import React from 'react'
import * as S from './styles'
import {ReactComponent as IconMessage} from '../../../../assets/message.svg'
import IconLife from 'components/Icon'
import { useNavigate } from 'react-router-dom'


const Messages = () => {
  const navigate = useNavigate()
  return (
    <S.Container>
      <S.Card onClick={() => navigate("/mensagens")}>
        <S.Div>
          <IconLife icon={<IconMessage/>} size={20} color="#2B45D4"/>
        </S.Div>
        <S.Div>
          <S.Title>Continue sua conversa!</S.Title>
        </S.Div>
        <S.Text>
          <S.Div style={{width: '273px'}}>
            <S.Text>
              Um bate-papo para você tirar dúvidas rápidas depois da consulta com nossos profissionais de saúde
            </S.Text>
          </S.Div>
        </S.Text>
      </S.Card>
    </S.Container>
  )
}

export default Messages