import Button2 from 'components/Button/Button';
import ButtonOutlined from 'components/Button/ButtonOutlined';
import Modal from 'components/Modal';
import React from 'react'
import { ContainerBtns } from '../Teleconsultation/styles';

type Props = {
  active: boolean
  setActive: React.Dispatch<React.SetStateAction<boolean>>
  onClick: () => void
}
const ExitQueue = ({active, setActive, onClick}: Props) => {
  return (
    <Modal
      isOpen={active}
      onClose={() => {
    setActive(!active);
  }}
      style={{width: "464px", padding: "24px"}}
      title="Você quer mesmo sair da fila?"
>
      <p style={{ marginTop: '24px'}}>
        Saindo da fila você perde o seu lugar e não terá atendimento com um
        profissional
      </p>

      <ContainerBtns>
        <ButtonOutlined
          onClick={onClick}
          style={{
        padding: '12px 40px',
        color: 'rgba(0, 0, 0, 0.56)',
        borderColor: 'rgba(0, 0, 0, 0.56)',
      }}
          variant="outlined"
    >
          Sair da fila
        </ButtonOutlined>
        <Button2
          onClick={() => setActive(false)}
          variant="primary"
          style={{
        padding: '12px 40px',
        marginLeft: '8px',
        backgroundColor: '#FFCC00',
        color: 'rgba(0, 0, 0, 0.88)',
      }}
    >
          Ficar na fila
        </Button2>
      </ContainerBtns>
    </Modal>
  )
}

export default ExitQueue