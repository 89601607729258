import styled from "styled-components";

export const ProfileContent = styled.div`


  #overlay {
      position: absolute;
      /* opacity: 0; */

      /* fill the screen */
      top: 0; 
      left: 0;
      right: 0;
      bottom: 0;
      
      /* color  */
      background-color:  transparent;
      /* remove click events */
      /* pointer-events: none; */

      /* Show the overlay and enable click */
      &.active {
        opacity: 1;
        pointer-events: all;
      }
    }

  .nav-menu {
   
    overflow: auto ;
    &::-webkit-scrollbar {
      display: none;
    }

    position: fixed;
    right: 0;
    top: 0;
    right: -150%;
    transition: 850ms;
    height: 100vh;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
    width: 366px;
    padding: 24px;
    z-index: 1000;

    .nav-menu-item {
      .btn-close {
        display: flex;
        margin-bottom: 2rem;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;

        img,
        svg {
          margin-right: 13px;
        }
      }

      .user {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;

        img,
        svg {
          margin-right: 1rem;
        }

        > div {
          display: flex;
          flex-direction: column;

          strong {
            color: var(--primary);
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
          }

          span {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.56);
          }
        }
      }

      .nav-item {
        background: #f6f6f9;
        width: 100%;
        height: 56px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px 0 18px;
        border-radius: 8px;

        &:hover {
          background: var(--primary);

          span {
            color: #fff;
          }

          svg path {
            fill: #f6f6f9;
          }
        }

        > div {
          display: flex;
          align-items: center;

          span {
            color: rgba(0, 0, 0, 0.88);
            font-size: 1rem;
            font-weight: 600;
            margin-left: 18px;
          }
        }
      }
    }
  }
  .nav-menu.active {
    right: 0;
    transition: 350ms;
  }
`;

export const PhotoContent = styled.div`
  height: 56px;
  width: 56px;
  border-radius: 50%;
  cursor: pointer;
`;
