import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 24px ;
`
export const Title = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.88);
  margin-bottom: 16px;
`

export const Questions = styled.div`
  display: flex;
  flex-direction: column ;
  gap: 16px;
`


export const TextAreaContainer = styled.div`
    position: relative;
    margin-top: 16px;
`
export const TextArea = styled.textarea`
    border: 0;
    font-family: 'Open Sans';
    outline: none ;
    resize: none ;
    min-height: 144px ;
    /* width: 328px ; */
    width: 100% ;
    font-weight: 400;
    padding: 16px ;
    border-radius: 4px ;
    background: #FFFFFF;
    font-size: 16px ;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.88);
    
    ::placeholder{
        color: rgba(0, 0, 0, 0.56);
    }

    :focus {
        outline: 2px solid #2B45D4;
    }

    :valid + label, 
    :focus + label {
        transform: translateY(-25px);
        font-size: 12px ;
        color: #000;
    }
    
`
export const Placeholder = styled.label`
    position: absolute;
    background-color: #fff ;
    top:  16px;
    left: 16px;
    padding: 0 5px;
    color: rgba(0, 0, 0, 0.88);

    transition:
        top 0.3s ease,
        font-size 0.3s ease,
        color 0.3s ease
    ;
`