import { Outlet } from 'react-router-dom';
import ButtonBack from 'components/ButtonBack';
import { ReactComponent as IconBack } from '../../../assets/backIcon.svg';
import * as S from './syles';


const ContactUs = () => {
  return (
    <>
      <S.Header>
        <ButtonBack to={-1} icon={<IconBack />}>
          Voltar
        </ButtonBack>
      </S.Header>
      <S.Wrapper>
        <S.Title>Fale com a gente</S.Title>

        <Outlet />
      </S.Wrapper>
    </>
  );
};

export default ContactUs;
