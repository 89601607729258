import React from "react";
import Button from "../../../components/Button/Button";
import { Container, Titulo, Texto, TextoInfo, ContainerBtns } from "./styles";
// import Input from "../../../components/Input/Index";
import { useUserContext } from "contexts/UserContext";
import InputLife from "components/InputLife";

const FormRightEmail = () => {
  const [email, setEmail] = React.useState<string>("");
  const [error, setError] = React.useState<string | null>(null);
  const [valid, setValid] = React.useState(false)


  const { authWithEmail, loading } = useUserContext();

  const handleEmail = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // if(error) validateEmail(e.target.value); //so valida se o usuario tiver errado uma primeira vez.. e sempre fica validando
    validateEmail(e.target.value); //valida sempre que digita
    setEmail(e.target.value);
  };

  const handleSubmitEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateEmail(email)) {
      authWithEmail(email);
    } else {
      setError("E-mail inválido.");
    }
  };

  const validateEmail = (value: string) => {
    const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (value.length === 0) {
      setError("Preencha o campo e-mail.");
      setValid(false)
      return false;
    } else if (!regex.test(value)) {
      setError("E-mail inválido. Confira o que foi digitado e tente mais uma vez.");
      setValid(false)
      return false;
    } else {
      setError(null);
      setValid(true)
      return true;
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    validateEmail(e.target.value);
  };

  return (
    <form onSubmit={handleSubmitEmail}>
      <Container>
        <Titulo>Passo 1</Titulo>
        <Texto>Digite seu e-mail para continuar</Texto>
        <TextoInfo>
          Informações sobre suas consultas, prescrições e pedidos serão enviadas
          para o contato escolhido
        </TextoInfo>


        <InputLife placeholder="E-mail" backgroundColor="white" borderColor="rgba(0, 0, 0, 0.16)" value={email} onChange={handleEmail} error={error} onBlur={e => handleBlur(e)}/>
        
        <ContainerBtns>
          {!loading ? (
            <Button
              type="submit"
              variant="primary"
              disabled={!valid}
              style={{width: '100%'}}
            >
              Continuar
            </Button>
          ) : (
            <Button style={{width: '100%'}} type="submit" variant="primary" disabled={true}>
              Enviando
            </Button>
          )}
        </ContainerBtns>
      </Container>
    </form>
  );
};

export default FormRightEmail;
