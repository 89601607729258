import React from 'react'
import Participant from '../Participant/Participant'
import * as S from './styles'

type PropsVideo  = {
  renderRemoteParticipants: JSX.Element[]
  renderFormattedTimer: () => any
  calculateIdealVideoSize: () => any
  room: any
  containerRef: any
  styleContainer?: React.CSSProperties 
}

const VideoN2N = ({styleContainer,renderRemoteParticipants, calculateIdealVideoSize, containerRef, renderFormattedTimer, room}: PropsVideo) => {
  return (
    <div style={styleContainer}>
      <S.container style={{ padding: '2rem 4rem 4rem' }} ref={containerRef}>
        <div className="InfoTop">
          <p>
            Consulta online em andamento
            <span>{renderFormattedTimer()}</span>
          </p>
        </div>
        <S.gradienteTop></S.gradienteTop>
        <S.gradienteBotton></S.gradienteBotton>
        {room && (
        <S.oneLocal style={calculateIdealVideoSize()}>
          <Participant
            key={room?.localParticipant.sid}
            participant={room?.localParticipant}
            style={{ minHeight: '100%' }}
            showInfo={true}
                />
        </S.oneLocal>
            )}
        {renderRemoteParticipants}
      </S.container>
    </div>
  )
}

export default VideoN2N