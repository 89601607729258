import styled from "styled-components";

export const Container = styled.div`
  padding: 16px;
  background-color: #fff;
  min-width: 366px;
  border-radius: 8px;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-around ;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column ;
  gap: 4px;
`

export const Title = styled.h1`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #243AB2;
`
export const Subtitle = styled.h1`
 font-weight: 600;
 font-size: 16px;
 line-height: 24px;
`
export const Text = styled.p`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.56);
`

export const Checkbox = styled.div`
  display: flex;
`