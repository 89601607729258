import styled from "styled-components";

export const Wrapper = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  margin: 40px 0 32px 0;
  display: flex;
  flex-direction: column;
  transition: all 0.2s;
`; 
export const Header = styled.header`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0 !important;
  transition: all 0.2s;
  cursor: pointer;

  div {
    flex: 1;
  }
`;

export const BoldText = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3453ff;
`;
export const Text = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.64);
  margin-right: 10px;
`;

export const Content = styled.section<{ isExpanded: boolean }>`
  display: ${(props) => (props.isExpanded ? "flex" : "none")};
  font-family: Open Sans;
  flex-direction: column;
  align-items: center;
  margin: 16px 0 12px 0;
  transition: all 0.2s;
`;

export const Description = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.64);
`;

export const FileAddedWrapper = styled.div`
  display: flex;
  font-family: Open Sans;
  background: rgba(43, 69, 212, 0.04);
  border-radius: 8px;
  margin: 32px 0px;
  padding: 11px;

  svg {
    margin-right: 11px;
  }
`;
export const FileFileAddedTitle = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.64);
  margin-bottom: 8px;
`;

export const ContainerFilesNames = styled.div`
    max-height: 230px ;
      
    &::-webkit-scrollbar {
      width: 8px;
      background-color: rgba(0, 0, 0, 0.15);
    }
  
    &::-webkit-scrollbar-thumb {
      background: #2b45d4;
      border-radius: 8px;
    }
`

export const FileFileAddedText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 3px;
`;
