import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'storybook-maida';
import Timetables from './components/Timetables';
import AddFile from './components/AddFile';

import { useConsultsContext } from 'contexts/ConsultsContext';
import { calendarDate } from 'utils/calendarDate';
import useSessionlStorage from 'hooks/useSessionlStorage';
import {
  CHECK_APPOINTMENT_SCHEDULE,
  GET_DAY_FOR_SPECIALTYLIST,
  GET_HOUR_FOR_DAY_lIST,
} from 'api/Services/NextConsult';
import { Title } from '../Specialty/styles';
import {
  Subtitle,
  Text,
  CalendarItem,
  Name,
  Day,
  WrapperNextStep,
  Wrapper,
  ContentCardExame,
} from './styles';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SchedulingConsult = () => {
  const [calendar, setCalendar] = useState<any[]>([]);
  const [days, setDays] = useState(['']);
  const [hours, setHours] = useState<any>([[]]);
  const [hasHours, setHasHours] = useState<boolean>(false);
  const [storage, setStorage] = useSessionlStorage('consulta');
  const [data, setData] = useSessionlStorage('consulta', storage);
  const [loading, setLoading] = useState(false);
  
  // Arquivos adicionados no upload
  const [filenames, setFileNames] = useState<string[]>([])

  const {
    consultDay,
    consultTime,
    handleTime,
    handleDay,
    updateStorage,
    setUpdateStorage,
  } = useConsultsContext();
  const navigate = useNavigate();

  //Configuração para o slide de horarios da consulta
  const settingsHours = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  //Configuração para o slide de datas da consulta
  const settingsCalendar = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: false,
  };

  //Dados mocados para renderizar na tela quando não há horarios disponiveis
  const mockedHoursStatic = [
    ['1:00', '1:45', '2:00', '3:00', '3:30', '23:40', '23:50'],
  ];

  function handleButtonDisabled() {
    if (consultDay !== null && consultTime !== null) {
      return false;
    } else {
      return true;
    }
  }

  //Faz a montagem do calendario partindo da data de acesso
  useEffect(() => {
    const setCalendarDate = () => {
      const today = new Date();
      let fullCalendar: any = calendarDate(today);
      let fullCalendarFormaded = fullCalendar.map((d: any) => {
        // console.log(d)
        const day = d.day.split('/')[0];
        return {
          ...d,
          day: day,
          dayText: d.day,
          selected: false,
          active: false,
        };
      });

      fullCalendarFormaded = fullCalendarFormaded.map((date: any) => {
        if (date.fullday === storage.date) {
          date.selected = true;
          loadData(fullCalendarFormaded);
        }
        return date;
      });

      setCalendar(fullCalendarFormaded);
    };

    setCalendarDate();
  }, []);

  //Faz o request dos dias disponiveis para a especialidade escolhida
  useEffect(() => {
    const getDayforSpecialtyList = async () => {
      const { options, url } = GET_DAY_FOR_SPECIALTYLIST(
        storage.specialtyId,
        storage.schedulingType,
      );
      const response = await fetch(url, options);
      const json = await response.json();

      setDays(json);
    };
    getDayforSpecialtyList();
  }, []);

  useEffect(() => {
    if (calendar.length) {
      hasDateAvailable();
    }
  }, [days]);

  useEffect(() => {
    const handleConfirmDate = async () => {
      let updateStorage;

      const chosenDate = calendar.filter((data) => data.selected === true);
      chosenDate.forEach((date: any) => {
        updateStorage = {
          ...storage,
          date: date.fullday,
          dateTxt: formatDayWeek(date.dayText),
          hour: consultTime,
        };
      });

      await setStorage(updateStorage);
    };

    if (updateStorage) handleConfirmDate();
    setUpdateStorage(false);
  }, [updateStorage]);

  const hasDateAvailable = () => {
    const calendarWithDaysActive = calendar.map((day) => {
      if (days.includes(day.dayText)) {
        return { ...day, active: true };
      }
      return day;
    });

    setCalendar(calendarWithDaysActive);
  };

  //Incia a pagina com os dados do sessionStorage
  const loadData = (date: any) => {
    const fullData = storage?.date;
    const filterDate = date.filter((data: any) => data.fullday === fullData);
    if (storage.hour) {
      getHourForDayList(filterDate[0]);
      setHasHours(true);
    }
  };

  //Verifica se existe horarios para o dia acessado
  const handleDays = (day: any, index: number) => {
    markDateAsSelected(index);
    const hasDay = days.includes(day.dayText);
    handleTime(null);
    handleDay(day);

    if (hasDay) {
      getHourForDayList(day);
      setHasHours(true);
    } else {
      setHasHours(false);
    }
  };
  //Faz o request dos horarios disponiveis
  const getHourForDayList = async (day: any) => {
    const { url, options } = GET_HOUR_FOR_DAY_lIST(
      storage.specialtyId,
      storage.schedulingType,
      day.fullday,
    );
    const response = await fetch(url, options);
    let json = await response.json();

    if (day.fullday !== getCurrentDay()) json = json.sort();

    const newMockedHours = separatesIntoGroups(json, 9);
    setHours(newMockedHours);
  };

  //Retorna uma string com o dia atual (Ex:'2022/03/31')
  const getCurrentDay = () => {
    let today = new Date();
    let currentDay = String(today.getDate()).padStart(2, '0');
    let currentMonth = String(today.getMonth() + 1).padStart(2, '0');
    let currentYear = today.getFullYear();
    return currentYear + '/' + currentMonth + '/' + currentDay;
  };

  //Verifica data selecionada para adicieonar estilo
  const markDateAsSelected = (index: number) => {
    const selectedCalendar = calendar.map((data, i) => {
      data.selected = false;
      if (i === index) data.selected = true;
      return data;
    });
    setCalendar(selectedCalendar);
  };

  //Função para serparar um array em grupos de array
  function separatesIntoGroups(base: any, max: any) {
    const novoArray = [];
    for (var i = 0; i < base.length; i = i + max) {
      novoArray.push(base.slice(i, i + max));
    }
    return novoArray;
  }

  //Retorna o calendario formatado
  //Necessario para o funcionamento do Slider
  const renderFormatCalendar = () => {
    return calendar.map((day: any, index: number) => {
      if (true) {
        return (
          <CalendarItem
            key={index}
            onClick={() => handleDays(day, index)}
            className={`${day?.selected ? 'selected' : ''} ${
              !day.active ? 'activo' : ''
            }`}
            style={day.active ? { background: 'red' } : { background: 'blue' }}
          >
            <Name>{day.dayWeek}</Name> <Day>{day.day}</Day>
          </CalendarItem>
        );
      }
    });
  };

  //Retorna data formatada
  const formatDayWeek = (str: string) => {
    let partes = str.split('/').map(Number);
    let data = new Date(partes[2], partes[1] - 1, partes[0]);
    return data.toLocaleString('pt-br', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    });
  };

  const agendar = async () => {
    let updateStorage;
    const { url, options } = CHECK_APPOINTMENT_SCHEDULE(storage);
    const response = await fetch(url, options);
    const { id, professional } = await response.json();

    try {
      setLoading(true);
      await fetch(url, options);
      updateStorage = {
        ...storage,
        id: id,
        profissional: professional.name,
      };
      setData(updateStorage);
      navigate('/consultas-online/eletiva/confirmar-agendamento');
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Wrapper>
      <Title>Agendar consulta</Title>
      <Subtitle>Agora selecione o dia</Subtitle>
      <Text>Qual o melhor dia para esse atendimento?</Text>

      <ContentCardExame>
        <Slider {...settingsCalendar}>{renderFormatCalendar()}</Slider>
      </ContentCardExame>

      <WrapperNextStep isDisabled={false}>
        <Subtitle>Escolha também o horário</Subtitle>
        <Text>E qual é o melhor horário para você?</Text>

        <Slider {...settingsHours}>
          {hasHours
            ? hours.map((hours: string[], index: number) => {
                return <Timetables array={hours} key={index} />;
              })
            : mockedHoursStatic.map((hours: string[], index: number) => {
                return (
                  <WrapperNextStep
                    key={index}
                    isDisabled={!!hours.length ? true : false}
                  >
                    <Timetables array={hours} />
                  </WrapperNextStep>
                );
              })}
        </Slider>

        {/* Adicionar Arquivos  */}
        <AddFile setFileNames={setFileNames} filenames={filenames} />


        <Button
          disabled={handleButtonDisabled() || loading}
          children={loading ? 'Confirmando...' : 'Próximo'}
          onClick={agendar}
          style={{ marginBottom: '38px' }}
        />
      </WrapperNextStep>
    </Wrapper>
  );
};

export default SchedulingConsult;
