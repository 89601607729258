import React from 'react'
import * as S from './styles'

type props = {
  id?: string
  name?: string;
  label: string,
  type?: string;
  value?: string | number;
  maxLength?: number,
  placeholder?: string,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  [rest:string]: any;
}

const InputLifeplace = ({ id, name, value, label, type, onChange, maxLength, ...rest }:props) => {

  return (
    <S.Wrapper >
      <input 
        className='input'
        type={type} 
        id={id} onChange={onChange} 
        value={value} 
        placeholder=" "
        name={name} 
        maxLength={maxLength}
        {...rest}
      />
      <label htmlFor={id}  className='label'>
        {label}
      </label>
    </S.Wrapper>
  )
}

export default InputLifeplace;