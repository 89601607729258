import styled from 'styled-components';

type CalendarProps = {
  $disabledDay?: boolean
}

export const Wrapper = styled.div`
  max-width: 400px;

  @media (max-width: 410px) {
    max-width: 360px;
  }
`;

export const Subtitle = styled.h2`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);
`;

export const Text = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);
  margin: 8px 0 24px 0;

  @media (max-width: 410px) {
    font-size: 14px;
  }
`;

export const CalendarWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  gap: 8px;
  max-width: 400px;
  width: 100%;

  .wrapper {
    max-width: 100% !important;
  }
`;

export const CalendarItem = styled.div<CalendarProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 74px !important;
  flex: 1;
  flex-direction: column;
  text-align: center;
  margin-bottom: 20px;
  background: rgba(0,0,0,0.04);
  border-radius: 4px;
  padding: 16px 25px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s;

  @media (max-width: 640px) {
    width: 95% !important;
    padding: 16px 0;
  }

  &:hover,
  &.selected {
    background: #2b45d4;

    p {
      color: #fff;
    }
  }

  &.activo {
    opacity: 0.3;
  }
`;



export const HourItem = styled.span`
  display: flex;
  width: 128px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #e9e9e9;
  border-radius: 4px;
  padding: 16px 25px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.2s;

  @media (max-width: 390px) {
    width: 100% !important;
    padding: 16px 0px !important;
  }

  &:hover,
  &.selected {
    background: #2b45d4;

    p {
      color: #fff;
    }
  }
`;

export const HoursWrapper = styled.div`
  max-width: 400px;
  display: grid;
  grid-template-columns: repeat(1fr, 3);
`;

export const Name = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);
`;

export const Day = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);
  margin-top: 8px;
`;

export const WrapperNextStep = styled.div<{ isDisabled: boolean }>`
  opacity: ${(props) => props.isDisabled && '0.4'};
  cursor: ${(props) => props.isDisabled && 'not-allowed'};
  pointer-events: ${(props) => props.isDisabled && 'none'};
`;

export const ContentCardExame = styled.div`
  height: 150px;
  max-width: 405px;
  overflow-x: hidden;

  .swiper-container {
    width: 100%;

    padding: 0 40px 0 0;

    .swiper-wrapper {
      /* background: blue; */
      display: flex;
    }
    .swiper-slide {
      width: 100%;
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
  }

  @media (max-width: 640px) {
    width: 100%;
    padding: 0;
  }
`;
