import React from 'react'
import * as S from './styles'
import {ReactComponent as IconArronDown} from '../../assets/arrow-down.svg'
import IconLife from 'components/Icon'
type Props = {
    children: React.ReactNode;
    title: string;
    icon: React.ReactComponentElement<'svg'>;
    subtitle: string
}
const Accordion = ({children, title, icon, subtitle}: Props) => {
  const [toggle, setToggle] = React.useState(localStorage.getItem('faq') === title ? true : false)

  // const style: React.CSSProperties = {
  //   height: 'auto' ,
  //   maxHeight: '9999px',
  //   transition: 'all 0.5s cubic-bezier(1,0,1,0)'
  // }


  const rotateIcon: React.CSSProperties = {
    transform: 'rotateX(180deg)', 
    top: '-6px'
  }

  const handleToggle = () => {
    if(!toggle){
      localStorage.setItem("faq", title)
    }
    setToggle(!toggle)
  }

  return (
    <S.Item>
      <S.Header style={{backgroundColor: toggle ? 'rgba(43, 69, 212, 0.04)': '#fff', borderRadius: '8px'}}>
        <S.Container onClick={() => setToggle(!toggle)}>
          <S.ContainerTitle>
            <IconLife icon={icon} color="#2B45D4"/>
            <S.Title>{title}</S.Title>
          </S.ContainerTitle>
          <S.ContainerIcon>
            <S.ToggleText>{toggle ? 'Ocultar' : 'Expandir'}</S.ToggleText>
            <IconLife style={ toggle ? rotateIcon : undefined} color="#2B45D4" icon={<IconArronDown/>} size={28} onClick={handleToggle}/>
          </S.ContainerIcon>
        </S.Container>
        <S.Subtitle>
          {subtitle}
        </S.Subtitle>
      </S.Header>
      {toggle && <S.Content>
        {
           children
        }
      </S.Content>}
    </S.Item>
  )
}

export default Accordion