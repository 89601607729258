import styled from 'styled-components'

type opacity = {
  type?: boolean;
}

export const container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: #1e1e1e;
  position: relative;
  align-content: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 640px ) {
    padding: 6rem 1rem !important;
    gap: 16px;

  }

  .InfoTop{
    position: absolute;
    top: 2rem;
    left: 2rem;
    color: #FFF;
    z-index: 999;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;

    span {
      display: block;
      color: #FFF;
    }
  }
`;

export const gradienteTop = styled.div`
  width: 100%;
  height: 6.125rem;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );
`;


export const gradienteBotton = styled.div`
  width: 100%;
  height: 10.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
`;

export const userIconContainer = styled.div`
  width: 115px;
  height: 115px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg path {
    fill: #ddd;
  }

  svg {
    width: 115px;
    height: 115px;
  }
`;


export const localParticipant = styled.div`
  width: 9.5rem;
  height: 5.375rem;
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 9999;

  @media(max-width: 450px){
    top: 4rem;
  }
`;


export const localMedia = styled.div`
  z-index: 999;
  position: fixed;

  width: 9.5rem;
  height: 5.375rem;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  svg path {
    fill: #777;
  }
`;


export const remoteParticipant = styled.div<opacity>`
  opacity: 1;
`;


export const info = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  display: flex;
  color: #fff;
  flex-direction: column;
  text-transform: capitalize;
  z-index: 99999;

  font-size: 16px;
  line-height: 24px;
  font-weight: 700;

  @media(max-width: 640px){
    bottom: 6rem;
  }
`;