import styled from 'styled-components'

type Props = {
    width: string; 
    height: string;
    icon: any;
}

type ContainerProps = {
    radius: any; 
    backgroundColor: string
}

export const Container = styled.div<ContainerProps>`
    display: inline-block;
    background-color: ${(props) => props.backgroundColor};
    border-radius: ${(props) => props.radius ? props.radius : 0};
`

export const ContainerIcon = styled.div<Props>`
    width: ${(props) => props.width};
    height: ${(props) => props.height};;
    background: url(${(props) => props.icon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: 2px;  
    
`
