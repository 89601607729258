import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column ;
  gap: 16px;
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
`

export const TextContainer = styled.div``
export const Text = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
`
