import styled from 'styled-components'

export const ContentCardJornada = styled.div`
  display: flex;
  margin-bottom: 24px;
  width: 600px;
  overflow-x: hidden;
  position: absolute;
  /* background: yellow;  */

  .swiper-container {    
    padding: 0 40px 0 0;

  .swiper-wrapper {
    /* background: blue; */
    display: flex;
  }
  .swiper-slide {
    
    width: 100%;
  /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  
  }
}

  @media (max-width: 880px) {
    width: 100%;
    /* padding: 0 24px    */
  }


`

export const CardItem = styled.div`
  height: 120px;
  width: 96px;
  border-radius: 24px;
  padding: 16px 24px 24px;
  transition: width .2s;
  margin-right: 8px;
  cursor: pointer;

  p { 
    display: none;
  }

  &:hover {
    width: 244px;

    p {
      display: inline-block;
    }
  }

  .circle-content {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  p {
    color: #fff;
    font-weight: 600;
    line-height: 24px;
  }

  @media (max-width: 790px) {
    width: 140px;

    p {
      display: block;
    }

    &:hover {
      width: 140px;
    }
  }
`