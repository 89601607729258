export const HeaderTelehealth = {
  Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
}

export function HeaderTelehealth2(token:string){
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`
  }
}
