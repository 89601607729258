export function ATTENDANCE_FEEDBACK(id: string, obj: {grade: string, comment: string}){
    return {
      url: process.env.REACT_APP_API_URL_TELEHEALTH + `attendance/${id}/feedback`,
      options: {
        method: 'POST',
        headers: {
         'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
        },
        body: JSON.stringify(obj)
      },
    };
  }