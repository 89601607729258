import React from "react";
import * as S from "./styles";
// import { BackgroundStatus, TextStatus, textColor } from "./types";
import IconFilter from "../../../../assets/Filter.svg";
import { ReactSVG } from "react-svg";
import {getPrescriptions} from '../../../../api/intancesAxios/onboardingURL'
// import LifeLottie from "pages/Home/HomeContent/LifeLottie";
// import load from "../../../../assets/Animation/load.json";
import CardItem from "../../../../components/CardQuery/index";
import Modal from '../../../../components/Modal/index'
import SelectComponent from "components/Select";
import Checkbox from "components/Checkbox";
import Button from '../../../../components/Button/Button'
import Icon from '../../../../assets/checkbox-checked.svg'
import NoData from "../../../../components/NoData/NoData";
import Tag from "components/Tag";
import InputSearch from "components/InputSearch";
// import { GET_DATA_ACCOUNT } from "api/Services/Profile";
import {formatDate, months} from '../../../../utils/Date/DayOfWeek'
// import { useUserContext } from "contexts/UserContext";
import LifeLottie from "pages/Home/HomeContent/LifeLottie";
import load from "../../../../assets/Animation/load.json";
import { CALL_ENPOINT } from "api/Services/CallEndpoint";
import { headerAppToken } from "api/headers/HeaderAppToken";

const List = () => {
  // const [attendance, setAtendance] = React.useState<any[]>([]);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState("");

  const [selectedMonth, setSelectedMonth] = React.useState("");
  const [selectedYear, setSelectedYear] = React.useState("");
  const [checkboxSelecteds, setCheckbxSelecteds] = React.useState({medicamentos: false, relatorios: false, solicitacao: false }) 
  
  const [appliedFilters, setAppliedFilters] = React.useState<string[]>()
  
  const [cpfClient, setCpfClient] = React.useState('')
  const [prescriptions, setPrescriptions] = React.useState([])


//   React.useEffect(() => {
//     getAttendance(search);
//     console.log(search);
//   }, [search]);

//   async function getAttendance(search: string) {
//     try {
//       const { url, options } = LIST_ATTENDANCE(search);
//       const response = await fetch(url, options);
//       const json = await response.json();

//       if (response.ok) {
//         setAtendance(json);
//         setLoading(true);
//       }
//     } catch (err) {
//       console.log(err);
//     }
//   }


  const years = [{label: '2021'},{label: '2022'},{label: '2023'}, 
  {label: '2024'}]

  const verifyFilters = () => {
    if(!selectedYear && !selectedMonth && !checkboxSelecteds.medicamentos && !checkboxSelecteds.relatorios && !checkboxSelecteds.solicitacao){
      return true 
    }
    return false
  }

  const checkSelectedFilters = () => {
    let arr: string[] = []
    if(selectedYear){
      arr.push(selectedYear)
    }
    if(selectedMonth){
      arr.push(selectedMonth)
    }
    if(checkboxSelecteds.medicamentos){
      arr.push("Medicamentos")
    }

    if(checkboxSelecteds.relatorios){
      arr.push("Relatórios")
    }

    if(checkboxSelecteds.solicitacao){
      arr.push("Solicitação")
    }
    return arr
  }

  const handleCloseTag = (tag: string) => {

    setAppliedFilters(appliedFilters?.filter(t => t !== tag))
   
    if(tag === 'Medicamentos'){
      setCheckbxSelecteds({...checkboxSelecteds, medicamentos: false})
    }
    if(tag === 'Relatórios'){
      setCheckbxSelecteds({...checkboxSelecteds, relatorios: false})
    }
    if(tag === 'Solicitação'){
      setCheckbxSelecteds({...checkboxSelecteds, solicitacao: false})
    }

    let arr = months.find(m => m.label === tag)
    if(arr){
      setSelectedMonth("")
    }

    let arrY = years.find(m => m.label === tag)
    if(arrY) setSelectedYear("")
  }

  React.useEffect(() => {
  }, [checkSelectedFilters])

  React.useEffect(()=> {

    setLoading(true)
    const dataAccount = async() => {
      try {
        // const { url, options } = GET_DATA_ACCOUNT()
        const { url, options } = CALL_ENPOINT('ONBOARDING_URL','api/clientes','GET',null, headerAppToken(localStorage.getItem('lifeplace@token') as string)) 

        const response = await fetch(url, options)
        const json = await response.json()
        
        if ( response.ok) {
          return json.cpf
        }
      } catch(err) {
       console.log(err)
      }
    }

    dataAccount().then(r => getPrescriptions(r).then(prescriptions => {
      setPrescriptions(prescriptions.content)
    }).catch(e => console.log("erro no prescricao ", e))
    .finally(() => {
      setLoading(false)
    })
    
    )
  },[])

  return (
    <S.Wrapper>
      <h2>Prescrições</h2>

      {
        (prescriptions.length > 0 ) ? 
      (
        <>      
          <div className="contentSearch">
            <div className="ContentInput">
              <InputSearch 
                placeholder={"Procurar"}
                backgroundColor="#FFCC00"
                color="#000"
                value={search}
                onChange={(e) => setSearch(e.currentTarget.value)}
            />
            </div>

            <div className="contentFilter" onClick={() => setShowModal(true)}>
              <ReactSVG src={IconFilter} />
              <span>Filtrar</span>
            </div>
          </div>

          {/* Filtros  */}
          {(appliedFilters && appliedFilters.length > 0) && <S.FiltrosContainer> 
            {
             appliedFilters.map((tag) => <Tag key={tag} onClose={() => handleCloseTag(tag)} label={tag} backgroundColor="rgba(255, 176, 0, 0.16)"/>)
            }
          </S.FiltrosContainer>}
          

          <div className="ContentItem">
            {prescriptions.map((p:any, index: any) => <CardItem
              type="prescription"
              key={index}
              color={"#FFF"}
              background={"#F44336"}
              badge={""}
              date={formatDate(new Date(p.createdAt))}
              specialty={p.specialty}
              nome={p.professional}
              link={`detalhes/${p.prescriptionId}`}
                />)}
       
     
          </div>
        </>
      )

      :

        (
          loading ?   <S.Loading>
            <LifeLottie animationData={load} height={65} width={65} />
          </S.Loading>  : <NoData
            description={'As prescrições são enviadas pra você assim que uma consulta é finalizada.'}
            subDrescription={'Por enquanto, não tem nada por aqui!'}  
        />
        )

      }

      {/* MODAl ==================================== */}
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(!showModal)}
        title="Filtrar por"
        style={{width: '328px', height: '544px', padding: '16px', overflow: 'hidden'}}
        titleStyle={{fontSize: '24px', marginBottom: '24px'}}
      >
        <S.ContainerSelects>
          <SelectComponent placeholder="Mês"  selected={selectedMonth} setSelected={setSelectedMonth} data={months}/>
          <SelectComponent placeholder="Ano" selected={selectedYear} setSelected={setSelectedYear} data={years}/>
          <p>Você pode selecionar mais de um item para a busca</p>
        </S.ContainerSelects>

        <S.ContainerCheckboxs>
          <Checkbox borderColor="#FF0073" iconColor="#FF0073" checked={checkboxSelecteds.medicamentos} pathSvg={Icon} onChange={() => setCheckbxSelecteds({...checkboxSelecteds, medicamentos: !checkboxSelecteds.medicamentos})}  color={checkboxSelecteds.medicamentos ? "#2B45D4": ''} label="Medicamentos">Medicamentos</Checkbox>
          <Checkbox borderColor="#FF0073" iconColor="#FF0073" checked={checkboxSelecteds.relatorios} pathSvg={Icon} onChange={() => setCheckbxSelecteds({...checkboxSelecteds, relatorios: !checkboxSelecteds.relatorios})} color={checkboxSelecteds.relatorios ? "#2B45D4": ''} label="Relatórios e atestados">Relatórios e atestados</Checkbox>
          <Checkbox borderColor="#FF0073" iconColor="#FF0073" checked={checkboxSelecteds.solicitacao} pathSvg={Icon} onChange={() => setCheckbxSelecteds({...checkboxSelecteds, solicitacao: !checkboxSelecteds.solicitacao})}color={checkboxSelecteds.solicitacao ? "#2B45D4": ''} label="Solicitações de exames">Solicitações de exames</Checkbox>
        </S.ContainerCheckboxs>
        
        <Button variant="primary" 
          disabled={verifyFilters()}
          onClick={() => {
          let arr= checkSelectedFilters()
          setAppliedFilters(arr)
          setShowModal(false)
        }} style={{width: '100%', padding: '12px 24px'}}>Buscar</Button>
      </Modal>
    </S.Wrapper>
  );
};

export default List;
