import styled from 'styled-components';

type color = {
  $checkdedColor?: string;
  $boderColor?: string;
};

export const Wrapper = styled.div<color>`
  display: flex; 
  align-items: center;
  width: fit-content ;
  /* outline: 1px solid gold ; */

  label {
    /* outline: 1px solid greenyellow ; */
    border-radius: 8px;
    display: block;
    padding: 10px 10px 10px 39px;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.56);
    line-height: 24px;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    white-space: nowrap;

    &:after,
    :before {
      display: block;
      content: '';
      position: absolute;
      border-radius: 50%;
    }

    &:after {
      height: 16px;
      width: 16px;
      border: 2px solid rgba(0, 0, 0, 0.56);
      left: 10px;
      top: 10px;
    }

    &:before {
      background: ${({ $checkdedColor }) =>
        $checkdedColor ? $checkdedColor : '#ff0073'};
      height: 10px;
      width: 10px;
      left: 15px;
      top: 15px;
      visibility: hidden;
    }
  }

  input[type='radio'] {
    display: none;

    &:checked ~ label:after {
      border: 2px solid
        ${({ $checkdedColor }) => ($checkdedColor ? $checkdedColor : '#ff0073')};
      
    }

    &:checked ~ label{
      color: #2B45D4;
    }

    &:checked ~ label:before {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }
`;
