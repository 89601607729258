import styled from "styled-components";

// header greetings
export const Heading = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 28px;
  padding: 0 24px;

  @media (max-width: 1280px) {
    /* padding: 0 24px; */
  }

  @media (max-width: 768px) {
    margin: 25px 0 30px 0;
    align-items: flex-start;
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.88);
`

export const Container = styled.div`
  display: flex;
  flex-direction: column ;
`

export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
`


export const Emojis = styled.div`
    display: flex;
    margin-top: 24px;
    justify-content: center ;
    gap: 50px;

    @media(min-width: 400px) {
      gap: 50px; 
    }

`

export const ContainerEmoji = styled.div`
    &.active {
        background: rgba(0, 0, 0, 0.08);
        border-radius: 8px;
    }
    &:hover {
        background: rgba(0, 0, 0, 0.08);
        border-radius: 8px;
    }
`
