//status color Background
export const BackgroundStatus: any = {
  CANCELED: '#F44336',
  SCHEDULED: '#FDB81E',
  REQUESTED: '#FDB81E',
  WAITING_IN_QUEUE: '#FDB81E',
  WAITING_INSURED: '#FDB81E',
  VIDEOCALL_IN_PROGRESS: '#008AD2',
  VIDEOCALL_ENDED: '#38B449',
  FINISHED: '#38B449',
  PAYMENT_CANCELED: '#F44336',
}

export const TextStatus: any = {
  CANCELED: 'Cancelada',
  SCHEDULED: 'Agendada',
  REQUESTED: 'Agendada',
  WAITING_IN_QUEUE: 'Aguardando na fila',
  WAITING_INSURED: 'Profissional Aguardando',
  VIDEOCALL_IN_PROGRESS: 'Em Andamento',
  VIDEOCALL_ENDED: 'Realizada',
  FINISHED: 'Realizada',
  PAYMENT_CANCELED: 'Pagamento Cancelado'
}
//status color text color
export const textColor: any = {
  CANCELED: '#FFF',
  SCHEDULED: '#000',
  REQUESTED: '#000',
  WAITING_IN_QUEUE: '#000',
  WAITING_INSURED: '#000',
  VIDEOCALL_IN_PROGRESS: '#FFF',
  VIDEOCALL_ENDED: '#FFF',
  FINISHED: '#FFF',
  PAYMENT_CANCELED: '#FFF',
}