
export type ParticipanteProps = {
  descricao: string
  chat_id: number
  participante_id: string
  unread_messages: number
}

export type MessageProps = {
  attachment_url: string
  chat_id: number 
  date_sended: string
  offset: number
  sender_uuid: string
  text_or_attachment_name: any
  senderName?: string
  type: "text" | "attachment"
}

export type ParticipanteWithMessagesProps = {
  chat_id: number
  last_viewed_offset: number
  messages: MessageProps[]
  requester_uuid: string
  unread_messages: number
  sender_uuid: string
}

export const groupMessages = (messages: MessageProps[], i: number) => {
  const myMessage = messages[i]; // mensagem atual
  const PreviousMessage = messages[i - 1]; // mensagem anterior
  //pega msgs a partir da primeira com datas iguais e coloca ==== >  NO INICIO
  // if (PreviousMessage && myMessage.date_sended.split(" ")[0] === PreviousMessage.date_sended.split(" ")[0]) {
  if (PreviousMessage && myMessage.sender_uuid === PreviousMessage.sender_uuid) {
    return true;
  }else {
    return false;
  }
}

export const groupMessagesForDate = (messages: MessageProps[], i: number) => {
  const myMessage = messages[i]; // mensagem atual
  const PreviousMessage = messages[i - 1]; // mensagem anterior
  //pega msgs a partir da primeira com datas iguais e coloca ==== >  NO INICIO
  if (PreviousMessage && myMessage.date_sended.split(" ")[0] === PreviousMessage.date_sended.split(" ")[0]) {
    return true;
  }else {
    return false;
  }
}

// verifica se a mensagem atual é a ultima do remetente do conjunto de datas
export const checkIfTheLastMessage = (messages: MessageProps[], i: number) => {
  const myMessage = messages[i]; // mensagem atual
  const nextMessage = messages[i + 1]; // proxima mensagem
  // const PreviousMessage = messages[i - 1]; // mensagem anterior
  //pega msgs a partir da proxima com datas iguais e coloca ==== >  NO FIM
  // if (nextMessage && myMessage.date_sended.split(" ")[0] === nextMessage.date_sended.split(" ")[0]) {
    if (nextMessage && myMessage.sender_uuid === nextMessage.sender_uuid) {
    return false;
  } else {
    return true;
  }
}