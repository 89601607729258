import React from 'react'
import {SeeMore, SeeMoreLink, ListCategories, SearchContainer, NoSearch, HeaderContent} from './styles'
import Button from '../../components/Button/Button'
import { Search } from "storybook-maida";
import Accordion from 'components/Accordion/Accordion';
import CardList from 'components/CardList';

import ScreenComponent from './Screen/Screen';
import {ReactComponent as IconMsg} from '../../assets/message.svg'
import {ReactComponent as NoSearchBg} from '../../assets/Backgrounds/search.svg'
import { useNavigate } from 'react-router-dom';
import { useHomeContext } from 'contexts/HomeContext';
import InputSearch from 'components/InputSearch';

const Faq = () => {
  
  const [search, setSearch] = React.useState("");
  const navigate = useNavigate()
  const {categories, setFAQSelected, faqSelected} = useHomeContext()

  const [filtrados, setFiltrados] = React.useState<any>(categories)

  // Search Method
  const searchFAQ = (value: any) => { 
    if(value){
        const filtrados = categories.filter( (item: any) => {
            const minusculo = item.title.toLowerCase()
            const itemMinu = value.toLowerCase()
            return minusculo.indexOf(itemMinu) > -1
        })
        setFiltrados(filtrados)
    }else{
        setFiltrados(categories)
    }
  }

  return (
    <ScreenComponent title='Ajuda' icon={false}>
      <HeaderContent>
        <SearchContainer>
          <InputSearch 
            placeholder={"Procurar"}
            backgroundColor="#FFCC00"
            color="#000"
            value={search}
            onKeyUp={(e) => searchFAQ(e.currentTarget.value)}
            onChange={(e) => setSearch(e.currentTarget.value)}
            />
      
        </SearchContainer>
        <Button className='btn' onClick={() => navigate('/faq/fale-conosco')}  variant='primary' style={{width: '32%', padding: '12px 40px'}}><span><IconMsg/></span>Fale com a gente</Button>
      </HeaderContent>

      {filtrados.length > 0 ? <ListCategories>
        {
            filtrados.map((categorie:any) => {
              return <Accordion key={categorie.title} icon={categorie.icon} title={categorie.title} subtitle={categorie.subtitle}>
                {
                  categorie.questions.filter((el: any, index: number) => index < 3)
                  .map((q: any, index: any) =>  <CardList key={q} title={q} onClick={() => {setFAQSelected({...faqSelected, category: categorie.title, question: q}); navigate('help')}}/> ) 
                }
                {
                  categorie.questions.length > 3 && <SeeMore onClick={() => {  setFAQSelected({...faqSelected, category: categorie.title}) ;navigate(`ver-mais`)}}><SeeMoreLink>Ver mais</SeeMoreLink></SeeMore>
                }
              </Accordion>
            })
          }
      </ListCategories> : 
        
      <NoSearch>
        <NoSearchBg/>
        <div style={{width: '512px', marginTop: '28px'}}>
          <p style={{fontWeight: '600', lineHeight: '24px', color: 'rgba(0, 0, 0, 0.64)'}}>Não encontramos sua dúvida, mas você pode tentar procurar com outras palavras ou nos enviar uma mensagem</p>            
        </div>
      </NoSearch> 

        }
    </ScreenComponent>
  )
}

export default Faq