import React from 'react'
import * as S from './styles'
import { ReactComponent as ChatIcon } from '../../../../assets/mai-ic-chat.mono.svg';
import { ReactComponent as IconBack } from "../../../../assets/backIcon.svg";
import { ReactComponent as IconSend} from "../../../../assets/mai-ic-send.mono.svg";
import Icon from '../../../../components/Icon'
import Message from './Message';
import InputWithFile from 'components/InputWithFile';
import AddFilesInChat from './AddFilesInChat/index';
import SubmittedFile from './SubmitedFile';
import { postCreateChat, postSendExamFilesInsideChat, postSendFiles, postSendMessage, putReadMessage } from 'api/intancesAxios/chat';
import { CALL_ENPOINT } from 'api/Services/CallEndpoint';
import ShareExam from './ShareExam/ShareExam';
import { ExamProps } from 'pages/Exams/ListExams';
import { MessageProps, ParticipanteProps, ParticipanteWithMessagesProps, checkIfTheLastMessage, groupMessages, groupMessagesForDate } from 'utils/Chat';
import ContainerMessage from './ContainerMessage';
import { month} from 'utils/Date/DayOfWeek';
import SelectChat from 'components/SelectChat';
import useWindowDimensions from 'hooks/useWindowDimensions';
import {ChatProps, ParticipantsPropsInAttendance, Props, } from '../types'

const Chat = ({onCloseChat, publicId_attendance, participantsOnline}: Props) => {

 const [message, setMessage] = React.useState('')
 const [showModalToSendFile, setShowModalToSendFile] = React.useState<boolean>(false);
 const [crud, setCrud] = React.useState(false)
 
 //vetor arquivo
 const [filesChat, setFilesChat] = React.useState<File[]>([]);

 // participante 1-1 do chat, no futuro sera N2N
 const [participanteChatWithMessages, setParticipanteChatWithMessages] = React.useState<ParticipanteWithMessagesProps | null>(null)
 
 //data for Select
 const [participanteChatsDataSelect, setParticipanteChatsDataSelect] = React.useState<any[] | undefined>(undefined)
 const [participantsAllData, setParticipantsAllData] = React.useState<any[]>([])
 
 // mensagens do chat
 const [messages, setMessages] = React.useState<MessageProps[]>([])
 const [showModalExam, setShowModalExam] = React.useState<boolean>(false);
 //Selecao de atendimentos para compartilhar
//  const [appointmentsSelecteds, setAppointmentSelecteds] = React.useState<string[]>([])
 //Exams selecteds 
//  const [examsSelecteds, setExamsSelecteds] = React.useState<ExamProps[]>([])
 const [numberOfMessages, setNumberOfMessages] = React.useState(10)
 const [quantityMsgs, ] = React.useState(0)
 const [selectedParticipant, setSelectedParticipant] = React.useState("");
 //################## ABERTURA DE CHATS

 const getParticipants = async (idAtendimento: string) => {
  const token = localStorage.getItem("telehealth@token");
  if(token){
    try {
      const { url, options } = CALL_ENPOINT('TELEHEALTH_URL',`chat/${idAtendimento}/participants`,'GET', null, {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`
      });
   
      const response = await fetch(url, options)
      if(!response.ok){
        return null
      }
      const json: ParticipantsPropsInAttendance = await response.json()
      // pode ser que n tenha chat
      if(json.chats){
        setParticipantsAllData(json.chats)
        let arr = json.chats.map(p => {
          return {label: p.descricao}
        })
        setParticipanteChatsDataSelect([...arr])
      }
      return json

    } catch (error) {
      console.log("Deu erro na busca do chats", error);
    }
  }
}

 //################## ENVIO DE MENSAGENS
 const handleSubmitMessage = (e: any) => {
    e.preventDefault()
    if(!participanteChatWithMessages) return
    postSendMessage(participanteChatWithMessages.chat_id.toString(), {body: message, type: "text"})
    .then(json => {
      createChatOrRestoreChat(participanteChatWithMessages.chat_id.toString(), participanteChatWithMessages.requester_uuid)
      setMessage("")
    })
    .catch(e => console.log("erro no envio da msg ", e))
 }

 // obtem participantes 
 // pode criar chat novo
 // ou pegar as informacoes de um chat existente 
 const createChatOrRestoreChat =  (chat_id: string, participanteId: string | null) => {
  postCreateChat({chatId: chat_id, attendanceId: publicId_attendance, participanteId: participanteId,
    offset: 0, size: 10
    }).then(async (json: any) => {
      // type was ParticipanteWithMessagesProps
      if(json.messages){
        setMessages([...json.messages.content.reverse()])
        // lerMessagem(json.chat_id.toString())
        await lerMessagem(json.chat_id.toString(), json.messages.content[json.messages.content.length - 1].offset)
      }
      setParticipanteChatWithMessages(json)
    })
 } 

 //################## ENVIO DE ARQUIVOS
 const getPromises : any = () => {
  if(!participanteChatWithMessages?.chat_id) return
  let arr = filesChat.map(file => {
    const formData = new FormData()
    formData.append('file', file as any);
    return postSendFiles(participanteChatWithMessages?.chat_id.toString(), formData)
  })
  return arr
 }

 const shareFiles = async () => {
  if(!participanteChatWithMessages?.chat_id) return
  let promises = getPromises();
  Promise.all(promises).then(() => {
    setCrud(prev => !prev)
    setShowModalToSendFile(false)
    setFilesChat([])
  }).catch((e) => console.log("erro no envio do anexo"))
 }

 const obterMsgs = () => {
  getParticipants(publicId_attendance).then((participantes: any) => {
    //obtendo o medico participante
    console.log("participantes = ", participantes);
    let participante: ChatProps = participantes.chats[0]
    setSelectedParticipant(participante.descricao)
    if(!participante.chat_id){
      //chama o outro endpoint para iniciar o chat
      postCreateChat({chatId: null, attendanceId: publicId_attendance, participanteId: participante.participante_id,
      offset: 0, size: 10
      }).then((json:ParticipanteProps) => {
        createChatOrRestoreChat(json.chat_id.toString(), participante.participante_id)
      })
    
    }else{
      //senao ele tem um chatId e ele sera usado para enviar msgs e tudo mais. 
      createChatOrRestoreChat(participante.chat_id.toString(), participante.participante_id)
    }
  })
 }

 const lerMessagem = async (idChat: string, offset: number) => {
  await putReadMessage(idChat, offset)
}

 React.useEffect(() => {
  obterMsgs()
  let id = setInterval(() => {
    obterMsgs()
  }, 10 * 1000)
  return () => {
    clearTimeout(id)
  }
 }, [crud])


//envio de exames no chat
const handleShareExams = async (appointmentsSelecteds: any[]) => {
  // Nessa parte, o modal de compartilhar exames nao tem o arquivo em si, somente a key e nome
  let newArray = appointmentsSelecteds.map(item => {
    return {
        name: item.documentName,
        key: item.publicId
      }
    }
  )

 if(participanteChatWithMessages){
  await postSendExamFilesInsideChat(participanteChatWithMessages.chat_id.toString(), {
    attachmentList: newArray
  }).catch(e => console.log("arquivo ja foi compartilhado ou deu erro"))
 }

}

  


  //  Observer para scroll infinito 
  React.useEffect(() => {
    let intersectionObserver = new IntersectionObserver((entries) => {
      if(entries.some(entry => entry.isIntersecting)){ //se tocou 
        if(messages.length <= quantityMsgs){
          setNumberOfMessages((prev) => prev + 10)
        }
      }
    })
    let el = document.querySelector('.sentinela')
    if(el){
      intersectionObserver.observe(el)
    }
    
    return () => intersectionObserver.disconnect()
  }, [])

  const getDateFormated = (date: string) => {
    let arr = date.split("-") //dia mes ano
    return `${arr[0]} de ${month[parseInt(arr[1])-1]} de ${arr[2]}`
  }

  const getName = (msg: any) => {
    if(participantsAllData?.length){
      let person = participantsAllData?.filter(p => p.participante_id === msg.sender_uuid)
      if(person[0]){
        return person[0].descricao
      }else{
        return "Profissional"
      }
    }
    return "Profissional"
  }
  
  // code for responsive
  const { width } = useWindowDimensions();
  const [showLayoutSide, setShowLayoutSide] = React.useState("")
  const [mobileLayout, setMobileLayout] = React.useState(false)
  const [widthResponsive, setWidthResponsive] = React.useState("75%")

  React.useEffect(() => {
    setMobileLayout(true)
    setShowLayoutSide("right")
    if(width <= 400){
      setWidthResponsive("400px")
    }else if(width > 400 && width <= 1000){
      setWidthResponsive("690px")
    }else{
      setMobileLayout(false)
      setShowLayoutSide("both")
    }
  }, [width])


  return (
    <>
      <S.Container>
        {/* Header */}
        <S.Header>
          <S.Row onClick={onCloseChat} style={{cursor: 'pointer', marginBottom: '16px'}}>
            <Icon size={18} color="#FF0073" icon={<IconBack/>}/>
            <S.Text>Voltar</S.Text>
          </S.Row>
          <S.Row>
            <ChatIcon/>
            <S.Title>Mensagens</S.Title>
          </S.Row>
          <div>
            <S.ParticipantsTitle style={{whiteSpace: 'nowrap'}}>Você está conversando com:</S.ParticipantsTitle>
            <SelectChat placeholder={selectedParticipant}  selected={selectedParticipant} setSelected={() => console.log("")} 
              data={participanteChatsDataSelect}/>
          </div>
        </S.Header>
        {/* Fim Header */}
        
        <S.Content style={{
          height: (mobileLayout && showLayoutSide === 'right') ? widthResponsive : '75%',
        }}>
          <S.ContainerMessages
            style={{
            height: (mobileLayout && showLayoutSide === 'right') ? widthResponsive : '85%',
          }}
            className='box'>
            <div className="sentinela"></div>
            {
            (messages && participantsAllData.length) && messages.map((msg:MessageProps,index) => {
              // Dia da mensagem enviada
              return <div 
                key={`${msg.date_sended+msg.text_or_attachment_name+index}`} >
                {
                !groupMessagesForDate(messages, index)  && <S.ContainerDate>
                  <S.MsgDate><S.TextDate>{getDateFormated(msg.date_sended.split(" ")[0])}</S.TextDate></S.MsgDate>
                </S.ContainerDate>
                }

                <ContainerMessage
                  alignSelf={participanteChatWithMessages?.requester_uuid === msg.sender_uuid ? "flex-end" : "flex-start"}>
                  {msg.type === 'text' ?
                    <Message 
                      color={participanteChatWithMessages?.requester_uuid === msg.sender_uuid ? '#fff' : "rgba(0, 0, 0, 0.88)"} 
                      background={participanteChatWithMessages?.requester_uuid === msg.sender_uuid ? '#2B45D4' : "#fff"} 
                      emissor={!groupMessages(messages, index) ? 
                        msg.sender_uuid === participanteChatWithMessages?.requester_uuid 
                        ? 
                       "Você" 
                       : getName(msg) : ""} 
                      date={checkIfTheLastMessage(messages, index) ? msg.date_sended.split(" ")[1] : ""} 
                      msg={msg.text_or_attachment_name} 
                      />
                      :
                    <SubmittedFile 
                      msgStyle={{fontSize: '14px'}}
                      background={'#2b45D4'} date={checkIfTheLastMessage(messages, index) ? msg.date_sended.split(" ")[1] : ""} msg='1 arquivo adicionado' 
                      fileName={msg.text_or_attachment_name} color='#fff'/>}
                </ContainerMessage>
              </div>
            })
           }
          </S.ContainerMessages>
          <form onSubmit={(e) => handleSubmitMessage(e)}>
            <S.Footer>
              {/* Input */}
              <S.ContainerInput>
                <InputWithFile value={message} handleClickOnIcon={() => setShowModalToSendFile(true)} onChange={(e) => setMessage(e.target.value)} style={{margin: 0, height: '48px'}} placeholder='Digite sua mensagem'/>
              </S.ContainerInput>
              {/* Icon Send */}
              <S.ContainerIcon>
                <S.Button type='submit'  disabled={!message}>
                  <IconSend/>
                </S.Button>
              </S.ContainerIcon>
            </S.Footer>
          </form>
        </S.Content>
      </S.Container>
      {/* Modal Upload de arquivos */}
      <AddFilesInChat 
        setOpenAnotherModal={() => {
          setShowModalExam(true)
        }}
        shareFiles={shareFiles} 
        files={filesChat} setFiles={setFilesChat} 
        showModal={showModalToSendFile} setShowModal={setShowModalToSendFile} />
      <ShareExam handleShareExams={handleShareExams} showModal={showModalExam} setShowModal={setShowModalExam}/>
   
    </>
  )
}

export default Chat

