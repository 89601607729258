import React from 'react'
import * as S from './styles'
import { useNavigate } from 'react-router-dom'
import Button from 'components/Button/Button'
import LottieComponent from 'components/AnimationLottie/Lottie'
import Animation from '../../../assets/Animation/success-animation.json'

const Finaliza = () => {
  const navigate = useNavigate()
  
  return (
    <S.Wrapper>
      <S.Container>
        <S.AnimationBox>
          <LottieComponent width={128} height={128} animationData={Animation}/>
          <S.Title>
            Avaliação concluída!
          </S.Title>
        </S.AnimationBox>
        <S.Text>Agradecemos a avaliação! Suas respostas são muito importantes pra gente</S.Text>
        <Button
          variant="primary"
          onClick={() => {
          navigate('/')
        }}
          style={{ width: '100%', marginTop: '19px', padding: '12px 40px' }}
          >
          Voltar para o início
        </Button>
      </S.Container>
    </S.Wrapper>
  )
}

export default Finaliza