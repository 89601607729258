import { Container, Left, Right, Image, Content} from './styles'

type Props = {
    children: React.ReactNode;
    backgroundImage: any;
    changeOrientation?: boolean;
}
const Screen = ({ backgroundImage , changeOrientation=false, children}: Props) => {
  return (
    <Container>
      <Left hasOrderInvert={changeOrientation}>
        <Image icon={backgroundImage}/>
      </Left>
      <Right>
        <Content>
          {children}
        </Content>
      </Right>
    </Container>
  )
}

export default Screen