import React from 'react'
import * as S from './styles'

type Props = {
  children?: React.ReactNode,
  question: string
  response?: string 
  style?: React.CSSProperties
}
const Tupla = ({children, question, response, style}: Props) => {
  return (
    <S.Tupla style={style}>
      <S.Text>
        {question}
      </S.Text>
      {response ? <S.TextResponse>{response}</S.TextResponse> : children}
    </S.Tupla>
  )
}

export default Tupla