import React from 'react'
import Participant from '../Participant/Participant'
import * as S from './styles'
import { ReactComponent as UserIcon } from '../../../../assets/ic-user.svg';

type PropsVideo  = {
  renderRemoteParticipants: JSX.Element[]
  renderFormattedTimer: () => any
  participantData: any
  room: any
  iconCamState: boolean
  styleContainer?: React.CSSProperties 
}
const VideoSide = ({styleContainer,renderRemoteParticipants, renderFormattedTimer, participantData, room, iconCamState}: PropsVideo) => {
  return (
    <div style={styleContainer}>
      <S.container>
        <div className="InfoTop">
          <p>
            Consulta online em andamento
            <span>{renderFormattedTimer()}</span>
          </p>
        </div>
        <S.gradienteTop></S.gradienteTop>
        <S.gradienteBotton></S.gradienteBotton>

        <S.userIconContainer>
          <UserIcon />
        </S.userIconContainer>

        <S.info>
          <span>{participantData.professionalName}</span>
          {participantData.specialtyName}
        </S.info>

        <S.localParticipant>
          {room && !iconCamState ? (
            <Participant
              key={room.localParticipant.sid}
              participant={room.localParticipant}
                />
              ) : (
                <S.localMedia>
                  <UserIcon />
                </S.localMedia>
              )}
        </S.localParticipant>
      </S.container>
      {/* participantes remotos */}
      <S.remoteParticipant>
        {renderRemoteParticipants}
      </S.remoteParticipant>
    </div>
  )
}

export default VideoSide