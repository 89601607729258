import React from 'react'
import * as S from './styles'
import { ReactSVG } from "react-svg";
import IconFilter from "../../../assets/Filter.svg";
import InputSearch from "components/InputSearch";
import Button from '../../../components/Button/Button'
import CardExams from 'components/CardExams';
// import { LIST_ATTENDANCE } from 'api/Services/Attendance';
import Tag from 'components/Tag';
import Modal from 'components/Modal';
import SelectComponent from 'components/Select';
import Checkbox from 'components/Checkbox';
import Icon from '../../../assets/checkbox-checked.svg'

import File from '../../../components/File'
import { ReactComponent as IconSuccess } from '../../../assets/mai-ic-success.mono.svg'
import { ReactComponent as IconClose } from '../../../assets/ic-close.svg'
import IconLife from '../../../components/Icon/index'
import SearchFile from '../../Consultations/Conference/AddFiles/SearchFileInDispositive/index'

import AlertBanner from 'components/AlertBanner/AlertBanner';
import { CALL_ENPOINT } from 'api/Services/CallEndpoint';
import NoData from 'components/NoData/NoData';

//Animação
import LifeLottie from "pages/Home/HomeContent/LifeLottie";
import load from "../../../assets/Animation/load.json";
import { headerAppToken } from 'api/headers/HeaderAppToken';
import Button2 from '../../../components/Button/Button';
import ButtonOutlined from 'components/Button/ButtonOutlined';
import { monthNumber, months, years } from 'utils/Date/DayOfWeek';
import { SHARE_FILES_EXAMS } from 'api/Services/Attendance';
import AlertSelect from '../AlertSelect/index'
import { useConsultsContext } from 'contexts/ConsultsContext';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from 'contexts/UserContext';
import { GET_DATA_ACCOUNT } from 'api/Services/Profile';
import { deleteExams, getExams, postExam, putExam } from 'api/intancesAxios/docs';


export type ExamProps = {
  documentName: string, 
  publicId: string, 
  registerDate: string
}

type ModalExamPropsOnClick = {
  open: boolean
  exam: ExamProps | null
}


const ListExams = () => {

  const navigate = useNavigate()
  const [CRUD, setCRUD] = React.useState(false)

  const [publicId, setPulicId] = React.useState('')

  //Exames
  const [examsData, setExamsData] = React.useState<ExamProps[]>([]);

  //Operation in exam 
  const [modalDelete, setModalDelete] = React.useState<ModalExamPropsOnClick>({open: false, exam: null})
  const [modalRename, setModalRename] = React.useState<ModalExamPropsOnClick>({open: false, exam: null})
  const [fileRenamed, setFileRenamed] = React.useState({document_name: ''})
  
 
  const [loading, setLoading] = React.useState<boolean>(true);
  // const [currentPage, setCurrentPage] = React.useState(1)

  const [search, setSearch] = React.useState("");
  const [filter, setFilter] = React.useState('');

  
  
  //modais
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showModalExames, setShowModalExames ] = React.useState(false)
  
  // Filter
  const [selectedMonth, setSelectedMonth] = React.useState("");
  const [selectedYear, setSelectedYear] = React.useState("");
  const [checkboxSelecteds, setCheckbxSelecteds] = React.useState({adicionados: false, recebidos: false }) 
  const [appliedFilters, setAppliedFilters] = React.useState<string[]>()


  //Files
  const [files, setFiles] = React.useState<File[]>([]);
  const [file, setFile] = React.useState<File | null>(null);
  //arquivos para upload - Listagem
  const [, setFileNames] = React.useState<string[]>([])
  const [attachmentFiles, ] = React.useState<any[]>()
  // handle animation
  const [btnShareDisabled, setBtnShareDisabled] = React.useState(false)


  //parte de seleção
  const [hasExamselected, setHasExamSelected] = React.useState(false)
  const {examsSelecteds, setExamsSelecteds, alertOnAction, setAlertOnAction} = useConsultsContext()
  //forçar da desmarcacao do checkbox
  const [forceUnmark, setForceUnmark] = React.useState<boolean | null>(null)
  const [noData, setNoData] = React.useState(false)

  // Enpoints Exames ========================================
  async function obterExames(search: string, page: number, filter:any, publicId?:string) {
    setLoading(true)
    setNoData(false)
    getExams(search, publicId as string, filter).then((data) => {
      const {data : {fileDto}} = data
      // significa que é o array geral sem dados e SEM FILTRO
      if(!filter && fileDto.length === 0 && !search){
        setNoData(true)
        return
      }
      setExamsData(fileDto)
      return fileDto
    }).finally(() => {
      setLoading(false)
    })
  }
  async function removerExames(id: string){
    setLoading(true)
    deleteExams(id).then(async () => {
      setAlertOnAction({label: 'Arquivo excluído com sucesso', onEndTime: () => setAlertOnAction(null), seconds: 3, type: 'success'})
      setModalDelete({open: false, exam: null})
      setCRUD(prev => !prev)
    }).finally(() => {
      setLoading(false)
    })
  }

  async function uploadExam(file: any){
    postExam(file, publicId).then(() => {
      setAlertOnAction({label: 'Arquivo adicionado com sucesso', onEndTime: () => setAlertOnAction(null), seconds: 3, type: 'success'})
      setCRUD(prev => !prev)
    }).catch((err) => {
      console.log("Deu erro no upload", err)
      setAlertOnAction({label: 'Erro no envio do exame', onEndTime: () => setAlertOnAction(null), seconds: 3, type: 'danger'})
    })
  }

  async function atualizaExame(id: string){
    putExam(id, fileRenamed).then(() => {
      setCRUD(prev => !prev)
      setAlertOnAction({label: 'Arquivo renomeado!', onEndTime: () => setAlertOnAction(null), seconds: 3, type: 'success'})
      setModalRename({open: false, exam: null})
    }).catch(() => {
      setAlertOnAction({label: 'Erro na renomeação do exame', onEndTime: () => setAlertOnAction(null), seconds: 3, type: 'danger'})
    }).finally(() => {
      setLoading(false)
    })
  }
  
  const addFiles = (file: File) => {
    setFiles([...files, file])
  }
 const removeFile = (nome: string) => {
    setFiles(files.filter(item => item.name !== nome))
  }
  // ========================================================


  const verifyFilters = () => {
    if((!selectedMonth && !selectedYear) && (checkboxSelecteds.adicionados || checkboxSelecteds.recebidos) ){
      return false
    }
    if(!selectedYear || !selectedMonth){
      return true
    }
    //desabilita se n tiver nenhum marcado
    if(!selectedYear && !selectedMonth && !checkboxSelecteds.adicionados && !checkboxSelecteds.recebidos){
      return true 
    }
    return false
  }


  const checkSelectedFilters = () => {
    let arr: any[] = []
    if(selectedYear){
      arr.push({value: selectedYear, option: 'year'})
    }
    if(selectedMonth){
      arr.push({value: selectedMonth, option: 'month'})
    }
    if(checkboxSelecteds.adicionados){
      arr.push({value:"Adicionados", option: 'adicionados'})
    }

    if(checkboxSelecteds.recebidos){
      arr.push({value: "Recebidos", option: 'recebidos'})
    }
    return arr
  }

  const handleCloseTag = (tag: string) => {
    setAppliedFilters(appliedFilters?.filter((obj:any) => obj.value !== tag))
   
    if(tag === 'Adicionados'){
      setCheckbxSelecteds({...checkboxSelecteds, adicionados: false})
    }
    if(tag === 'Recebidos'){
      setCheckbxSelecteds({...checkboxSelecteds, recebidos: false})
    }
    //mes
    let arr = months.find(m => m.label === tag)
    if(arr){
      setSelectedMonth("")
      setFilter('')
    }

    //ano
    let arrY = years.find(m => m.label === tag)
    if(arrY) {
      setSelectedYear("")
      setFilter('')
    }
  }

  const mountFilter = (filter:any) => {
    let newFilter = filter.filter((el:any) => (el.option === 'year' || el.option === 'month'))
    
    let arrFilter = newFilter.map((el:any) => {
      if(el.option === 'month'){
        return `&${el.option}=${monthNumber[el.value]}`
      }
      return `&${el.option}=${el.value}`
    })
    setFilter(arrFilter.join(''))
  }

  const handleSubmitFiles = () => {
    setFileNames([]) //reseta o vetor de adicionados
    if (!files.length) return
    files.forEach(file => {

      const formData = new FormData()
      formData.append('name', file.name);
      formData.append('file', file as any);
      uploadExam(formData)
    })
    emptyDatas()
  }

  const emptyDatas = () => {
    setFiles([])
    setFileNames([])
    setShowModalExames(false)
  }

 
  
  const checkMarkers = (exam: ExamProps) => {
    if(examsSelecteds.length > 0){
      let res =   examsSelecteds.find(ex => exam.publicId === ex.publicId)
      if(res){
        return true
      }
    }
    return false
  }

  const downloadExam = () => {
    if(examsSelecteds.length === 0){
      setAlertOnAction({label: 'Nenhum arquivo selecionado', onEndTime: () => setAlertOnAction(null), seconds: 3, type: 'warning'})
       return
     }
  }
  const deleteExam = () => {
    if(examsSelecteds.length === 0){
      setAlertOnAction({label: 'Nenhum arquivo selecionado', onEndTime: () => setAlertOnAction(null), seconds: 3, type: 'warning'})
       return
     }
     let vetorExames = examsSelecteds.map(ex => ex.publicId)
     removerExames(vetorExames.join())
     setExamsSelecteds([])
  }

  // console.log(noData, examsData);

  React.useEffect(() => {
    // run initial data without filters
    const getPublicId = async() => {
      try {
        const { url, options } =  GET_DATA_ACCOUNT()
        const response = await fetch(url, options)
        const json = await response.json()
        if (response.ok) {
         setPulicId(json.publicId)
         obterExames(search, 1, filter, json.publicId)
        
        }
      } catch(err) {
       console.log(err)
      }
    }
    getPublicId()
  }, [search,filter, CRUD])

  React.useEffect(() => {
  }, [checkSelectedFilters])

  React.useEffect(() => {
    if (file) {
      addFiles(file)
    }
  }, [file])

  return (
    <S.Wrapper>
      {/* Alerta para operações no exame */}
      { alertOnAction && <AlertBanner
        label={alertOnAction.label}
        type={alertOnAction.type} 
        seconds={alertOnAction.seconds}
        onEndTime={() => { alertOnAction.onEndTime()}}
      />}
      {/* 
      =================================
      veio tudo ou veio algo do filtro 
      =================================
      */}
      { ((!loading && (examsData.length > 0 || filter || search.length > 0) && !noData)) &&
        <>
          <div className="HeaderContent">
            <h2>Exames</h2>
            <Button
              children={'Guardar exames'}
              variant={'primary'}
              onClick={()=> setShowModalExames(!showModalExames)}
        />
          </div>

          <div className="contentSearch">
            <div className="ContentInput">
              <InputSearch 
                placeholder={"Procurar"}
                backgroundColor="#FFCC00"
                color="#000"
                value={search}
                onChange={(e) => setSearch(e.currentTarget.value)}
          />
            </div>

            <div className="contentFilter" onClick={() => setShowModal(true)}>
              <ReactSVG src={IconFilter} />
              <span>Filtrar</span>
            </div>
          </div>
          {/* Filtros  */}
          {(appliedFilters && appliedFilters.length > 0) && 
          <S.FiltrosContainer> 
            {
          appliedFilters.map((obj:any) => <Tag key={obj.value} onClose={() => handleCloseTag(obj.value)} label={obj.value} backgroundColor="rgba(255, 176, 0, 0.16)"/>)
        }
          </S.FiltrosContainer>
        }

          {/* ============================================================= 
            ############################# ITENS SELECIONADOS #############################
          */}
          { (examsSelecteds.length > 0) && (
          <AlertSelect label={`${examsSelecteds.length} item selecionado`} 
            onClose={() => {
              setHasExamSelected(false)
              // quando clicar no x , desmarca tudo
              setForceUnmark(!forceUnmark)
              setExamsSelecteds([])
            }}
            onShare={() => navigate('compartilhar')}
            onDelete={deleteExam}
            download={downloadExam}
          />)}

          {/* Cards de exames */}
          <div className='ContentItem'>
            {
            (examsData.length > 0) ? examsData.map(exam => {
            return <CardExams
              exam={exam}
              marked={checkMarkers(exam)}
              forceUnmark={forceUnmark}
              examsSelecteds={examsSelecteds}
              setExamsSelecteds={setExamsSelecteds}
              select={hasExamselected}
              setSelect={setHasExamSelected}
              remove={() => setModalDelete({open: true, exam: exam})}
              rename={() => {setModalRename({open: true, exam: exam})
              setSearch('')
              }}
              id={exam.publicId}
              key={exam.publicId}
              archive={exam.documentName}
              laboratorio={''}
              date={exam.registerDate} 
          />})
          :
           ((filter && examsData.length === 0 )|| search) && <div style={{
            gridColumn: "1 / 4",
          }}>
             <p>
               Não encontramos nenhum resultado para esta busca. Que tal tentarmos de novo?
             </p>
           </div>
          }
          
          </div>
        </>

          }

      {
      !loading && noData && <NoData
        onClickFirstButton={()=> setShowModalExames(true)}
        textFirstButton="Guardar exames"
        showSecondButton={true}
        subDrescription={'Guarde seus exames antigos aqui e tenha acesso fácil a eles sempre que precisar!'}/>  
    }
      
    

      {
      loading && <S.Loading>
        <LifeLottie animationData={load} height={65} width={65} />
      </S.Loading> 
     }

      {/* MODAl ==================================== */}
      {/* Filtros */}
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(!showModal)}
        title="Filtrar por"
        style={{width: '464px', height: '464px', padding: '16px'}}
        titleStyle={{fontSize: '24px'}}
      >
        <S.ContainerSelects style={{marginTop: '24px'}}>
          <SelectComponent placeholder="Mês"  selected={selectedMonth} setSelected={setSelectedMonth} data={months}/>
          <SelectComponent placeholder="Ano" selected={selectedYear} setSelected={setSelectedYear} data={years}/>
          <p>Você pode selecionar mais de um item para a busca</p>
        </S.ContainerSelects>

        <S.ContainerCheckboxs>
          <Checkbox 
            borderColor={checkboxSelecteds.adicionados ? "#FF0073": 'rgba(0, 0, 0, 0.56)'} 
            iconColor="#FF0073" checked={checkboxSelecteds.adicionados} pathSvg={Icon} onChange={() => setCheckbxSelecteds({...checkboxSelecteds, adicionados: !checkboxSelecteds.adicionados})}  
            color={checkboxSelecteds.adicionados ? "#2B45D4": 'rgba(0, 0, 0, 0.56)'} label="Adicionados">Adicionados</Checkbox>
          <Checkbox 
            borderColor={checkboxSelecteds.recebidos ? "#FF0073": 'rgba(0, 0, 0, 0.56)'} 
            iconColor="#FF0073" checked={checkboxSelecteds.recebidos} pathSvg={Icon} onChange={() => setCheckbxSelecteds({...checkboxSelecteds, recebidos: !checkboxSelecteds.recebidos})} 
            color={checkboxSelecteds.recebidos? "#2B45D4": 'rgba(0, 0, 0, 0.56)'} label="Recebidos">Recebidos</Checkbox>
        </S.ContainerCheckboxs>
        
        <Button variant="primary" 
          disabled={verifyFilters()}
          onClick={() => {
          let arr= checkSelectedFilters()
          setAppliedFilters(arr)
          mountFilter(arr)
          setShowModal(false)
        }} style={{width: '100%', padding: '12px 24px'}}>Buscar</Button>
      </Modal>

      {/* Upload */}

      <Modal title={''} isOpen={showModalExames} style={{ padding: '24px', width: '376px' }} onClose={() => {
        setShowModalExames(false)
        setFileNames([])
      }}>
        {/* Lista de arquivos adicionados no atendimento */}
        {(attachmentFiles && attachmentFiles.length !== 0) && (
        <S.FileAddedWrapper >
          <div>
            <IconSuccess />
          </div>
          <div style={{width: '100%'}}>
            <S.FileFileAddedTitle>{attachmentFiles.length > 1 ? 
                `${attachmentFiles.length} arquivos adicionados` : `${attachmentFiles.length} arquivo adicionado` } 
            </S.FileFileAddedTitle>

            <S.ContainerFilesNames scroll={attachmentFiles.length > 3 ? true : false}>
              {
                    attachmentFiles.map((file:any) => {
                      return <S.FileFileAddedText key={file.id}>{file.name}</S.FileFileAddedText>
                    })
                }
            </S.ContainerFilesNames>
          </div>
        </S.FileAddedWrapper>
        )}
        {(attachmentFiles && attachmentFiles.length !== 0) && <S.Saparador/> }
        {/* Upload do 1 arquivo OU Lista de arquivos para upload  */}
        {(files && files.length === 0) ? 
          // Nao tem arquivos 
          <SearchFile showFooter={false} addFiles={addFiles} />
          // Fim do Nao ter arquivos
          : (
            <S.Content>
              <S.Title>{files.length > 1 ? `${files.length} arquivos adicionados!` : `${files.length} arquivo adicionado!`} </S.Title>
              <S.FilesContainer scroll={files.length > 3 ? true : false}>
                {
                  files.map((item, index) => {
                    return <S.FilesData key={index}>
                      <IconLife icon={<IconSuccess />} color="#2B45D4" />
                      <S.ContainerInfo>
                        <S.FileName>{item.name}</S.FileName>
                        <S.ProgressBar onAnimationStart={() => setBtnShareDisabled(true)} onAnimationEnd={() => setBtnShareDisabled(false)}></S.ProgressBar>
                      </S.ContainerInfo>
                      <IconLife icon={<IconClose />} onClick={() => removeFile(item.name)} color="red" style={{ position: 'absolute', top: 0, right: 0, cursor: 'pointer' }} />
                    </S.FilesData>
                  })
                }
              </S.FilesContainer>

              <Button disabled={(btnShareDisabled || loading)} onClick={handleSubmitFiles} variant='primary' style={{ width: '100%', padding: '12px 24px' }}>Compartilhar</Button>
              <File setFile={setFile} limitSize={0} />
            </S.Content>
          )
        }
      </Modal>
      {/* Fim UPLOAD ARQUIVOS */}

      {/* Modal Excluir */}
      <Modal title='Você quer excluir seu exame?' isOpen={modalDelete.open} onClose={() => setModalDelete({open: false, exam: null})}>
        <S.ContainerInsideModal>
          <S.TextP>Tem certeza que quer excluir o arquivo selecionado da sua lista de exames?</S.TextP>
          <S.ContainerButtons>
            <ButtonOutlined onClick={() => removerExames(modalDelete.exam?.publicId as string)}
              disabled={loading ? true: false}
              style={{width: '125px', padding: '12px 40px', borderColor: 'rgba(0, 0, 0, 0.56)', color: 'rgba(0, 0, 0, 0.56)'}}
              variant='outlined'>{loading ? 'Excluindo' : 'Excluir'}</ButtonOutlined>
            <Button2  style={{width: '194px', padding: '12px 40px'}} variant='primary'>
              <S.TextInsideButton onClick={() => setModalDelete({open: false, exam: null})}>Manter arquivo</S.TextInsideButton>
            </Button2>
          </S.ContainerButtons>
        </S.ContainerInsideModal>
      </Modal>

      {/* Modal Rename */}
      <Modal title='Renomear arquivo' isOpen={modalRename.open} onClose={() => {
        setFileRenamed({document_name: 'Digite o nome aqui'})
        setModalRename({open: false, exam: null})}} >
        <S.ContentTextModal>
          <div
            onBlur={e => setFileRenamed({document_name: e.target.innerText})}
            contentEditable
            suppressContentEditableWarning={true}
>
            <p>{modalRename.exam?.documentName}</p>
          </div>
   
          <span>-</span>
          <span>{modalRename.exam?.registerDate}</span>
        </S.ContentTextModal>

        <S.ContentBtn>
          <ButtonOutlined onClick={() => setModalRename({open: false, exam: null})} style={{width: '125px', padding: '12px 40px', borderColor: 'rgba(0, 0, 0, 0.56)', color: 'rgba(0, 0, 0, 0.56)'}} variant='outlined'>
            Cancelar
          </ButtonOutlined>
          <Button2 
            disabled={loading ? true: false}
            onClick={() => atualizaExame(modalRename.exam?.publicId as string)} style={{width: '194px', padding: '12px 40px'}} variant='primary'>
            <S.TextInsideButton >{loading ? 'Renomeando' : 'Renomear'}</S.TextInsideButton>
          </Button2>
        </S.ContentBtn>
      </Modal>

    </S.Wrapper>
  )
}

export default ListExams;