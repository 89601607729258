import * as S from "./styles";
import { ReactComponent as IconConsult } from "../../../assets/MakeConsult.svg";
import { Outlet } from "react-router-dom";

const ConsultUrgency = () => {
  return (
    <S.Wrapper>
      <S.FormWrapper>
        <Outlet />
      </S.FormWrapper>
      <S.ImageWrapper>
        <IconConsult />
      </S.ImageWrapper>
    </S.Wrapper>
  )
}

export default ConsultUrgency;