import styled from 'styled-components'

type hoverColor = {
  $hoverColor?: string | undefined;
  $boderColor?: string | undefined
}

export const Wrapper = styled.div<hoverColor>`

  max-width: 100%;

  display: flex;
  align-items: center;

  padding: 1rem;
  border-radius: 8px;
  border: 1px solid ${({$boderColor}) => $boderColor ? $boderColor : '#2B45D4'};
  

  font-size: 14px;
  line-height: 24px;
  font-weight: 400;

  color: rgba(0,0,0,0.88);
  background-color: #FFF;

  cursor: pointer;

  transition: 0.1s ease;
  box-sizing: content-box;

  :hover {
    background-color: ${({$hoverColor}) => !!$hoverColor ? $hoverColor : 'rgba(255,255,255,0.5)'};
  }
  
  .iconLeft{
    display: none;
    justify-content: flex-start;
    align-items: center;
    width: 24px;
    height: 24px;
  }

  .iconRight{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 24px;
    height: 24px;
  }
`

export const Content = styled.div`
   flex: 2;

   .subTitle {
     display: block;
     font-size: 13px;
   }
`