import styled from "styled-components";

export const Wrapper = styled.section`
  max-width: 367px;
  margin: 0 auto;
`;

export const ConsultDetails = styled.div`
  font-family: "Open Sans", sans-serif;
  background: #ffffff;
  border-radius: 8px;
  padding: 24px 24px 16px 24px;
  margin-bottom: 24px;
`;

export const TextTypeConsult = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
  margin: 24px 0 16px 0;

  strong {
    color: #000;
  }
`;

export const List = styled.li`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);
  margin-bottom: 8px;

  svg {
    margin-right: 18px;
  }
`;
