import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width:100% ;
  align-items: center;
  justify-content: space-between ;
  outline: 1px solid rgba(0, 0, 0, 0.16);
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden ;

  :focus-within{
    transition-delay: 0.1s;
    outline: 2px solid #2B45D4;
  }
`
export const Label = styled.label`
  position: relative ;
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.88);
  width: 100%;

  input {
    padding: 12px;
    margin-top: 16px;
    border: 0;
    box-sizing: border-box;
    font-size: 16px;
    height: 56px;
    outline: 0 ;

    ::placeholder{
        color: rgba(0, 0, 0, 0.56);
    }

    /* :focus{
        transition-delay: 0.1s;
        outline: 1px solid blue;

    } */
  }


`

export const ErrorMsg = styled.p`
  position: absolute ;
  font-size: 12px !important ;
  line-height: 16px !important ;
  bottom: -12px;
  left: 3px;
  color: #F44336 !important;
  margin-top: 4px ;
`