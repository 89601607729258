
// export function GET_NEXT_CONSULT(email: string, token: string) {
//   return {
//     url:
//       process.env.REACT_APP_ONBOARDING_URL + `api/agendamento?email=${email}`,
//     options: {
//       method: 'GET',
//       headers: {
//         'Content-Type': 'application/json',
//         'X-ILY-API-appKey': process.env.REACT_APP_HEADER_APPKEY as string,
//         'X-ILY-API-appToken': `Bearer ${token}`,
//       },
//     },
//   };
// }

import { ScheduleAppointment } from "api/types/Agendamento/ScheduleAppointment";

//listar consulta urgência especialidades
// export function GET_LIST_SPECEIALTY(token: string) {
//   return {
//     url: process.env.REACT_APP_API_URL_TELEHEALTH + `specialty`,
//     options: {
//       method: 'GET',
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     },
//   };
// }

export function POS_LOGIN_FILA(token: string, specialtyId: string) {
  return {
    url: process.env.REACT_APP_API_URL_TELEHEALTH + `queue`,
    options: {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body:JSON.stringify({specialtyId: specialtyId})
    },
  };
}

//check position
// export function GET_POSITION_IN_QUEUE() {
//   return {
//     url: process.env.REACT_APP_API_URL_TELEHEALTH + `queue/position`,
//     options: {
//       method: 'GET',
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
//       },
//     },
//   };
// }

//sair da fila
export function LEAVE_QUEUE(){
  return {
    url: process.env.REACT_APP_API_URL_TELEHEALTH + `queue/leave`,
    options: {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`
      },
    }
  }
}

//Validar número
export function POST_NUMBER(token: string, number: string) {
  return {
    url: process.env.REACT_APP_API_URL_TELEHEALTH + `insured/phone_number`,
    options: {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body:JSON.stringify({number: number})
    },
  };
}

//Entrar na fila de urgência
// export function POST_LOGIN_FILA( token: string) {
//   return {
//     url: process.env.REACT_APP_API_URL_TELEHEALTH + `queue`,
//     options: {
//       method: 'POST',
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//       // body: JSON.stringify(body)
//     },
//   };
// }

// export function GET_ATTENDANCES(tokenTelehealth: string) {
//   return {
//     url: process.env.REACT_APP_API_URL_TELEHEALTH + `attendance/incoming`,
//     options: {
//       method: 'GET',
//       headers: {
//         Authorization: `Bearer ${tokenTelehealth}`,
//       },
//     },
//   };
// }

//Lista dias disponiveis para a consulta da especialidade passada como paramentro
export function GET_DAY_FOR_SPECIALTYLIST(specialtyId: any, appointment: string) {
  const appointmentFormat = (appointment === 'Consulta de Retorno' ? 'RETURN_APPOINTMENT' : 'FIRST_APPOINTMENT')
  return {
    url:
      process.env.REACT_APP_API_URL_TELEHEALTH +
      `vacancy/days?specialtyId=${specialtyId}&schedulingType=${appointmentFormat}`,
    options: {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
      },
    },
  };
}

//Lista horas disponiveis para a consulta da especialidade passada como paramentro junto com a data
export function GET_HOUR_FOR_DAY_lIST(specialtyId: any, schedulingType: string, date: any,  ) {
  const schedulingTypeFormat = (schedulingType === 'Consulta de Retorno' ? 'RETURN_APPOINTMENT' : 'FIRST_APPOINTMENT')
  return {
    url:
      process.env.REACT_APP_API_URL_TELEHEALTH +
      `vacancy/day/hours?specialtyId=${specialtyId}&schedulingType=${schedulingTypeFormat}&date=${date}&fromNow=true`,
    options: {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
      },
    },
  };
}

//Confirmar agendamento de consulta eletiva
export function SAVE_SCHEDULE_APPOINTMENT(saveScheduleAppointment: any) {
  const { id, motivo } = saveScheduleAppointment
  const body = { 
    id,
    returnReason: motivo
  }
  return {
    url: process.env.REACT_APP_API_URL_TELEHEALTH + `attendance/self-schedule`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
      },
      body: JSON.stringify(body),
    },
  };
}

//cancelar agendamento 
export function CANCEL_APPOINTMENT(id: string | any ) {
  return {
    url: process.env.REACT_APP_API_URL_TELEHEALTH + `attendance/${id}/cancel`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
      },
    },
  };
}

//checar as informações do agendamento disponível
export function CHECK_APPOINTMENT_SCHEDULE(saveScheduleAppointment: ScheduleAppointment) {
  return {
    url: process.env.REACT_APP_API_URL_TELEHEALTH + `attendance/self-schedule-vacancy`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
      },
      body: JSON.stringify(saveScheduleAppointment),
    },
  };
}

//Checka se tem conuslta de retorno
export function CHECK_RETURN(insuredId: string | number, specialtyId:string) {
  return {
    url: process.env.REACT_APP_API_URL_TELEHEALTH + `attendance/check-return-right?insuredId=${insuredId}&specialtyId=${specialtyId}`,
    options: {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
      }   
    },
  };
}
