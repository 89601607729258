import styled from 'styled-components'

export const Container = styled.div`
  padding: 6px;
  background: #F6F6F9;
  margin-top: 24px ;
  display: flex;
  flex-direction: column ;
  gap: 8px;
  height: 78%;
  overflow-y: scroll ;

  &::-webkit-scrollbar {
      width: 8px;
      /* background-color: rgba(0, 0, 0, 0.15); */
    }
  
    &::-webkit-scrollbar-thumb {
      background: #2b45d4;
      border-radius: 8px;
    }

`