import { Button } from 'storybook-maida'
import * as S from './styles'
import React from 'react'
// import { GET_ADDRESS } from 'api/Services/Profile'
import { useHomeContext } from 'contexts/HomeContext';
import { CardAdressProps } from './types';
import { CALL_ENPOINT } from 'api/Services/CallEndpoint';
import { headerAppToken } from 'api/headers/HeaderAppToken';


const CardAdressAccount = ({ listAddress, setListAddress ,cep, logradouro, numero, complemento, bairro, estado, uf, id, enderecoPadrao, functionEdit}: CardAdressProps) => {
  const [ editar, setEditar ] = React.useState<boolean>(false)
  
  // const { setListAddress, listAddress } = useHomeContext()

  React.useEffect(()=> {
    functionEdit( editar, id )
  },[editar])

  async function excluir() {
    await deleteAddress()
    const result = await ListAddressRegistered()
    
    if(result !== undefined) {
      setListAddress(()=>[...result])
      return
    }
    setListAddress([])

  }

  //endpoint delete address
  const deleteAddress = async() => {
    try {
      // const { url, options } = DELETE_ADDRESS(id)
      const { url, options } = CALL_ENPOINT('ONBOARDING_URL',`api/clientes/${id}/enderecos`,'DELETE',null, headerAppToken(localStorage.getItem('lifeplace@token') as string))
      await fetch(url, options)
    } catch(err) {
      console.log(err)
    }
  }

  //endpoint lista endereços
  const ListAddressRegistered = async() => {
    try {
      // const { url, options } =  GET_ADDRESS()
      const { url, options } = CALL_ENPOINT('ONBOARDING_URL',`api/clientes/enderecos`,'GET',null, headerAppToken(localStorage.getItem('lifeplace@token') as string))

      const response = await fetch(url, options)
      const json = await response.json()
      
      if ( response.ok) {
        return json
      }
    } catch(err) {
      console.log(err)
    }
  }


  return (
    <S.Wrapper>
      <span className='id_adress'>{id}</span>
      {enderecoPadrao && <h2>Seu endereço padrão</h2>}
      <h3>{cep}</h3>
      <div className="info">
        <span>{logradouro}</span>
        <span>{complemento}</span>
        <span>{numero}</span>
        <span>{bairro}, {estado}, {uf}</span>
        <span>Brasil</span>
      </div>

      <div className="contentBtn">
        <div>
          <Button children={'Excluir'} variant={'outlined'} onClick={excluir} />
        </div>
        <div>
          <Button children={'Editar'} variant={'outlined'} onClick={()=> setEditar( prev => !prev )} />
        </div>
      </div>
    </S.Wrapper>
  )
}

export default CardAdressAccount