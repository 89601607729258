import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TrackReplyRounded from 'components/TrackReplyRounded';
import ButtonOutlined from 'components/Button/ButtonOutlined';
import AlertBanner from 'components/AlertBanner/AlertBanner';
import InputLifeplace from 'components/InputLifeplace';
import Participant from './Participant/Participant';
import Button2 from 'components/Button/Button';
import DropUp from 'components/DropUp';
import Modal from 'components/Modal';
import AlertMessage from 'components/AlertMessage/AlertMessage';

// import { HEALTH_INSURER } from 'api/Services/Attendance';
import Video from 'twilio-video';

import { useConsultsContext } from 'contexts/ConsultsContext';
import { useHomeContext } from 'contexts/HomeContext';
import { useRoom } from '../../../hooks/useRoom';
import AddFiles from './AddFiles';

import * as S from './styles';

import { ReactComponent as AttachmentIcon } from '../../../assets/mai-ic-attachment.mono.svg';
import { ReactComponent as UserPlusIcon } from '../../../assets/user-plus.svg';
import { ReactComponent as ChatIcon } from '../../../assets/mai-ic-chat.mono.svg';

import { formatDayWeek } from 'utils/formatDayWeek';
import { CALL_ENPOINT } from 'api/Services/CallEndpoint';
import Chat from './Chat/Chat';
import ShareExam from './ShareExam/ShareExam';
import { getParticipant } from 'api/intancesAxios/chat';
import useWindowDimensions from 'hooks/useWindowDimensions';
import VideoSide from './VideoSide';
import VideoSideN2N from './VideoSideN2N';

let dropUpData = [
  { icon: <ChatIcon />, description: 'Mensagens' },
  { icon: <AttachmentIcon />, description: 'Adicionar arquivos' },
  { icon: <UserPlusIcon />, description: 'Convidar pessoas' },
];


const Conference = () => {
  const navigate = useNavigate();
  const [disconnectedParticipant, setDisconnectedParticipant] = useState('');
  const [iconCamState, setIconCamState] = useState<boolean>(false);
  const [iconMicState, seticonMicState] = useState<boolean>(false);
  const [participants, setParticipants] = useState<any[]>([]);
  const [room, setRoom] = useState<Video.Room | null>(null);
  const [showChat, setShowChat] = useState(false)

  //dropUp
  const [dropUp, setDropUp] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);

  //clipboard
  const [modalClipboard, setModalClipboard] = useState<boolean>(false);
  const [clipboardBanner, setClipboardBanner] = useState<boolean>(false);
  const [clipboard, setClipboard] = useState<string>('');

  //AddFiles
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalExam, setShowModalExam] = useState<boolean>(false);
  
  //Timer
  const [timer, setTimer] = useState<number>(1);

  //Dados da consulta
  const [docData, setDocData] = useState({
    professionalName: '',
    specialtyName: '',
  });

  const { audioStream, videoStream, attendanceData } = useConsultsContext();
  const { noticeTele, setNoticeTele } = useHomeContext();

  const [attendanceId, setAttendanceId] = useState<number>(0);

  const { connect, disconnect } = useRoom({
    token: attendanceData.roomToken,
    roomName: attendanceData.roomName,
  });


  const containerRef = useRef<any>()

  //Faz a conexão com a sala usando o connect do useRoom
  useEffect(() => {
    connect()
      .then((room) => {
        setRoom(room);
        room.localParticipant.setNetworkQualityConfiguration({
          local: 2,
          remote: 1,
        });
        room.on('participantConnected', participantConnected);
        room.on('participantDisconnected', participantDisconnected);
        room.participants.forEach(participantConnected);

        room.once('disconnected', (room, error) => {
          if (error) {
            console.log('Disconnected ', error.code, error.message);
          }
          room.participants.forEach((participant: any) =>
            participantDisconnected(participant),
          );
        });
      })
      .catch((error) => {
        console.log(error);
      });

    //Adiciona o participante a um array de participantes
    const participantConnected = (participant: any) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    //Remove o participante de um array de participantes
    const participantDisconnected = (participant: any) => {
      setDisconnectedParticipant(participant.identity);
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant),
      );
    };

    return () => {
      disconnect();
    };
  }, []);

  //Verifica se o medico saiu da sala
  //Se sim, os paciente é desconectado e encaminhado para home
  useEffect(() => {
    const professional = attendanceData.attendance.professional.name;
    if (disconnectedParticipant === professional) {
      navigateToNextScreen();

    }
  }, [disconnectedParticipant]);

  //Seta os dados do professional e da consulta para ser exibido na tela
  useEffect(() => {
    setDocData({
      ...docData,
      professionalName: attendanceData.attendance.professional.name,
      specialtyName: attendanceData.attendance.specialty.name,
    });

    setAttendanceId(attendanceData.attendance.id);
  }, []);

  //Verifica se não tem mais participantes na sala
  useEffect(() => {
    if (room?.participants.size === 0) {
      navigateToNextScreen();

    }
  }, [participants]);

  //Cria o interval referente ao tempo de atendimento
  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, []);


  // console.log("Atendimento = ", attendanceData)

  //Renderiza o tempo de atendimento
  const renderFormattedTimer = () => {
    const min = Math.floor(timer / 60);
    const sec = timer % 60;
    return `${min > 9 ? min : `0${min}`}:${sec > 9 ? sec : `0${sec}`}`;
  };

  //Encerra atendimento e navega para proxima pagina
  const navigateToNextScreen = () => {
    
    try {
      setNoticeTele(null);
      // const { url, options } = END_ROOM(attendanceId);
      const { url, options } = CALL_ENPOINT('TELEHEALTH_URL',`attendance/${attendanceId}/stop`,'POST',null,{
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
      });

      fetch(url, options);
      room?.localParticipant?.tracks?.forEach((publication: any) => {
        const attachedElements = publication?.track?.detach();
        attachedElements.forEach((element: any) => element?.remove());
      });

      room?.localParticipant.tracks.forEach((trackPublication: any) => {
        trackPublication.track.stop();
      });

      stopTracks();
      room?.disconnect();
    } catch (err) {
      console.log(err);
    }
    // const { url, options } = HEALTH_INSURER();
    const { url, options } = CALL_ENPOINT('TELEHEALTH_URL','health_insurer','GET',null,{
      Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
    });

    fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.enabledPatientFeedback) {
          return navigate(`/avaliacao/${attendanceData.attendance.publicId}`);
        }
      });
  };

  //Encerra o audio e video do navegador
  const stopTracks = () => {
    audioStream?.getTracks().forEach((track: any) => {
      track.stop();
    });
    videoStream?.getTracks().forEach((track: any) => {
      track.stop();
    });
  };

  //Toggle para ligar e desligar camera
  const toggleShowCam = () => {
    setIconCamState(!iconCamState);

    if (iconCamState) {
      try {
        room?.localParticipant?.videoTracks?.forEach((publication) => {
          publication.track.enable();
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        room?.localParticipant?.videoTracks?.forEach((publication) => {
          publication.track.disable();
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  //Toggle para ligar e desligar microfone
  const toggleMic = () => {
    seticonMicState(!iconMicState);
    if (iconMicState) {
      try {
        room?.localParticipant?.audioTracks?.forEach((publication) => {
          publication.track.enable();
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        room?.localParticipant?.audioTracks?.forEach((publication) => {
          publication.track.disable();
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

 

  //Verifica a opção e faz a acção no menu
  const handleChoiceOptionMenu = (event: any) => {
    const optionSelected = event.target.innerText;
    const { metadata } = noticeTele;
    if (optionSelected === 'Convidar pessoas' && metadata) {
      setModalClipboard(true);
      setClipboard(
        `${process.env.REACT_APP_URL_LIFEPLACE}convite?invite=${metadata.inviteToken}&attendanceId=${metadata.id}`,
      );
    }

    if (optionSelected === 'Adicionar arquivos') {
      setShowModal(true);
    }

    if (optionSelected === 'Mensagens') {
      setShowChat(!showChat)
    }

    setDropUp(false);
    setClipboardBanner(false);
  };

  //Faz a ação de copiar texto formatado
  const copyToClipboard = () => {
    const { metadata } = noticeTele;
    const dateFormated = formatedDate(metadata?.createdAt);
    const text = `
    Esse é o convite para participar de uma consulta online!

    Quando: ${dateFormated}
    Especialidade: ${metadata?.specialty?.name}
    Paciente: ${metadata?.insured?.name}
    Link do atendimento: ${clipboard}`;
    navigator.clipboard.writeText(text);
    setClipboardBanner(true);
    setModalClipboard(false);
  };

  //Retorna a data formatada 
  const formatedDate = (date: string) => {
    const newDate = new Date(date);
    let formatedDate = formatDayWeek(
      `${newDate.getDay()}/${newDate.getMonth()}/${newDate.getUTCFullYear()}`,
    );
    formatedDate = formatedDate = `${formatedDate}, às ${newDate.getHours()}h${
      (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes()
    }`;

    return formatedDate[0].toUpperCase() + formatedDate.substr(1);
  };

  //fecha o dropUp
  const handleCloseDropUp = () => {
    setDropUp(false)
  } 

  //Desconeta paciente e vai para home 
  const leaveTheConference = () => {
    disconnect();
    navigate('/home');
  };

  const calculateIdealVideoSize = () => {
    let widthContainer = containerRef.current?.clientWidth
    let totalParticipants = participants.length + 1 
    let measure = ((widthContainer / totalParticipants) - 100)
    return {width:(measure * 1.77778)+'px', height: measure+'px'}
  }


  useEffect(()=>{
    window.addEventListener('resize', calculateIdealVideoSize);

    return () => {
      window.removeEventListener('resize', calculateIdealVideoSize);
    };
  },[])

  //Renderiza o parcipantes remotos
  const renderRemoteParticipants = participants.map((participant, index) =>
    participants.length <= 1 ? (
      <Participant
        styleVideo={
          participants.length <= 1
            ? {
                top: 0,
                position: 'absolute',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              }
            : {}
        }
        key={participant.sid}
        participant={participant}
        hasRemote={true}
      />
    ) : (
      <S.oneRemote key={participant.sid} style={calculateIdealVideoSize()}>
        <Participant
          style={{ minHeight: '100%' }}
          key={participant.sid}
          participant={participant}
          showInfo={true}
          
        />
      </S.oneRemote>
    ),
  );
  
  const [unreadMsg, setUnreadMsg] = useState("")

  // msgs nao lidas
  useEffect(() => {
    getParticipant("", "").then(json => {
      setUnreadMsg(json["unread_messages_total"])
    }).catch(e => console.log("erro ao obter unread-messages"))

    let id = setInterval(() => {
      getParticipant("", "").then(json => {
        setUnreadMsg(json["unread_messages_total"])
      }).catch(e => console.log("erro ao obter unread-messages"))
    }, 10 * 1000)

  return () => {
    clearTimeout(id)
  }
  }, [])

  // code for responsive
  const { width } = useWindowDimensions();
  const [showLayoutSide, setShowLayoutSide] = useState("")
  const [mobileLayout, setMobileLayout] = useState(false)

  useEffect(() => {
    if(width <= 950 && showChat){
      setMobileLayout(true)
      setShowLayoutSide("right")
    }else{
      setMobileLayout(false)
      setShowLayoutSide("both")
    }
  }, [width])


  return (
    <>
      {/* paciente e medico */}
      {participants.length <= 1 && (
        // se o chat open - screen video -> 70%
        //chat close - sv -> 100%
        //chat open e mobile => show only chat 
        <S.ContainerScreen>
          <VideoSide 
            room={room}
            participantData={docData}
            renderFormattedTimer={renderFormattedTimer}
            renderRemoteParticipants={renderRemoteParticipants}
            styleContainer={{width: showChat ? (mobileLayout && (showLayoutSide === 'left')) ? '100%' : '70%' : '100%', transition: "all 0.3s",
            display: ((mobileLayout && showLayoutSide === 'left') || showLayoutSide === "both") ? 'inline' : 'none'
             }}
            iconCamState={iconCamState}
          />
       
            {
              (showChat && noticeTele ) && <div 
                style={{
                  width: (mobileLayout && showLayoutSide === 'right') ? '100%' : '30%', backgroundColor: 'green', position: 'relative',
                  display: ((mobileLayout && showLayoutSide === 'right') || showLayoutSide === "both") ? 'inline' : 'none',
              }}>
                <Chat participantsOnline={participants} showChat={showChat} idAtendimento={noticeTele.metadata.id} 
                  publicId_attendance={noticeTele.metadata.publicId} 
                  onCloseChat={() => {
                  if(showLayoutSide === 'both'){
                    setShowChat(false)
                  }else{
                    setShowLayoutSide("left")
                  }
                }}/>
              </div>
            }

        </S.ContainerScreen>
      )}

      {/* N2N */}
      {participants.length > 1 && (
        <div style={{display: 'flex'}}>
          <VideoSideN2N 
            renderRemoteParticipants={renderRemoteParticipants}
            containerRef={containerRef}
            room={room}
            renderFormattedTimer={renderFormattedTimer}
            calculateIdealVideoSize={calculateIdealVideoSize}
            styleContainer={{width: showChat ? (mobileLayout && (showLayoutSide === 'left')) ? '100%' : '70%' : '100%', transition: "all 0.3s",
            display: ((mobileLayout && showLayoutSide === 'left') || showLayoutSide === "both") ? 'inline' : 'none'
             }}
            />
           

          {
                  (showChat && noticeTele ) && <div 
                    style={{
                    width: (mobileLayout && showLayoutSide === 'right') ? '100%' : '30%', backgroundColor: 'green', position: 'relative',
                    display: ((mobileLayout && showLayoutSide === 'right') || showLayoutSide === "both") ? 'inline' : 'none',
                }}>
                    <Chat participantsOnline={participants} idAtendimento={noticeTele.metadata.id} 
                      publicId_attendance={noticeTele.metadata.publicId}
                      onCloseChat={() => {
                      if(showLayoutSide === 'both'){
                        setShowChat(false)
                      }else{
                        setShowLayoutSide("left")
                      }
                    }}
                    />
                  </div>
            }
        </div>
      )}

      <S.trackButtons>
        { showLayoutSide !== 'right' &&
          <div className="wrapper-buttons">
            <TrackReplyRounded
              icon="mic"
              marked={iconMicState}
              onClick={toggleMic}
          ></TrackReplyRounded>
            <TrackReplyRounded
              icon="phone"
              onClick={() => setActive(true)}
          ></TrackReplyRounded>
            <TrackReplyRounded
              icon="cam"
              marked={iconCamState}
              onClick={toggleShowCam}
          ></TrackReplyRounded>

            {/* contador de msgs não lidas  */}
            <TrackReplyRounded
              icon="more"
              unreadMessage={unreadMsg ? unreadMsg : ""}
              onClick={() => setDropUp(!dropUp)}
          ></TrackReplyRounded>
          </div>}

        {dropUp && (
          <div className="dropUp">
            <DropUp
              unreadMsg={unreadMsg ? unreadMsg : ""}
              data={dropUpData}
              onClick={(event: any) => handleChoiceOptionMenu(event)}
              onClose={handleCloseDropUp}
              isOpen={dropUp}
            />
          </div>
        )}
      </S.trackButtons>

      {/* Modal de Sair da conferencia */}
      <Modal
        isOpen={active}
        onClose={() => setActive(!active)}
        title="Você quer mesmo sair da sala?"
        style={{width: '400px'}}
      >
        <S.description>
          Lembrando que sair da sala não encerra a consulta. Nossa equipe médica
          é quem encerra o seu atendimento
        </S.description>

        <S.containerBtns>
          <ButtonOutlined
            onClick={leaveTheConference}
            style={{
              padding: '12px 40px',
              color: 'rgba(0, 0, 0, 0.56)',
              borderColor: 'rgba(0, 0, 0, 0.56)',
              whiteSpace:'nowrap'
            }}
            variant="outlined"
          >
            Sair da sala
          </ButtonOutlined>
          <Button2
            onClick={() => setActive(false)}
            variant="primary"
            style={{
              padding: '12px 40px',
              marginLeft: '8px',
              backgroundColor: '#FFCC00',
              color: 'rgba(0, 0, 0, 0.88)',
              whiteSpace:'nowrap'
            }}
          >
            Ficar na sala
          </Button2>
        </S.containerBtns>
      </Modal>

      {/* Modal de convidar partipante */}
      <Modal
        style={{ maxWidth: '300px' }}
        isOpen={modalClipboard}
        onClose={() => setModalClipboard(!modalClipboard)}
        title="Convidar pessoas"
      >
        <S.containerModal>
          <p>
            Compartilhe o link da consulta com quem você quer que acompanhe esse
            atendimento:
          </p>
          <InputLifeplace label="" type="text" value={clipboard} disabled />
          <ButtonOutlined
            variant="outlined"
            style={{ width: '100%' }}
            onClick={() => copyToClipboard()}
          >
            Copiar link{' '}
          </ButtonOutlined>
        </S.containerModal>
      </Modal>

      {/* Banner de confirmação da copia do link de convite */}
      {clipboardBanner && (
        <S.banner>
          <AlertBanner
            label="Link do atendimento copiado!"
            type="success"
            seconds={3}
          ></AlertBanner>
        </S.banner>
      )}
     
      {/* falta só colocar no posicionamento certo */}
      {/* <S.banner>
        <AlertMessage doctorName='Dra. Lívia Komatsu' label={'Olá, Filipe. Nesse caso, é melhor agendar uma consulta de...'} seconds={10}/>
      </S.banner> */}
      

      {/* Modal Upload de arquivos */}
      <AddFiles setOpenAnotherModal={setShowModalExam} showModal={showModal} setShowModal={setShowModal} />
      { noticeTele && <ShareExam idAtendimento={noticeTele.metadata.id} showModal={showModalExam} setShowModal={setShowModalExam}/>}
    </>
  );
};

export default Conference;
