// TYPES ========================================

let variavel = {
  "API_URL": process.env.REACT_APP_API_URL,
  "ONBOARDING_URL": process.env.REACT_APP_ONBOARDING_URL,
  "TELEHEALTH_URL": process.env.REACT_APP_API_URL_TELEHEALTH,
  "GESTAO_DOC":  'https://gestao-documento-hmg.dokku.maida.health/'
}

// END TYPES =====================================

export function CALL_ENPOINT(variavelAmbiente: "API_URL" | "ONBOARDING_URL" | "TELEHEALTH_URL" | "GESTAO_DOC" , url: string, method: "GET"|"POST"|"PUT"|"DELETE", body: any, headers:any){
  return {
    url: variavel[variavelAmbiente] + url,
    options: {
      method: method,
      headers: headers,
      body: body ? JSON.stringify(body) : null
    }
  }
}
