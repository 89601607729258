import styled from 'styled-components'

export const Container = styled.div`
    height: 100vh ;
    display: flex;
    flex-direction: column ;
    background: #F6F6F9;
    align-items: center;

    gap: 20px ;
    padding-top: 16px ;

    @media (min-width: 768px){
      flex-direction: row ;
      padding-top: 0px ;
      gap: 0px;
    }
`
type PropsOrder = {
    hasOrderInvert: boolean
}
export const Left = styled.div<PropsOrder>`
    display: flex;
    order:  ${(props) => props.hasOrderInvert ? 1 : 0} ;
    flex: 1;
    justify-content: center ;
    align-items: center;
    /* outline: 1px solid red ; */
`
type Props = {
    icon: any
}
export const Image = styled.div<Props>`
    width: 400px ;
    height: 425px ;
    background: url(${(props) => props.icon});
`
export const Right = styled.div`
    height: 100% ;
    display: flex;
    justify-content: center ;
    align-items: center;
    flex: 1;
    /* outline: 1px solid cyan ; */

`
export const Content = styled.div`
    display: flex;
    flex-direction: column ;
    width: 400px ;
    padding-bottom: 16px ;

    @media (min-width: 768px){
        padding-bottom: 0px ;
    }
`
