import {Container, Content} from './styles'
import Header from "./Header";
import {Outlet} from 'react-router-dom'
const PrescriptionHistory = () => {
  return (
    <>
      <Container>
        <Content>
          <Header />
          <Outlet/>
        </Content>
      </Container>
    </>
  )
}

export default PrescriptionHistory