import { useUserContext } from "contexts/UserContext";
import { Navigate, Outlet } from "react-router-dom";

const LoginGuard = () => {
  const { login } = useUserContext();
 
  let auth;

  if(window.localStorage.getItem("lifeplace@token") || login){
    auth = true  
  }else{
    auth = false
  }
  
  return auth ? <Outlet /> : <Navigate to="/" />;
};

export default LoginGuard;
