import React from 'react'
import Modal from 'components/Modal'
import SearchFile from './SearchFileInDispositive'
import * as S from './styles'
import File from '../../../../components/File'
import { useConsultsContext } from 'contexts/ConsultsContext'
import Button from '../../../../components/Button/Button'
import { ReactComponent as IconSuccess } from '../../../../assets/mai-ic-success.mono.svg'
import { ReactComponent as IconClose } from '../../../../assets/ic-close.svg'
import IconLife from '../../../../components/Icon/index'
import { useHomeContext } from 'contexts/HomeContext'
import AlertBanner from 'components/AlertBanner/AlertBanner'
type Props = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenAnotherModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
const AddFiles = ({ showModal, setShowModal, setOpenAnotherModal }: Props) => {

  const { shareFile, errorHook, loading, getFiles} = useConsultsContext()
  const [attachmentFiles, setAttachmentFiles] = React.useState<any[]>()
  const { noticeTele } = useHomeContext()

  // vetor de arquivos
  const [files, setFiles] = React.useState<File[]>([]);
  // arquivo inserido por vez
  const [file, setFile] = React.useState<File | null>(null);

  // handle animation
  const [btnShareDisabled, setBtnShareDisabled] = React.useState(false)

  //arquivos para upload - Listagem
  const [filenames, setFileNames] = React.useState<string[]>([])

  const addFiles = (file: File) => {
    setFiles([...files, file])
  }


  React.useEffect(() => {
    if (file) {
      addFiles(file)
    }
  }, [file])

  const removeFile = (nome: string) => {
    setFiles(files.filter(item => item.name !== nome))
  }

  const handleSubmitFiles = () => {
    setFileNames([]) //reseta o vetor de adicionados
    if (!files.length) return

    files.forEach(file => {
      const formData = new FormData()
      formData.append('name', file.name);
      formData.append('file', file as any);
      if (noticeTele && noticeTele.typeAction === 'ATTENDANCE_IN_PROGRESS') {
        shareFile(noticeTele.metadata.id, formData).then((json: any) => {
          if (json !== null) {
            setFileNames([...filenames, json.name])
          }
        })
      }

    })

    emptyDatas()
    
    if(errorHook){
      setShowModal(false)
      return
    }else{
      setShowModal(true)
    }

  }

  const emptyDatas = () => {
    setFiles([])
    setFileNames([])
  }

  React.useEffect(() => {
    if(noticeTele){
      // getFiles(noticeTele.metadata.id)
      getFiles(noticeTele.metadata.id).then((r:any) => {
        setAttachmentFiles(r)
      })
    }
  }, [filenames])



  return (
    <>
      {/* Alerta de arquivos compartilhados  */}
      <div style={{position: 'absolute', left: 20, top: 20, zIndex: 7099, width: '90%'}}>
        {errorHook && <AlertBanner type='danger' label='Erro no envio de um ou mais arquivos' seconds={3}/>}
      </div>

      <div style={{position: 'absolute', left: 20, top: 20, zIndex: 7099, width: '90%'}}>
        {(filenames.length !== 0) && <AlertBanner type='success' label='Seus arquivos foram compartilhados' seconds={3}/>}
      </div>

      <Modal title={''} isOpen={showModal} style={{ padding: '16px' }} onClose={() => {
        setShowModal(false)
        setFileNames([])
      }}>
        {/* Lista de arquivos adicionados no atendimento */}
        {(attachmentFiles && attachmentFiles.length !== 0) && (
          <S.FileAddedWrapper >
            <div>
              <IconSuccess />
            </div>
            <div style={{width: '100%'}}>
              <S.FileFileAddedTitle>{attachmentFiles.length > 1 ? 
                `${attachmentFiles.length} arquivos adicionados` : `${attachmentFiles.length} arquivo adicionado` } 
              </S.FileFileAddedTitle>

              <S.ContainerFilesNames scroll={attachmentFiles.length > 3 ? true : false}>
                {
                    attachmentFiles.map((file:any) => {
                      return <S.FileFileAddedText key={file.id}>{file.name}</S.FileFileAddedText>
                    })
                }
              </S.ContainerFilesNames>
            </div>
          </S.FileAddedWrapper>
        )}
        {(attachmentFiles && attachmentFiles.length !== 0) && <S.Saparador/> }
        {/* Upload do 1 arquivo OU Lista de arquivos para upload  */}
        {(files && files.length === 0) ? 
          // Nao tem arquivos 
          <SearchFile setOpenAnotherModal={setOpenAnotherModal} addFiles={addFiles} />
          // Fim do Nao ter arquivos
          : (
            <S.Content>
              <S.Title>{files.length} arquivos adicionados!</S.Title>
              <S.FilesContainer scroll={files.length > 3 ? true : false}>
                {
                  files.map((item, index) => {
                    return <S.FilesData key={index}>
                      <IconLife icon={<IconSuccess />} color="#2B45D4" />
                      <S.ContainerInfo>
                        <S.FileName>{item.name}</S.FileName>
                        <S.ProgressBar onAnimationStart={() => setBtnShareDisabled(true)} onAnimationEnd={() => setBtnShareDisabled(false)}></S.ProgressBar>
                      </S.ContainerInfo>
                      <IconLife icon={<IconClose />} onClick={() => removeFile(item.name)} color="red" style={{ position: 'absolute', top: 0, right: 0, cursor: 'pointer' }} />
                    </S.FilesData>
                  })
                }
              </S.FilesContainer>

              <Button disabled={(btnShareDisabled || loading)} onClick={handleSubmitFiles} variant='primary' style={{ width: '100%', padding: '12px 24px' }}>Compartilhar</Button>
              <File setFile={setFile} limitSize={0} />
            </S.Content>
          )
        }
      </Modal>
    </>
  )
}

export default AddFiles