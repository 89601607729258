import { ReactComponent as IconClose} from '../../assets/ic-close-pink.svg' 
import React from 'react'
// import { POST_ADDRESS } from '../../api/Services/Profile';
import * as S from './styles'
import InputLife from 'components/InputLife'
import { Button } from 'storybook-maida'
import { ListAdressProps, useHomeContext } from '../../contexts/HomeContext'
import { CALL_ENPOINT } from 'api/Services/CallEndpoint';
import { headerAppToken } from 'api/headers/HeaderAppToken';
import InputNoLabel from 'components/InputNoLabel'


const FormAdress = ( {btnClose, idForm, saveNewAdress }: {btnClose:()=> void, idForm: string, saveNewAdress:(adress: ListAdressProps )=> void}) => {
  
  const [id, setId ] = React.useState("")
  const [ cep, setCep] = React.useState('')
  const [ logradouro, setLogradouro] = React.useState('')
  const [ numero, setNumero] = React.useState('')
  const [ complemento, setComplemento] = React.useState('')
  const [ bairro, setBairro] = React.useState('')
  const [ localidade, setLocalidade] = React.useState('')
  const [ uf, setUf] = React.useState('')
  const [ enderecoPadrao, setEnderecoPadrao ] = React.useState(false)
  const [error, setError] = React.useState(false)

  const { setAlertUpdateAccount } = useHomeContext()
  

  //armazena json da requisição da API via CEP
  const [ dataCep, setDataCep ] = React.useState<ListAdressProps>({} as ListAdressProps)

 //Salva os dados do formulário
 function handleSave(e: any){
  e.preventDefault()
  addAddress()
  //call the alert feedback
  setAlertUpdateAccount(true)
    setTimeout(()=>{
      setAlertUpdateAccount(false)
    },2000)
  
  btnClose()  
 }

 //valida CEP (máscara)
 const validaCep = (e: React.FormEvent<HTMLInputElement>) => {
    if(isNaN(parseInt(e.currentTarget.value))){ //isNotANumber
      setError(true)
      setCep('')
    }else{
      let value = e.currentTarget.value
      let cepMask = value.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2').replace(/(-\d{3})\d+?$/, '$1')
      setCep(cepMask)
      setError(false)
    }
 }

 //Busca o CEP digitado
 const buscaCep =  async(cep: string) => {
    const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
    const json = await response.json()
    setDataCep(json)
 }

 //Função usada para atualizar endereço
 const addAddress = async() => {
   try {
      // const { url, options } = POST_ADDRESS({cep, bairro, complemento, logradouro, uf, localidade, numero, enderecoPadrao})
      const { url, options } = CALL_ENPOINT('ONBOARDING_URL','api/clientes/enderecos','POST',{cep, bairro, complemento, logradouro, uf, localidade, numero, enderecoPadrao}, headerAppToken(localStorage.getItem('lifeplace@token') as string))
      
      const response = await fetch(url, options)
      const json = await response.json()
      
      if ( response.ok) {
        saveNewAdress(json)
        
      }
   } catch(err) {
    // console.log(err)
   }
 }

  //seta valores nos input's
  React.useEffect(()=>{
    if(dataCep.logradouro){
      setBairro(dataCep.bairro)
      setUf(dataCep.uf)
      setComplemento(dataCep.complemento)
      setLogradouro(dataCep.logradouro)
      setLocalidade(dataCep.localidade)
    }
  },[dataCep])

//só executa se digtar o CEP completo
 React.useEffect(()=>{
    if(cep.length < 9 ) return 
    buscaCep(cep)
 },[cep])
//  console.log(enderecoPadrao);
 
  return (
    <S.ContentForm >
      <div className='header'>
        <p>Adicionar endereço</p>
        <IconClose onClick={btnClose} />
      </div>
      <form onSubmit={handleSave}>
        <div className='group'>
          <InputNoLabel
            error={error ? "Somente números" : ""}
            placeholder ='CEP'
            borderColor={'#ddd'}
            borderColorOnFocus={'#ddd'}
            noLabel = {true}
            value={cep}
            onChange={validaCep}
          />
       
          <InputLife 
            placeholder ='Rua'
            borderColor={'#ddd'}
            borderColorOnFocus={'#ddd'}
            noLabel = {true}
            value={logradouro}
            onChange={(e)=> setLogradouro(e.target.value)}
          />
        </div>

        <div className="group-3">
          <InputLife 
            placeholder ='Número'
            borderColor={'#ddd'}
            borderColorOnFocus={'#ddd'}
            noLabel = {true}
            value={numero}
            onChange={(e)=> setNumero(e.target.value)}
          />

          <InputLife 
            placeholder ='Complemento'
            borderColor={'#ddd'}
            borderColorOnFocus={'#ddd'}
            noLabel = {true}
            value={complemento}
            onChange={(e)=> setComplemento(e.target.value)}
          />

          <InputLife 
            placeholder ='Bairro'
            borderColor={'#ddd'}
            borderColorOnFocus={'#ddd'}
            noLabel = {true}
            value={bairro}
            onChange={(e)=> setBairro(e.target.value)}
          />
        </div>

        <div className="group">
          <InputLife 
            placeholder ='Cidade'
            borderColor={'#ddd'}
            borderColorOnFocus={'#ddd'}
            noLabel = {true}
            value={localidade}
            onChange={(e)=> setLocalidade(e.target.value)}
          />

          <InputLife 
            placeholder ='Estado'
            borderColor={'#ddd'}
            borderColorOnFocus={'#ddd'}
            noLabel = {true}
            value={uf}
            onChange={(e)=> setUf(e.target.value)}
          />
        </div>

        <div className='contentBtn'>
          <div className="component-radio">
            <input onChange={() => setEnderecoPadrao(!enderecoPadrao)} checked={enderecoPadrao} type="checkbox" name="address" id="address"/>
            <label htmlFor="address"> Este é o meu endereço padrão</label>
          </div>
          <div>
            <Button 
              disabled={ cep ? false : true}  
              children={'Salvar'}
              style={{ 
                width: 'fit-content !important', 
              }}
            />
          </div>
        </div>
      </form>
    </S.ContentForm>
  )
}

export default FormAdress