import styled from "styled-components";

export const Wrapper = styled.section`
  width: 100%;
  max-width: 1366px;
  margin: 40px auto 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: start;
  

  @media (max-width: 630px) {
    margin: 24px 0;
    max-width: 400px  ;
  }
`;

export const FormWrapper = styled.section`
  display: flex;
  flex-direction: column;
  font-family: Open Sans, sans-serif;
  width: 100%;
  padding-left:5rem;

  

  @media (max-width: 1165px) {
    
    max-width: 400px;
  }

  @media (max-width: 410px) {
    padding-left:0;
  }
`;

export const ImageWrapper = styled.section`
 padding-right:5rem;
  @media (max-width: 1165px) {
    display: none;
  }
`;
