import styled, { keyframes } from "styled-components";

type bgColor = {
  type: string;
}
export const ContainerRelative = styled.div`
  position: relative;
`

export const close = keyframes`
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-150px);
  }
`;
export const open = keyframes`
  from {
    transform: translateY(-150px);
  }

  to {
    transform: translateY(0px);
  }
`;

// const fn = (s: string) => {
//     if(s === 'open'){
//         return open
//     }
//     return close
// }

export const Container = styled.div<bgColor>`
    display: flex;
    align-items: center;
    min-width: 100%;
    background-color: ${({type}) => (type === 'success') ? '#38B449' : '#F44336'};
    padding: 19px;
    border-radius: 4px;

    position: absolute;
    top: 20 ;
    z-index: 10;

`



export const Left = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 19px;
`
export const Right = styled.div`
    color: #fff
`