import Button2 from 'components/Button/Button'
import React from 'react'
import * as S from './styles'
import Card from './Card';
import { CALL_ENPOINT } from 'api/Services/CallEndpoint';
import { HeaderTelehealth2 } from 'api/headers/HeaderTeleHealth';
import { Attendance } from 'contexts/types/Attendance';
import {ReactComponent as Icon} from '../../../assets/shape3.svg'
import { useConsultsContext } from 'contexts/ConsultsContext';
import { ExamProps } from '../ListExams';
import AlertBanner from 'components/AlertBanner/AlertBanner';
import { useNavigate } from 'react-router-dom';
import Modal from 'components/Modal';
import ButtonOutlined from 'components/Button/ButtonOutlined';


type ModalExamPropsOnClick = {
  open: boolean
  exam: ExamProps | null
}


const ShareExam = () => {
  
  const navigate = useNavigate()
  const [modalDelete, setModalDelete] = React.useState<ModalExamPropsOnClick>({open: false, exam: null})
  const [attendances, setAttendances] = React.useState([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [, setError] = React.useState<boolean | null>(null)
  
  //Selecao de atendimentos para compartilhar
  const [appointmentsSelecteds, setAppointmentSelecteds] = React.useState<string[]>([])
  const {examsSelecteds, setExamsSelecteds, setAlertOnAction} = useConsultsContext()


  //Attendances
 const getNextAttendances = async () => {
  const tokenTele = window.localStorage.getItem('telehealth@token')
  if(tokenTele){
    const {url, options} = CALL_ENPOINT('TELEHEALTH_URL',`attendance/incoming`,'GET', null, HeaderTelehealth2(tokenTele));
    try {
      setError(false)
      setLoading(true)
      const response = await fetch(url, options)
    
      if(response.ok){
        const responseText = await response.text()
        if(responseText){
          const arr = JSON.parse(responseText)
          let newArr = arr.filter((att: Attendance) => att.type !== 'URGENCY')
          setAttendances(newArr)
        }
      }
    } catch (error) {
      console.log(error);
      setError(true)
    }finally{
      setLoading(false)
    }

  }
}

  const shareExams = async (idAttendance: string, arrayExms: any[]) => {
    const tokenTele = window.localStorage.getItem('telehealth@token')
    if(tokenTele){
      const {url, options} = CALL_ENPOINT('TELEHEALTH_URL',`attendance/${idAttendance}/attachment-form`,'POST', {attachmentList: arrayExms}, HeaderTelehealth2(tokenTele));
      try {
        setError(false)
        setLoading(true)
        const response = await fetch(url, options)
      
        if(response.ok){
         setAlertOnAction({label: 'Arquivos compartilhados!', onEndTime: () => setAlertOnAction(null), seconds: 3, type: 'success'})
        }
      } catch (error) {
        console.log(error);
        setError(true)
      }finally{
        setLoading(false)
      }
    }
  }

  const handleShareExams = (appointmentsSelecteds: any[]) => {
    let attachmentList = examsSelecteds.map((ex: ExamProps) => {
      return {name: ex.documentName, key: ex.publicId}
    })

    appointmentsSelecteds.forEach(appointmentId => {
      shareExams(appointmentId, attachmentList).then(r => {
        setAppointmentSelecteds([])
        setExamsSelecteds([])
        navigate('/exames')
      }).catch(e => console.log(e))
    })
  }

  React.useEffect(()=> {
    getNextAttendances()
  },[])

  return (
    <>
 
      <S.Wrapper>

        <S.Header>
          <S.Title>Compartilhar arquivo</S.Title>
          <S.Info>{attendances.length > 0 ? 'Com quais atendimentos e profissionais você quer compartilhar seus arquivos?' : 'Você não tem agendamentos disponíveis.'}</S.Info>
        </S.Header>
        <S.Body>
          {/* Cards */}
          <S.CardsContainer>
            { attendances.length > 0 && attendances.map((att: Attendance) => {
            return <Card id={att.id.toString()} appointmentsSelecteds={appointmentsSelecteds} setAppointmentsSelecteds={setAppointmentSelecteds} professional={att.professional.name} specialty={att.specialty.name} date={att.prettySchedulingDate} />
          })}
          </S.CardsContainer>
          {/* Count exams */}
          {appointmentsSelecteds.length > 0 && <S.NumberOfExams style={{width: '100%'}}>
            <Icon/>
            <S.Info style={{color: 'rgba(0, 0, 0, 0.64)', fontWeight: 600}}>{examsSelecteds.length > 1 ? `${examsSelecteds.length } arquivos serão compartilhados`: `${examsSelecteds.length} arquivo será compartilhado`} </S.Info>
          </S.NumberOfExams>}
          {/* Footer */}
          <S.Content>
            <Button2 disabled={loading || (attendances.length <= 0 || (appointmentsSelecteds.length <= 0))} 
              onClick={() =>  setModalDelete({open: true, exam: null})}
              style={{width: '366px'}} variant='primary'>Compartilhar</Button2>
          </S.Content>
        </S.Body>
      </S.Wrapper>

      {/* Modal Excluir */}
      <Modal title='Podemos confirmar o compartilhamento?' isOpen={modalDelete.open} onClose={() => setModalDelete({open: false, exam: null})}>
        <S.ContainerInsideModal style={{marginTop: '24px'}}>
          <S.TextP>Os arquivos selecionados ficarão salvos no prontuário deste atendimento, e somente os profissionais de saúde que te atenderem poderão visualizá-lo</S.TextP>
          <S.ContainerButtons>
            <ButtonOutlined
              onClick={() => setModalDelete({open: false, exam: null})}
              style={{width: '125px', padding: '12px 40px', borderColor: 'rgba(0, 0, 0, 0.56)', color: 'rgba(0, 0, 0, 0.56)'}}
              variant='outlined'>Cancelar</ButtonOutlined>
            <Button2 onClick={() => handleShareExams(appointmentsSelecteds)} 
              style={{width: '194px', padding: '12px 40px'}} variant='primary'>
              <S.TextInsideButton onClick={() => setModalDelete({open: false, exam: null})}>Confirmar</S.TextInsideButton>
            </Button2>
          </S.ContainerButtons>
        </S.ContainerInsideModal>
      </Modal>
    </>

  )
}

export default ShareExam