import * as React from 'react';

import Card from './Card/index'
import * as S from './styles'
import * as SCard from './Card/styles'

import { ReactComponent as IconQrCode } from '../.../../../../../assets/qrCodeIcon.svg'
import { ReactComponent as IconAvatar } from '../../../../assets/avatar.svg'
import { ReactComponent as IconCardioGram}  from '../../../../assets/ic-cardiogram-rounded.svg'
import { ReactComponent as IconDoc}  from '../../../../assets/ic-document.svg'
import { getPrescriptionDetails } from 'api/intancesAxios/onboardingURL'
import {useNavigate} from 'react-router-dom'
// import { useUserContext } from 'contexts/UserContext'
import { useParams } from 'react-router-dom';
import { CALL_ENPOINT } from 'api/Services/CallEndpoint';
import { headerAppToken } from 'api/headers/HeaderAppToken';
import { PrescriptionProps } from 'api/types/Prescricao/Prescription';
// import { GET_DATA_ACCOUNT } from 'api/Services/Profile';

const Prescription = () => {
  
  const navigate = useNavigate()
  
  const {id} = useParams()
  const [prescription, setPrescription] = React.useState<PrescriptionProps>({} as PrescriptionProps)

  const dataAccount = async() => {
    try {
      // const { url, options } =  GET_DATA_ACCOUNT()
      const { url, options } = CALL_ENPOINT('ONBOARDING_URL','api/clientes','GET',null, headerAppToken(localStorage.getItem('lifeplace@token') as string)) 

      const response = await fetch(url, options)
      const json = await response.json()
      if ( response.ok) {
        return json.cpf
      }
    } catch(err) {
     console.log(err)
    }
  }
  
  
  React.useEffect(() => {
    dataAccount().then(cpf => getPrescriptionDetails(cpf, id as string).then(p => {
      setPrescription(p)
    }) )
  }, [])

  // const routeChange = (path: string) =>{ 
  //   console.log(path)
  //   navigate(path);
  // }


  return (
    <S.Container>
      {/* Container Receita */}
      <S.PrescriptionContainer>
        <S.Left>
          {/* Lado esquerdo - Receita */}
          <S.ContainerTexts>
            <S.Title>Receita QKRTHD</S.Title>
            <S.Text>Emissão em 30/08/2021</S.Text>
          </S.ContainerTexts>
          <S.ContainerIcon onClick={() => navigate('qrcode')}>
            <IconQrCode/>
          </S.ContainerIcon>
        </S.Left>
        <S.Right>
          {/* Lado direito  - Info Médico */}
          <S.ContainerIcon>
            <IconAvatar/>
          </S.ContainerIcon>
          <S.ContainerTexts style={{marginLeft: '16px'}}>
            <S.Text style={{fontWeight: '600', color: 'rgba(0, 0, 0, 0.88)', fontSize: '14px'}}>Dr.{prescription.professional}</S.Text>
            <S.Text style={{fontSize: '14px'}}>{prescription.association} {prescription.associationNumber}</S.Text>
          </S.ContainerTexts>
        </S.Right>
      </S.PrescriptionContainer>

      {/* Card - Medicamento */}
      <SCard.Title style={{marginTop: '32px', marginBottom: '8px'}}>Medicamentos</SCard.Title>
      <Card 
        labelFirstButton='Comprar medicamentos'
        labelSecondBtn='Ver prescrição'
        title='Amoxil 100mg/mL, Pó para suspensão oral (1un de 150mL) GSK - Glaxosmithkline'
        subtitle='Amoxilina 100mg/mL'
        quantity='1 embalagem'
        recommendation='Diluir um pacote em 1 litro e tomar de 3 em 3 horas durante 7 dias'
        containerInsiderRow={false}
        linkSecondBtn={`https://airmed-dev-api.maida.health${prescription.medicinePrescribedPdf}`}

      />
      {/* Card Solicitação de Exames */}
      <SCard.Title style={{marginTop: '32px', marginBottom: '8px'}}>Solicitações de exames</SCard.Title>

      <S.ContainerDatas>
        { 
          prescription?.requestedExamDtos?.map(ex => {
          return <Card
            key={ex.tussCode}
            icon={<IconCardioGram/>}
            labelFirstButton='Ver solicitações de exames' 
            title={ex.description}
            subtitle={`TUSS ${ex.tussCode}`}
            quantity='0'
            containerInsiderRow={true}
            link={`https://airmed-dev-api.maida.health${prescription.requestedExamsPdf}`}
        />
        })
      }
      </S.ContainerDatas>


      {/* Card Relatórios e Atestados */}
      <SCard.Title style={{marginTop: '32px', marginBottom: '8px'}}>Relatórios e atestados</SCard.Title>
      <S.ContainerDatas>
        { 
          prescription?.generatedReportDtos?.map(pdf => {
          return <Card 
            key={pdf.reportPdf}
            icon={<IconDoc/>}
            showHeaders={false}
            labelFirstButton='Ver documento' 
            link={`https://airmed-dev-api.maida.health${pdf.reportPdf}`}
            quantity='0'
            recommendation={pdf.text}
            containerInsiderRow={true}
            />
          })
        }
      </S.ContainerDatas>
    </S.Container>
  )
}

export default Prescription