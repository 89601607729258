import * as S from '../styles'
import React from 'react'
import ButtonOutlined from 'components/Button/ButtonOutlined';
import { UserData } from '..';
import Tupla from '../Tupla';



type Props = {
  setEditar: React.Dispatch<React.SetStateAction<boolean>>
  healthData: UserData
}

export type Gender = "CISGENERO" | 'TRANSGENERO' | 'NAODECLARAR' | 'NAOBINARIO' | 'OUTRO' ;
export type Sex = "MASCULINO" | 'FEMININO' | 'INTERSEXO'
export type Disability = 'AUDITIVA' | 'MULTIPLA' | 'FISICA' | 'INTELECTUAL' | 'VISUAL' | 'OUTRA'

export let DisabilityObj = {
  AUDITIVA: 'Auditiva',
  MULTIPLA: 'Deficiência múltipla',
  FISICA: 'Física',
  INTELECTUAL:'Intelectual',
  VISUAL:'Visual',
  OUTRA:'Outra '
}

export let Genders =  {
  CISGENERO : 'Cisgênero',
  TRANSGENERO : 'Transgênero' ,
  NAODECLARAR : 'Não declarar',
  NAOBINARIO : 'Não binário',
  OUTRO : 'Outro'
}

export let SexObject = {
  MASCULINO: 'Masculino',
  FEMININO: 'Feminino',
  INTERSEXO: 'Intersexo',
  NAODECLARAR: 'Prefiro não declarar',
  OUTRO: 'outro'
}

const HealthDataDetails = ({setEditar, healthData}: Props) => {

 

  return (
    <>
      <S.Container style={{backgroundColor: '#fff', padding: '16px', flexDirection: 'column'}}>
        <S.Subtitle>Seus dados</S.Subtitle>
        <S.Flex>
          <S.Left>
            {/* Sexo designado */}
            <Tupla question='Sexo designado no nascimento' response={SexObject[healthData.sexo as Sex] || 'Não'}/>
          
            {/* Altura */}
            <Tupla question='Altura' response={healthData.altura + 'm' || 'Não'}/>
    
            {/* Medicação contínua */}
            <Tupla question='Você usa medicação contínua?' response={healthData.medicacao || 'Não'}/>
           
            {/* Doença Preexistente */}
            <Tupla question='Tem alguma doença preexistente?' response={healthData.doenca_pre || 'Não'}/>
           
          </S.Left>
          {/* Right =========================== */}
          <S.Right>
            {/* Sexo designado */}
            <Tupla question='Identidade de gênero' response={Genders[healthData.genero as Gender] || 'Outro'}/>
            {/* Peso */}
            <Tupla question='Peso' response={healthData.peso + 'kg' || 'Não'}/>
            {/* Deficiencia */}
            <Tupla question='Você tem alguma deficiência?' response={DisabilityObj[healthData.deficiencia as Disability] || 'Não'}/>
            {/* Alergia */}
            <Tupla question='Você tem alguma Alergia?' response={healthData.alergia || 'Não'}/>
          </S.Right>
        </S.Flex>
        <S.ContainerRadios style={{display: 'flex', justifyContent: 'flex-end'}}>
          <ButtonOutlined  variant='outlined' style={{border: 0, color: '#FF0073'}} onClick={() => setEditar(true)}>
            Editar
          </ButtonOutlined>
        </S.ContainerRadios>
      </S.Container>
    </>
  )
}

export default HealthDataDetails