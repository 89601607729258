import styled from 'styled-components'

export const Container = styled.div`
  background: var(--background);
  width: 100%;
  height: 100vh;
`

export const Content = styled.div`
  max-width: 1366px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 5rem;

  header {
    margin-top: 45px;
  }

  .main {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .content-image {
      margin-left: 156px;
    }

    .information {
      height: 351px;
      max-width: 328px;
      display: flex;
      flex-direction: column;
      margin-right: 165px;

      h1 {
        font-size: 1.5rem;
        line-height: 2.5rem;
        font-weight: 600;
        color: var(--primary);
      }

      p {
        font-size: 0.875rem;
        font-weight: normal;
        color: var(--life-neutral-black-64);
        margin: 2rem 0;
      }

      .btn-login {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border: 1px solid var(--life-neutral-black-16);
        box-sizing: border-box;
        width: 328px;
        padding: 12px 24px;
        border-radius: 8px;
        margin-bottom: 1rem;
        cursor: pointer;

        font-size: 1rem;
        color: var(--life-neutral-black-64);
        font-weight: 600;

        img {
          margin-right: 1rem;
        }
      }
    }
  }

  @media (max-width: 1160px) {
    padding: 0 14px;

    header {
      margin-top: 24px;
      margin-bottom: 6px;
    }
    .main {
      flex-direction: column;
      justify-content: center;

      .content-image {
        margin: 0px;
        margin-bottom: 36px;
        img {
          height: 160px;
        }
      }

      .information {
        text-align: center;
        margin: 0px;


        h1 {
          font-weight: bold;
          font-size: 1.125rem;
          line-height: 1.5rem;
          color: var(--primary-dark);
        }
      }
    }
  }
`

export const ContentBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;


  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: start;
  }
`

export const ContentHeader = styled.div`
  max-width: 1366px;
  background: yellow;
`

type OrderProps = {
  ordem: boolean,
}

export const BoxLeft = styled.div<OrderProps>`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  order: ${(props)=>props.ordem? 2 : 1};

  @media (max-width: 900px) {
    margin-top: 3.3rem;
    margin-bottom: 2rem;
    height: auto;

    img {
      height: 215px;
    }
  }
`
export const BoxRight = styled.div<OrderProps>`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  order: ${(props)=>props.ordem? 1 : 2};
  height: 100%;

  font-size: 1.5rem;
  font-weight: 600;

  @media (max-width: 800px) {
    height: auto;
    width: 100%;
  }
`
