import axios from 'axios'

export const axiosTele = axios.create({
  baseURL: process.env.REACT_APP_API_URL_TELEHEALTH,

  headers: {
    'Content-Type': 'application/json',
  }
})

axiosTele.interceptors.request.use(
  (config) => {

    if(config.headers){
      config.headers.authorization = `Bearer ${process.env.REACT_APP_API_BEARER}`  
    }
    return config
  },
  (error) => Promise.reject(error)
)



async function getHoursServer() {
  const response = await axiosTele.get(`/user/hello`)
  return response.data
}

async function putPatientOnline(id: string){
  const response = await axiosTele.put(`client/attendance/${id}/patient-online`)
  return response.data
}

async function getAuthInTelehealth(token: string){
  const response = await axiosTele.get(`auth/${token}`)
  return response.data
}

async function getPositionInQueue(){
  const response = await axiosTele.get(`queue/position`)
  return response.data
}



export { getHoursServer, putPatientOnline, getAuthInTelehealth, getPositionInQueue}
