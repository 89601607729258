import styled from 'styled-components';

export const Content = styled.div`
/* create overlay */
  #overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: -80vh; 
    left: -50vw;
    right: 0;
    bottom: 0;
    z-index: -1;
    
    background-color: transparent;

    &.active {
      opacity: 1;
      pointer-events: all;
    }

    @media (max-width: 500px){
      left: -30vw;
    }
  }
`

export const container = styled.div`
  background-color: #fff;
  padding: 8px 0;
  
  min-width: 240px;
  border-radius: 8px;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between ;
    padding: 12px 16px;
    cursor: pointer;

    :hover{
      background-color: rgba(0,0,0,0.05);
    }

    p {
      margin-left: 1rem;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.56);
    }
  }
`;

export const Count = styled.div`
  width: 19px ;
  height: 19px ;
  border-radius: 50% ;
  background: #2B45D4;
  font-size: 14px !important ;
  margin-left: 16px ;
  color: #fff;
  display: flex;
  justify-content: center ;
  align-items: center ;
`