import { useState, useEffect, useRef } from 'react';
import {
  AudioTrack,
  AudioTrackPublication,
  Participant,
  Track,
  VideoTrack,
  VideoTrackPublication,
} from 'twilio-video';
import * as S from './styles';

import InfoIcon from '../InfoIcons';
import { ReactComponent as UserIcon } from '../../../../assets/ic-user.svg';

interface ParticipantProps {
  participant: Participant;
  style?: any;
  styleVideo?: any;
  showInfo?: boolean;
  hasRemote?: boolean;
}

const Participante = ({
  participant,
  style,
  styleVideo,
  showInfo = false,
  hasRemote = false,
}: ParticipantProps) => {
  const [videoTracks, setVideoTracks] = useState<any>([]);
  const [audioTracks, setAudioTracks] = useState<any>([]);
  const [showVideo, setShowVideo] = useState<boolean>(true);
  const [isRemoteCameraDisabledFromLocal, setIsRemoteCameraDisabledFromLocal] =
    useState(true);
  const [isRemoteMicDisabledFromLocal, setIsRemoteMicDisabledFromLocal] =
    useState(true);

  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  const trackpubsToTracks = (
    trackMap: Map<Track.SID, VideoTrackPublication | AudioTrackPublication>,
  ) => {
    return Array.from(trackMap.values())
      .map((publication: any) => publication.track)
      .filter((track) => track !== null);
  };

  const setIconAudioOnInit = () => {
    const audio = participant.audioTracks;

    audio.forEach((trackAudio: any) => {
      setIsRemoteMicDisabledFromLocal(trackAudio.isTrackEnabled);
    });
  };

  const setIconVideoOnInit = () => {
    const video = participant.videoTracks;

    video.forEach((trackVideo: any) => {
      setIsRemoteCameraDisabledFromLocal(trackVideo.isTrackEnabled);
    });
  };

  useEffect(() => {
    const trackSubscribed = (track: any) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks: any) => [...videoTracks, track]);
      } else {
        setAudioTracks((audioTracks: any) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track: VideoTrack | AudioTrack) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks: any) =>
          videoTracks.filter((v: any) => v !== track),
        );
      } else {
        setAudioTracks((audioTracks: any) =>
          audioTracks.filter((a: any) => a !== track),
        );
      }
    };

    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    participant.on('trackEnabled', (e) => {
      if (e.kind === 'video') {
        setIsRemoteCameraDisabledFromLocal(true);
        setShowVideo(true);
        // trackSubscribed(e);
      } else {
        setIsRemoteMicDisabledFromLocal(true);
      }
    });
    participant.on('trackDisabled', (e) => {
      if (e.kind === 'video') {
        setIsRemoteCameraDisabledFromLocal(false);
        setShowVideo(false);
        // trackSubscribed(e);
      } else {
        setIsRemoteMicDisabledFromLocal(false);
      }
    });

    setIconAudioOnInit();
    setIconVideoOnInit();
    return () => {
      participant.off('trackSubscribed', trackSubscribed);
      participant.off('trackUnsubscribed', trackUnsubscribed);
      setVideoTracks([]);
      setAudioTracks([]);
    };
  }, [participant]);

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack?.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);

  return (
    <S.container
      style={style}
      $type={hasRemote && !isRemoteCameraDisabledFromLocal}
    >
      <div className="info">
        {showInfo && (
          <InfoIcon
            cam={isRemoteCameraDisabledFromLocal}
            mic={isRemoteMicDisabledFromLocal}
            signal={participant?.networkQualityLevel}
          />
        )}
      </div>

      <S.userName>
        {showInfo && (
          <div className="bagdeName">
            <p>{participant?.identity}</p>
          </div>
        )}
      </S.userName>
      {!isRemoteCameraDisabledFromLocal && (
        <S.videoOff>
          <UserIcon />
        </S.videoOff>
      )}
      <video
        className="video"
        style={styleVideo}
        ref={videoRef}
        autoPlay={true}
        width="100%"
      />
      <audio ref={audioRef} autoPlay={true} muted={false} />
    
    </S.container>
     
  );
};

export default Participante;
