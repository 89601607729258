import styled from 'styled-components'

export const Container = styled.div``

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column ;
  gap: 8px;
  padding: 16px;
  background: #2B45D4;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 16px 16px 0px 16px;
`

export const Row = styled.div`
  display: flex;
  gap: 11px;
`

export const Filename = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
`

export const Message = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #FFFFFF;
`

export const Data = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end ;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.56);
  
`