import React from 'react';
import { Header, Title, SubTitle, ContainerInputs, Footer } from './styles'
import Button from '../../../../components/Button/Button'
import InputLife from 'components/InputLife'
import {ReactComponent as IconUser} from '../../../../assets/mai-ic-user.mono.svg'
import {ReactComponent as IconDoc} from '../../../../assets/mai-ic-card-id.mono.svg'
import Screen from './Screen'
import Background from '../../../../assets/Backgrounds/social-feed-colour.svg'
// import { GET_AUTH_GUEST, POST_AUTH_GUEST } from 'api/Services/GuestAuth';
import { mask } from 'utils/maskForInput';
import useQuery from 'hooks/useQuery';
import { useConsultsContext } from 'contexts/ConsultsContext';
import { useNavigate } from 'react-router-dom';
import { CALL_ENPOINT } from 'api/Services/CallEndpoint';


const AuthGuest = () => {

  const navigate = useNavigate()
  const {setGuestData} = useConsultsContext()
  const [name, setName] = React.useState('')
  const [cpf, setCPF] = React.useState('')
  const [loading, setLoading] = React.useState(true)

  const query = useQuery()

  // Parameters for 
  const inviteId = query.get("invite")
  const idAttendance = query.get("attendanceId")


  const auth = async () => {
    
    if(!inviteId || !idAttendance) return 

    let cpfTratado = cpf.replaceAll(".","").replace("-","")
    
    try {
      // const {url, options} = POST_AUTH_GUEST({id: idAttendance, inviteCode: inviteId}, {name, cpf: cpfTratado})
      const {url, options} = CALL_ENPOINT('TELEHEALTH_URL',`attendance/${idAttendance}/invite/${inviteId}`,'POST',{name, cpf: cpfTratado}, {
        'Content-Type': 'application/json',
      })

      const response = await fetch(url, options)
      const json = await response.json()

      if(!response.ok) return
      window.localStorage.setItem("telehealth@token", json.tokenData.token);
      setGuestData(json)
      navigate('conferencia')
      

    } catch (error) {
      console.log("deu erro", error);
    }
  }

  const checkAttendance = async () => {
    try {
      const {url, options} = CALL_ENPOINT('TELEHEALTH_URL',`attendance/${idAttendance}/invite/${inviteId}`,'GET', null, {
        'Content-Type': 'application/json',
      })
      const response = await fetch(url, options)
      if(!response.ok){
        return navigate('/atendimento-encerrado')
      }
      
    } catch (error) {
      console.log("deu erro", error);
    } finally{
      setLoading(false)
    }
  }

  function handleChangeCPF(event: React.FocusEvent<HTMLInputElement, Element>) {
    const { value } = event.target
    setCPF(mask(value))
  }

  React.useEffect(() => {
    checkAttendance()
  },[])

  if(loading){
    return <h1>Carregando</h1>
  }
  
  return (
    <Screen backgroundImage={Background} changeOrientation={false}>
      <Header>
        <Title>Entrar como acompanhante</Title>
        <SubTitle>Preencha os dados abaixo para acessar a consulta online:</SubTitle>
      </Header>
      <ContainerInputs>
        <InputLife borderColorOnFocus='blue' borderColor='#ddd' value={name} onChange={e => setName(e.target.value)} placeholder='Nome' iconSize={"24px"} iconLeft={<IconUser/>}/>
        <InputLife borderColorOnFocus="blue" borderColor="#ddd" value={cpf} maxLength={14} onChange={handleChangeCPF} placeholder='CPF' iconSize={"24px"} iconLeft={<IconDoc/>}/>
      </ContainerInputs>
      <Footer>
        <Button variant='primary' onClick={() => auth()} style={{width: '100%', padding: '12px 24px'}}>Entrar na sala</Button>
      </Footer>
    </Screen>
  )
}

export default AuthGuest