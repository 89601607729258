import styled from 'styled-components';

export const Header = styled.div`
  padding: 40px 67px; ;

  
  @media (max-width: 640px) {
    padding: 2rem;
  }
`;

export const Wrapper = styled.section`
  max-width: 605px;
  margin: 0 auto;

  @media (max-width: 640px) {
    padding: 1rem;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  line-height: 40px;
  font-weight: 600;
  color: var(--primary-dark);
  margin-bottom: 32px;

`;

export const Description = styled.div`
  margin-bottom: 32px;

  p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: var(--life-neutral-black-56);
  }
`;

export const RadioContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  margin-bottom: 16px;
`;
