import styled from "styled-components";

export const Content = styled.section`
    display: flex;
    justify-content: center;
    padding-top: 107px;
    width: 100%;
    height: 100%;
`
export const ContainerInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-width: 300px;
    /* outline: 1px solid green; */

`
export const QrcodeContainer = styled.div`
    display: flex;
    justify-content: center;
`
export const Info = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export const CodeText = styled.h2``
export const ContainerOthersInfos = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
`

export const Text = styled.p`
    font-size: 14px;
    margin-left: 19px;
`

export const Link = styled.a`
    font-size: 14px;
    text-align: center;
`

export const ContainerRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`