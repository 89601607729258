import styled from 'styled-components'


export const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;

  .id_adress {
    display: none;
  }

  h2 {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.88);
  }

  h3 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.88);
  }

  .info {
    display: flex;
    flex-direction: column;
    margin-top: 5px;

    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.56);
  }

  .contentBtn {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    div {
      width: 180px;
      
      button {
        color: #2B45D4;
      }
    }
  }

  @media (max-width: 600px) {
    .contentBtn {
      > div {
        width: 100%;
      }
    }
  }
`