import React from 'react'
import * as S from './styles'
const ContentInfo = () => {
  return (
    <S.Content className="content-info">
      <S.Info className='info'>
        <p className='title'>Aí vão algumas dicas para a sua consulta online:</p>
        <p>
          <strong>Não perca seu horário!</strong> <br />
          Programe-se para entrar na sala do atendimento 5 minutos antes do horário agendado. O link da consulta fica disponível na sua área logada, no site ou aplicativo, mas nós também o enviaremos via SMS. Se não puder comparecer, reagende ou cancele o atendimento;
        </p>
        <p>
          <strong>Fique bem no vídeo!</strong> <br />
          Antes de entrar na consulta, procure um ambiente iluminado e silencioso — você pode arrumar o cabelo e passar perfume se for uma pessoa mais vaidosa 😏;
        </p>
        <p>
          <strong>Testando... 1,2,3!</strong> <br />
          E oh, a gente também pede para você testar o áudio e vídeo do seu aparelho, assim garantimos um atendimento com mais qualidade!
        </p>
      </S.Info>
    </S.Content>
  )
}

export default ContentInfo