import React from 'react'
import * as S from './styles'

type PropsMessage = {
  background: string
  emissor: string
  msg: string
  date: string
  color: string
}
const Message = ({background, emissor, msg, date, color="#fff"}: PropsMessage) => {
  return (
    <S.Container >
      <S.Emissor>{emissor}</S.Emissor>
      <S.MessageContainer style={{backgroundColor: background}}>
        <S.Message style={{color: color}}>{msg}</S.Message>
      </S.MessageContainer>
      <S.Data>{date}</S.Data>
    </S.Container>
  )
}

export default Message