import styled from "styled-components";

export const Header = styled.div`
  max-width: 366px ;
  display: flex;
  flex-direction: column ;
  gap: 24px;
`
export const Title = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: #2B45D4;
`

export const Info = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
`

export const Wrapper = styled.div`
  height: 600px ;
  display: flex;
  flex-direction: column ;
  gap: 24px;

`
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100% ;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column ;
  align-self: center ;
  justify-content: flex-end ;
  height: 100% ;
`

export const CardsContainer = styled.div`
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
`

export const NumberOfExams = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
  background: rgba(43, 69, 212, 0.04);
  margin-top: 8px;
  border-radius: 8px;
  padding: 8px;
  max-width: 366px;
`

//modal
export const ContainerInsideModal = styled.div`
  display: flex ;
  flex-direction: column ;
  gap: 24px;
`

export const TextP = styled.p``
export const ContainerButtons = styled.div`
  display: flex;
  gap: 8px;
`

export const TextInsideButton = styled.p`
  white-space: nowrap;
  color: #fff !important;
  line-height: 1rem;
  font-weight: 600 !important;
  color: rgba(0, 0, 0, 0.56);
`
