import React from 'react'
import * as S from './styles'

// Icones
import {ReactComponent as IconClose} from '../../../assets/ic-close.svg';
import { ReactComponent as IconBack } from "../../../assets/backIcon.svg";
// React Router Dom
import { useNavigate, useParams } from 'react-router-dom'
// Componentes
import ButtonBack from '../../../components/ButtonBack';
import { Button} from 'storybook-maida'
import CardQueryDatails from '../../../components/CardQueryDetails/index';
import AddFile from '../ConsultScheduled/Scheduling/components/AddFile/index';
import Slider from 'react-slick';

import { calendarDate } from 'utils/calendarDate';
// Enpoints
import { CANCEL_APPOINTMENT, GET_DAY_FOR_SPECIALTYLIST, GET_HOUR_FOR_DAY_lIST } from '../../../api/Services/NextConsult';
// Hooks
import  useSessionlStorage from 'hooks/useSessionlStorage';
import Timetables from '../ConsultScheduled/Scheduling/components/Timetables';

// Contextos ===============
import { useConsultsContext } from 'contexts/ConsultsContext';
import { useHomeContext } from 'contexts/HomeContext';
// import { FIND_ATTENDANCE} from 'api/Services/Attendance';
import AlertBanner from 'components/AlertBanner/AlertBanner';

//Axios ===========
import {getHoursServer, putPatientOnline} from '../../../api/intancesAxios/telehealthURL'
import { getDifferenceHours } from 'utils/differenceHours';

//Mock ==============
import { DayOfWeek, month, mockedHoursStatic } from 'utils/Date/DayOfWeek';
import ContentInfo from './ContentInfo';
import ModalConsultDetails from './ModalConsultDetails';
import { CALL_ENPOINT } from 'api/Services/CallEndpoint';
import { ScheduleAppointmentProps } from 'api/types/Agendamento/ScheduleAppointment';

const TIME_DIFFERENCE_TO_START_ATTENDANCE = 10 // 10 minutos

const ConsultDetalhes = () => {
  const { id } = useParams();
  const [ dataConsult, setDataConsult ] = React.useState<any>()
  const {setCanceledConsult, alertSchedulingReturn, setAletSchedulingReturn, noticeTele } = useHomeContext()
  const {errorHook} = useConsultsContext()
  
   //Get day of week
   const d = new Date(dataConsult?.schedulingDate as string);
   let day = d.getDay();
 

  const [ active, setActive ] = React.useState<boolean>(false);
  const [ reagendar, setReagendar ] = React.useState<boolean>(false);


  const [calendar, setCalendar] = React.useState<any[]>([]);
  const [days, setDays] = React.useState(['']);
  const [hours, setHours] = React.useState<any>([[]]);
  const [hasHours, setHasHours] = React.useState<boolean>(true);

  let data: ScheduleAppointmentProps = {
    id: dataConsult?.id.toString(),
    profissional: '', 
    specialityTxt: '',
    specialtyId: dataConsult?.specialty.id.toString(),
    schedulingType: 'Primeira Consulta',
    dateTxt: '',
    date: '',
    hour: '',
  };


  const [storage, setStorage] = useSessionlStorage('consulta', data);
  const { consultDay, consultTime, handleTime, handleDay, updateStorage, setUpdateStorage, reschedule, alertScheduling, setAlertScheduling , idAttendance} = useConsultsContext();

  // Arquivos adicionados no upload
  const [filenames, setFileNames] = React.useState<string[]>([])

  //Datas for patient online implementation 
  const [btnStartAttendace, setBtnStartAttendance] = React.useState(true)
  const [stayOnline, setStayOnline] = React.useState(false)
  const { setAttendanceData } = useConsultsContext();
  const [loading, setLoading] = React.useState<boolean>(false);

  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/home')
  }

  const dados = {
    date: "2022-03-10",
    dateTxt: "quinta-feira, 10 de março",
    hour: "15:30",
    schedulingType: "Primeira Consulta",
    specialityTxt: "Clínica Médica",
    specialtyId: 17
  }


   //Configuração para o slide de horarios da consulta
  const settingsHours = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  //Configuração para o slide de datas da consulta
  const settingsCalendar = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
  };



  function handleButtonDisabled() {
    if (consultDay !== null && consultTime !== null) {
      return false;
    } else {
      return true;
    }
  }

  async function findAttendacce(id: string | undefined) {
    let token = localStorage.getItem('telehealth@token')
    if(!token) return

    try {
      const { url, options } = CALL_ENPOINT('TELEHEALTH_URL',`attendance/${id}`,'GET',null, {
        Authorization: `Bearer ${token}`,
      });
      const response = await fetch(url, options);
      const json = await response.json();

      let minutes = -1

      if (response.ok) {
        setDataConsult(json);

        getHoursServer().then(r => {
          let dif = Math.round(getDifferenceHours(new Date(json.schedulingDate), new Date(r.currentDate)))
          minutes = dif
          // Verifica tempo para colocar paciente ONLINE
          //se a diferença for menor que o determinado, entao seta o paciente online
          // e por consequencia, o medico ira chamar .. e isso desabilita o btn,
          //pois o status do notice ira mudar
          if(minutes > 0 && minutes <= TIME_DIFFERENCE_TO_START_ATTENDANCE){
            if(stayOnline === false){
              putPatientOnline(id as string)
              .then(r => {
                setStayOnline(true)
              })
              .catch(e => console.log("erro no paciente online ", e))
            }
         
            // Se o PACIENTE FICA ONLINE, ENTAO TERA ALTERACAO NO STATUS DO BTN DE INCIAR CONSULTA
            if(json.status === "WAITING_PATIENT" || json.status === 'WAITING_INSURED' || json.status === "VIDEOCALL_IN_PROGRESS"){
              setBtnStartAttendance(false)
            }

          }
        
        })
        .catch(error => console.log("deu erro no horario ", error))

      }
    } catch (err) {
      console.log(err);
    }
  }

  //Faz a montagem do calendario partindo da data de acesso
  React.useEffect(() => {
    const setCalendarDate = () => {
      const today = new Date();
      let fullCalendar: any = calendarDate(today);
      let fullCalendarFormaded = fullCalendar.map((d: any) => {
        const day = d.day.split('/')[0];
        return { ...d, day: day, dayText: d.day, selected: false };
      });

      fullCalendarFormaded = fullCalendarFormaded.map((date: any) => {
        if (date.fullday === dados.date) {
          date.selected = true;
          loadData(fullCalendarFormaded)
        }
        return date;
      });
      setCalendar(fullCalendarFormaded);
    };
    
    setCalendarDate();
    
  }, []);

   //Faz o request das especialidades ao renderizar a pagina
   React.useEffect(() => {
    const getDayforSpecialtyList = async () => {
      const { options, url } = GET_DAY_FOR_SPECIALTYLIST(17, 'Primeira Consulta');
      const response = await fetch(url, options);
      const json = await response.json();
      setDays(json);
    };
    getDayforSpecialtyList();
    
  }, []);

  React.useEffect(() => {
    if (calendar.length) {
      hasDateAvailable();
    }
  }, [days]);

  React.useEffect(()=>{
    const handleConfirmDate = async () => {
      let updateStorage;

      const chosenDate = calendar.filter((data) => data.selected === true);
      chosenDate.forEach((date: any) => {
        updateStorage = {
          ...storage,
          date: date.fullday,
          dateTxt: formatDayWeek(date.dayText),
          hour: consultTime,
        };
      });

      await setStorage(updateStorage);

      // navigate('confirmar');
    };

    if(updateStorage) handleConfirmDate()
    setUpdateStorage(false)
  },[updateStorage])


  

  const hasDateAvailable = () => {
    const calendarWithDaysActive = calendar.map((day) => {
      if (days.includes(day.dayText)) {
        return { ...day, active: true };
      }
      return day;
    });

    setCalendar(calendarWithDaysActive);
  };


//Incia a pagina com os dados do sessionStorage
  const loadData = (date:any)=> {
    const fullData = dados?.date
    const filterDate = date.filter((data:any)=> data.fullday === fullData)
    if(dados.hour){
      getHourForDayList(filterDate[0])
      setHasHours(true);
    }
  }

  //Verifica se existe horarios para o dia acessado
  const handleDays = (day: any, index: number) => {
    markDateAsSelected(index);
    const hasDay = days.includes(day.dayText);
    handleTime(null);
    handleDay(day);
    if (hasDay) {
      getHourForDayList(day);
      setHasHours(true);
    } else {
      setHasHours(false);
    }
  };
  //Faz o request dos horarios disponiveis
  const getHourForDayList = async (day: any) => {
    const { url, options } = GET_HOUR_FOR_DAY_lIST(
      dados.specialtyId,
      storage.schedulingType,
      day.fullday,
    );
    const response = await fetch(url, options);
    const json = await response.json();
    const newMockedHours = separatesIntoGroups(json, 9);
    setHours(newMockedHours);
  };

  //Verifica data selecionada para adicieonar estilo
  const markDateAsSelected = (index: number) => {
    const selectedCalendar = calendar.map((data, i) => {
      data.selected = false;
      if (i === index) data.selected = true;
      return data;
    });
    setCalendar(selectedCalendar);
  };

  //Função para serparar um array em grupos de array
  function separatesIntoGroups(base: any, max: any) {
    const novoArray = [];
    for (var i = 0; i < base.length; i = i + max) {
      novoArray.push(base.slice(i, i + max));
    }
    return novoArray;
  }

  //Retorna o calendario formatado
  //Necessario para o funcionamento do Slider
  const formatCalendar = () => {
    return calendar.map((day: any, index: number) => {
      return (
        <S.CalendarItem
          key={index}
          onClick={() => handleDays(day, index)}
          className={`${day?.selected ? 'selected' : ''} ${
            !day.active ? 'activo' : ''
          }`}
        >
          <S.Name>{day.dayWeek}</S.Name> <S.Day>{day.day}</S.Day>
        </S.CalendarItem>
      );
    });
  };

  const formatDayWeek = (str: string) => {
    let partes = str.split('/').map(Number);
    let data = new Date(partes[2], partes[1] - 1, partes[0]);
    return data.toLocaleString('pt-br', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    });
  };

  const handleConfirmDate = async () => {
    let updateStorage;
    const chosenDate = calendar.filter((data) => data.selected === true);
    chosenDate.forEach((date: any) => {
      updateStorage = {
        ...dados,
        date: date.fullday,
        dateTxt: formatDayWeek(date.dayText),
        hour: consultTime,
      };
    });
    await setStorage(updateStorage);

    navigate('confirmar');
  };

  type Obj = {
    specialtyId: string, date:string, schedulingType: string, hour: string
  }

  //Confirmar consulta
  const handleConfirmReSchedule = () => {
    const {hour, date, schedulingType } = storage; 

    const obj: Obj = {
      specialtyId: dataConsult.specialty.id,
      hour, 
      date,
      schedulingType
    }
    if(dataConsult){
      reschedule(obj, dataConsult.id.toString())
    }
  }

  //cancelar consulta
  const cancelConsult = async () => {
    if(!dataConsult) return
    dataConsult.id.toString()

    const { options, url } =  CANCEL_APPOINTMENT(dataConsult.id.toString())
   
    try {
      const response = await fetch(url, options)
      
      if (response.ok) {
        setActive(false)
        setCanceledConsult(true)
        navigate('/')
      }
    } catch(err) {
      console.log(err)
    }
  }

  // Call differenceHours every 10s
  React.useEffect(() => {

    let idTime: NodeJS.Timeout 
    findAttendacce(id)
    setReagendar(false)
    
    // Alert 
    setTimeout(()=>{
      setAlertScheduling(false) 
    },5000) 

    // Verificar notices e posicao a cada 10s
    idTime = setInterval(() => {
        findAttendacce(id)
     }, 10000);

    return () => {
      clearInterval(idTime);
      setAletSchedulingReturn(false)
    }

  }, [idAttendance]);

  // Entra na sala de atendimento
  const enterTheRoom = async () => {
    try {
      setLoading(true);
      // const { url, options } = START_ROOM(noticeTele?.metadata?.id);
      const { url, options } = CALL_ENPOINT('TELEHEALTH_URL',`attendance/${noticeTele?.metadata?.id}/start`,'POST',null,{
        Authorization: `Bearer ${localStorage.getItem('telehealth@token')}`,
      });
      const response = await fetch(url, options);
      const data = await response.json();
      setAttendanceData(data);
      navigate('/conferencia');
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <S.Wrapper>
      
      <div className="content">
        <div className='main'>
              
          {/* ErrorAlert */}
          {errorHook && 
          <S.ContainerAlert>
            <AlertBanner type='danger' label='Erro no envio de um ou mais arquivos' seconds={3}/>
          </S.ContainerAlert>
          }

          {/* alert de envio de arquivos */}
          {(filenames.length !== 0) && 
          <S.ContainerAlert>
            <AlertBanner type='success' label='Seus arquivos foram compartilhados' seconds={3}/>
          </S.ContainerAlert>
          }
          {/* alert de consulta de retorno agendada com sucesso */}
          {(alertSchedulingReturn || alertScheduling) &&
            <S.ContainerAlert
            >
              <AlertBanner 
                type='success' 
                label={'Sua consulta de retorno foi agendada!'} 
                seconds={5}
              />
            </S.ContainerAlert>
          }
                     
          <header>
            <ButtonBack onClick={handleClick} icon={<IconBack />} to={'/home'}>Voltar</ButtonBack>
          </header>
         
          
          { dataConsult &&
          <CardQueryDatails
            // 'Quarta-feira, 15 de janeiro'
            date = {`${DayOfWeek[day]}, ${d.getDate()} de ${month[d.getMonth()]}`}
            hour = {dataConsult.prettySchedulingDate}
            doctor = {dataConsult?.professional.name}
            specialty = {dataConsult?.specialty.name}
           >
            {/* Nao clicou em Reagendar e o Atendimento tem status: "Agendado" */}
            { (!reagendar && dataConsult.status === "SCHEDULED") &&
              <div>
                {/* Dois botoes: REAGENDAR - CANCELAR */}
                <Button children="Reagendar consulta" onClick={()=> setReagendar(true)}/>
                <button
                  onClick={()=> setActive(true)}
                  style={{padding: '0', margin:'0 0 12px 0', background: 'none', color:'#F44336'}}
                >Cancelar consulta</button>
              </div>
}
              {/*
                Botão de iniciar consulta
                hr server - hr do atendimento <= 10 min => PACIENTE ONLINE
                desabilita o btn - notice !== scheduled or CANCELED
                */}
              {(dataConsult.status === "WAITING_PATIENT" || dataConsult.status === 'WAITING_INSURED' || dataConsult.status === "VIDEOCALL_IN_PROGRESS")  && <div>
                <Button children="Iniciar consulta" disabled={btnStartAttendace} onClick={()=> {
                  enterTheRoom() 
                }}/>
              </div> 
            } 
          </CardQueryDatails>
          }
          
          {reagendar &&
            <div className='contentReagendamento'>
              <header>
                <p className='title'>Primeiro, escolha o dia</p>
                <IconClose onClick={()=> setReagendar(false)} />
              </header>
            
              <S.ContentCardExame>
                <Slider {...settingsCalendar}>{formatCalendar()}</Slider>
              </S.ContentCardExame>

              <p className='title' style={{marginBottom: '24px'}}>Agora, escolha o horário</p>
              <div style={{margin: '0 0 3rem 0'}}>
                <Slider {...settingsHours}>
                  {hasHours
                  ? hours.map((hours: string[], index: number) => {
                      return <Timetables array={hours} key={index} />;
                    })
                  : mockedHoursStatic.map((hours: string[], index: number) => {
                      return (
                        <S.WrapperNextStep
                          key={index}
                          isDisabled={!!hours.length ? true : false}
                        >
                          <Timetables array={hours} />
                        </S.WrapperNextStep>
                      );
                    })}
                </Slider>
              </div>

              <Button 
                disabled={handleButtonDisabled()}
                onClick={handleConfirmReSchedule}
                children={'Confirmar reagendamento'}
              />
            </div>

          }
          {/* Screen to add files  */}
          <AddFile setFileNames={setFileNames} filenames={filenames}/>

          <div className='help'>
            <p>Não encontrou o que procurava?</p>
            <a href="#">Como funciona a consulta</a>
            <a href="#">Regras de cancelamento</a>
            <a href="#">Ir para ajuda</a>
          </div>
        </div>
      </div>

      {/* Lado direito */}
      <ContentInfo/>
      {/* Fim lado direito ============ */}

      {/* conteúdo apresentado apenas no mobile */}
      <div className='help-mobile'>
        <p>Não encontrou o que procurava?</p>
        <a href="#">Como funciona a consulta</a>
        <a href="#">Regras de cancelamento</a>
        <a href="#">Ir para ajuda</a>
      </div>

      {/* modal de cancelamento */}
      <ModalConsultDetails active={active} setActive={setActive} cancelConsult={cancelConsult} />
    </S.Wrapper>
  )
}

export default ConsultDetalhes