import styled from 'styled-components'

export const Wrapper = styled.div`

  #overlayCard {
    position: fixed;
    /* opacity: 0; */

    /* fill the screen */
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    
    /* color  */
    background-color:  transparent;
    /* remove click events */
    /* pointer-events: none; */

    /* Show the overlay and enable click */
    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }

  .bg-color{
    background-color: rgba(255, 176, 0, 0.16) !important;
  }
  .contentInfo {
    position: relative;
    border-radius: 8px;
    max-width: 295px;
    height: 188px;
    background: #fff;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap:0;

    /* svg, img { 
      position: absolute;
      top: 20px;
      right: 25px;
      cursor: pointer;
    } */

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
    }

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.56);
    }

    > a {
      position: absolute;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #FF0073;
      bottom: 1rem;
      right: 27px;
    }
  }


  @media(max-width: 780px) {
   .contentInfo {
      max-width: 100%;
   }
  }

  @media(max-width:570px) {
    .contentInfo {
      width: 100%;
    }
  }
`

export const Icon = styled.div`
  position: absolute;
  top: 20px;
  right: 25px;
  cursor: pointer;
`

export const CardSelect = styled.div`
  min-width: 240px;
  position: absolute;
  top: 50px;
  right: 16px;
  z-index: 1001;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  ul {
    list-style: none;
    
    li:first-child {
      border-radius: 8px 8px 0 0; 
      
    }

    li:last-child {
      border-radius: 0 0 8px 8px; 
      
    }

    li {
      width: 100%;
      padding: 12px 16px;
      

      &:hover {
        color: #fff;
        background: #2B45D4;

        a {
          color: #fff;
        }
      }
      a {
        width: 100%;
        color: rgba(0, 0, 0, 0.88);
        text-decoration: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`

export const ContentTextModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 24px;

  div {
    margin-bottom: 8px ;
  }

  p {
    outline: none;
    background: rgba(255, 176, 0, 0.16);
    color: rgba(0, 0, 0, 0.4);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  span {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.56);

  }
`

export const ContentBtn = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 24px;
`