import IconUser from './icons/IconUser'
import Prescricao from './icons/Prescricao'
import IconExame from './icons/IconExame'
import IconConsult from './icons/IconConsult'
import IconPedidos from './icons/IconPedidos'
import IconAjuda from './icons/IconAjuda'
import IconTermos from './icons/IconTermos'
import IconMessage from './icons/IconMessage'


export const SidebarData = [
  {
    title: 'Meu perfil',
    path: '/profile',
    icon: <IconUser />,
    cName: 'nav-item'
  },

  {
    title: 'Prescrições',
    path: '/prescricoes',
    icon: <Prescricao />,
    cName: 'nav-item'
  },

  {
    title: 'Exames',
    path: '/exames',
    icon: <IconExame />,
    cName: 'nav-item'
  },

  {
    title: 'Histórico de consultas',
    path: '/historico',
    icon: <IconConsult />,
    cName: 'nav-item'
  },
  {
    title: 'Mensagens',
    path: '/mensagens',
    icon: <IconMessage />,
    cName: 'nav-item'
  },

  {
    title: 'Pedidos',
    path: '/',
    icon: <IconPedidos />,
    cName: 'nav-item'
  },

  {
    title: 'Ajuda',
    path: '/faq',
    icon: <IconAjuda />,
    cName: 'nav-item'
  },

  {
    title: 'Termos de Uso e Política de Privacidade',
    path: '/',
    icon: <IconTermos />,
    cName: 'nav-item'
  },

]