import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 640px) {
    padding: 1rem;
  }
`

export const Wrapper = styled.section`
  max-width: 608px;
  width: 100%;
  text-align: center;
`

export const Confirm = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h1`
  font-size: 24px;
  line-height: 40px;
  font-weight: 600;
  color: #2B45D4;
  margin-top: 24px;
`

export const Message = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: var(--life-neutral-black-56);
  margin-top: 24px;
  margin-bottom: 43px;
`