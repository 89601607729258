import * as S from './styles';
import { InputHTMLAttributes } from 'react';

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  id?: string;
  groupName?: string | any;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checkedColor?: string;
  boderColor?: string;
}

const Radio = ({
  label,
  groupName,
  value,
  onChange,
  checkedColor,
  boderColor,
  ...rest
}: RadioProps) => {
  return (
    <S.Wrapper $boderColor={boderColor} $checkdedColor={checkedColor}>
      <input
        id={label}
        type="radio"
        name={groupName}
        value={value}
        onChange={onChange}
        {...rest}
      />

      <label htmlFor={label}>{label}</label>
    </S.Wrapper>
  );
};

export default Radio;
