import * as S from '../styles'
import React from 'react'
import InputLife from 'components/InputLife';
import {ReactComponent as IconHelp} from '../../../../assets/mai-ic-help.mono.svg'
import SelectComponent from 'components/Select';
import SimpleRadio from '../../SimpleRadio';
import Button2 from 'components/Button/Button';
import { CALL_ENPOINT } from 'api/Services/CallEndpoint';
import { headerAppToken } from 'api/headers/HeaderAppToken';
import { UserData } from '..';
import Tupla from '../Tupla';
import Modal from 'components/Modal';
import { Disability, DisabilityObj, Sex, SexObject } from '../Details';
import { useNavigate } from 'react-router-dom';
import { useConsultsContext } from 'contexts/ConsultsContext';
import AlertBanner from 'components/AlertBanner/AlertBanner';

type Props = {
  healthData: UserData
  setAlertUpdateHealthData: React.Dispatch<React.SetStateAction<boolean>>
  setEditar: React.Dispatch<React.SetStateAction<boolean>>
  getHealthData: () => void
}

type DisabilityTypes = 'Auditiva' | 'Deficiência múltipla' | 'Física' | 'Intelectual' | 'Visual' | 'Outra'
export let DisabilityObjFinal = {
  'Auditiva': 'AUDITIVA',
  'Deficiência múltipla': 'MULTIPLA',
  'Física' :'FISICA' ,
  'Intelectual':'INTELECTUAL',
  'Visual':'VISUAL',
  'Outra':'OUTRA'
}

const  HealthDataEdit = ({healthData, getHealthData, setEditar, setAlertUpdateHealthData}: Props) => {
  
  const [selectedSexo, setSelectedSexo] = React.useState(SexObject[healthData.sexo as Sex]);
  const sexo = [{label: 'Feminino'},{label: 'Masculino'},{label: 'Intersexo'}, {label: 'Prefiro não declarar'}]
  const { alertOnAction, setAlertOnAction} = useConsultsContext()
  // Modal ===
  const [modalGenderIdentify, setModalGenderIdetify] = React.useState(false)
  const [modalInterSexo, setModalInterSexo] = React.useState(false)
  // =============================


  const [hasDeficiencia, setHasDeficiencia] = React.useState<string | null>(healthData.deficiencia ? 'Sim' : 'Nao')
  const [hasAllergy, setHasAllergy] = React.useState<string | null>(healthData.alergia ? 'Sim' : 'Nao')
  const [hasContinuousMedication, setHasContinuousMedication] = React.useState<string | null>(healthData.medicacao ? 'Sim' : 'Nao')
  const [hasPreExistingDisease, setHasPreExistingDisease] = React.useState<string | null>(healthData.doenca_pre ? 'Sim' : 'Nao')
  const [anotherSex, setAnotherSex] = React.useState<string>('')


  const [selectedDeficiencia, setSelectedDeficiencia] = React.useState(DisabilityObj[healthData.deficiencia as Disability]);
  const deficiencias = [{label: 'Auditiva'},{label: 'Deficiência múltipla'}
  ,{label: 'Física'}
  ,{label: 'Intelectual'}
  ,{label: 'Visual'}
  ,{label: 'Outra '}
]

// 


  const [finalObject, setFinalObject] = React.useState<UserData>({
    sexo: healthData.sexo,
    altura: healthData.altura, 
    peso: healthData.peso,
    deficiencia: healthData.deficiencia ? 'Sim' : 'Nao',
    alergia: healthData.alergia,
    genero: healthData.genero,
    medicacao: healthData.medicacao,
    doenca_pre: healthData.doenca_pre 
  })


  const handleSubmit = () => {
    if(selectedSexo === 'Prefiro não declarar'){
      finalObject.sexo = null
    }else{
      if(anotherSex.length > 0){
        finalObject.sexo = anotherSex.toUpperCase()
      }
      if(selectedSexo){
        finalObject.sexo = selectedSexo.toUpperCase()
      }
    }

    if(hasDeficiencia !== 'Sim'){
      finalObject.deficiencia = null
    }else{
      if(selectedDeficiencia){
        finalObject.deficiencia = DisabilityObjFinal[selectedDeficiencia as DisabilityTypes]
      }
    }
    
    updateHealthData()
  }

  const updateHealthData = async() => {
    try {
      setEditar(false)
      const { url, options } = CALL_ENPOINT('ONBOARDING_URL','api/clientes/saude', 'PUT',finalObject, headerAppToken(localStorage.getItem('lifeplace@token') as string))
      
      const response = await fetch(url, options)
      const json = await response.json()

      if(!response.ok){
        setAlertOnAction({label: json.mensagem, onEndTime: () => setAlertOnAction(null), seconds: 3, type: 'warning'})
        return 
      }
      setAlertOnAction({label: 'Dados da Saúde atualizados!', onEndTime: () => setAlertOnAction(null), seconds: 3, type: 'success'})
      getHealthData()
      
    } catch(err) {
     console.log(err)
    }
  }


  return (
    <>

      <S.Container style={{gap: '87px'}}>
        <S.Left>
          {/* Sexo designado */}
          <S.Tupla>
            <S.Text style={{marginBottom: '8px'}}>
              Sexo designado no nascimento <S.Icon onClick={() => setModalInterSexo(true)}><IconHelp/></S.Icon>
            </S.Text>
            <SelectComponent placeholder="Selecione uma opção"  selected={selectedSexo} setSelected={setSelectedSexo} data={sexo}/>
          </S.Tupla>
          {/* Identidade genero */}
          <S.Tupla>
            <S.Text>
              Identidade de gênero  <S.Icon onClick={() => setModalGenderIdetify(true)}><IconHelp/></S.Icon>
            </S.Text>
            <S.ContainerRadios style={{marginTop: '8px', flexDirection: 'column', justifyContent: 'normal'}}>
              <SimpleRadio  checked={finalObject.genero === 'CISGENERO'}   onChange={e => setFinalObject({...finalObject, genero: e.target.value})} label='Cisgênero' id='cisgenero' groupName={"identidade-genero"} value='CISGENERO'/>
              <SimpleRadio  checked={finalObject.genero === 'TRANSGENERO'} onChange={e => setFinalObject({...finalObject, genero: e.target.value})} label='Transgênero' id='transgenero' groupName={"identidade-genero"} value='TRANSGENERO'/>
              <SimpleRadio  checked={finalObject.genero === 'NAOBINARIO'} onChange={e => setFinalObject({...finalObject, genero: e.target.value})} label='Não binário' id='naobinario' groupName={"identidade-genero"} value='NAOBINARIO'/>
              <SimpleRadio  checked={finalObject.genero === 'PREFIRONAODECLARAR' || finalObject.genero === null} onChange={e => setFinalObject({...finalObject, genero: null})} label='Não declarar' id='naodeclarar' groupName={"identidade-genero"} value='PREFIRONAODECLARAR'/>
              <SimpleRadio  checked={finalObject.genero === 'OUTRO'} onChange={e => setFinalObject({...finalObject, genero: e.target.value})} label='Outra' id='genero-outro' groupName={"identidade-genero"} value='OUTRO'/>
            </S.ContainerRadios>

            { finalObject.genero === 'OUTRO' && <InputLife placeholder='Digite sua identidade de gênero'
              borderColor={'#ddd'}
              type='text'
              value={anotherSex}
              borderColorOnFocus={'#ddd'}
              noLabel = {true}
              style={{marginTop: '8px'}}
              onChange={e => setAnotherSex(e.target.value)}
                />}
            
          </S.Tupla>
          {/* Altura */}
          <Tupla question='Altura'>
            <InputLife placeholder='Digite apenas os números da sua altura'
              borderColor={'#ddd'}
              type='number'
              value={finalObject.altura}
              borderColorOnFocus={'#ddd'}
              noLabel = {true}
              style={{marginTop: '8px'}}
              onChange={e => setFinalObject({...finalObject, altura: e.target.value})}
                />
          </Tupla>
         
          {/* Peso */}
          <Tupla question='Peso'>
            <InputLife placeholder='Digite apenas os números do seu peso'
              borderColor={'#ddd'}
              type='number'
              value={finalObject.peso}
              style={{marginTop: '8px'}}
              borderColorOnFocus={'#ddd'}
              noLabel = {true}
              onChange={e => setFinalObject({...finalObject, peso: e.target.value})}
                />
          </Tupla>
        </S.Left>

        {/* lado Direito ======================== */}
        <S.Right>

          <Tupla question='Você usa medicação contínua?'>
            <S.ContainerRadios>
              <SimpleRadio label='Sim' id='medicacaoContinua-sim' groupName={"medicacaoContinua"}
                checked={hasContinuousMedication === 'Sim'}
                onChange={e => setHasContinuousMedication(e.target.value)}
                value='Sim'/>
              <SimpleRadio label='Não'groupName={"medicacaoContinua"}
                checked={hasContinuousMedication === 'Nao' || hasContinuousMedication === null }
                onChange={e => {
                  setHasContinuousMedication(null)
                  setFinalObject({...finalObject, medicacao: null})
                }
                }
                value='Nao' id='medicacaoContinua-nao'/>
            </S.ContainerRadios>
            { hasContinuousMedication === 'Sim' &&  <InputLife placeholder='Digite o nome da medicação'
              borderColor={'#ddd'}
              type='text'
              value={finalObject.medicacao}
              style={{marginTop: '8px'}}
              borderColorOnFocus={'#ddd'}
              noLabel = {true}
              onChange={e => setFinalObject({...finalObject, medicacao: e.target.value})}
                />}
          </Tupla>
          <Tupla question='Você tem alguma deficiência?'>
            <S.ContainerRadios style={{display: 'flex',flexDirection: 'column', justifyContent: 'normal'}}>
              <S.ContainerRadios>
                {/* Seleciona que tem deficiencia */}
                <SimpleRadio label='Sim' groupName={"deficiencia"}
                  value={'Sim'}
                  id='deficiencia-sim'
                  checked={hasDeficiencia === 'Sim'}
                  onChange={e => setHasDeficiencia(e.target.value)}
                  />
                <SimpleRadio label='Não'
                  checked={hasDeficiencia === 'Nao' || hasDeficiencia === null }
                  onChange={e => setHasDeficiencia(null)}
                  id='deficiencia-nao' groupName={"deficiencia"} value='Nao'/>
              </S.ContainerRadios>
              { hasDeficiencia === 'Sim' && <SelectComponent placeholder="Selecione uma opção"  selected={selectedDeficiencia} setSelected={setSelectedDeficiencia} data={deficiencias}/>}
            </S.ContainerRadios>
          </Tupla>
          <Tupla question='Tem alguma doença preexistente?'>
            <S.ContainerRadios>
              <SimpleRadio label='Sim' groupName={"doenca-preexistente"}
                checked={hasPreExistingDisease === 'Sim'}
                id={"doenca-preexistente-sim"}
                onChange={e => {
                  setHasPreExistingDisease(e.target.value)
                }}
                value='Sim'/>
              <SimpleRadio 
                id={"doenca-preexistente-nao"}
                checked={hasPreExistingDisease === 'Nao' || hasPreExistingDisease === null }
                onChange={e =>{
                   setHasPreExistingDisease(null)
                   setFinalObject({...finalObject, doenca_pre: null})
                  }}
                label='Não' groupName={"doenca-preexistente"}
                value='Nao'/>
            </S.ContainerRadios>
            { hasPreExistingDisease === 'Sim' &&  <InputLife placeholder='Digite o nome da doença'
              borderColor={'#ddd'}
              type='text'
              value={finalObject.doenca_pre}
              style={{marginTop: '8px'}}
              borderColorOnFocus={'#ddd'}
              noLabel = {true}
              onChange={e => setFinalObject({...finalObject, doenca_pre: e.target.value})}
                />}
          </Tupla>
          {/* Alergia */}
          <Tupla question='Você tem alguma Alergia?'>
            <S.ContainerRadios>
              <SimpleRadio label='Sim' id='temalergia-sim' groupName={"alergia"}
                checked={hasAllergy === 'Sim'}
                onChange={e => setHasAllergy(e.target.value)}
                value='Sim'/>
              <SimpleRadio label='Não'
                checked={hasAllergy === 'Nao' || hasAllergy === null }
                onChange={e => {
                  setHasAllergy(null)
                  setFinalObject({...finalObject, alergia: null})
                }}
                id="temalergia-nao" groupName={"alergia"}
                value='Nao'/>
            </S.ContainerRadios>
            { hasAllergy === 'Sim' &&  <InputLife placeholder='Digite o nome da sua alergia'
              borderColor={'#ddd'}
              type='text'
              value={finalObject.alergia}
              style={{marginTop: '8px'}}
              borderColorOnFocus={'#ddd'}
              noLabel = {true}
              onChange={e => setFinalObject({...finalObject, alergia: e.target.value})}
                />}

          </Tupla>
        </S.Right>
      </S.Container>
      <S.ContainerRadios style={{justifyContent: 'flex-end'}}>
        <Button2 variant='primary' onClick={handleSubmit}>
          Salvar
        </Button2>
      </S.ContainerRadios>

      {/* Modal de Identidade de Gênero */}
      <Modal containerStyle={{padding: '16px'}} isOpen={modalGenderIdentify} title='Tire suas dúvidas sobre identidade de gênero' onClose={() => setModalGenderIdetify(!modalGenderIdentify)}>
        <S.Flex style={{marginTop: '24px', flexDirection: 'column', gap: '24px'}}>
          <S.TextIdentify>
            <b>Cisgênero</b> é quem se identifica com o sexo que lhe foi designado no nascimento. 
          </S.TextIdentify>
          <S.TextIdentify>
            <b>Transgênero</b> é quem se identifica com o sexo oposto ao que lhe foi designado no nascimento.  
          </S.TextIdentify>
          <S.TextIdentify>
            <b>Não-binária</b> é a pessoa que não se identifica completamente com o sexo que lhe foi designado no nascimento, nem com o oposto. 
          </S.TextIdentify>
        </S.Flex>
        <Button2 onClick={() => setModalGenderIdetify(false)} style={{width: '100%', marginTop: '24px'}} variant='primary'>Ok, entendi</Button2>
      </Modal>

      {/* Modal Intersexualidade */}
      <Modal containerStyle={{padding: '16px'}} isOpen={modalInterSexo} title='Entenda o que é intersexualidade' onClose={() => setModalInterSexo(!modalInterSexo)}>
        <S.Flex style={{marginTop: '24px', flexDirection: 'column', gap: '24px'}}>
          <S.TextIdentify>
            <b>Pessoas intersexo</b> nascem com características sexuais físicas — como anatomia sexual, órgãos reprodutivos, padrões hormonais e/ou padrões cromossômicos — que não se enquadram nas definições típicas e binária de corpos masculinos ou femininos. 
          </S.TextIdentify>
        </S.Flex>
        <Button2 onClick={() => setModalInterSexo(false)} style={{width: '100%', marginTop: '24px'}} variant='primary'>Ok, entendi</Button2>
      </Modal>


    </>
  )
}

export default HealthDataEdit