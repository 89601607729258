import styled from "styled-components";

export const Wrapper = styled.section`
  width: 100%;
  
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media(max-width: 1307px) {
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 410px) {
    margin: 24px auto 0 auto;
  }
`;

export const VideoWrapper = styled.section`
  display: flex;
  font-family: Open Sans, sans-serif;
  width: 100%;

  @media(max-width: 1307px) {
    
    justify-content: center;
  }
`;

export const InfoWrapper = styled.section`
    text-align: center;
    max-width: 398px;
    width: 100%;
    
  p {
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.88);
    margin-bottom: 24px;
  }

  span {
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.56);
  }

  .btnEntrar{
    margin-top:24px;
    margin-bottom: 16px;
  }
  
  @media(max-width: 1307px) {
    margin-top: 1rem;
  }
`;

export const ContainerBtns = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px; 
  justify-content: end;
`