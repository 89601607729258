import styled from 'styled-components'

type PropsOverlay = {
  overlayColor?: string
}

export const Content = styled.div<PropsOverlay>`
/* create overlay */
  #overlay {
    position: fixed;
    /* opacity: 0; */

    /* fill the screen */
    z-index: 1000;
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    
    /* color  */
    background-color: ${(props) => props.overlayColor ? props.overlayColor : 'rgba(0,0,0,.5)'};
    /* remove click events */
    /* pointer-events: none; */

    /* Show the overlay and enable click */
    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
`

export const Wrapper = styled.div`
  position: fixed;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  padding: 24px;
  transform: translate(-50%,-50%) scale(0);
  border-radius: 8px;
  z-index: 99999;
  background: #FFF;
  transition: 200ms ease-in-out; 
  min-width: 328px;
  width: 366px;

  &.active {
    transform: translate(-50%,-50%) scale(1);
  }
  
  @media(max-width: 520px) {
    width: 90%;
  }

  &.scroll {
    overflow-y: scroll ;
    padding-right: 8px;
    &::-webkit-scrollbar {
      width: 6px;
      background-color: rgba(0, 0, 0, 0.15);
    }

    &::-webkit-scrollbar-thumb {
      background: #2b45d4;
      border-radius: 8px;
    }
  }

`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 40px;
    color: rgba(0, 0, 0, 0.88);
  }
`

export const Body = styled.div`
  /* p {
    text-align: left;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.64);
  } */
  
`